import { createSelector } from '@ngrx/store';
import { Point } from '../../models/point.model';
import { getGazetteerState } from '../reducers';
import * as fromGazetter from '../reducers/gazetteer.reducer';

export const getSelectedPoint = createSelector(
  getGazetteerState,
  fromGazetter.getSelectedPoint
);

export const getIsSelectedPointEmpty = createSelector(
  getSelectedPoint,
  isSelectedPointEmpty
);

function isSelectedPointEmpty(selectedPoint: {
  location: Point | null;
  address: string;
}): boolean {
  return !selectedPoint?.location || selectedPoint.address === '';
}
