import { Component, Input } from '@angular/core';
import { LocatorStatusError } from 'src/app/locator/models/locator-import-status-error';

@Component({
  selector: 'atlas-steps-dialog-errors',
  templateUrl: './steps-dialog-errors.component.html',
  styleUrls: ['./steps-dialog-errors.component.less']
})
export class StepsDialogErrorsComponent {
  @Input() dialogStatusError: LocatorStatusError;

  constructor() {}
}
