import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/spatial-modeller-store';
import { initialiseAppAttempt } from '../core/store/actions';
import * as fromAppFeatureStore from 'src/app/core/store';
import { LoadingOverlayService } from '../core/services/loading-overlay.service';
import * as fromUIStore from 'src/app/core/store';
import { LayoutService } from '../shared/atlas-layout/services/layout.service';

@Component({
  selector: 'atlas-home-workspace',
  templateUrl: './home-workspace.component.html',
  styleUrls: ['./home-workspace.component.less']
})
export class HomeWorkspaceComponent implements OnInit {
  isAnyFeatureAllowed$ = this.store$.select(
    fromAppFeatureStore.isAnyFeatureAllowed
  );
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  constructor(
    private smStore$: Store<fromStore.State>,

    private store$: Store<fromAppFeatureStore.State>,
    public loadingOverlayService: LoadingOverlayService,
    private UIStore$: Store<fromUIStore.State>,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.smStore$.dispatch(initialiseAppAttempt());
  }
}
