import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Part } from '@aws-sdk/client-s3';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocatorImportService {
  private atlasLocatorImportUrl = `${environment.baseUrl}api/locator/import-library-data`;

  constructor(private http: HttpClient) {}

  public fileUploadStartedStatus(libraryId: number) {
    return this.http.put(
      `${this.atlasLocatorImportUrl}/file-upload-started/${libraryId}`,
      null
    );
  }

  public fileUploadCompleteStatus(libraryId: number) {
    return this.http.put(
      `${this.atlasLocatorImportUrl}/file-upload-complete/${libraryId}`,
      null
    );
  }

  public fileUploadFailedStatus(libraryId: number, errorMsg: string) {
    return this.http.put(`${this.atlasLocatorImportUrl}/file-upload-failed`, {
      libraryId: libraryId,
      errorMessage: errorMsg
    });
  }

  public fileUploadUpdateFileKey(libraryId: number, fileKey: string) {
    return this.http.put(
      `${this.atlasLocatorImportUrl}/update-filekey/${libraryId}/${fileKey}`,
      null
    );
  }

  public uploadMetaDataConfig(
    fileKey: string,
    libraryId: any,
    importUsingGeocoding: boolean
  ) {
    return this.http.post(`${this.atlasLocatorImportUrl}/upload-meta-data/`, {
      fileKey: fileKey,
      libraryId,
      importUsingGeocoding
    });
  }

  public getExportedPostcodeWithAcornUrl(filekey: string, libraryId: any) {
    return this.http.get<{ presignedUrl: any }>(
      `${this.atlasLocatorImportUrl}/exported-postcodes/presigned-url/${libraryId}/${filekey}`
    );
  }

  public deleteFilesFromS3(fileKey: string) {
    return this.http.delete(
      `${this.atlasLocatorImportUrl}/delete-filekey/${fileKey}`
    );
  }

  public initiateMultipartUpload(filename: string) {
    return this.http.get<{ uploadId: string }>(
      `${this.atlasLocatorImportUrl}/initiate-multipart-upload/${filename}`
    );
  }

  async getMultipartPresignedUrl(
    filename: string,
    uploadId: string,
    partNumber: number
  ) {
    const httpResponse$ = this.http.get<{ presignedUrl: string }>(
      `${this.atlasLocatorImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`
    );
    return await firstValueFrom(httpResponse$);
  }

  public completeMultipartUpload(
    libraryId: number,
    filename: string,
    uploadId: string,
    partsList: Part[]
  ) {
    return this.http.put(
      `${this.atlasLocatorImportUrl}/complete-multipart-upload/${libraryId}/${filename}/${uploadId}`,
      partsList.map(({ ETag, PartNumber }) => ({
        ETag: ETag,
        PartNumber: PartNumber
      }))
    );
  }

  public cancelMultipartUpload(filename: string, uploadId: string) {
    return this.http.delete(
      `${this.atlasLocatorImportUrl}/cancel-multipart-upload/${filename}/${uploadId}`
    );
  }

  public hasLocatorLibraryLocationsLimitBeenExceeded(libraryId: number) {
    return this.http.get<any>(
      `${this.atlasLocatorImportUrl}/${libraryId}/exceeded-library-limit`
    );
  }
}
