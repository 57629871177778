import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as fromUIStore from 'src/app/core/store';
import { allowWhenCondition } from '../utils/effect-utils';

@Injectable({
  providedIn: 'root'
})
export class AppFeatureStateService {
  constructor(private UIStore$: Store<fromUIStore.State>) {}

  allowWhenSmFeatureSelected() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.isSMFeatureSelected).pipe(
        map((smFeatureSelected) => smFeatureSelected)
      )
    );
  }

  allowWhenSmFeatureAllowed() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.isSMFeatureAllowed).pipe(
        map((smFeatureAllowed) => smFeatureAllowed)
      )
    );
  }

  allowWhenNetworkPlanningSystemIsNotReadOnly() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.getNetworkPlanningReadonlySystem).pipe(
        map((isReadOnlySystem) => !isReadOnlySystem)
      )
    );
  }

  allowWhenLocatorFeatureSelected() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.isLocatorFeatureSelected).pipe(
        map((locatorFeatureSelected) => locatorFeatureSelected)
      )
    );
  }

  allowWhenLocatorFeatureAllowed() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.isLocatorFeatureAllowed).pipe(
        map((locatorFeatureAllowed) => locatorFeatureAllowed)
      )
    );
  }
  allowWhenProfilerFeatureAllowed() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.isProfilerFeatureAllowed).pipe(
        map((profilerFeatureAllowed) => profilerFeatureAllowed)
      )
    );
  }

  allowWhenLocatorFeatureNotLoaded() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.getHasLocatorFeatureLoaded).pipe(
        map((hasLocatorFeatureLoaded) => !hasLocatorFeatureLoaded)
      )
    );
  }

  allowWhenProfilerFeatureNotLoaded() {
    return allowWhenCondition(
      this.UIStore$.select(fromUIStore.getHasProfilerFeatureLoaded).pipe(
        map((hasProfilerFeatureLoaded) => !hasProfilerFeatureLoaded)
      )
    );
  }
}
