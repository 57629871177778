import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { AtlasChipListComponent } from './atlas-chip-list.component';
@Component({
  selector: 'atlas-scenario-name-chip-list',
  templateUrl: 'atlas-chip-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AtlasScenarioNameChipListComponent
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'chip-list-autocomplete-overlay-pane'
      }
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AtlasScenarioNameChipListComponent)
    }
  ],
  styleUrls: ['./atlas-chip-list.component.less']
})
export class AtlasScenarioNameChipListComponent
  extends AtlasChipListComponent
  implements Validator
{
  onValidationChange: any = () => {};
  public propagateUserValueChange(newValue: string[]) {
    this.markAsTouched();
    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
      this.valueChanged.emit(newValue);
      this.onValidationChange();
    }
  }
  validate(control: AbstractControl): ValidationErrors | null {
    //  if value is not required and there are no values added => control is VALID
    if (!control.hasValidator(Validators.required) && !control.value) {
      return null;
    }
    if (!this.areAddedScenariosInAllScenarios(control.value)) {
      this.errorMessage = 'The added scenario is not included in the list';
      // console.log(this.errorMessage);
      return {
        areScenariosIncluded: { allIncluded: false }
      };
    }
    return null;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
  areAddedScenariosInAllScenarios(values: string[]): boolean {
    return values?.every((value) => {
      return this.allItems.includes(value);
    });
  }
}
