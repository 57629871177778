import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map } from 'rxjs';

import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';
import * as fromProfilerStore from 'src/app/profiler-store';
import * as fromLocatorStore from 'src/app/locator-store';

@Component({
  selector: 'atlas-properties-panel',
  templateUrl: './properties-panel.component.html',
  styleUrls: ['./properties-panel.component.less']
})
export class PropertiesPanelComponent {
  selectedPoint$ = this.gazetteerStore$.select(
    fromGazetteerStore.getSelectedPoint
  );
  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );
  isSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsSupplyPoint
  );
  isSupplyPointClosed$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsSupplyPointClosed
  );

  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  profilerStatus$ = this.profilerStore$.select(
    fromProfilerStore.getImportStatusMessage
  );

  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  selectedSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getSpatialModellerSupplyPoint
  );
  isPendingUnsavedSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getPendingUnsavedSupplyPoint
  );
  
  areMultipleLocationsSelected$ = this.locatorStore$.select(
    fromLocatorStore.areMultipleLocationsSelected
  );

  canShowPropertiesPanel$ = combineLatest([
    this.isSelectedPointEmpty$,
    this.areMultipleLocationsSelected$
  ]).pipe(
    map(
      ([isSelectedPointEmpty, areMultipleLocationsSelected]) =>
        !isSelectedPointEmpty || areMultipleLocationsSelected
    )
  );

  // Emits a value if the selected supply point changes (retrieved form our backend after an update or an insert),
  // It does not emit a value if the supply point changes while the user is modifying the Supply Point properties.
  originalSelectedSupplyPoint$ = combineLatest([
    this.isPendingUnsavedSupplyPoint$,
    this.selectedSupplyPoint$
  ]).pipe(
    filter(([isPendingUnsavedSupplyPoint, _]) => !isPendingUnsavedSupplyPoint),
    map(([_, selectedSupplyPoint]) => {
      return selectedSupplyPoint;
    })
  );

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private profilerStore$: Store<fromProfilerStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>
  ) {}
}
