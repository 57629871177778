import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromGazetteer from './gazetteer.reducer';

export interface State {
  gazetteer: fromGazetteer.State;
}

export const reducers: ActionReducerMap<State> = {
  gazetteer: fromGazetteer.gazetteerReducer,
};

export const gazetteerFeatureKey = 'gazetteer';

export const getGazetteerModuleState =
  createFeatureSelector<State>(gazetteerFeatureKey);

export const getGazetteerState = createSelector(
  getGazetteerModuleState,
  (state) => state && state.gazetteer
);

export const getFoundLocations = createSelector(
  getGazetteerState,
  fromGazetteer.getFoundLocations
);

export const getSearchText = createSelector(
  getGazetteerState,
  fromGazetteer.getSearchedText
);
