import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';

@Component({
  selector: 'atlas-move-validation-dialog',
  templateUrl: './move-validation-dialog.component.html',
  styleUrls: ['./move-validation-dialog.component.less']
})
export class MoveValidationDialogComponent {
  dialogMode: DialogMode;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      validationMessages: string[];
    }
  ) {}
}
