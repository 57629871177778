import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-layer-legend-detail',
  templateUrl: './layer-legend-detail.component.html',
  styleUrls: ['./layer-legend-detail.component.less'],
})
export class LayerLegendDetailComponent {
  @Input() layer: any;

  constructor() {}

  trackByFn(index: any, item: any) {
    return index;
  }
}
