import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUIStore from 'src/app/core/store';

import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';

@Component({
  selector: 'atlas-profiler-reports-stats',
  templateUrl: './profiler-reports-stats.component.html',
  styleUrls: ['./profiler-reports-stats.component.less']
})
export class ProfilerReportsStatsComponent {
  @Input() queryItem: ProfilerLofiQueryResult;
  isSimpleStatsView$ = this.UIStore$.select(fromUIStore.getIsSimpleStatsView);
  constructor(private UIStore$: Store<fromUIStore.State>) {}
}
