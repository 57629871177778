import { Component } from '@angular/core';

@Component({
  selector: 'atlas-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.less']
})
export class HelpContentComponent {
  helpEmail = 'insitehelpline@caci.co.uk';
  helpLineNumber = '+442076056055';

  navigateToResources() {
    const resourcesURL = 'https://help.insite.caci.co.uk/OXvHtUGibcp55twDVc8j';
    window.open(resourcesURL, '_blank');
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  copyContactNumber() {
    this.copyToClipboard(this.helpLineNumber);
  }

  copyHelpEmail() {
    this.copyToClipboard(this.helpEmail);
  }
}
