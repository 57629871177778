<div style="padding-bottom: 15px">
  <div class="layer-panel-body" *ngIf="mixedLayers$ | async as layers">
    <ng-container *ngFor="let item of layers">
      <!-- Grouped layers -->
      <ng-container *ngIf="item.isGroup; else singleLayer">
        <atlas-layer-group
          [group]="item"
          [layersExpandedDictionary]="layersExpandedDictionary"
          [layerExpandedState]="layersExpandedDictionary.get(item.id)"
          (layerExpandedStateChanged)="
            onLayerExpandedStateChanged(item.id, $event)
          "
        >
        </atlas-layer-group>
      </ng-container>

      <!-- Standard ungrouped layer -->
      <ng-template #singleLayer>
        <atlas-layer-legend
          [layer]="item"
          [layerExpandedState]="layersExpandedDictionary.get(item.id)"
          (layerExpandedStateChanged)="
            onLayerExpandedStateChanged(item.id, $event)
          "
        >
        </atlas-layer-legend>
      </ng-template>
    </ng-container>

    <div>
      <atlas-base-map-layer-collection
        [initialBaseMapLayerType]="initialBaseMapLayerType"
      >
      </atlas-base-map-layer-collection>
    </div>
  </div>
</div>
