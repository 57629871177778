import { Component, EventEmitter, Input, Output } from '@angular/core';
import { measureOptions } from 'src/app/shared/utils/map-utils';

@Component({
  selector: 'atlas-map-measure-control',
  templateUrl: './map-measure-control.component.html',
  styleUrl: './map-measure-control.component.less'
})
export class MapMeasureControlComponent {
  @Input() measurement: number = 0;
  @Output() close = new EventEmitter<void>();
  @Output() measureOptionClicked = new EventEmitter<measureOptions>();

  showPill: boolean;

  onCloseClicked() {
    this.showPill = false;
    this.close.emit();
  }

  onMeasuredMapClicked(option: measureOptions) {
    this.showPill = true;
    this.measureOptionClicked.emit(option);
  }
}
