import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import * as fromUIStore from 'src/app/core/store';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import {
  changeLocationCancelled,
  changeLocationClicked,
  changeLocationConfirmedAttempt
} from 'src/app/core/store/actions/spatial-modeller-ui.actions';

import { spatialModellerModelLocationStartedFromSPSave } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { isEmptyString } from 'src/app/shared/utils/string-utils';

@Component({
  selector: 'atlas-location-panel',
  templateUrl: './location-panel.component.html',
  styleUrls: ['./location-panel.component.less']
})
export class LocationPanelComponent {
  @Input() address: string | undefined;
  @Input() selectedLocation: Point | null | undefined;
  @Input() isSupplyPoint: boolean | null;
  @Input() isSupplyPointClosed: boolean | null | undefined;
  @Input() isNetworkPlanningReadonlySystem: boolean | null;
  @Input() selectedSupplyPoint: SupplyPoint | null | undefined;
  @Input() areMultipleLocationsSelected: boolean | null;

  updateLocation$ = this.UIStore$.select(fromUIStore.isLocationChanging);

  newLocationSelected$ = this.UIStore$.select(
    fromUIStore.getNewLocationSelected
  );

  isSmFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  constructor(
    private UIStore$: Store<fromUIStore.State>,
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>
  ) {}

  onChangeLocation() {
    this.UIStore$.dispatch(
      changeLocationClicked({
        address: this.address,
        location: this.selectedLocation
      })
    );
  }

  onConfirm() {
    this.UIStore$.dispatch(changeLocationConfirmedAttempt());
    this.spatialModellerStore$.dispatch(
      spatialModellerModelLocationStartedFromSPSave()
    );
  }

  onCancel() {
    this.UIStore$.dispatch(changeLocationCancelled());
  }

  isChangeLocationAllowed() {
    return (
      this.isSupplyPoint &&
      !this.isSupplyPointClosed &&
      !this.isNetworkPlanningReadonlySystem
    );
  }

  areSupplyPointAddressPropertiesEmpty() {
    return (
      isEmptyString(this.selectedSupplyPoint?.address1) &&
      isEmptyString(this.selectedSupplyPoint?.address2) &&
      isEmptyString(this.selectedSupplyPoint?.address3) &&
      isEmptyString(this.selectedSupplyPoint?.address4)
    );
  }
}
