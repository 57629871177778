import { Injectable } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';

@Injectable({ providedIn: 'root' })
export class DataGridService {
  private _dataGrid: DxDataGridComponent | null = null;

  setDataGrid(dataGrid: DxDataGridComponent) {
    this._dataGrid = dataGrid;
  }

  async refreshGrid() {
    await this._dataGrid?.instance.getDataSource()?.reload();
  }

  async refreshGridAndClearSelection() {
    this.clearSelection();
    await this.refreshGrid();
  }

  clearSelection() {
    this._dataGrid?.instance.clearSelection();
    this._dataGrid?.instance.option('focusedRowKey', null);
  }

  async selectRow(row: number | null) {
    await this._dataGrid?.instance.selectRows([row], false);
  }
}
