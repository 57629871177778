<form [formGroup]="editLocationNameForm" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Location name</mat-label>
      <input
        matInput
        type="text"
        placeholder="What are you working on?"
        formControlName="locationName"
        cdkFocusInitial
      />
      <mat-error *ngIf="emptyLocationName()">
        Please enter a location name
      </mat-error>
      <mat-error *ngIf="locationNameAlreadyExists()">
        Another location has this name, please choose another name
      </mat-error>
    </mat-form-field>
  </div>
</form>
