import { createAction, props } from '@ngrx/store';

export const checkDataUpdateAccepted = createAction(
  '[Data Update Effect] Check Data Update Accepted'
);

export const showDataUpdateDialog = createAction(
  '[Data Update Effect] Show Data Update Dialog'
);

export const updateDataUpdateAcceptedAttempt = createAction(
  '[Data Update Dialog] Update Data Update Accepted Attempt'
);

export const updateDataUpdateAcceptedError = createAction(
  '[Data Update Effect] Update Data Update Accepted Error',
  props<{ errorOn: string; error: string }>()
);

