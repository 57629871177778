import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { DefineNewLocationFormComponent } from '../atlas-define-new-location-form/atlas-define-new-location-form.component';
import { defineNewLocationDialogButtonClicked } from '../../store/actions/gazetteer.actions';

@Component({
  selector: 'atlas-define-new-location-dialog',
  templateUrl: './atlas-define-new-location-dialog.component.html'
})
export class DefineNewLocationDialogComponent {
  dialogMode: DialogMode;

  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
    },
    private gazetteerStore$: Store<fromGazetteerStore.State>
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
  }

  onAddNewLocationClicked({
    defineNewLocationForm
  }: DefineNewLocationFormComponent) {
    this.gazetteerStore$.dispatch(
      defineNewLocationDialogButtonClicked({
        location: {
          latitude: parseFloat(defineNewLocationForm.value.latitude),
          longitude: parseFloat(defineNewLocationForm.value.longitude)
        }
      })
    );
  }

  isAffirmativeButtonDisabled(
    defineNewLocationForm: DefineNewLocationFormComponent
  ) {
    return defineNewLocationForm.invalidForm();
  }
}
