<div class="title">Report complete</div>
<div class="spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="0" class="background-spinner">
  </mat-progress-spinner>
  <mat-progress-spinner
    [mode]="mode"
    [value]="percentage"
    class="percentage-spinner"
  >
  </mat-progress-spinner>
</div>

<div class="percentage-text">
  <span class="percentage-text">{{ percentage }}%</span>
</div>
