import { Part } from '@aws-sdk/client-s3';
import { createAction, props } from '@ngrx/store';

export const fileImportAttempt = createAction(
  '[Profiler File Import] File Import Attempt',
  props<{
    file: File;
  }>()
);

export const fileImportSuccess = createAction(
  '[Profiler File Import] File Import Succeess',
  props<{
    file: File;
  }>()
);

export const fileUploadError = createAction(
  '[Profiler File Upload] File Upload Error',
  props<{
    fileKey: string;
    errors: any;
  }>()
);

export const metaDataUploadAttempt = createAction(
  '[Import Service] File Upload Meta Data Attempt',
  props<{
    fileKey: string;
  }>()
);

export const metaDataUploadSuccess = createAction(
  '[Import Service] File Upload Meta Data Success',
  props<{
    fileKey: string;
  }>()
);

export const fileUploadUpdateFileKey = createAction(
  '[Upload Service] File Upload Update FileKey',
  props<{
    fileKey: string;
  }>()
);

export const fileUploadCompleteStatusSuccess = createAction(
  '[Profiler File Import] File Upload Complete Status Success'
);

export const initiateMultipartUploadAttempt = createAction(
  '[Import Service] Initiate Multipart Upload Attempt',
  props<{
    file: File;
    fileKey: string;
  }>()
);

export const initiateMultipartUploadSuccess = createAction(
  '[Import Service] Initiate Multipart Upload Success',
  props<{
    uploadId: string;
    file: File;
  }>()
);

export const multipartUploadComplete = createAction(
  '[Import Service] Multipart Upload Complete',
  props<{
    uploadId: string;
    filename: string;
    parts: Part[];
  }>()
);

export const abortMultipartUpload = createAction(
  '[Import Service] Abort Multipart Upload',
  props<{
    uploadId: string;
    filename: string;
    errorMessage: any;
  }>()
);

export const exportImportedPostcodesWithAcornTypeAttempt = createAction(
  '[Profiler Properties Component] Export Imported Postcodes With Acorn Type Succeeded.'
);

export const exportImportedPostcodesWithAcornTypeSucceeded = createAction(
  '[Import Service]  Export Imported Postcodes With Acorn Type Succeeded.',
  props<{
    exportedFileUrl: string;
  }>()
);

export const hasProfilerLimitBeenExceededAttempt = createAction(
  '[Create Profiler Library Dialog] Check has profiler limit been exceeded Attempt'
);

export const hasProfilerLimitBeenExceededResult = createAction(
  '[Profiler Import Effect] Result of profiler limit check',
  props<{
    result: boolean;
  }>()
);
