import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-password-validation-panel',
  templateUrl: './password-validation-panel.component.html',
  styleUrls: ['./password-validation-panel.component.less'],
})
export class PasswordValidationPanelComponent {
  @Input() passwordNotTouched: boolean;
  @Input() minLengthRequirementAccomplished: boolean = false;
  @Input() numberRequirementAccomplished: boolean = false;
  @Input() specialCharRequirementAccomplished: boolean = false;
  @Input() upperCaseLetterRequirementAccomplished: boolean = false;
  @Input() lowerCaseLetterRequirementAccomplished: boolean = false;

  isPasswordValidationWrong() {
    return (
      !this.passwordNotTouched &&
      (!this.minLengthRequirementAccomplished ||
        !this.numberRequirementAccomplished ||
        !this.specialCharRequirementAccomplished ||
        !this.upperCaseLetterRequirementAccomplished ||
        !this.lowerCaseLetterRequirementAccomplished)
    );
  }

  isPasswordValidationPassed() {
    return (
      this.minLengthRequirementAccomplished &&
      this.numberRequirementAccomplished &&
      this.specialCharRequirementAccomplished &&
      this.upperCaseLetterRequirementAccomplished &&
      this.lowerCaseLetterRequirementAccomplished
    );
  }
}
