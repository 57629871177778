<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(addScenarioForm)"
  [saveDisabled]="addScenarioForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-add-edit-scenario-form
    #addScenarioForm
    formContent
    [scenario]="data.scenario"
    [userScenarios]="userScenarios"
  ></atlas-add-edit-scenario-form>
</atlas-add-edit-dialog>
