import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { LocatorShapeDefaults } from './locator-shape-defaults';
import { DriveCatchmentSpeeds } from '../enums/drive-catchment-speeds.enum';
import { TooltipLayouts } from '../enums/tooltip-layout.enum';
import { NetworkPlanningSettings } from './network-planning-settings';
import { CatchmentReportingSettings } from './catchment-reporting-settings';

export class AppFeatures {
  spatialModellerFeature: boolean;
  locatorFeature: boolean;
  profilerFeature: boolean;
  currency: string;
  distanceUnit: string;
  initialMapPosition: Point;
  networkPlanning: NetworkPlanningSettings;
  locator: LocatorShapeDefaults;
  catchmentReporting: CatchmentReportingSettings;
  zoom: number;
  removeSystemLayers: string[];
  driveCatchmentSpeed: DriveCatchmentSpeeds;
  hideUsageSection: boolean;
  tooltipLayout: TooltipLayouts;
  gazetteerCountryRestriction: string;
  allowInternalUsersUsage: boolean;
  disableZoomToExtent: boolean;
  isUATMode: boolean;
}

export enum SelectedFeature {
  Modeller = 'Modeller',
  Locator = 'Locator',
  Profiler = 'Profiler'
}
