export type InputControlType =
  | 'Textbox'
  | 'NumericTextbox'
  | 'DatePicker';

export enum FormControlTypes {
  ComboBox = 'ComboBox',
  Radio = 'Radio',
  List = 'List',
  Checkbox = 'Checkbox',
  Slider = 'Slider',
  Textbox = 'Textbox',
  NumericTextbox = 'NumericTextbox',
  DatePicker = 'DatePicker'
}
