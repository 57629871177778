import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClearStateService } from 'src/app/core/services/clear-state.service';

@Component({
  selector: 'atlas-help-workspace',
  templateUrl: './help-workspace.component.html',
  styleUrls: ['./help-workspace.component.less']
})
export class HelpWorkspaceComponent {
  constructor(
    private route: Router,
    private clearStateService: ClearStateService
  ) {}
  onBack() {
    this.clearStateService.clearFeatureState();
    this.route.navigate(['/home']);
  }
}
