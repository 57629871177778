import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  getIconUrl,
  rgbToHex
} from 'src/app/shared/atlas-mapping/helpers/thematic-style-helper';
import { Store } from '@ngrx/store';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import { pinDropLayerIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { getSpatialModellerSupplyPoint } from 'src/app/spatial-modeller-store';
import { UserAction } from 'src/app/spatial-modeller/models/supply-point';
import { selectionColour } from 'src/app/shared/atlas-mapping/helpers/selection-layer-helper';
import * as fromLocatorStore from 'src/app/locator-store';

import * as fromUIStore from 'src/app/core/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-properties-legend',
  templateUrl: './properties-legend.component.html',
  styleUrls: ['./properties-legend.component.less']
})
export class PropertiesLegendComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  layer$ = this.mapService.selectionParentLayer$;
  currentSupplyPoint$ = this.store$.select(getSpatialModellerSupplyPoint);
  userAction = UserAction;

  isSMFeatureAllowed$ = this.UIStore$.select(fromUIStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureAllowed
  );

  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );

  selectedLocation$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  );

  areMultipleLocationsSelected$ = this.locatorStore$.select(
    fromLocatorStore.areMultipleLocationsSelected
  );

  currentSupplyPointFascia: string | undefined;

  constructor(
    private store$: Store<fromScenarioStore.State>,
    private mapService: MapService,
    private UIStore$: Store<fromUIStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.currentSupplyPoint$.subscribe(
        (sp) => (this.currentSupplyPointFascia = sp?.fascia)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSelectionColour() {
    return rgbToHex(selectionColour[0], selectionColour[1], selectionColour[2]);
  }

  getIconSelectionColour(layerId: string, iconMask: boolean) {
    return layerId !== pinDropLayerIdentifier && iconMask
      ? // This filter will change when the selection colour changes, using a function here
        // to calculate the filter seems to cause ExpressionChangedAfterView errors, hence hardcoding here.
        'invert(35%) sepia(59%) saturate(3386%) hue-rotate(205deg) brightness(104%) contrast(104%)'
      : '';
  }

  getIconUrl(layerProperties: any) {
    return this.containsIconProperties(layerProperties) &&
      this.currentSupplyPointFascia
      ? getIconUrl(
          layerProperties.iconAttribute,
          layerProperties.iconDomain,
          layerProperties.icons,
          layerProperties.iconSourceUrl,
          layerProperties.iconOther,
          this.currentSupplyPointFascia
        )
      : layerProperties.legendInfo[0].iconUrl;
  }

  private containsIconProperties(layerProperties: any) {
    return (
      layerProperties.iconAttribute &&
      layerProperties.iconDomain &&
      layerProperties.icons &&
      layerProperties.iconSourceUrl &&
      layerProperties.iconOther
    );
  }
}
