import { createSelector } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

import * as fromFeature from '../reducers';

export const isLocationChanging = createSelector(
  fromFeature.getSpatialModellerUIState,
  (state) => state?.changeLocation
);

export const getNewLocationSelected = createSelector(
  fromFeature.getSpatialModellerUIState,
  (state) => state?.newLocationSelected
);

export const getExistingSelectedLocation = createSelector(
  fromFeature.getSpatialModellerUIState,
  (state) => {
    return state?.existingSelectedLocation?.location as Point
  }
);

export const isSMFeatureAllowed = createSelector(
  fromFeature.getSpatialModellerUIState,
  (state) => state?.spatialModellerFeatureAllowed
);

export const isSMFeatureSelected = createSelector(
  fromFeature.getSpatialModellerUIState,
  (state) => state?.featureSelected
);

