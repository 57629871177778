import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { updateDataUpdateAcceptedAttempt } from 'src/app/reducers';

@Component({
  selector: 'atlas-data-update-dialog',
  templateUrl: './data-update-dialog.component.html',
  styleUrl: './data-update-dialog.component.less'
})
export class DataUpdateDialogComponent {
  constructor(
    private store$: Store
  ) { }

  handleButtonClicked() {
    this.store$.dispatch(updateDataUpdateAcceptedAttempt());
  }
}
