<div class="settings-container">
  <div class="settings-content">
    <div class="scroll-bar">
      <form
        [formGroup]="locatorSettingsFormGroup"
        autocomplete="off"
        style="height: 100%"
      >
        <div>
          <div class="title">Catchment mode</div>
          <mat-radio-group
            formControlName="multiCatchmentMode"
            (change)="onRadioChange($event)"
          >
            <span>Default catchments</span>
            <mat-radio-button
              [value]="false"
              class="radio-button-margin radio-button-padding"
            >
            </mat-radio-button>
            <div class="description">
              Enables users to set their own default catchment size when new
              locations are defined.
            </div>
            <div class="inputFields">
              <div class="catchments">
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label>Drive ({{ driveUnit }}s)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="carCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{ maxCarRangeLimit }}
                  </div>
                  @if(emptyCarCatchmentError() || minCarCatchmentSizeError() ||
                  maxCarCatchmentSizeError() ||
                  positiveIntegersCarCatchmentSizeError()){
                  <div>
                    <mat-error class="error-message">
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxCarRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field
                  appearance="outline"
                  hideRequiredMarker="true"
                  style="margin-top: -5px"
                >
                  <mat-label>Walk ({{ walkUnit }}s)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="walkCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{ maxWalkRangeLimit }}
                  </div>
                  @if( emptyWalkCatchmentError() || minWalkCatchmentSizeError()
                  || maxWalkCatchmentSizeError() ||
                  positiveIntegersWalkCatchmentSizeError()){
                  <div>
                    <mat-error class="error-message">
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxWalkRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label
                    >PublicTransport ({{ publicTransportUnit }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="publicTransportCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max: {{ minRangeLimit }}:{{
                      maxPublicTransportRangeLimit
                    }}
                  </div>
                  @if( emptyPublicTransportCatchmentError() ||
                  minPublicTransportCatchmentSizeError() ||
                  maxPublicTransportCatchmentSizeError() ||
                  positiveIntegersPublicTransportCatchmentSizeError()){
                  <div>
                    <mat-error class="error-message">
                      <span
                        >Please enter an integer from {{ minRangeLimit }} to
                        {{ maxPublicTransportRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
              </div>

              <div class="catchments">
                <mat-form-field appearance="outline" hideRequiredMarker="true">
                  <mat-label>Circle (radius, {{ circleUnit }}s)</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="circleCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">
                    Min/Max {{ minCircleRangeLimit }}:{{ maxCircleRangeLimit }}
                  </div>
                  @if(emptyCircleCatchmentError() ||
                  minCircleCatchmentSizeError() ||
                  maxCircleCatchmentSizeError()){
                  <div>
                    <mat-error class="error-message">
                      <span
                        >Please enter a number up to
                        {{ maxCircleRangeLimit }}</span
                      >
                    </mat-error>
                  </div>
                  }
                </div>
                <mat-form-field
                  appearance="outline"
                  hideRequiredMarker="true"
                  style="margin-top: -5px"
                >
                  <mat-label
                    >Polygon (radius, {{ polygonUnitToShow }}s)</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    formControlName="polyCatchmentSizeControl"
                  />
                </mat-form-field>
                <div class="info">
                  <div class="rangeLimit">Min: 0.1</div>
                  @if( emptyPolyCatchmentError() ||
                  minPolyCatchmentSizeError()){
                  <div>
                    <mat-error class="error-message">
                      <span>Please enter a number greater than 0.1</span>
                    </mat-error>
                  </div>
                  }
                </div>
              </div>
            </div>
            <span>Multiple catchments</span>
            <mat-radio-button
              [value]="true"
              class="radio-button-margin radio-button-padding"
            >
            </mat-radio-button>
            <div>
              <div class="description">
                Enables users to create multiple catchments of pre-determined
                types and sizes.
              </div>
            </div>
          </mat-radio-group>
          <div>
            <atlas-multi-catchment-settings
              [defaultDistanceUnit]="defaultDistanceUnit$ | async"
              [multipleCatchmentsFormArray]="multipleCatchmentsFormArray"
              [isMultiCatchmentsMode]="multiCatchmentsMode"
            ></atlas-multi-catchment-settings>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="save-button-container">
    <button
      mat-flat-button
      color="primary"
      class="save-button"
      (click)="saveSettings()"
      [disabled]="invalidForm()"
    >
      Save
    </button>
  </div>
</div>
