import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUIStore from 'src/app/core/store';
import { getLocatorState } from 'src/app/locator-store/reducers';
import * as fromLocator from 'src/app/locator-store/reducers/locator.reducer';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getDisableImportLocations } from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';

@Component({
  selector: 'atlas-library-management',
  templateUrl: './library-management.component.html',
  styleUrls: ['./library-management.component.less']
})
export class LibraryManagementComponent implements OnInit {
  currentLibrary: LocatorLibrary;
  private subscription = new Subscription();
  @ViewChild('tooltipSpan') tooltipSpan: ElementRef<HTMLElement>;
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );
  disableImportLocations$ = this.UIStore$.select(getDisableImportLocations);

  constructor(
    private locatorState$: Store<fromLocatorStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.getCurrentLibrary();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getCurrentLibrary() {
    this.subscription.add(
      this.locatorState$.select(getLocatorState).subscribe(
        (locator: fromLocator.State) =>
          (this.currentLibrary = {
            id: locator.id,
            name: locator.name,
            createDt: locator.createDt!,
            editedDt: locator.editedDt!,
            data: []
          })
      )
    );
  }

  isTooltipVisible() {
    return (
      this.tooltipSpan?.nativeElement.offsetHeight <
      this.tooltipSpan?.nativeElement.scrollHeight
    );
  }

  getTooltipText(): string {
    return this.currentLibrary.name ? this.currentLibrary.name : '';
  }
}
