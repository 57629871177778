import { Action } from '@ngrx/store';
import { ErrorInfo } from 'src/app/core/models/error-info.model';

export enum ErrorActionType {
  AddGlobalError = '[Error interceptor] Add Global Error to error state',
  ErrorEffect = '[Any effect] Effect error used to close the effect stream',
}

// need an error function so as not to  break the stream when an error occurs in an effect
export class EffectError implements Action {
  readonly type = ErrorActionType.ErrorEffect;
  constructor(public payload: ErrorInfo | null = null) {}
}

export class AddGlobalError implements Action {
  readonly type = ErrorActionType.AddGlobalError;
  constructor(public payload: ErrorInfo) {}
}

export type ErrorActions = EffectError | AddGlobalError;
