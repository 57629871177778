import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'atlas-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent {
  hide = true;
  loginForm = this.fb.group({
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
        )
      ])
    ],
    password: ['', Validators.required]
  });

  constructor(
    private fb: UntypedFormBuilder,
    public authService: AuthService
  ) {}
  onLogin() {
    this.authService.login(
      this.loginForm.controls.email.value,
      this.loginForm.controls.password.value
    );
  }
  onResetPassword() {
    this.authService.forgotPassword();
  }
  invalidEmail() {
    return (
      this.loginForm.controls.email.invalid ||
      (this.loginForm.controls.email.errors &&
        (this.loginForm.controls.email.errors.required ||
          this.loginForm.controls.email.errors.pattern))
    );
  }

  emptyPassword() {
    return (
      this.loginForm.controls.password.errors &&
      this.loginForm.controls.password.errors.required
    );
  }

  public showSignup() {
    this.authService.setAuthAction('signup');
  }
}
