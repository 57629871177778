<div [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>{{formControl.displayText}}</mat-label>
        <input [formControlName]="formControl.name" matInput [type]="controlType">  
    </mat-form-field>
    <atlas-dynamic-form-validation
        [formGroup]="formGroup"
        [control]="formControl"
    >
    </atlas-dynamic-form-validation>
</div>