import { Layer } from '../models/layer';
import { GeoJsonLayer, IconLayer } from '@deck.gl/layers';
import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';

export class ThematicIconLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('ThematicIcon', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    return new GeoJsonLayer(layerProperties);
  }
}
