import { Pipe, PipeTransform } from '@angular/core';

// This pipe is used to allow an array to be iterated in reverse. it is used in the layer managment control
// excellent article on all the pitfalls around this
// https://stackoverflow.com/questions/35703258/invert-angular-2-ngfor
// I am using the example catering for Ivy so the binding is important
// Note that we have to bind the generator function to this because this might have a different meaning in other contexts
// if we don't. We would normally fix that by using an arrow function, but there is no such syntax for generator functions,
// so we have to bind them explicitly.

@Pipe({ name: 'reverseIterable' })
export class ReverseIterablePipe implements PipeTransform {
  private array: any[] = [];
  private reverseIterable: Iterable<any>;

  constructor() {
    this.reverseIterable = {
      [Symbol.iterator]: function* (this: ReverseIterablePipe) {
        for (let i = this.array.length - 1; i >= 0; i--) {
          yield this.array[i];
        }
      }.bind(this),
    };
  }

  transform<T>(value: T[]): Iterable<T> {
    this.array = value;
    return this.reverseIterable;
  }
}
