<atlas-add-edit-dialog
  [saveButtonText]="affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  [closeOnSave]="currentStage == 2"
  (saveClicked)="onNextClicked()"
  [saveDisabled]="isAffirmativeButtonDisabled()"
  [cancelDisabled]="isCancelButtonDisabled()"
  [showProgressBar]="true"
  [noOfStages]="2"
  [currentStage]="currentStage"
  class="add-edit-dialog-content"
>
  @if(currentStage===1){
  <atlas-locator-library-summary-stats-step-1
    [errorsProcessingData]="summaryStatsProcessStepState[0].state === 'Error'"
    [summaryStatsProcessStepState]="summaryStatsProcessStepState"
    formContent
  >
  </atlas-locator-library-summary-stats-step-1>
  } @if(currentStage===2){
  <atlas-locator-library-summary-stats-step-2 formContent>
  </atlas-locator-library-summary-stats-step-2>
  }
  <atlas-steps-dialog-errors
    formValidation
    [dialogStatusError]="locatorSummaryStatsStatusError"
  >
  </atlas-steps-dialog-errors>
</atlas-add-edit-dialog>
