<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container"
      (ngSubmit)="onLogin()"
      [formGroup]="loginForm"
      autocomplete="off"
    >
      <div class="centered">
        <img
          src="../../../../assets/logos/insite-logo.png"
          class="insite-everywhere-logo"
        />
      </div>
      <div
        class="success-action-container"
        [style.display]="
          (authService.successActionText$ | async) != '' ? 'inherit' : 'none'
        "
      >
        <span class="success-action-text body-large">
          {{ authService.successActionText$.value }}
        </span>
      </div>
      <div class="centered column-direction">
        <span class="auth-title">Login</span>
        <span
          class="auth-error-message"
          [style.visibility]="
            (authService.authServiceError$ | async) != '' ? 'visible' : 'hidden'
          "
        >
          {{ authService.authServiceError$.value }}
        </span>
      </div>
      <div>
        <div class="auth-input-fields">
          <div class="auth-control">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="email"
                formControlName="email"
                placeholder="Email"
              />
              <mat-error *ngIf="invalidEmail()">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div>
          <div class="auth-control">
            <mat-form-field appearance="outline" hideRequiredMarker="true">
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                placeholder="Password"
              />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error *ngIf="emptyPassword()">
                Please enter your password
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="auth-control auth-btn-div">
          <button
            mat-raised-button
            class="auth-btn"
            color="primary"
            type="submit"
          >
            Login
          </button>
        </div>
        <div class="reset-password-div centered">
          <span>Forgot password?</span>
          <span class="link" (click)="onResetPassword()">Reset here</span>
        </div>
        <div class="auth-control centered">
          <a
            class="link"
            style="cursor: pointer"
            target="_blank"
            [routerLink]="['/legal']"
          >
            Legal statement
          </a>
        </div>
      </div>
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
