import { createAction, props } from '@ngrx/store';

export const checkFeatureUpdateAccepted = createAction(
  '[Feature Update Effect] Check Feature Update Accepted'
);

export const showFeatureUpdateDialog = createAction(
  '[Feature Update Effect] Show Feature Update Dialog',
  props<{ releaseNotes: string[] }>()
);

export const updateFeatureUpdateAcceptedAttempt = createAction(
  '[Feature Update Dialog] Update Feature Update Accepted Attempt'
);

export const updateFeatureUpdateAcceptedError = createAction(
  '[Feature Update Effect] Update Feature Update Accepted Error',
  props<{ errorOn: string; error: string }>()
);
