import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'atlas-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less'],
})
export class SignupComponent {
  hide = true;
  signupForm = this.fb.group({
    firstName: ['', Validators.required],
    surname: ['', Validators.required],
    tenantId: ['', Validators.required],
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
        ),
      ]),
    ],
    password: ['', Validators.required],
  });

  constructor(private fb: UntypedFormBuilder, private authService: AuthService) {}

  onSignup() {
    this.authService.signUp(
      this.signupForm.controls.firstName.value,
      this.signupForm.controls.surname.value,
      this.signupForm.controls.tenantId.value,
      this.signupForm.controls.email.value,
      this.signupForm.controls.password.value
    );
  }

  invalidEmail() {
    return (
      this.signupForm.controls.email.valid ||
      (this.signupForm.controls.email.errors &&
        (this.signupForm.controls.email.errors.required ||
          this.signupForm.controls.email.errors.pattern))
    );
  }

  emptyPassword() {
    return (
      this.signupForm.controls.password.errors &&
      this.signupForm.controls.password.errors.required
    );
  }

  emptyFirstName() {
    return (
      this.signupForm.controls.firstName.errors &&
      this.signupForm.controls.firstName.errors.required
    );
  }

  emptySurname() {
    return (
      this.signupForm.controls.surname.errors &&
      this.signupForm.controls.surname.errors.required
    );
  }

  emptyCompanyId() {
    return (
      this.signupForm.controls.tenantId.errors &&
      this.signupForm.controls.tenantId.errors.required
    );
  }
  public showLogin() {
    this.authService.setAuthAction('login');
  }
}
