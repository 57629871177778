import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromProfilerStore from 'src/app/profiler-store/';
import { exportImportedPostcodesWithAcornTypeAttempt } from 'src/app/profiler-store/actions/profiler-import.actions';

@Component({
  selector: 'atlas-profiler-export-button',
  templateUrl: './profiler-export-button.component.html',
  styleUrls: ['./profiler-export-button.component.less']
})
export class ProfilerExportButton {
  constructor(private profilerStore$: Store<fromProfilerStore.State>) {}

  onExportProfile() {
    this.profilerStore$.dispatch(exportImportedPostcodesWithAcornTypeAttempt());
  }
}
