import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { isShapeDuplicate } from 'src/app/locator/helpers/locator-shape.helper';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';

export function shapeDuplicateValidator(
  currentShapes: LocatorShape[] | null,
  shapeType: LocatorShapeTypes,
  originalValue: number | null,
  driveCatchmentSpeed: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const sizeValue = control.value;
    if (
      isShapeDuplicate(
        [sizeValue],
        currentShapes,
        shapeType,
        driveCatchmentSpeed
      )
    ) {
      return { shapeDuplicate: true };
    }
    return null;
  };
}
