import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  getLayerThematicColours,
  isIconLayer,
  isLayerSpotlightSupported
} from 'src/app/shared/atlas-mapping/helpers/map-Legend-helper';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';
import { MapLayerTypes } from 'src/app/shared/atlas-mapping/types/map-layer.types';

@Component({
  selector: 'atlas-layer-legend-detail',
  templateUrl: './layer-legend-detail.component.html',
  styleUrls: ['./layer-legend-detail.component.less']
})
export class LayerLegendDetailComponent {
  @Input() layer: any;
  @Output() legendItemClick = new EventEmitter<string>();

  spotlightedDomains: Set<string> = new Set();
  isSpotlightSupported = false;

  constructor(private layerStyleService: MapLayerStyleService) {}

  ngOnInit() {
    this.isSpotlightSupported = isLayerSpotlightSupported(this.layer);
    if (this.isSpotlightSupported) {
      var colours = getLayerThematicColours(this.layer);

      this.layerStyleService.initializeSpotLightLayerColors(
        this.layer.id,
        colours
      );

      if (isIconLayer(this.layer)) {
        var icons = this.layer.props.icons;
        this.layerStyleService.initializeSpotLightLayerIcons(
          this.layer.id,
          icons
        );
      }
    }

    this.spotlightedDomains =
      this.layerStyleService.getSpotlightedDomainsOnLayer(this.layer.id);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onLegendItemClick(domain: string) {
    this.legendItemClick.emit(domain);
  }
}
