<div class="main-container">
  <div class="side-column">
    <button
      mat-flat-button
      type="button"
      class="secondary-button"
      (click)="onBack()"
    >
      <mat-icon class="chevron-back">chevron_left</mat-icon>Back
    </button>
  </div>
  <div class="workspace-container">
    <div><h1>Settings</h1></div>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      class="tab-group-control"
    >
      <mat-tab label="General">
        <atlas-general-settings-tab-content></atlas-general-settings-tab-content
      ></mat-tab>
      <mat-tab label="Network planning" *ngIf="isSMFeatureAllowed$ | async"
        ><atlas-network-planning-settings-tab-content></atlas-network-planning-settings-tab-content>
      </mat-tab>
      <mat-tab
        label="Catchment reporting"
        *ngIf="isLocatorFeatureAllowed$ | async"
      >
        <atlas-catchment-reporting-settings-tab-content></atlas-catchment-reporting-settings-tab-content>
      </mat-tab>
      <mat-tab label="Profiling" *ngIf="isProfilerFeatureAllowed$ | async">
        <atlas-profiling-settings-tab-content></atlas-profiling-settings-tab-content>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="side-column"></div>
</div>
