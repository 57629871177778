import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromLocatorStore from 'src/app/locator-store';
import { updateLibraryDialogSaveClicked } from 'src/app/locator-store/actions/locator-library.actions';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { AddEditLibraryFormComponent } from '../add-edit-library-form/add-edit-library-form.component';

@Component({
  selector: 'atlas-edit-library-dialog',
  templateUrl: './edit-library-dialog.component.html',
  styleUrls: ['./edit-library-dialog.component.less']
})
export class EditLibraryDialogComponent {
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  private subscription = new Subscription();
  constructor(
    private locatorService: LocatorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      library: LocatorLibrary;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<EditLibraryDialogComponent>,
    private store$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit() {
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllLibraries() {
    this.subscription.add(
      this.locatorService
        .getAllLibraries()
        .subscribe((libraries: LocatorLibrary[]) => {
          this.userLibraries = libraries
            .filter((library) => this.data.library.name !== library.name)
            .map((library) => library.name);
        })
    );
  }

  onSaveClicked(editLibraryFormComponent: AddEditLibraryFormComponent) {
    const newLibrary = editLibraryFormComponent.addEditLibraryForm.value;
    if (editLibraryFormComponent.addEditLibraryForm.valid) {
      this.store$.dispatch(
        updateLibraryDialogSaveClicked({
          id: newLibrary.id,
          libraryRequest: {
            name: newLibrary.libraryName
          }
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: editLibraryFormComponent.addEditLibraryForm.controls.libraryName
          .value
      });
    }
  }
}
