import { createSelector } from '@ngrx/store';
import * as fromLocatorLibrarySummaryStats from '../reducers/locator-library-summary-stats.reducer';
import * as fromFeature from '../reducers';

export const getLocatorLibrarySummaryStatsStatus = createSelector(
  fromFeature.getLocatorLibrarySummaryStatsState,
  fromLocatorLibrarySummaryStats.getLocatorSummaryStatsStatus
);

export const getLocatorLibrarySummaryStatsStatusMessage = createSelector(
  fromFeature.getLocatorLibrarySummaryStatsState,
  fromLocatorLibrarySummaryStats.getLocatorSummaryStatsStatusMessage
);
