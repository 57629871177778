<mat-tab-group [selectedIndex]="getSelectedTabIndex()" mat-align-tabs="center">
  @for (tab of loadedTabs; track tab) { @if((tab.condition | async)){
  <mat-tab [disabled]="tab.disabled">
    <ng-template mat-tab-label>
      <button
        mat-button
        (click)="onTabButtonClicked(tab.feature)"
        [matTooltip]="tab.tooltip"
        [matTooltipShowDelay]="1000"
      >
        @if(tab.class && !tab.icon){
        <div class="{{ tab.class }}"></div>
        } @else if(!tab.class && tab.icon){
        <mat-icon>{{ tab.icon }}</mat-icon>
        } @else{
        <span class="{{ tab.class }}">{{ tab.icon }}</span>
        }
      </button>
    </ng-template>
  </mat-tab>
  } }
</mat-tab-group>
