import { createAction, props } from '@ngrx/store';
import { UserTenantsInfo } from '../../models/user-tenants-info';

export const getUserTenantsInfoAttempt = createAction(
  '[HomeWorkSpace Component] Get User Tenants Info Attempt'
);

export const getUserTenantsInfoSucceeded = createAction(
  '[Tenant Management Effects] Get User Tenants Info Succeeded',
  props<{ userTenantsInfo: UserTenantsInfo }>()
);

export const getUserTenantsInfoFailed = createAction(
  '[AtlasAPI] Get User Tenants Info Failed',
  props<{ errorOn: string; error: string }>()
);

export const updateUserTenantAttempt = createAction(
  '[Switch Tenant Confirmation Component] Update User Tenant Attempt',
  props<{ tenantId: number; tenantName: string }>()
);

export const updateUserTenantSucceeded = createAction(
  '[Tenant Management Effects] Update User Tenant Succeeded',
  props<{ userTenantsInfo: UserTenantsInfo }>()
);

export const updateUserTenantFailed = createAction(
  '[AtlasAPI] Update User Tenant Failed',
  props<{ errorOn: string; error: string }>()
);

export const logOutUserAfterSwappingTenant = createAction(
  '[OnLogOut] Log Out User After Swapping Tenant'
);
