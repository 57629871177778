import { createAction, props } from '@ngrx/store';
import { ReportStatusResponse } from 'src/app/spatial-modeller/models/report-status-response';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';

export const exportReportClicked = createAction(
  '[Reports panel] Export Button Clicked',
  props<{ reportId: string }>()
);

export const exportReportGenerated = createAction(
  '[Export effect] Export report generated',
  props<{ reportStatusReponse: ReportStatusResponse }>()
);

export const exportReportComplete = createAction(
  '[Export effect] Export report complete'
);

export const exportReportErrorOccurred = createAction(
  '[Atlas Backend API] Export report error occurred',
  props<{ errorOn: string; error: string }>()
);

export const exportReportSucceeded = createAction(
  '[Export effect] Export report succeeded'
);

export const getReportsSucceded = createAction(
  '[Spatial Modeller Reporting Effect] Get Reports Succeded',
  props<{ reports: SmReportingInfo[] }>()
);

export const ShareSmPowerBIReportAttempt = createAction(
  '[Report panel ] Share Power Bi Report Attempt',
  props<{ jobId: string }>()
);

export const ShareSmPowerBIReportSucceeded = createAction(
  '[Atlas API]  Share Power Bi Report Succeeded'
);

export const ShareSmPowerBIReportErrorOccurred = createAction(
  '[Atlas Backend API]  Share Power Bi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);
