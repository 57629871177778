<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div class="text">
      Are you sure you want to delete '{{ this.data.library.name }}'?
    </div></mat-dialog-content
  >

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Delete"
        (clicked)="onDeleteClicked()"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
