import {
  ErrorActionType,
  ErrorActions,
} from 'src/app/core/store/actions/error.actions';

import { ErrorInfo } from 'src/app/core/models/error-info.model';

export interface ErrorState {
  errorInfo: ErrorInfo | null;
}

const initialState: ErrorState = {
  errorInfo: null,
};

export function reducer(
  state: ErrorState = initialState,
  action: ErrorActions
): ErrorState {
  switch (action.type) {
    case ErrorActionType.AddGlobalError:
      return {
        ...state,
        errorInfo: action.payload,
      };
    default:
      return state;
  }
}
