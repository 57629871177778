<div class="combo-box">
  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label>Style</mat-label>
    <mat-select
      [hideSingleSelectionIndicator]="true"
      [value]="selectedStyleOption"
      (valueChange)="onStyleChanged($event)"
    >
      <mat-option *ngFor="let option of dataSource" [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
