import { BaseMapLayersType } from '../models/base-map-layer-types';
import { BASEMAP } from '@deck.gl/carto';

export function getBaseMapLayerValue(baseMapLayersType: BaseMapLayersType) {
  const baseMapLayerTypes = {
    Positron: BASEMAP.POSITRON,
    Dark_Matter: BASEMAP.DARK_MATTER,
    Voyager: BASEMAP.VOYAGER,
    Satellite: 'mapbox://styles/mapbox/satellite-streets-v11',
  };
  return baseMapLayerTypes[baseMapLayersType];
}
