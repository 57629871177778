import { PipeTransform, Pipe } from '@angular/core';
import { isEmptyString } from '../utils/string-utils';

type StringOrNullType = string | null;

@Pipe({ name: 'concatSupplyPointAddressProperties' })
export class ConcatSupplyPointAddressProperties implements PipeTransform {
  transform(value: StringOrNullType[] | null): string | undefined {
    return value?.filter((str) => !isEmptyString(str)).join(', ');
  }
}
