<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(editScenarioForm)"
  [saveDisabled]="editScenarioForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-add-edit-scenario-form
    #editScenarioForm
    formContent
    [scenario]="data.scenario"
    [userScenarios]="userScenarios"
    bSideHint="Add additional scenarios"
  ></atlas-add-edit-scenario-form>
</atlas-add-edit-dialog>
