import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-stats-item',
  templateUrl: './stats-item.component.html',
  styleUrls: ['./stats-item.component.less'],
})
export class StatsItemComponent {
  @Input() iconName: string;
  @Input() value: string;
  @Input() name: string;
  @Input() isMainStats: boolean;
  @Input() isOddIndex: boolean;

  constructor() {}
}
