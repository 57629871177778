import { createReducer, on } from '@ngrx/store';
import { LocatorShapeDefaults } from '../../models/locator-shape-defaults';
import {
  getUserSettingsSuceeded,
  updateGeneralUserSettingsSucceeded,
  updateLocatorUserSettingsSucceeded,
  updateNetworkPlanningUserSettingSucceeded
} from '../actions';
import { GeneralSettings } from '../../models/general-settings';
import { NetworkPlanningSettings } from '../../models/network-planning-settings';

export interface State {
  general: GeneralSettings | null;
  locator: LocatorShapeDefaults | null;
  networkPlanning: NetworkPlanningSettings | null;
}

export const initialState: State = {
  general: null,
  locator: null,
  networkPlanning: null
};

export const userSettingsReducer = createReducer(
  initialState,
  on(
    getUserSettingsSuceeded,
    updateGeneralUserSettingsSucceeded,
    updateLocatorUserSettingsSucceeded,
    updateNetworkPlanningUserSettingSucceeded,
    (state, { userSettings }) => {
      return {
        ...state,
        general: userSettings ? userSettings.general : null,
        locator: userSettings ? userSettings.locator : null,
        networkPlanning: userSettings ? userSettings.networkPlanning : null
      };
    }
  )
);
