import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataUpdateService {

  dataUpdateApiUrl = `${environment.baseUrl}api/users/data-update-accepted`;

  constructor(private http: HttpClient) {}

  public updateUserDataAccepted() {
    return this.http.put(this.dataUpdateApiUrl, {});
  }

  public hasUserAcceptedDataUpdate(): Observable<boolean> {
    return this.http.get<boolean>(this.dataUpdateApiUrl);
  }

}
