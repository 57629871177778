import { createReducer, on } from '@ngrx/store';
import { UserTenantsInfo } from '../../models/user-tenants-info';
import {
  getUserTenantsInfoSucceeded,
  updateUserTenantSucceeded
} from '../actions/tenant-management.actions';

export interface State {
  userTenantsInfo: UserTenantsInfo;
}

export const initialState: State = {
  userTenantsInfo: {
    tenantId: 0,
    tenantName: '',
    name: '',
    availableUserTenants: []
  }
};

export const tenantManagementReducer = createReducer(
  initialState,
  on(getUserTenantsInfoSucceeded, (state, { userTenantsInfo }) => {
    return {
      ...state,
      userTenantsInfo
    };
  }),
  on(updateUserTenantSucceeded, (state) => {
    return {
      ...initialState // State is reset after current tenant is updated
    };
  })
);
