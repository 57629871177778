<div class="row-container" *ngIf="isProfilerFeatureSelected$ | async">
  <div class="library-name">
    <span #tooltipSpan class="body-large library-name">
      {{ getTooltipText() }}
    </span>
  </div>
  <div class="buttons-container">
    <atlas-add-profiler-library></atlas-add-profiler-library>
    <atlas-swap-profiler-library></atlas-swap-profiler-library>
    <atlas-edit-profiler-library
      [currentLibrary]="currentLibrary"
    ></atlas-edit-profiler-library>
    <div class="buttons-container"></div>
  </div>
</div>
