import { createAction, props } from '@ngrx/store';
import { AppFeatures } from '../../models/feature';

export const loadAppFeaturesSucceeded = createAction(
  '[Login] Loaded app features successfully',
  props<{ appFeatures: AppFeatures }>()
);

export const noFeatureSelected = createAction(
  '[Layer manager effects] No feature was enabled or selected.'
);

export const clearAppFeaturesStateOnLogoff = createAction(
  '[Logout] Reset the app features state on log off'
);
