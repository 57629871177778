import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as fromLocatorStore from 'src/app/locator-store';
import { AddEditCatchmentDialogComponent } from 'src/app/core/components/properties-panel/locator-properties/add-edit-catchment-dialog/add-edit-catchment-dialog.component';
import { DeleteCatchmentConfirmationDialogComponent } from 'src/app/core/components/properties-panel/locator-properties/catchment-list/delete-catchment-confirmation-dialog/delete-catchment-confirmation-dialog.component';
import { MultiCatchment } from 'src/app/core/models/multi-catchment';
import { DialogService } from 'src/app/core/services/dialog.service';
import { getShapeTypeName } from 'src/app/locator/helpers/locator-shape.helper';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { Store } from '@ngrx/store';
import { updateMultipleCatchmentsSelectionSucceeded } from 'src/app/core/store';
import { Subscription } from 'rxjs';
import { LocatorShapeDefaults } from 'src/app/core/models/locator-shape-defaults';
import {
  DriveCatchmentSpeedNames,
  DriveCatchmentSpeeds
} from 'src/app/core/enums/drive-catchment-speeds.enum';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { UntypedFormArray } from '@angular/forms';
@Component({
  selector: 'atlas-multi-catchment-settings',
  templateUrl: './multi-catchment-settings.component.html',
  styleUrl: './multi-catchment-settings.component.less'
})
export class MultiCatchmentSettingsComponent implements OnInit, OnDestroy {
  locatorShapeTypes = LocatorShapeTypes;
  dataSource: MultiCatchment[] | undefined;
  private subscription = new Subscription();

  driveCatchmentSpeedList = DriveCatchmentSpeedNames;
  constructor(
    public dialogService: DialogService,
    private store$: Store<fromLocatorStore.State>
  ) {}
  index: number = 0;

  @Input()
  isMultiCatchmentsMode: boolean;
  @Input()
  defaultDistanceUnit: string | null;

  _multipleCatchmentsFormArray: UntypedFormArray;
  @Input()
  get multipleCatchmentsFormArray() {
    return this._multipleCatchmentsFormArray;
  }

  set multipleCatchmentsFormArray(value: UntypedFormArray) {
    this._multipleCatchmentsFormArray = value;

    this.dataSource = value.value?.map((catchment: MultiCatchment) => ({
      ...catchment,
      description: this.generateDefaultShapeName(catchment)
    }));
  }

  ngOnInit() {
    this.subscription.add(
      this.multipleCatchmentsFormArray.valueChanges.subscribe(() => {
        this.dataSource = this.multipleCatchmentsFormArray.value?.map(
          (catchment: MultiCatchment) => ({
            ...catchment,
            description:
              catchment.description != null && catchment.description !== ''
                ? catchment.description
                : this.generateDefaultShapeName(catchment)
          })
        );
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddCatchment(type: LocatorShapeTypes) {
    const dialogRef = this.dialogService['dialog'].open(
      AddEditCatchmentDialogComponent,
      {
        width: DialogWidth.Small,
        panelClass: 'dialog-full-width-height',
        data: {
          entityName: getShapeTypeName(type),
          mode: DialogMode.Add,
          headerPrefix: 'Add',
          affirmativeButtonText: 'Confirm',
          shapeType: type,
          shape: null,
          isSettings: true
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.action === DialogResult.Affirmative) {
        this.addCatchmentToTable(
          result.data.shapeType,
          result.data.shapeDefaults
        );
      }
    });
  }
  addCatchmentToTable(
    shapeType: LocatorShapeTypes,
    shapeDefaults: LocatorShapeDefaults
  ) {
    const addCatchments = (
      shapeKey: keyof LocatorShapeDefaults,
      values: number[]
    ) => {
      values.forEach((value) => {
        const newCatchment: MultiCatchment = {
          type: shapeType,
          locator: {
            ...shapeDefaults,
            [shapeKey]: {
              ...shapeDefaults[shapeKey],
              timeInSeconds:
                shapeKey === 'car' ||
                shapeKey === 'walk' ||
                shapeKey === 'publicTransport'
                  ? value
                  : undefined,
              radius: shapeKey === 'circle' ? value : undefined
            }
          },
          description: this.generateDefaultShapeName({
            type: shapeType,
            locator: {
              ...shapeDefaults,
              [shapeKey]: {
                ...shapeDefaults[shapeKey],
                timeInSeconds:
                  shapeKey === 'car' ||
                  shapeKey === 'walk' ||
                  shapeKey === 'publicTransport'
                    ? value
                    : undefined,
                radius: shapeKey === 'circle' ? value : undefined
              }
            },
            description: ''
          })
        };

        const existingCatchment = this.dataSource?.find(
          (catchment) => catchment.description === newCatchment.description
        );
        if (!existingCatchment) {
          this.dataSource = [...(this.dataSource || []), newCatchment];
        }
      });
    };

    switch (shapeType) {
      case LocatorShapeTypes.Car:
        if (Array.isArray(shapeDefaults?.car?.timeInSeconds)) {
          addCatchments('car', shapeDefaults.car!.timeInSeconds);
        }
        break;

      case LocatorShapeTypes.Walk:
        if (Array.isArray(shapeDefaults?.walk?.timeInSeconds)) {
          addCatchments('walk', shapeDefaults?.walk!.timeInSeconds);
        }
        break;

      case LocatorShapeTypes.Circle:
        if (Array.isArray(shapeDefaults?.circle?.radius)) {
          addCatchments('circle', shapeDefaults?.circle!.radius);
        }
        break;

      case LocatorShapeTypes.PublicTransport:
        if (Array.isArray(shapeDefaults?.publicTransport?.timeInSeconds)) {
          addCatchments(
            'publicTransport',
            shapeDefaults?.publicTransport!.timeInSeconds
          );
        }
        break;
    }

    this.store$.dispatch(
      updateMultipleCatchmentsSelectionSucceeded({
        multipleCatchments: this.dataSource!
      })
    );
    this.multipleCatchmentsFormArray.markAsDirty();
  }

  onDeleteCatchment(catchment: MultiCatchment) {
    this.dialogService.show(DeleteCatchmentConfirmationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width',
      data: {
        shape: {
          name: catchment.description,
          type: catchment.type
        },
        isSettings: true,
        updatedCatchments: this.dataSource?.filter(
          (c) => c.description !== catchment.description
        )
      }
    });
    this.multipleCatchmentsFormArray.markAsDirty();
  }

  //Generating the shape name to display in the description column
  //in a way that is consistent with the backend implementation.
  generateShapeName(
    setting: LocatorShapeDefaults,
    shapeType: LocatorShapeTypes,
    distanceUnit: string,
    driveCatchmentSpeeds: DriveCatchmentSpeeds | '',
    range: number
  ): string {
    const driveCatchmentSpeedName =
      shapeType === LocatorShapeTypes.Car ||
      shapeType === LocatorShapeTypes.PublicTransport
        ? ` (${this.getDriveCatchmentSpeedName(driveCatchmentSpeeds)})`
        : '';
    switch (shapeType) {
      case LocatorShapeTypes.Circle:
        return `${range}-${distanceUnit} circle`;
      case LocatorShapeTypes.Car:
        return setting.car?.distance === 0
          ? `${range / 60}-minute drive${driveCatchmentSpeedName}`
          : `${range}-${distanceUnit} drive${driveCatchmentSpeedName}`;
      case LocatorShapeTypes.Walk:
        return setting.walk?.distance === 0
          ? `${range / 60}-minute walk`
          : `${range}-${distanceUnit} walk`;
      case LocatorShapeTypes.PublicTransport:
        return setting.publicTransport?.distance === 0
          ? `${range / 60}-minute public transport${driveCatchmentSpeedName}`
          : `${range}-${distanceUnit} public transport${driveCatchmentSpeedName}`;
      default:
        return '';
    }
  }
  generateDefaultShapeName(multiCatchment: MultiCatchment): string {
    const { type, locator } = multiCatchment;
    switch (type) {
      case LocatorShapeTypes.Circle:
        return this.generateShapeName(
          locator,
          type,
          this.defaultDistanceUnit!,
          '',
          locator.circle?.radius || 0
        );
      case LocatorShapeTypes.Car:
        const carRange =
          locator.car?.distance === 0
            ? locator.car?.timeInSeconds
            : locator.car?.distance;
        return this.generateShapeName(
          locator,
          type,
          this.defaultDistanceUnit!,
          locator.car?.driveCatchmentSpeed!,
          carRange || 0
        );
      case LocatorShapeTypes.Walk:
        const walkRange =
          locator.walk?.distance === 0
            ? locator.walk?.timeInSeconds
            : locator.walk?.distance;
        return this.generateShapeName(
          locator,
          type,
          this.defaultDistanceUnit!,
          '',
          walkRange || 0
        );
      case LocatorShapeTypes.PublicTransport:
        const transportRange =
          locator.publicTransport?.distance === 0
            ? locator.publicTransport?.timeInSeconds
            : locator.publicTransport?.distance;
        return this.generateShapeName(
          locator,
          type,
          this.defaultDistanceUnit!,
          locator.publicTransport?.driveCatchmentSpeed!,
          transportRange || 0
        );
      default:
        return '';
    }
  }
  getDriveCatchmentSpeedName(
    driveCatchmentSpeed: DriveCatchmentSpeeds | ''
  ): string {
    const speed = DriveCatchmentSpeedNames.find(
      (item) => item.key === driveCatchmentSpeed
    );
    return speed ? speed.value.split(' ')[0] : '';
  }
}
