import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Store } from '@ngrx/store';

import {
  draggableSelectionLayerIdentifier,
  locatorLibraryLocationsTilesetLayerIdentifier,
  pinDropLayerIdentifier,
  selectionLayerIdentifier,
  smScenarioBaseLayerIdentifier,
  smScenarioBaseTilesetLayerIdentifier,
  smScenarioDeltaLayerIdentifier
} from '../layers/layer.constants';
import { MapService } from './map.service';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { manualPinDropSucceded } from '../../atlas-gazetteer/store/actions/gazetteer.actions';
import {
  legendLayerTurnedOff,
  manualPinClicked,
  supplyPointClicked,
  supplyPointDeselectAttempt
} from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as fromUIStore from 'src/app/core/store';
import { DraggableSelectionLayer } from '../layers/draggable-selection-layer';
import { Point } from '../../atlas-gazetteer/models/point.model';
import { environment } from 'src/environments/environment';
import { locationFromGazetteerClicked } from 'src/app/locator-store/actions/locator-location.actions';
import { ThematicPointTileSetLayer } from '../layers/thematic-point-tileset-layer';
import { ThematicIconTileSetLayer } from '../layers/thematic-icon-tileset-layer';
import { PointTileSetLayer } from '../layers/point-tileset-layer';
import { libraryLocationClicked } from 'src/app/locator-store/actions/locator-library.actions';

@Injectable({
  providedIn: 'root'
})
export class PinDropAndSelectionService {
  // Temporary code until adding this variable to the map state
  private allowManualPinDropSubject$ = new BehaviorSubject<boolean>(false);
  allowManualPinDrop$ = this.allowManualPinDropSubject$.asObservable();

  private pinDropLayerAvailabilitySubject$ = new BehaviorSubject<boolean>(
    false
  );

  private isLocationChanging: boolean;

  pinDropLayerAvailability$ =
    this.pinDropLayerAvailabilitySubject$.asObservable();

  setAllowManualPinDrop(value: boolean) {
    this.allowManualPinDropSubject$.next(value);
  }

  constructor(
    private mapService: MapService,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private authService: AuthService,
    private UIStore$: Store<fromUIStore.State>,
    private draggableSelectionLayer: DraggableSelectionLayer
  ) {
    this.UIStore$.select(fromUIStore.isLocationChanging).subscribe(
      (locationChanging) => (this.isLocationChanging = locationChanging)
    );
  }

  checkPinDropLayerAvailability() {
    this.mapService.getLayer(pinDropLayerIdentifier)
      ? this.pinDropLayerAvailabilitySubject$.next(true)
      : this.pinDropLayerAvailabilitySubject$.next(false);
  }

  // This can be moved to the deck helper ts more atlas logic
  onManualPinDrop(info: any, event: any) {
    this.addPinToLayer(info, event);
    this.reselectManualPin();
    this.gazetteerStore$.dispatch(
      manualPinDropSucceded({
        location: {
          latitude: info.coordinate[1],
          longitude: info.coordinate[0]
        }
      })
    );

    // This should dispatch an action when implementing the map storage
    this.setAllowManualPinDrop(false);
    this.mapService.selectionParentLayer.next(
      this.mapService.getLayer(pinDropLayerIdentifier)
    );
  }

  onPinSelection(info: any) {
    if (info.object.properties?.is_supply_point) {
      this.dispatchSupplyPointClicked(info);
    }

    if (info.object.properties?.is_library_location_point) {
      this.dispatchLibraryLocationClicked(info);
    }
  }

  onPinDeselection() {
    this.gazetteerStore$.dispatch(supplyPointDeselectAttempt());
  }

  removeSelectionLayer() {
    this.mapService.removeLayer(selectionLayerIdentifier);
  }

  removeDraggableSelectionLayer() {
    this.mapService.removeLayer(draggableSelectionLayerIdentifier);
  }

  updateDraggableSelectionLayerLocation(location: Point) {
    if (location) {
      let props = {
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [location.longitude, location.latitude]
          }
        }
      };
      this.mapService.updateLayer(draggableSelectionLayerIdentifier, props);
    }
  }

  removeSelectionIfMatchingLayer(layerId: string) {
    let selectionLayer = this.mapService.getLayer(selectionLayerIdentifier);
    if (layerId === selectionLayer?.props.parentLayerId) {
      this.removeSelectionLayer();
      this.gazetteerStore$.dispatch(legendLayerTurnedOff());
    }
  }

  public async highlightSelectedPin(info: any) {
    if (info.layer) {
      let selectionProperties = {
        ...info.layer.props,
        data: {
          type: 'Feature',
          geometry: { ...info.object.geometry },
          properties: { ...info.object.properties }
        },
        parentLayerId: info.layer.props.id
      };

      // sm base tileset layer: Coordinates are not coming in the info.object.geometry property
      if (info.layer.id === smScenarioBaseTilesetLayerIdentifier) {
        selectionProperties.data.geometry.coordinates = [
          info.object.properties.__longitude,
          info.object.properties.__latitude
        ];
      }

      let draggableSelectionLayer =
        this.draggableSelectionLayer.getSelectionLayer(
          info.layer.props.mapLayerType,
          this,
          selectionProperties
        );

      this.mapService.addSelectionLayer(draggableSelectionLayer);
      this.mapService.selectionParentLayer.next(info.layer);
    }
  }

  public dispatchSupplyPointSelectedFromSearch(layer: any, feature: any) {
    this.gazetteerStore$.dispatch(
      supplyPointClicked({
        id: feature.properties.supply_key,
        location: {
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0]
        }
      })
    );

    this.highlightSelectedPinByFeature(layer, feature);
  }

  public dispatchLocatorLocationFromSearch(locationId: number) {
    this.gazetteerStore$.dispatch(
      locationFromGazetteerClicked({
        libraryDataId: locationId
      })
    );
  }

  public async highlightSelectedPinByFeature(layer: any, feature: any) {
    let selectionProperties = {
      ...layer.props,
      data: feature,
      parentLayerId: layer.props.id
    };

    let draggableSelectionLayer =
      this.draggableSelectionLayer.getSelectionLayer(
        layer.props.mapLayerType,
        this,
        selectionProperties
      );

    this.mapService.addSelectionLayer(draggableSelectionLayer);
    this.mapService.selectionParentLayer.next(layer);
  }

  public reselectManualPin() {
    this.mapService.updateLayer(pinDropLayerIdentifier, {
      iconMapping: {
        marker: {
          x: 0,
          y: 0,
          width: 24,
          height: 24,
          anchorY: 20,
          mask: false
        }
      }
    });
  }

  private addPinToLayer(info: any, event: any) {
    if (info && info.coordinate) {
      this.mapService.updateLayer(pinDropLayerIdentifier, {
        data: [info.coordinate]
      });
    }
  }

  private dispatchSupplyPointClicked(info: any) {
    this.gazetteerStore$.dispatch(
      supplyPointClicked({
        id: info.object.properties.supply_key,
        location: {
          latitude: info.object.geometry.coordinates[1],
          longitude: info.object.geometry.coordinates[0]
        }
      })
    );

    if (!this.isLocationChanging) this.highlightSelectedPin(info);
  }

  private dispatchManualPinClicked(info: any) {
    this.gazetteerStore$.dispatch(
      manualPinClicked({
        location: {
          latitude: info.object[1],
          longitude: info.object[0]
        }
      })
    );
    this.mapService.selectionParentLayer.next(
      this.mapService.getLayer(pinDropLayerIdentifier)
    );
  }

  // This code may move to a Data and Map service if more functions added
  public refreshBaseAndDeltaLayersScenarioData(scenarioId: number) {
    if (scenarioId && scenarioId != 0) {
      this.refreshBaseGeoJson(scenarioId);
      this.refreshDeltaGeoJson(scenarioId);
      this.refreshBaseTileset(scenarioId);
    }
  }

  refreshBaseGeoJson(scenarioId: number) {
    // After carto introduced the nocache, we need to add nocache property to hit endpoint in the backend to refresh delta and base layers.
    const nocache = this.getRandomInt();
    const baseLayer = this.mapService.getLayer(smScenarioBaseLayerIdentifier);
    if (baseLayer) {
      const baseThematicAttribute = baseLayer.props.iconThematicScaleConfig
        ? baseLayer.props.iconThematicScaleConfig.attr
        : baseLayer.props.fillThematicScaleConfig.attr;

      this.mapService.updateLayer(smScenarioBaseLayerIdentifier, {
        data: `${environment.baseUrl}api/mapping/spatial-modeller/scenario/${scenarioId}/base/thematic-attribute-name/${baseThematicAttribute}/locations?nocache=${nocache}`,
        noCache: nocache
      });
    }
  }

  refreshDeltaGeoJson(scenarioId: number) {
    // After carto introduced the nocache, we need to add nocache property to hit endpoint in the backend to refresh delta and base layers.
    const nocache = this.getRandomInt();

    const deltaLayer = this.mapService.getLayer(smScenarioDeltaLayerIdentifier);

    if (deltaLayer) {
      const deltaThematicAttribute = deltaLayer.props.iconThematicScaleConfig
        ? deltaLayer.props.iconThematicScaleConfig.attr
        : deltaLayer.props.fillThematicScaleConfig.attr;

      this.mapService.updateLayer(smScenarioDeltaLayerIdentifier, {
        data: `${environment.baseUrl}api/mapping/spatial-modeller/scenario/${scenarioId}/delta/thematic-attribute-name/${deltaThematicAttribute}/locations?nocache=${nocache}`,
        noCache: nocache
      });
    }
  }
  refreshBaseTileset(scenarioId: number) {
    const baseTilesetLayer = this.mapService.getLayer(
      smScenarioBaseTilesetLayerIdentifier
    );

    if (baseTilesetLayer) {
      const baseThematicAttribute = baseTilesetLayer.props
        .iconThematicScaleConfig
        ? baseTilesetLayer.props.iconThematicScaleConfig.attr
        : baseTilesetLayer.props.fillThematicScaleConfig.attr;
      this.updateSmBaseTilesetLayer(
        this.mapService,
        scenarioId,
        baseThematicAttribute
      );
    }
  }

  refreshLocatorLibraryLocationsTileset(libraryId: number) {
    // TODO check if the locations layer has been configured by the ADMIN

    const locatorIbraryLocationsTilesetLayer = this.mapService.getLayer(
      locatorLibraryLocationsTilesetLayerIdentifier
    );

    if (locatorIbraryLocationsTilesetLayer) {
      this.updateLocatorLibraryTilesetLayer(this.mapService, libraryId);
    }
  }

  // dealing with a dynamic tileset we cant change its data so we need to remove it from the deck and recreate it.
  private async updateLocatorLibraryTilesetLayer(
    mapService: MapService,
    libraryId: number
  ) {
    var layer = mapService.getLayer(
      locatorLibraryLocationsTilesetLayerIdentifier
    );

    if (layer) {
      mapService.removeLayer(locatorLibraryLocationsTilesetLayerIdentifier);
    }

    var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
      locatorLibraryLocationsTilesetLayerIdentifier
    );

    let layerProperties = {
      ...layer.props,
      data: `${mapService.getBaseUrl()}api/mapping/locator/libraries/${libraryId}/dynamic-tileset/locations`
    };

    layerProperties.zOrder = 20;

    //enforce data and visiblity properties
    layerProperties.visible = usersVisiblePreference;

    var pointTileSetLayer = new PointTileSetLayer(layerProperties);
    pointTileSetLayer.type = 'PointTileSet';

    await mapService.addLayer(pointTileSetLayer);
  }

  // dealing with a dynamic tileset we cant change its data so we need to remove it from the deck and recreate it.
  private async updateSmBaseTilesetLayer(
    mapService: MapService,
    scenarioId: number,
    baseThematicAttribute: string
  ) {
    var layer = mapService.getLayer(smScenarioBaseTilesetLayerIdentifier);

    if (layer) {
      mapService.removeLayer(smScenarioBaseTilesetLayerIdentifier);
    }

    var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
      smScenarioBaseTilesetLayerIdentifier
    );

    let layerProperties = {
      ...layer.props,
      data: `${mapService.getBaseUrl()}api/mapping/spatial-modeller/scenario/${scenarioId}/base/thematic-attribute-name/${baseThematicAttribute}/dynamic-tileset/locations`
    };

    let thematicTileSetLayer = this.instantiateThematicTilesetLayer(
      layerProperties.type,
      layerProperties
    );

    thematicTileSetLayer.zOrder = 44;

    //enforce data and visiblity properties
    thematicTileSetLayer.visible = usersVisiblePreference;

    await mapService.addLayer(thematicTileSetLayer);
  }

  private instantiateThematicTilesetLayer(type: string, layerProperties: any) {
    if (type === 'ThematicIconTileSet') {
      const thematicTileSetLayer = new ThematicIconTileSetLayer(
        layerProperties
      );
      thematicTileSetLayer.type = 'ThematicIconTileSet';
      return thematicTileSetLayer;
    } else {
      const thematicTileSetLayer = new ThematicPointTileSetLayer(
        layerProperties
      );
      thematicTileSetLayer.type = 'ThematicPointTileSet';
      return thematicTileSetLayer;
    }
  }

  private getRandomInt() {
    var min = Math.ceil(0);
    var max = Math.floor(9007199254740991);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  private dispatchLibraryLocationClicked(info: any) {
    this.gazetteerStore$.dispatch(
      libraryLocationClicked({
        libraryDataId: info.object.properties.id
      })
    );
  }
}
