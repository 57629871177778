export function openStreetView(
  latitude: number | undefined,
  longitude: number | undefined
) {
  window.open(
    `https://maps.google.co.uk/maps?q=&layer=c&cbll=${latitude},${longitude}&cbp=11,0,0,0,0`,
    '_blank'
  );
}

export type CopyMapOptions = 'Clipboard' | 'Download';
