import { Component } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { AddProfilerLibraryDialogComponent } from '../add-profiler-library-dialog/add-profiler-library-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';

@Component({
  selector: 'atlas-add-profiler-library',
  templateUrl: './add-profiler-library.component.html',
  styleUrls: ['./add-profiler-library.component.less']
})
export class AddProfilerLibraryComponent {
  constructor(public dialogService: DialogService) {}

  onOpenNewLibraryDialog() {
    this.dialogService.show(AddProfilerLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: 'Profile',
        mode: DialogMode.Add,
        headerPrefix: 'Create a new',
        affirmativeButtonText: 'Import'
      },
      disableClose: true
    });
  }
}
