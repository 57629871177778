import { createAction, props } from '@ngrx/store';
import { LocatorImportStatus } from 'src/app/locator/models/locator-import-status';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

export const getLocatorLibrarySucceeded = createAction(
  '[Locator Library Effect] Get Locator Library Succeeded',
  props<{ library: LocatorLibrary }>()
);

export const getLocatorLibraryDataSucceeded = createAction(
  '[Locator Library Effect] Get Locator Library Data Succeeded',
  props<{ locations: LocatorLibraryData[] }>()
);

export const getLocatorLibraryDataSearchAttempt = createAction(
  '[Locator Library Effect] Get Locator Library Data Search Attempt',
  props<{
    filter: string;
    sortBy: string;
    sortOrder: string;
    page: number;
    pageSize: number;
  }>()
);

export const generateTempLocatorLibraryDataPointAttempt = createAction(
  '[Locator Library Effect] Generate temp local library data point Attempt',
  props<{ location: Point }>()
);

export const generateTempFreeFormLocatorLibraryDataPointAttempt = createAction(
  '[Locator Library Effect] Generate temp free form shape on map',
  props<{ location: Point }>()
);

export const saveTempFreeFormLocatorLibraryDataPointAttempt = createAction(
  '[Locator Library Effect] save temp free form local library data point to datbase Attempt',
  props<{ location: Point; geoJson: string; description: string }>()
);

export const generateTempLocatorLibraryDataPointSucceded = createAction(
  '[Locator Library Effect] Generate temp  library data point Succeded',
  props<{ locationData: LocatorLibraryData }>()
);

export const generateDefaultFreeFormPolygonSucceded = createAction(
  '[Locator Library Effect] Generate Default free form polgon for  library data point Succeded'
);

export const generateTempLocatorLibraryDataPointErrorOccurred = createAction(
  '[Atlas Backend API] Locator data point Execution Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const clearLocatorLibraryStateOnLogoff = createAction(
  '[Menu component] Reset the locator library state on log off'
);

export const getLocatorPowerBiReportingInfoSucceded = createAction(
  '[Locator Library Effect] Get Locator Power Bi Reporting Info Succeded',
  props<{ pbiReports: LocatorPowerBiReportingInfo[] }>()
);

export const getLocatorPowerBiReportingInfoErrorOccurred = createAction(
  '[Atlas Backend API] Get Locator Power Bi Reporting Info Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const getLocatorLoFiReportAttempt = createAction(
  '[Locator Reporting Effect] Get Locator Lofi report attempt',
  props<{ libraryId: number; locationDataId: number; shapeId: number }>()
);

export const getLocatorLoFiReportSucceded = createAction(
  '[Locator Library Effect] Get Locator Lofi report Succeded',
  props<{ report: LocatorLofiQueryResult[] }>()
);

export const getLocatorLoFiReportErrorOccurred = createAction(
  '[Atlas Backend API] Get Locator Lofi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const createLibraryDialogSaveClicked = createAction(
  '[Add Library Dialog] Create Library Save Clicked',
  props<{
    libraryRequest: {
      name: string;
    };
  }>()
);

export const createLibrarySucceeded = createAction(
  '[Atlas API] Create Library Succeeded',
  props<{ library: LocatorLibrary }>()
);

export const updateLibraryDialogSaveClicked = createAction(
  '[Edit Library Dialog] Update Library Save Clicked',
  props<{
    id: number;
    libraryRequest: {
      name: string;
    };
  }>()
);

export const updateLibrarySucceeded = createAction(
  '[Atlas API] Update Library Succeeded',
  props<{ library: LocatorLibrary }>()
);

export const libraryErrorOccurred = createAction(
  '[Atlas Backend API] Library Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const swapLibraryAttempt = createAction(
  '[Swap Library Dialog] Swap Library Attempt',
  props<{ id: number }>()
);

export const swapLibrarySucceeded = createAction(
  '[Atlas API] Swap Library Succeeded',
  props<{ library: LocatorLibrary }>()
);

export const deleteLibraryAttempt = createAction(
  '[Delete Library Dialog] Delete Library Attempt',
  props<{ id: number }>()
);

export const deleteLibrarySucceeded = createAction(
  '[Atlas API] Delete Library Succeeded',
  props<{ id: number }>()
);

export const updateLocationNameDialogSaveClicked = createAction(
  '[Edit Library Dialog] Update Location Name Save Clicked',
  props<{
    libraryId: number;
    libraryDataId: number;
    locationRequest: {
      name: string;
    };
  }>()
);

export const updateLocationNameSucceeded = createAction(
  '[Atlas API] Update Location Name Succeeded',
  props<{ locationData: LocatorLibraryData }>()
);

export const LocationNameErrorOccurred = createAction(
  '[Atlas Backend API] Location Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const getReferenceLibrariesSucceeded = createAction(
  '[Atlas API] Get reference Libraries Succeeded',
  props<{ libraries: LocatorReferenceLibrary[] }>()
);

export const ReferenceLibrariesErrorOccurred = createAction(
  '[Atlas Backend API] Reference Library Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const importReferenceLibraryDataClicked = createAction(
  '[Reference Libraries Dialog] Import Reference Library Clicked',
  props<{ referenceLibraryId: number }>()
);

export const importReferenceLibraryDataSucceded = createAction(
  '[Locator Library Effect] Import reference library data Succeded'
);

export const importReferenceLibraryErrorOccurred = createAction(
  '[Atlas Backend API] Import Reference Library Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const libraryLocationClicked = createAction(
  '[Map Component] Library location selected on map',
  props<{ libraryDataId: number }>()
);

export const getLocatorImportLibraryDataSucceded = createAction(
  '[Locator Import Effect] Get Locator Import Library Data Succeded',
  props<{ locatorImportStatus: LocatorImportStatus }>()
);
