@if(hasReportsForSelectedShapes$ | async) {
<div class="button-container">
  <button
    mat-flat-button
    color="primary"
    class="launch-button"
    (click)="onLaunchReporting()"
    [disabled]="pbiReportList.length === 0 || !reportDataAvailable"
  >
    Launch Reporting
  </button>
</div>
}
