import { Component, Input } from '@angular/core';
import { ProfilerStatusProgressStep } from '../../model/profiler-status-progress-step';
import { ProfilerImportStatusError } from '../../model/profiler-import-status-error';

@Component({
  selector: 'atlas-profiler-progress',
  templateUrl: './profiler-progress.component.html',
  styleUrls: ['./profiler-progress.component.less']
})
export class ProfilerProgressComponent {
  @Input()
  progressSteps: ProfilerStatusProgressStep[] | null;
  @Input()
  importStatusErrors: ProfilerImportStatusError | null;
}
