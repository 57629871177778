import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { delay, map, tap, withLatestFrom } from 'rxjs/operators';
import * as fromUIStore from 'src/app/core/store';

import { LayoutService } from '../../services/layout.service';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store/';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromProfilerStore from 'src/app/profiler-store';
import { getProfilerLibraryId } from 'src/app/profiler-store';
import { LocatorReportTypes } from 'src/app/locator/types/locator-report.types';

@Component({
  selector: 'atlas-properties-layout',
  templateUrl: './atlas-properties-layout.component.html',
  styleUrls: ['./atlas-properties-layout.component.less']
  // animations: [
  //   //matExpansionAnimations.bodyExpansion,
  //   trigger('bodyExpansion', [
  //     state('collapsed', style({ height: '0', visibility: 'visible' })),
  //     state('expanded', style({ height: '*', visibility: 'visible' })),
  //     transition(
  //       'expanded => collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
  //     ),
  //     transition(
  //       'collapsed => expanded', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
  //     ),
  //   ]),
  // ]
})
export class AtlasPropertiesLayoutComponent {
  constructor(
    public layoutService: LayoutService,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private store$: Store<fromProfilerStore.State>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );

  profilerLibraryId$ = this.store$.select(getProfilerLibraryId);

  areTestStatsAvailable$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getAreTestStatsAvailable
  );

  isLofiReportAvailable$ = this.locatorStore$.select(
    fromLocatorStore.getIsLofiReportAvailable
  );

  locatorLibraryDataId$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  );

  isAnyLocatorShapeSelected$ = this.locatorStore$.select(
    fromLocatorStore.getIsAnyShapeAvailable
  );

  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );

  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  isNetworkPlanningFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isSMFeatureSelected
  );

  isSupplyPointSelected$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsSupplyPoint
  );

  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  profilerStatus$ = this.store$.select(
    fromProfilerStore.getImportStatusMessage
  );

  areMultipleLocationsSelected$ = this.locatorStore$.select(
    fromLocatorStore.areMultipleLocationsSelected
  );

  summaryStatsReportAvailable$ = this.locatorStore$
    .select(fromLocatorStore.getLocatorPowerBiReports)
    .pipe(
      map((pbiReportList) =>
        pbiReportList.some(
          (pbiReport) =>
            pbiReport.reportType === LocatorReportTypes.Summary_Stats
        )
      )
    );

  showSummaryStatsReport$ = this.UIStore$.select(
    fromUIStore.showSummaryStatsReport
  );

  enableReportPanel$ = combineLatest([
    this.areTestStatsAvailable$,
    this.isAnyLocatorShapeSelected$,
    this.isLofiReportAvailable$,
    this.profilerLibraryId$,
    this.isProfilerFeatureSelected$,
    this.profilerStatus$,
    this.areMultipleLocationsSelected$,
    this.isSelectedPointEmpty$,
    this.isLocatorFeatureSelected$,
    this.showSummaryStatsReport$,
    this.summaryStatsReportAvailable$
  ]).pipe(
    delay(100),
    map(
      ([
        statsAvailable,
        locatorShapesAvailable,
        isLofiReportAvailable,
        profilerLibraryId,
        isProfilerSelected,
        isProfilerStatusSucceeded,
        areMultipleLocationsSelected,
        isSelectedPointEmpty,
        isLocatorFeatureSelected,
        showSummaryStatsReport,
        summaryStatsReportAvailable
      ]) => {
        return (
          ((statsAvailable ||
            isLofiReportAvailable ||
            locatorShapesAvailable ||
            (profilerLibraryId &&
              isProfilerSelected &&
              isProfilerStatusSucceeded === 'ReportAggregateCompleted')) &&
            (!isLocatorFeatureSelected ||
              (!areMultipleLocationsSelected && !isSelectedPointEmpty))) ||
          (isLocatorFeatureSelected &&
            showSummaryStatsReport &&
            summaryStatsReportAvailable)
        );
      }
    )
  );

  isPropertiesPanelDisabled$ = combineLatest([
    this.isSelectedPointEmpty$,
    this.isProfilerFeatureSelected$,
    this.profilerStatus$,
    this.profilerLibraryId$
  ]).pipe(
    delay(100),
    map(
      ([
        isSelectedPointEmpty,
        isProfilerFeatureSelected,
        profilerStatus,
        profilerLibraryId
      ]) => {
        return (
          isSelectedPointEmpty &&
          (!isProfilerFeatureSelected ||
            profilerStatus !== 'ReportAggregateCompleted' ||
            profilerLibraryId === 0)
        );
      }
    ),
    tap(() => {
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      });
    })
  );

  isPropertiesPanelDisabledForNetworkPlanningReadonlySystems$ = combineLatest([
    this.isNetworkPlanningFeatureSelected$,
    this.isSupplyPointSelected$,
    this.isNetworkPlanningReadonlySystem$
  ]).pipe(
    delay(100),
    map(
      ([
        isNetworkPlanningFeatureSelected,
        isSupplyPointSelected,
        isNetworkPlanningReadonlySystem
      ]) => {
        return (
          isNetworkPlanningFeatureSelected &&
          !isSupplyPointSelected &&
          isNetworkPlanningReadonlySystem
        );
      }
    )
  );

  isPropertiesPanelExpandedForNetworkPlanning$ = combineLatest([
    this.isSelectedPointEmpty$,
    this.isNetworkPlanningFeatureSelected$,
    this.isSupplyPointSelected$,
    this.isNetworkPlanningReadonlySystem$
  ]).pipe(
    delay(100),
    map(
      ([
        isSelectedPointEmpty,
        isNetworkPlanningFeatureSelected,
        isSupplyPointSelected,
        isNetworkPlanningReadonlySystem
      ]) => {
        return (
          !isSelectedPointEmpty &&
          isNetworkPlanningFeatureSelected &&
          ((isSupplyPointSelected && isNetworkPlanningReadonlySystem) ||
            !isNetworkPlanningReadonlySystem)
        );
      }
    )
  );

  isPropertiesPanelExpandedForCatchmentReporting$ = combineLatest([
    this.isSelectedPointEmpty$,
    this.isLocatorFeatureSelected$,
    this.areMultipleLocationsSelected$
  ]).pipe(
    delay(100),
    map(
      ([
        isSelectedPointEmpty,
        isLocatorFeatureSelected,
        areMultipleLocationsSelected
      ]) => {
        return (
          areMultipleLocationsSelected ||
          (!isSelectedPointEmpty && isLocatorFeatureSelected)
        );
      }
    )
  );

  isPropertiesPanelExpandedForProfiler$ = combineLatest([
    this.isProfilerFeatureSelected$,
    this.profilerLibraryId$
  ]).pipe(
    delay(100),
    map(([isProfilerFeatureSelected, profilerLibraryId]) => {
      return isProfilerFeatureSelected && profilerLibraryId! > 0;
    })
  );
}
