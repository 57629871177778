import { UserEmail } from 'src/app/reporting/models/usage-summary';

export function hideCACIUsers<T extends UserEmail>(
  users: T[] | undefined,
  allowInternalUsersUsage: boolean
) {
  if (!users) {
    return [];
  }
  return allowInternalUsersUsage
    ? users
    : users?.filter((user) => !user.email.endsWith('@caci.co.uk'));
}
