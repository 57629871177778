import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';

import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { Subscription } from 'rxjs';

import * as fromLocator from 'src/app/locator-store';

import { DialogService } from 'src/app/core/services/dialog.service';

import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { swapLibraryAttempt } from 'src/app/locator-store/actions/locator-library.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { DeleteLibraryConfirmationDialogComponent } from '../delete-library-confirmation-dialog/delete-library-confirmation-dialog.component';

@Component({
  selector: 'atlas-swap-library-dialog',
  styleUrls: ['./swap-library-dialog.component.less'],
  templateUrl: './swap-library-dialog.component.html'
})
export class SwapLibraryDialogComponent implements OnInit, OnDestroy {
  dialogMode: DialogMode;
  userLibraries: LocatorLibrary[] = [];
  private subscription = new Subscription();

  constructor(
    private locatorService: LocatorService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      currentLibraryId: number;
    },
    private dialogRef: MatDialogRef<SwapLibraryDialogComponent>,
    private store$: Store<fromLocator.State>
  ) {}

  ngOnInit() {
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllLibraries() {
    this.subscription.add(
      this.locatorService
        .getAllLibraries()
        .subscribe((libraries: LocatorLibrary[]) => {
          this.userLibraries = libraries;
        })
    );
  }

  onSwitchClicked(libraryId: number) {
    this.store$.dispatch(
      swapLibraryAttempt({
        id: libraryId
      })
    );

    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  onDeleteClicked(event: any) {
    this.dialogService.show(
      DeleteLibraryConfirmationDialogComponent,
      {
        width: DialogWidth.Small,
        panelClass: 'dialog-95vw-width',
        data: {
          library: event
        }
      },
      (id: number) =>
        (this.userLibraries = this.userLibraries.filter(
          (library) => library.id !== id
        ))
    );
  }
}
