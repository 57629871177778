import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUIStore from 'src/app/core/store';

@Component({
  selector: 'atlas-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent {
  isUATMode$ = this.UIStore$.select(fromUIStore.getIsUATMode);
  constructor(private UIStore$: Store<fromUIStore.State>) {}
}
