<div class="auth-container">
  <div class="auth-body">
    <form
      class="form-container"
      (ngSubmit)="onChangeFirstTimePassword()"
      [formGroup]="firstTimeLoginForm"
      autocomplete="off"
    >
      <div class="centered">
        <img
          src="../../../../assets/logos/insite-logo.png"
          class="insite-everywhere-logo"
        />
      </div>
      <div class="centered column-direction">
        <span class="auth-title">Create New Password</span>
        <span class="first-time-login-description body-large">
          Please create a new password after your first time login. Your
          provided temporary password will expire in 7 days.
        </span>
        <span
          class="auth-error-message"
          [style.visibility]="
            (authService.authServiceError$ | async) != '' ? 'visible' : 'hidden'
          "
        >
          {{ authService.authServiceError$.value }}
        </span>
      </div>
      <div class="auth-input-fields">
        <div class="first-time-login-control">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>New password</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              placeholder="New password"
            />
            <mat-icon matSuffix (click)="hide = !hide">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <mat-error *ngIf="emptyPassword()">
              Please enter your new password
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="first-time-login-control"
          [style.margin-bottom]="invalidConfirmPassword() ? '10px' : '25px'"
        >
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Confirm new password</mat-label>
            <input
              matInput
              [type]="hideConfirm ? 'password' : 'text'"
              formControlName="confirmPassword"
              placeholder="Confirm new password"
            />
            <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
              hideConfirm ? 'visibility_off' : 'visibility'
            }}</mat-icon>
            <mat-error *ngIf="showEmptyConfirmPasswordError()">
              Please confirm your password
            </mat-error>
          </mat-form-field>
          <mat-error
            class="matching-password-error"
            [style.display]="invalidConfirmPassword() ? 'inherit' : 'none'"
          >
            Please make sure your passwords match
          </mat-error>
        </div>
        <atlas-password-validation-panel
          [minLengthRequirementAccomplished]="hasMinLength()"
          [numberRequirementAccomplished]="hasNumber()"
          [specialCharRequirementAccomplished]="hasSpecialChar()"
          [upperCaseLetterRequirementAccomplished]="hasUpperCase()"
          [lowerCaseLetterRequirementAccomplished]="hasLowerCase()"
        ></atlas-password-validation-panel>
      </div>
      <div class="verify-new-password-control auth-btn-div">
        <button
          mat-raised-button
          class="auth-btn"
          color="primary"
          type="submit"
          [disabled]="invalidForm()"
        >
          Complete new password
        </button>
      </div>
      <div class="back-to-login centered">
        <span>Go back to login?</span>
        <span class="link" (click)="onGoBackToLogin()">Click here</span>
      </div>
    </form>
  </div>
  <div class="auth-footer">
    <img src="../../../../assets/logos/CACI-logo 1.svg" class="caci-logo" />
  </div>
</div>
