<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="swap library"
    (click)="onSwapLibrary()"
    matTooltip="Switch to another library"
    [matTooltipShowDelay]="1000"
    [disabled]="
      (isLocationChanging$ | async) ||
      (isCatchmentReportingReadonlySystem$ | async)
    "
  >
    <mat-icon class="swap-button">swap_horiz</mat-icon>
  </button>
</div>
