import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';


@Component({
  selector: 'atlas-radio-button',
  templateUrl: './atlas-radio-button.component.html',
  styleUrls: ['./atlas-radio-button.component.less']
})
export class AtlasRadioButtonComponent {
  @Input() control: SupplyPointAttribute;
  
  formGroup: UntypedFormGroup;  

  constructor(private parentFormDirective: FormGroupDirective) { 
    this.formGroup = this.parentFormDirective.control;
  }

}
