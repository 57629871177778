import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function formControlValuesMatchingValidator(
  valueCtrl: string,
  confirmValueCtrl: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.parent?.get(valueCtrl)?.value;
    const confirmValue = control?.parent?.get(confirmValueCtrl)?.value;

    return checkMatchingValues(value, confirmValue);
  };
}

export function formGroupControlValuesMatchingValidator(
  valueCtrl: string,
  confirmValueCtrl: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.get(valueCtrl)?.value;
    const confirmValue = control?.get(confirmValueCtrl)?.value;

    return checkMatchingValues(value, confirmValue);
  };
}

function checkMatchingValues(
  value1: string,
  value2: string
): ValidationErrors | null {
  return value1 === value2 ? null : { missMatch: true };
}
