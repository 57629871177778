import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {

  //https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#global-context
  addGlobalContextProperty(key: string, value: any): void {
    datadogRum.setGlobalContextProperty(key, value);
  }
}
