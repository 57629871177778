import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { selectionLayerIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';

import { BaseMapLayerService } from 'src/app/shared/atlas-mapping/services/base-map-layer.service';
import { BaseMapLayersType } from 'src/app/shared/atlas-mapping/models/base-map-layer-types';

@Component({
  selector: 'atlas-layer-management',
  templateUrl: './layer-management.component.html',
  styleUrls: ['./layer-management.component.less']
})
export class LayerManagementComponent implements OnInit {
  layersExpandedDictionary = new Map<string, boolean>();

  layers$: Observable<any[]>;
  initialBaseMapLayerType: BaseMapLayersType;

  constructor(
    private mapService: MapService,
    private baseMapLayerService: BaseMapLayerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.layers$ = this.mapService.layers$.pipe(
      // Filter out the selection layer as this should not be shown.
      map((layers) => {
        this.setLayersInitialExpandedState(layers);

        return layers.filter((layer) => {
          return layer.id !== selectionLayerIdentifier;
        });
      }),

      // This is required here as clicking on the map doesn't trigger any check detection.
      // It has to be done manually using detectChanges(). The setTimeout() has to be used here
      // otherwise the change detection cycle is missed when executing
      // change detectionstraightaway.
      tap(() => {
        setTimeout(() => {
          this.changeDetectorRef.detectChanges();
        });
      })
    );
  }

  async ngOnInit() {
    this.getSelectedBaseMapLayer();
  }

  private setLayersInitialExpandedState(layers: any[]) {
    layers.forEach((layer: any) => {
      if (this.layersExpandedDictionary.get(layer.id) === undefined) {
        this.layersExpandedDictionary.set(layer.id, false);
      }
    });
  }

  private getSelectedBaseMapLayer() {
    this.baseMapLayerService.baseMapLayerType$
      .pipe(take(1))
      .subscribe(
        (baseMapLayerType: BaseMapLayersType) =>
          (this.initialBaseMapLayerType = baseMapLayerType)
      );
  }

  onLayerExpandedStateChanged(layerId: string, event: boolean) {
    this.layersExpandedDictionary.set(layerId, event);
    this.changeDetectorRef.detectChanges();
  }

}
