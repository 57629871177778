import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  apiBaseUrl = '';

  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.startsWith(environment.baseUrl) ||
      request.url.startsWith(environment.reportingApiBaseUrl)
    ) {
      // Only add the bearer token for request going to the Atlas API or the Reporting API
      return this.addBearerToken(request, next);
    } else {
      // For requests going directly to S3 we don't want the bearer token in the header
      return next.handle(request);
    }
  }

  private addBearerToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.getJwtToken().pipe(
      switchMap((jwt) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        });

        const withAuthRequest = request.clone({ headers });
        return next.handle(withAuthRequest);
      })
    );
  }

  private getJwtToken(): Observable<string> {
    return this.authService.getJwtToken();
  }
}
