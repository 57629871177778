<div [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>{{control.displayText}}</mat-label>
        <mat-select [formControlName]="control.name" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let option of control.keyPairValueItems" [value]="option.key.toString()">
                {{option.value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <atlas-dynamic-form-validation 
        [formGroup]="formGroup" 
        [control]="control"
    >
    </atlas-dynamic-form-validation>
</div>