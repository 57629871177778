<mat-accordion multi class="legend">
  <mat-expansion-panel>
    <mat-expansion-panel-header class="legend-headers-align"  collapsedHeight="40px" expandedHeight="40px">
      <mat-panel-title> Base map </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="legend-background">
      <mat-button-toggle-group
        class="base-map-layer-group-container"
        #group="matButtonToggleGroup"
        (change)="baseMapLayerValueChanged($event)"
        [value]="initialBaseMapLayerType"
      >
        <div class="base-map-layer-container">
          <div
            class="base-map-layer-item"
            *ngFor="let baseMapLayer of baseMapLayers"
          >
            <atlas-base-map-layer-item
              [baseMapLayerName]="baseMapLayer[0]"
              [baseMapLayerType]="baseMapLayer[1]"
            ></atlas-base-map-layer-item>
          </div>
        </div>
      </mat-button-toggle-group>
    </div>
  </mat-expansion-panel>
</mat-accordion>
