import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { removeItem } from '../../utils/array-utils';

@Component({
  selector: 'atlas-chip-list',
  templateUrl: 'atlas-chip-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AtlasChipListComponent
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'chip-list-autocomplete-overlay-pane'
      }
    }
  ],
  styleUrls: ['./atlas-chip-list.component.less']
})
export class AtlasChipListComponent implements ControlValueAccessor {
  allItems$ = new BehaviorSubject<string[]>([]);

  @Input() chipListTitle: string;
  @Input() placeholder: string;
  @Input() allowFreeTyping: boolean = false;

  private _allItems: string[] = [];
  @Input()
  set allItems(newValue: string[]) {
    this._allItems = [...newValue];
    this.allItems$.next(this._allItems);
  }
  get allItems() {
    return this._allItems;
  }

  private _fixedItems: string[] = [];
  @Input()
  set fixedItems(newValue: string[]) {
    this._fixedItems = [...newValue];
    this.propagateUserValueChange(this._fixedItems);
  }
  get fixedItems() {
    return this._fixedItems;
  }

  @Input() hint: string = '';

  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  chipListCtrl = new UntypedFormControl('');
  filteredItems$: Observable<string[]>;
  errorMessage: string = '';

  get addedItems() {
    return this.value;
  }

  @ViewChild('chipListInput') chipListInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.filteredItems$ = combineLatest([
      this.getAllItems(),
      this.getFilteredItems()
    ]).pipe(
      map(([allItems, filteredItems]) => {
        return filteredItems ? filteredItems! : allItems;
      })
    );
  }

  getFilteredItems(): Observable<string[] | null> {
    return this.chipListCtrl.valueChanges.pipe(
      startWith(null),
      map((search: string | null) =>
        search ? this.containsFilter(search) : null
      )
    );
  }
  getAllItems(): Observable<string[]> {
    return this.allItems$;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (
      !(this.allowFreeTyping || this.allItems.includes(value)) ||
      this.addedItems.includes(value)
    ) {
      return;
    }

    if (value) {
      this.propagateUserValueChange([...this.addedItems, value.trim()]);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.resetChipListFilter();
  }

  remove(item: string): void {
    this.propagateUserValueChange(removeItem(this.addedItems, item));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.propagateUserValueChange([...this.addedItems, event.option.viewValue]);

    this.chipListInput.nativeElement.value = '';
    this.chipListInput.nativeElement.blur();
    this.resetChipListFilter();
  }

  resetChipListFilter() {
    this.chipListCtrl.setValue(null);
  }

  private containsFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allItems.filter((s) =>
      s.toLowerCase().includes(filterValue.trim().toLowerCase())
    );
  }

  areFixedItems(item: string) {
    return this.fixedItems.includes(item);
  }

  chipInputListFocus() {
    this.chipListInput.nativeElement.blur();
    this.chipListInput.nativeElement.focus();
  }

  /* ControlValueAccessor */
  @Output() valueChanged = new EventEmitter<any>();

  public value: string[] = [];
  public disabled = false;
  public touched = false;

  // Call when value has changed programmatically
  public onChange(newValue: string[]) {}
  public onTouched(_?: any) {}

  public writeValue(obj: string[]): void {
    this.value = obj ? [...obj] : [];
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public propagateUserValueChange(newValue: string[]) {
    this.markAsTouched();

    if (newValue !== this.value) {
      this.value = newValue;
      this.onChange(this.value);
      this.valueChanged.emit(newValue);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
