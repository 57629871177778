import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfilerReportingDialogComponent } from '../profiler-reporting-dialog/profiler-reporting-dialog.component';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getProfilerLibraryId } from 'src/app/profiler-store';

@Component({
  selector: 'atlas-profiler-reports-panel-buttons',
  templateUrl: './profiler-reports-panel-buttons.component.html',
  styleUrls: ['./profiler-reports-panel-buttons.component.less']
})
export class ProfilerReportsPanelButtonsComponent implements OnInit, OnDestroy {
  pbiReportList: ProfilerPowerBiReportingInfo[] = [];
  libraryId$ = this.store$.select(getProfilerLibraryId);
  libraryId: number | null;
  private subscription: Subscription = new Subscription();
  constructor(
    private dialogService: DialogService,
    private profilerStore$: Store<fromProfilerStore.State>,
    private store$: Store<fromProfilerStore.State>
  ) {}
  ngOnInit(): void {
    this.subscription.add(
      this.profilerStore$
        .select(fromProfilerStore.getProfilerPowerBiReports)
        .subscribe((reports) => {
          this.pbiReportList = reports ? reports : [];
        })
    );
    this.subscription.add(
      this.libraryId$.subscribe((id) => {
        this.libraryId = id;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onLaunchReporting() {
    this.dialogService.show(ProfilerReportingDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        pbiReportList: this.pbiReportList,
        libraryId: this.libraryId
      },
      disableClose: true
    });
  }
}
