import { NgModule } from '@angular/core';

import * as fromComponents from './components';
import * as fromServices from './services';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../shared/material/material-components.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [CommonModule, MaterialComponentsModule],
  exports: [...fromComponents.components],
  providers: [...fromServices.services]
})
export class AtlasProfilerModule {}
