import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';

@Directive({
  selector: '[atlasDxiInterbandGapOptions]',
})
export class AtlasDxiInterbandGapOptionsDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.allowReordering = false;
    dataGridColumn.allowResizing = false;
    dataGridColumn.showInColumnChooser = false;
    dataGridColumn.width = 10;
    dataGridColumn.allowFixing = true;
    dataGridColumn.cssClass = 'atlas-dx-datagrid-interband-gap';
  }
}
