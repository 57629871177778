import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import * as fromSmStore from 'src/app/spatial-modeller-store';
import { changeLocationCancelled } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'atlas-change-location-pending-confirmation-dialog',
  templateUrl: './change-location-pending-confirmation-dialog.component.html',
  styleUrls: ['./change-location-pending-confirmation-dialog.component.less']
})
export class ChangeLocationPendingConfirmationDialogComponent {
  constructor(
    private store$: Store<fromSmStore.State>,
    private dialogRef: MatDialogRef<ChangeLocationPendingConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {}

  onNo() {
    this.dialogRef.close({
      action: DialogResult.Cancel
    });
  }

  onYes() {
    this.store$.dispatch(changeLocationCancelled());
    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }
}
