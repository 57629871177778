import {
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Component
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Subscription } from 'rxjs';
import { containsSameElements } from '../../utils/collection-utils';
import { SelectionOrigin } from './selection-origin';

// built in onSelectionChanged event does not differentiate between a user and programmatic selection
// NB we are no also setting the grid row highlight do not bind onto [selectedRowKeys] property when using this component
// Multi select Devexpress support tickets
// tslint:disable-next-line: max-line-length
// https://www.devexpress.com/Support/Center/Question/Details/T836859/datagrid-selection-behavior-of-the-oncellclick-and-onselectionchanged-events
// https://www.devexpress.com/Support/Center/Question/Details/T836484/datagrid-multi-selection-behavior
@Component({
  selector: 'atlas-dx-grid-selection-handler',
  template: ''
})
export class DxDataGridSelectionHandlerComponent implements OnInit, OnDestroy {
  constructor(private dataGridHost: DxDataGridComponent) {}

  private selectionOrigin = SelectionOrigin.None;
  private subscription = new Subscription();

  _selectedItemIds: string[];
  get selectedItemIds(): string[] {
    return this._selectedItemIds;
  }

  @Input('selectedItemIds')
  set selectedItemIds(value: string[]) {
    if (this.gridAlreadyUpdated()) {
      this._selectedItemIds = value;
      return;
    }

    this.selectionOrigin = this.willSelectionChangedBeCalled(value)
      ? SelectionOrigin.Store
      : SelectionOrigin.None;

    this._selectedItemIds = value;

    // set the row highlight
    this.dataGridHost.selectedRowKeys = value;
  }

  @Input()
  identifier: string;

  @Output()
  itemSelected = new EventEmitter<any>();

  ngOnInit() {
    this.subscription.add(
      this.dataGridHost.onCellClick.subscribe(this.cellClicked)
    );

    this.subscription.add(
      this.dataGridHost.onSelectionChanged.subscribe(this.selectionChanged)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // user selects row, this updates the store,
  // store selector then triggers the selectedItemIds input but they are already up to date
  gridAlreadyUpdated() {
    if (this.selectionOrigin === SelectionOrigin.User) {
      this.selectionOrigin = SelectionOrigin.None;
      return true;
    }

    return false;
  }

  // a quirk of the dev express grid if the selection item ids start in a falsy state
  // and are subsequently changed, the onSelectionChanged event does not fire so
  // we need to take this into account when setting selectionOrigin
  willSelectionChangedBeCalled(newSelectedIds: string[]) {
    return this.isFirstRender()
      ? false
      : this.hasSelectionChanged(this._selectedItemIds, newSelectedIds);
  }

  hasSelectionChanged(oldSelection: string[], newSelection: string[]) {
    return !containsSameElements(oldSelection, newSelection);
  }

  isFirstRender() {
    return this._selectedItemIds === undefined;
  }

  selectionChanged = (event: any) => {
    if (this.storeRequiresUpdating()) {
      if(event.selectedRowKeys == undefined) {
        this.itemSelected.emit(event.selectedRowsData);
      } else {
        this.itemSelected.emit(event.selectedRowKeys);  

      }
    }
    if (this.selectedItemIds.length === 0) {
      // Removes the focus from any row when there is no item selected
      this.dataGridHost.focusedRowIndex = -1;
    }
  };

  storeRequiresUpdating() {
    if (this.selectionOrigin === SelectionOrigin.Store) {
      this.selectionOrigin = SelectionOrigin.None;
      return false;
    }

    this.assumeSelectionByUser();
    return true;
  }

  assumeSelectionByUser() {
    this.selectionOrigin = SelectionOrigin.User;
  }

  cellClicked = (event: any) => {
    this.handleSelectAll(event);

    if (event.rowType === 'filter' || event.rowType === 'header') {
      return;
    }

    this.handleKeyBoardSelectionBehaviour(event);
  };

  handleKeyBoardSelectionBehaviour(event: any) {
    if (!this.selectCheckboxWasClicked(event) && !event.event.ctrlKey) {
      if (event.component.getSelectedRowKeys().length >= 1) {
        setTimeout(() => {
          event.event.shiftKey
            ? this.handleShiftKeySelectionBehaviour(event)
            : this.handleCtrlKeySelectionBehaviour(event);
        });
      }
    }
  }

  handleCtrlKeySelectionBehaviour = (event: any) => {
    event.component.selectRows([event.key], false);
  };

  // Workaround to make multiple selection using 'Shift Key' since
  // that option doesn't work properly when the scrolling mode of the grid is 'virtual'.
  // https://supportcenter.devexpress.com/ticket/details/t695917/datagrid-multiple-selection-with-the-shift-key-pressed-doesn-t-work-properly-when
  handleShiftKeySelectionBehaviour = (event: any) => {
    event.component.selectRows(event.component.getSelectedRowKeys(), true);
    this.dataGridHost.focusedRowIndex = -1;
  };

  // TODO handle select all and deselect all
  handleSelectAll = (event: any) => {
    if (event.rowType === 'header') {
      // has the select all check box been selected
      if (this.selectCheckboxWasClicked(event)) {
        // get the select all check box value
        /*console.log(
          'select all - ',
         getCheckboxValue(event)
        );*/
      }
    }
  };

  getCheckboxValue = (event: any) => {
    return event.cellElement.getElementsByClassName('dx-select-checkbox')[0]
      .firstElementChild.value;
  };

  selectCheckboxWasClicked = (event: any) => {
    return event.cellElement.className.includes('dx-command-select');
  };
}
