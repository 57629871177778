import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store/';
import * as fromUIStore from 'src/app/core/store';
import * as fromProfilerStore from 'src/app/profiler-store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import { clearLocatorLibraryStateOnLogoff } from 'src/app/locator-store/actions/locator-library.actions';
import { clearProfilerLibraryStateOnLogoff } from 'src/app/profiler-store/actions/profiler-library.actions';
import { searchTextCleared } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { clearScenarioStateOnLogoff } from 'src/app/spatial-modeller-store/actions/scenario.actions';

@Injectable({
  providedIn: 'root'
})
export class ClearStateService {
  constructor(
    private UIStore$: Store<fromUIStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private scenarioStore$: Store<fromScenarioStore.State>,
    private profilerStore$: Store<fromProfilerStore.State>
  ) {}

  resetApplication(clearAppFeaturesState: boolean = true) {
    this.gazetteerStore$.dispatch(searchTextCleared());
    this.scenarioStore$.dispatch(clearScenarioStateOnLogoff());
    this.locatorStore$.dispatch(clearLocatorLibraryStateOnLogoff());
    this.profilerStore$.dispatch(clearProfilerLibraryStateOnLogoff());
    this.UIStore$.dispatch(fromUIStore.resetChangeLocationState());
    this.UIStore$.dispatch(fromUIStore.resetHasLocatorFeatureLoaded());
    if (clearAppFeaturesState) {
      this.UIStore$.dispatch(fromUIStore.clearAppFeaturesStateOnLogoff());
    }
  }

  clearFeatureState() {
    this.UIStore$.dispatch(fromUIStore.clearAppFeaturesStateOnLogoff());
  }
}
