import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromProfiler from './profiler.reducer';

export const profilerFeatureKey = 'profiler';

export interface State {
  profiler: fromProfiler.State;
}

export const reducers: ActionReducerMap<State> = {
  profiler: fromProfiler.profilerReducer
};

export const getProfilerModuleState =
  createFeatureSelector<State>(profilerFeatureKey);

export const getProfilerState = createSelector(
  getProfilerModuleState,
  (state) => state && state.profiler
);
