import { createSelector } from '@ngrx/store';

import * as fromCore from '../reducers';

export const getTenantId = createSelector(
  fromCore.getTenantManagementState,
  (state) => state?.userTenantsInfo.tenantId
);

export const getTenantName = createSelector(
  fromCore.getTenantManagementState,
  (state) => state?.userTenantsInfo.tenantName
);

export const getSystemName = createSelector(
  fromCore.getTenantManagementState,
  (state) => state?.userTenantsInfo.name
);

export const getAvailableTenants = createSelector(
  fromCore.getTenantManagementState,
  (state) => state?.userTenantsInfo.availableUserTenants
);
