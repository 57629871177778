import { Component, Input } from '@angular/core';
import { UsersGeocoderRecordsUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-user-geocoded-records-usage-summary',
  templateUrl: './user-geocoded-records-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class UserGeocodedRecordsUsageSummaryComponent {
  @Input()
  dataSource: UsersGeocoderRecordsUsage | null;
}
