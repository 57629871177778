import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  createScenarioDialogSaveClicked,
  createScenarioSucceeded,
  deleteScenarioAttempt,
  deleteScenarioSucceeded,
  getScenarioSucceded,
  swapScenarioAttempt,
  swapScenarioSucceeded,
  updateScenarioDialogSaveClicked,
  updateScenarioSucceeded,
  getDeltaSupplyPointsSummarySucceeded,
  clearScenarioStateOnLogoff,
  getDeltaSupplyPointsForListView,
  getAllDeltaSupplyPointsSucceeded,
  moveDeltaSupplyPointsClicked,
  moveDeltaSupplyPointsSucceeded,
  moveDeltaSupplyPointsValidationProblems,
  createUpdateScenarioValidationConflicts
} from '../actions/scenario.actions';

import { ScenarioService } from 'src/app/spatial-modeller/services/scenario.service';
import { Scenario } from 'src/app/core/models/scenario';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as fromSmStore from 'src/app/spatial-modeller-store/actions';
import { getScenarioId } from '../selectors';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { DeltaSummary } from 'src/app/spatial-modeller/models/delta-summary';
import {
  deleteSupplyPointSucceeded,
  saveSupplyPointSucceeded,
  closeSupplyPointSucceeded,
  reopenSupplyPointSucceeded
} from '../actions/supply-point.actions';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { MoveValidationDialogComponent } from 'src/app/spatial-modeller/components/move-validation-dialog/move-validation-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogService } from 'src/app/core/services/dialog.service';
import { AddEditScenarioValidationDialogComponent } from 'src/app/core/components/scenario-management/add-edit-scenario-validation-dialog/add-edit-scenario-validation-dialog.component';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { smFeatureClicked } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';

@Injectable()
export class ScenarioEffects {
  constructor(
    private actions$: Actions,
    private scenarioDataService: ScenarioService,
    private localStorageService: LocalStorageService,
    private store$: Store<State>,
    private mapService: MapService,
    private snackbarService: SnackbarService,
    public dialogService: DialogService,
    private appFeatureStateService: AppFeatureStateService
  ) {}

  getScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenSmFeatureAllowed(),
      switchMap(() => {
        const id = this.getScenarioId();
        if (id > 0) {
          return this.scenarioDataService.getScenarioById(id).pipe(
            map((scenario: Scenario) => {
              this.localStorageService.set('scenario-id', scenario.id);
              return getScenarioSucceded({ scenario });
            })
          );
        } else {
          return this.scenarioDataService.getLastedEditedScenario().pipe(
            map((scenario: Scenario) => {
              this.localStorageService.set('scenario-id', scenario.id);

              return getScenarioSucceded({ scenario });
            })
          );
        }
      })
    )
  );

  createScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createScenarioDialogSaveClicked),
      switchMap(({ scenarioRequest }) =>
        this.scenarioDataService.createScenario(scenarioRequest).pipe(
          map((scenario: Scenario) => {
            this.localStorageService.set('scenario-id', scenario.id);

            return createScenarioSucceeded({
              scenario
            });
          }),
          catchError((error) =>
            of(
              fromSmStore.ScenarioActions.scenarioErrorOccurred({
                errorOn: 'Error scenario',
                error: `Scenario ${scenarioRequest.name} has not been created.`
              }),
              fromSmStore.ScenarioActions.createUpdateScenarioValidationConflicts(
                {
                  message: error,
                  targetScenario: scenarioRequest.name
                }
              )
            )
          )
        )
      )
    )
  );

  updateScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScenarioDialogSaveClicked),
      switchMap(({ id, scenarioRequest }) =>
        this.scenarioDataService.updateScenario(id, scenarioRequest).pipe(
          map((scenario: Scenario) => {
            return updateScenarioSucceeded({
              scenario
            });
          }),
          catchError((error) =>
            of(
              fromSmStore.ScenarioActions.scenarioErrorOccurred({
                errorOn: 'Error scenario',
                error: `Scenario ${scenarioRequest.name} has not been updated.`
              }),
              fromSmStore.ScenarioActions.createUpdateScenarioValidationConflicts(
                {
                  message: error,
                  targetScenario: scenarioRequest.name
                }
              )
            )
          )
        )
      )
    )
  );

  swapScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(swapScenarioAttempt),
      switchMap(({ id }) => {
        return this.scenarioDataService.getScenarioById(id).pipe(
          map((scenario: Scenario) => {
            this.localStorageService.set('scenario-id', scenario.id);
            this.localStorageService.remove('delta-supply-points-grid-storage');
            return swapScenarioSucceeded({ scenario });
          }),
          catchError((error) =>
            of(
              fromSmStore.ScenarioActions.scenarioErrorOccurred({
                errorOn: 'Error scenario',
                error: `Swap Scenario has failed.`
              })
            )
          )
        );
      })
    )
  );

  deleteScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteScenarioAttempt),
      switchMap(({ id }) => {
        return this.scenarioDataService.deleteScenario(id).pipe(
          map(() => deleteScenarioSucceeded({ id })),
          catchError((error) =>
            of(
              fromSmStore.ScenarioActions.scenarioErrorOccurred({
                errorOn: 'Error scenario',
                error: `Delete Scenario has failed.`
              })
            )
          )
        );
      })
    )
  );

  getDeltaSupplyPointsSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        smFeatureClicked,
        swapScenarioSucceeded,
        updateScenarioSucceeded,
        createScenarioSucceeded,
        getScenarioSucceded,
        deleteScenarioSucceeded,
        saveSupplyPointSucceeded,
        deleteSupplyPointSucceeded,
        closeSupplyPointSucceeded,
        reopenSupplyPointSucceeded
      ),
      this.appFeatureStateService.allowWhenSmFeatureSelected(),
      withLatestFrom(this.store$.select(getScenarioId)),
      filter(([_, scenarioId]) => scenarioId > 0),
      switchMap(([_, scenarioId]) => {
        return this.scenarioDataService
          .getDeltaSupplyPointSummary(scenarioId)
          .pipe(
            map((summary: DeltaSummary) => {
              return getDeltaSupplyPointsSummarySucceeded({ summary });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error delta summary',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  getAllDeltaSupplyPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDeltaSupplyPointsForListView, moveDeltaSupplyPointsSucceeded),
      withLatestFrom(this.store$.select(getScenarioId)),
      switchMap(([_, scenarioId]) => {
        return this.scenarioDataService
          .getAllDeltaSupplyPoints(scenarioId)
          .pipe(
            map((deltaSupplyPoints: SupplyPoint[]) => {
              return getAllDeltaSupplyPointsSucceeded({ deltaSupplyPoints });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error delta locations',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  clearScenarioStateOnLogoff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearScenarioStateOnLogoff),
        map(() => {
          this.mapService.resetMapService();
          this.snackbarService.closeSnackBar();
          this.localStorageService.remove('delta-supply-points-grid-storage');
          this.localStorageService.remove('users-list-grid-storage');
        })
      ),
    { dispatch: false }
  );

  moveDeltaSupplypointsScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveDeltaSupplyPointsClicked),
      withLatestFrom(this.store$.select(getScenarioId)),
      switchMap(([{ supplyIds, toScenarioId }, scenarioId]) =>
        this.scenarioDataService
          .moveDeltaSupplyPoints(scenarioId, toScenarioId, supplyIds)
          .pipe(
            map((result: any) => {
              if (!result.success) {
                return moveDeltaSupplyPointsValidationProblems({
                  validationMessages: result.validationMessages
                });
              }

              return moveDeltaSupplyPointsSucceeded();
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error delta location(s)',
                  error: `There was a problem validating the selected location(s) `
                })
              )
            )
          )
      )
    )
  );

  moveValidationProblems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(moveDeltaSupplyPointsValidationProblems),
        map((payload) => {
          this.dialogService.show(MoveValidationDialogComponent, {
            width: DialogWidth.Medium,
            panelClass: 'dialog-full-width-height',
            data: {
              headerText: 'Selected location(s) cannot be moved',
              validationMessages: payload.validationMessages
            },
            disableClose: true,
            autoFocus: false
          });
        })
      ),
    { dispatch: false }
  );

  createUpdateScenarioValidationConflicts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createUpdateScenarioValidationConflicts),
        map((payload) => {
          this.dialogService.show(AddEditScenarioValidationDialogComponent, {
            width: DialogWidth.Small,
            panelClass: 'dialog-full-width-height',
            data: {
              headerText: 'Scenario not updated.',
              validationMessage: payload.message,
              targetScenario: payload.targetScenario
            },
            disableClose: true,
            autoFocus: false
          });
        })
      ),
    { dispatch: false }
  );

  private getScenarioId() {
    var id = this.localStorageService.get('scenario-id');
    return id ? id : 0;
  }
}
