import { Component, Input } from '@angular/core';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import * as fromUIStore from 'src/app/core/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'atlas-locator-reports-stats',
  templateUrl: './locator-reports-stats.component.html',
  styleUrls: ['./locator-reports-stats.component.less']
})
export class LocatorReportsStatsComponent {
  @Input() queryItem: LocatorLofiQueryResult;
  isSimpleStatsView$ = this.UIStore$.select(fromUIStore.getIsSimpleStatsView);
  constructor(private UIStore$: Store<fromUIStore.State>) {}
}
