import { createAction, props } from '@ngrx/store';
import { UserSettings } from '../../models/user-settings';
import { LocatorShapeDefaults } from '../../models/locator-shape-defaults';
import { GeneralSettings } from '../../models/general-settings';
import { NetworkPlanningSettings } from '../../models/network-planning-settings';

export const getUserSettingsAttempt = createAction(
  '[Settings WorkSpace Component] Get User settings Info Attempt'
);
export const getUserSettingsSuceeded = createAction(
  '[Settings WorkSpace Component] Get User settings Info Suceeded',
  props<{ userSettings: UserSettings | null }>()
);
export const updateLocatorUserSettingsOnSaveClicked = createAction(
  '[Settings Workspace Component] Update Locator User Settings OnSaveClicked',
  props<{ locator: LocatorShapeDefaults }>()
);
export const updateLocatorUserSettingsSucceeded = createAction(
  '[Atlas Backend API] Update Locator User Settings Suceeded',
  props<{ userSettings: UserSettings }>()
);
export const updateLocatorUserSettingsError = createAction(
  '[Atlas Backend API] Update Locator User Settings Error',
  props<{ errorOn: string; error: string }>()
);
export const updateNetworkPlanningUserSettingsOnSaveClicked = createAction(
  '[Settings Workspace Component] Update Network Planning User Settings OnSaveClicked',
  props<{ networkPlanning: NetworkPlanningSettings }>()
);
export const updateNetworkPlanningUserSettingSucceeded = createAction(
  '[Atlas Backend API] Update Network Planning  User Settings Suceeded',
  props<{ userSettings: UserSettings }>()
);
export const updateNetworkPlanningUserSettingError = createAction(
  '[Atlas Backend API] Update Network Planning  User Settings Error',
  props<{ errorOn: string; error: string }>()
);
export const updateGeneralUserSettingsOnSaveClicked = createAction(
  '[Settings Workspace Component] Update General User Settings OnSaveClicked',
  props<{ generalSettings: GeneralSettings }>()
);
export const updateGeneralUserSettingsSucceeded = createAction(
  '[Atlas Backend API] Update General User Settings Suceeded',
  props<{ userSettings: UserSettings }>()
);
export const updateGeneralUserSettingsError = createAction(
  '[Atlas Backend API] Update General User Settings Error',
  props<{ errorOn: string; error: string }>()
);
