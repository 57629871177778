import { Component } from '@angular/core';

import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import {
  baseSelectionChanged,
  classificationSelectionChanged
} from 'src/app/profiler-store/actions/profiler-library.actions';
@Component({
  selector: 'atlas-profiler-base-classification-selection',
  templateUrl: './profiler-base-classification-selection.component.html',
  styleUrls: ['./profiler-base-classification-selection.component.less']
})
export class ProfilerBaseClassificationSelectionComponent {
  selectedBaseId$ = this.store$.select(fromProfilerStore.getSelectedBaseId);

  selectedClassificationId$ = this.store$.select(
    fromProfilerStore.getSelectedClassificationId
  );

  bases$ = this.store$.select(fromProfilerStore.getBases);
  classifications$ = this.store$.select(fromProfilerStore.getClassifications);

  constructor(private store$: Store<fromProfilerStore.State>) {}

  onBaseSelectionChange(event: any) {
    this.store$.dispatch(baseSelectionChanged({ selectedBaseId: event.value }));
  }

  onClassificationSelectionChange(event: any) {
    this.store$.dispatch(
      classificationSelectionChanged({ selectedClassificationId: event.value })
    );
  }
}
