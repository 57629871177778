import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';
import { Router } from '@angular/router';
import { getSelectedPbiReportUrl } from 'src/app/core/helpers/power-bi-reports.helper';
import { PowerBiReportViewerService } from 'src/app/core/services/report-viewer.service';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';
import {
  ListSortOptions,
  SearchAndSortValues,
  applyFilter,
  applySort
} from 'src/app/shared/utils/list-utils';

@Component({
  selector: 'atlas-profiler-reporting-form',
  templateUrl: './profiler-reporting-form.component.html',
  styleUrls: ['./profiler-reporting-form.component.less']
})
export class ProfilerReportingFormComponent {
  private selectedSortOption: ListSortOptions = 'NameAsc';
  private _pbiReports: ProfilerPowerBiReportingInfo[] = [];
  @Input()
  set pbiReports(newValue: ProfilerPowerBiReportingInfo[]) {
    this._pbiReports = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
  }
  get pbiReports(): ProfilerPowerBiReportingInfo[] {
    return this._pbiReports;
  }

  profilerReportingForm: UntypedFormGroup;
  dataSource: ProfilerPowerBiReportingInfo[];
  selectedReport: ProfilerPowerBiReportingInfo | null = null;
  selectedPbiReportUrl: string = '';
  @Input() libraryId: number;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private powerBiReportViewerService: PowerBiReportViewerService
  ) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: ''
    } as SearchAndSortValues;
    this.profilerReportingForm = this.createForm(initialValue);
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter]
    });
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._pbiReports);
  }

  onSelectionChange(change: MatSelectionListChange) {
    this.selectedPbiReportUrl = '';
    this.selectedReport = change.options.find(
      (option) => option.selected
    )?.value;

    this.getSelectedPbiReportUrl();
  }

  private getSelectedPbiReportUrl() {
    getSelectedPbiReportUrl(
      this.powerBiReportViewerService,
      this.router,
      this.selectedReport?.workspaceId!,
      this.selectedReport?.powerBiReportId!,
      [this.libraryId]
    ).subscribe((pbiReportUrl) => (this.selectedPbiReportUrl = pbiReportUrl));
  }
}
