<div *ngIf="(isLofiReportAvailable$ | async) || shapesLength >= 0">
  <div *ngIf="shapesLength > 1 && !(isLofiReportAvailable$ | async)">
    <span style="font-size: 12px">
      Multiple catchments selected. Please select one catchment to see the
      summary information.
    </span>
  </div>

  <div *ngIf="(isLofiReportAvailable$ | async) || shapesLength === 1">
    <div class="spinner" *ngIf="!selectedQueryItem">
      <mat-spinner diameter="24" strokeWidth="2"> </mat-spinner>
    </div>

    <div class="image-container" *ngIf="selectedQueryItem">
      @if (!(isReportDataAvailable$ | async) && shapesLength === 1 ) { There is
      no data available for the selected catchment. } @else {
      <div
        style="text-align: center"
        id="{{ identifier }}"
        *ngIf="queryItems.length > 1"
      >
        <span
          *ngFor="let row of queryItems; let i = index"
          style="text-align: center"
          class="dot"
          [ngClass]="{ ' active': i === 0 }"
          (click)="selectQuery(i)"
        ></span>
      </div>
      <div *ngIf="titleType" style="text-align: center">
        {{ titleType }}
      </div>
      <atlas-locator-reports-stats
        [queryItem]="selectedQueryItem"
      ></atlas-locator-reports-stats>

      <atlas-locator-reports-graph
        [pieGridView]="pieGridView"
        [numberCardView]="numberCardView"
        [height]="graphHeight"
        [queryItem]="selectedQueryItem"
        [graphConfig]="graphConfig"
      >
      </atlas-locator-reports-graph>
      }
    </div>
  </div>
</div>
