<div class="zoom-container">
  <button (click)="onZoomOutClicked()" class="zoom-buttons zoom-out">
    <mat-icon>remove</mat-icon>
  </button>
  <button class="zoom-buttons zoom-indicator">
    {{ this.mapService.zoom | number : '.0-0' }}
  </button>
  <button (click)="onZoomInClicked()" class="zoom-buttons zoom-in">
    <mat-icon>add</mat-icon>
  </button>
</div>

<div *ngIf="layoutService.isDesktopView$ | async">
  <div class="map-icons-container">
    <atlas-map-measure-control
      *ngIf="showButtons"
      [measurement]="measurement"
      (measureOptionClicked)="onMeasuredMapClicked($event)"
      (close)="onMeasuredMapCloseClicked()"
    ></atlas-map-measure-control>
    <button
      matSuffix
      mat-icon-button
      color="primary"
      class="icon button"
      (click)="onCopydMapClicked()"
      *ngIf="showButtons"
    >
      <span class="material-symbols-outlined">content_copy</span>
    </button>
    <button
      matSuffix
      mat-icon-button
      color="primary"
      class="icon button"
      (click)="onDownloadMapClicked()"
      *ngIf="showButtons"
    >
      <span class="material-symbols-outlined">download_2</span>
    </button>
  </div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="icon main-icon button"
    (click)="onScreenshotClicked()"
  >
    <span class="material-symbols-outlined">map</span>
  </button>

  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="close-icon main-icon button"
    (click)="onCloseClicked()"
    *ngIf="showButtons"
  >
    <span class="material-symbols-outlined">Close</span>
  </button>
</div>
