<atlas-add-edit-dialog
  [saveButtonText]="affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onNextClicked()"
  [saveDisabled]="isAffirmativeButtonDisabled()"
  [cancelDisabled]="isCancelButtonDisabled()"
  class="add-edit-dialog-content"
>
  <atlas-locator-library-summary-stats-step-1
    [errorsProcessingData]="locatorSummaryStatsStatus === 'SummaryStatsFailed'"
    [summaryStatsStatus]="locatorSummaryStatsStatus"
    formContent
  >
  </atlas-locator-library-summary-stats-step-1>

  <atlas-steps-dialog-errors
    formValidation
    [dialogStatusError]="locatorSummaryStatsStatusError"
  >
  </atlas-steps-dialog-errors>
</atlas-add-edit-dialog>
