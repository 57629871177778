import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  withLatestFrom,
  map,
  catchError,
  delay
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { ExportService } from 'src/app/spatial-modeller/services/spatial-modeller-reporting.service';
import { State } from '../reducers';
import { getScenarioId, getTestModelJobId } from '../selectors';
import {
  ShareSmPowerBIReportAttempt,
  ShareSmPowerBIReportErrorOccurred,
  ShareSmPowerBIReportSucceeded,
  exportReportClicked,
  exportReportComplete,
  exportReportErrorOccurred,
  exportReportGenerated,
  getReportsSucceded
} from '../actions/sm-report.actions';
import { loadAppFeaturesSucceeded } from 'src/app/core/store/actions/app-feature-ui.actions';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';

@Injectable()
export class SmReportingEffects {
  constructor(
    private actions$: Actions,
    private exportService: ExportService,
    private store$: Store<State>,
    private appFeatureStateService: AppFeatureStateService
  ) {}

  exportReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportReportClicked),
      withLatestFrom(this.store$.select(getTestModelJobId)),
      switchMap(([{ reportId }, modelJobId]) =>
        this.exportService.pollForReportStatus(reportId, modelJobId!).pipe(
          map((reportStatusReponse) => {
            return exportReportGenerated({ reportStatusReponse });
          }),
          catchError((error) =>
            of(
              exportReportErrorOccurred({
                errorOn: 'Export report:',
                error
              })
            )
          )
        )
      )
    )
  );

  getSmReportingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppFeaturesSucceeded),
      this.appFeatureStateService.allowWhenSmFeatureAllowed(),
      switchMap(() =>
        this.exportService.getReportCollection().pipe(
          map((reports: SmReportingInfo[]) => {
            return getReportsSucceded({ reports });
          }),
          catchError((error) =>
            of(
              exportReportErrorOccurred({
                errorOn: '',
                error
              })
            )
          )
        )
      )
    )
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportReportGenerated),
      map(({ reportStatusReponse }) => {
        this.openFileLink(reportStatusReponse.reportUrl);
        return exportReportComplete();
      })
    )
  );

  shareSmPowerBiReportDataAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShareSmPowerBIReportAttempt),
      withLatestFrom(this.store$.select(getScenarioId)),
      switchMap(([{ jobId }, scenarioId]) => {
        return this.exportService
          .shareSmPowerBIReportData(scenarioId, jobId)
          .pipe(
            map(() => ShareSmPowerBIReportSucceeded()),
            catchError((error) =>
              of(
                ShareSmPowerBIReportErrorOccurred({
                  errorOn: 'Reporting',
                  error: `The selected power bi report has not been shared.`
                })
              )
            )
          );
      })
    )
  );

  private openFileLink(fileLink: string) {
    window.open(fileLink, '_top');
  }
}
