<div>
  <div class="summary-container">
    <div *ngIf="!isProfileStatusSucceded()">
      <span>No profile available </span>
    </div>
    <div *ngIf="isProfileStatusSucceded()">
      <div class="summary-row">
        <span>Created on {{ createDate | date : 'dd/MM/y' }} </span>
      </div>
      <div class="summary-row">
        <span class="profiled-records-label">{{
          profiledRecords | number
        }}</span
        ><span>
          records matched ({{
            profiledRecordsPercentage | number : '1.1-1'
          }}%)</span
        >
      </div>
      <div class="summary-row">
        <span class="imported-records-label"
          >{{ importedRecords | number }} </span
        ><span>records imported</span>
      </div>
    </div>
  </div>
</div>
