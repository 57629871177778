import { Component, Input } from '@angular/core';
import { UsersProfiledRecordsUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-user-profiled-records-usage-summary',
  templateUrl: './user-profiled-records-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class UserProfiledRecordsUsageSummaryComponent {
  @Input()
  dataSource: UsersProfiledRecordsUsage | null;
}
