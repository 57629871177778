import { createAction, props } from '@ngrx/store';
import { DriveCatchmentSpeeds } from 'src/app/core/enums/drive-catchment-speeds.enum';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

export const shapeFromLocationShapeListClicked = createAction(
  '[Locator Location Shape List] Shape Selected From Location Shape List',
  props<{ id: number }>()
);

export const allShapesFromLocationShapeListClicked = createAction(
  '[Locator Location Shape List] All Shapes Selected From Location Shape List',
  props<{ allSelected: boolean }>()
);

export const allShapesFromLocationShapeListCompleted = createAction(
  '[Locator Location Shape List] All Shapes Selected From Location Shape List Completed'
);

export const selectedShapeInfoRetrieved = createAction(
  '[Locator Shapes Effects] Selected Shape Info Retrieved From State',
  props<{ shape: LocatorShape }>()
);

export const generateLocatorLoFiReportAttempt = createAction(
  '[Locator Location Shape List] Generate Locator Lofi report attempt',
  props<{ libraryId: number; locationDataId: number; shapeId: number }>()
);

export const generateLocatorLoFiReportSucceded = createAction(
  '[Locator Reporting Effect] Generate Locator Lofi report Succeded',
  props<{ report: LocatorLofiQueryResult[] }>()
);

export const generateLocatorLoFiReportErrorOccurred = createAction(
  '[Atlas Backend API] Generate Locator Lofi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const generateLocatorDataReportAttempt = createAction(
  '[Locator Location Shape List] Generate Locator Data report attempt',
  props<{ libraryId: number; locationDataId: number; shapeIds: number[] }>()
);

export const generateLocatorDataReportSucceded = createAction(
  '[Locator Reporting Effect] Generate Locator Data report Succeded'
);

export const generateLocatorDataReportErrorOccurred = createAction(
  '[Atlas Backend API] Generate Locator Data Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const createLocatorShapesAttempt = createAction(
  '[Locator Location Shape List] Create Locator Shapes Attempt',
  props<{
    shapeType: LocatorShapeTypes;
    shapeRanges: number[];
    freeFormGeoJson: string | null;
    freeFormName: string | null;
    driveCatchmentSpeed: DriveCatchmentSpeeds | null;
    multipleCatchments: boolean;
  }>()
);

export const createLocatorShapesSucceeded = createAction(
  '[Locator Shape Effect] Create Locator Shapes Succeeded',
  props<{
    newShapes: LocatorShape[];
    multipleCatchments: boolean;
  }>()
);

export const createLocatorShapesErrorOccurred = createAction(
  '[Atlas Backend API] Create Locator Shapes Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const deleteLibraryLocationShapeAttempt = createAction(
  '[Locator properties delete Dialog] Delete Library Location Shape Attempt',
  props<{ shapeId: number }>()
);

export const deleteLibraryLocationShapeSucceeded = createAction(
  '[Atlas API] Delete Library Location Shape Succeeded',
  props<{ LibraryDataId: number; shapeId: number }>()
);

export const libraryLocationShapeErrorOccurred = createAction(
  '[Atlas Backend API] Library Location shape Error Occured',
  props<{ errorOn: string; error: string }>()
);

export const logPowerBiReportAttempt = createAction(
  '[Report panel ] Log Power Bi Report usage Attempt'
);

export const logPowerBiReportSucceeded = createAction(
  '[Atlas API]  Log Power Bi Report usage Succeeded'
);

export const addingPolygonConfirmAttempt = createAction(
  '[Locator Map] Confirm Polygon Add Attempt',
  props<{ location: Point; geoJson: string }>()
);

export const addingPolygonConfirmComplete = createAction(
  '[Locator Add Polygon] Confirm Polygon Add Complete',
  props<{ location: Point; geoJson: string; description: string }>()
);

export const addingPolygonConfirmCancelled = createAction(
  '[Locator Add Polygon] Confirm Polygon Add Cancelled'
);

export const updateLocatorShapeAttempt = createAction(
  '[Locator Location Shape List] Update Selected Locator Shape Attempt',
  props<{
    shapeRange: number;
    freeFormGeoJson: string | null;
    freeFormName: string | null;
    driveCathmentSpeed: DriveCatchmentSpeeds | null;
  }>()
);

export const updateLocatorShapeSucceeded = createAction(
  '[Locator Shape Effect] Update Locator Shape Succeeded',
  props<{
    updatedShape: LocatorShape;
  }>()
);

export const updateLocatorShapesErrorOccurred = createAction(
  '[Atlas Backend API] Update Locator Shape Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const SharePowerBIReportAttempt = createAction(
  '[Report panel ] Share Power Bi Report Attempt',
  props<{ shapeIds: number[] }>()
);

export const SharePowerBIReportSucceeded = createAction(
  '[Atlas API]  Share Power Bi Report Succeeded'
);

export const SharePowerBIReportErrorOccurred = createAction(
  '[Atlas Backend API]  Share Power Bi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const updateLocatorPolygonDescriptionAttempt = createAction(
  '[Locator Location Shape List] Update Selected Polygon Description Attempt',
  props<{
    name: string | null;
  }>()
);

export const editLocatorPolygonShapeAttempt = createAction(
  '[Locator Location Shape List] Edit Selected Polygon Shape Attempt'
);

export const editLocatorPolygonShapeConfirm = createAction(
  '[Locator Location Shape List] Edit Selected Polygon Shape Confirm',
  props<{ geoJson: string | null }>()
);

export const editLocatorPolygonShapeConfirmCancelled = createAction(
  '[Locator Location Shape List] Edit Selected Polygon Shape Confirm Cancelled',
  props<{ geoJson: string | null }>()
);

export const locatorLaunchReportingClicked = createAction(
  '[Launch Reporting button] Locator Launch Reporting Button Clicked'
);

export const getPowerBiReportDataCountSucceded = createAction(
  '[Locator Reporting Effect] Get Locator Power Bi Data count Succeded',
  props<{ numberOfReportDataRecords: number }>()
);
