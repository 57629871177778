import { DriveCatchmentSpeeds } from '../enums/drive-catchment-speeds.enum';
export class LocatorShapeDefaults {
  car: DriveTimeShapeDefaults | null;
  walk: DriveTimeShapeDefaults | null;
  circle: CircleShapeDefaults | null;
  polygon: PolygonShapeDefaults | null;
  publicTransport: DriveTimeShapeDefaults | null;
}

export class CommomShapeDefaults {
  isDisabled: boolean;
}

export class DriveTimeShapeDefaults extends CommomShapeDefaults {
  distance: number;
  timeInSeconds: number;
  rangeLimit: number;
  driveCatchmentSpeed: DriveCatchmentSpeeds;
}

export class CircleShapeDefaults extends CommomShapeDefaults {
  radius: number;
  rangeLimit: number;
}

export class PolygonShapeDefaults extends CommomShapeDefaults {
  steps: number;
  radiusInKilometers: number;
}
