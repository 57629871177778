<h1>Typography</h1>
<hr />
<span style="font-size: 14px">Euclid Circular B: OTF</span>
<div class="typography-row" style="padding: 20px">
  <div class="typography-property-row">
    <h1>Heading 1</h1>
    <div>Font size: 22px</div>
    <div>Line height: 28.6px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
  <div class="typography-property-row">
    <h2>Heading 2</h2>
    <div>Font size: 18px</div>
    <div>Line height:20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
  <div class="typography-property-row">
    <h3>Heading 3</h3>
    <div>Font size: 16px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
  <div class="typography-property-row">
    <h4>Heading 4</h4>
    <div>Font size: 14px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
  <div class="typography-property-row">
    <h5 class="h6">Heading 5</h5>
    <div>Font size: 12px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
  <div class="typography-property-row">
    <h6 class="h6">Heading 6</h6>
    <div>Font size: 10px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Bold and Regular</div>
  </div>
</div>
<div class="typography-row" style="padding: 20px">
  <div class="typography-property-row">
    <div>Body large</div>
    <div>Font size: 14px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
  </div>
  <div class="typography-property-row">
    <div class="body-medium">Body medium</div>
    <div>Font size: 12px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
    <div>Weight: Medium and regular</div>
  </div>
  <div class="typography-property-row">
    <div class="body-small">Body small</div>
    <div>Font size: 10px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
  </div>
  <div class="typography-property-row">
    <div class="non-important-text">Non important text</div>
    <div>Font size: 8px</div>
    <div>Line height: 20px</div>
    <div>Character space: 0px</div>
  </div>
  <div class="typography-property-row">
    <div class="caption-basic">Caption basic</div>
    <div>Font size: 10px</div>
    <div>Line height: 10px</div>
    <div>Character space: 0px</div>
  </div>
  <div class="typography-property-row">
    <div class="caption-underline">Caption underline</div>
    <div>Font size: 12px</div>
    <div>Line height: 12px</div>
    <div>Character space: 0px</div>
  </div>

  <div>
    <a
      href="https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/"
    >
      Possible source of icons for configurable stats</a
    >
  </div>
</div>
