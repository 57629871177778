import { createAction, props } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';

export const saveSupplyPointClicked = createAction(
  '[Dynamic Form Component] Save Supply Point Clicked',
  props<{ executeModel: boolean }>()
);
export const saveSupplyPointSucceeded = createAction(
  '[Atlas API] Save Supply Point Succeeded',
  props<{ supplyPoint: SupplyPoint; location: Point; executeModel: boolean }>()
);

export const deleteSupplyPointClicked = createAction(
  '[Dynamic Form Component] Delete Supply Point Clicked'
);
export const deleteSupplyPointSucceeded = createAction(
  '[Atlas API] delete Supply Point Succeeded'
);

export const closeSupplyPointClicked = createAction(
  '[Dynamic Form Component] Close Supply Point Clicked'
);

export const closeSupplyPointSucceeded = createAction(
  '[Supply Point Effects] Close Supply Point Succeeded',
  props<{ supplyPoint: SupplyPoint; location: Point }>()
);

export const savePendingSupplyPointClicked = createAction(
  '[Save Pending Supply Point Confirmation Dialog Component] Save Pending Supply Point Clicked'
);

export const discardPendingSupplyPointClicked = createAction(
  '[Save Pending Supply Point Confirmation Dialog Component] Discard Pending Supply Point Clicked'
);

export const reopenSupplyPointClicked = createAction(
  '[Dynamic Form Component] Reopen Supply Point Clicked'
);

export const reopenSupplyPointSucceeded = createAction(
  '[Supply Point Effects] Dynamic Form Component] Reopen Supply Point Succeeded',
  props<{ supplyPoint: SupplyPoint; location: Point }>()
);
