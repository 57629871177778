import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, timer } from 'rxjs';
import { filter, map, switchMap, takeWhile } from 'rxjs/operators';

import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { ModelTestStatus } from '../models/model-test-status';
import { ReportStatusResponse } from '../models/report-status-response';
import { SmReportingInfo } from '../models/sm-reporting-info';
import { Store } from '@ngrx/store';
import * as spatialModellerStore from 'src/app/spatial-modeller-store';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  private atlasSpatialModellerApiUrl = `${environment.baseUrl}api/spatial-modeller/`;
  statusPollDelay = 2 * 1000;
  currentSupplyPointUid: number | undefined;
  shouldPollingContinue: boolean;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private spatialModellerStore$: Store<spatialModellerStore.State>
  ) {
    this.spatialModellerStore$
      .select(spatialModellerStore.getReportInProgress)
      .subscribe(
        (isReportInProgress) =>
          (this.shouldPollingContinue = isReportInProgress)
      );
  }

  getReportCollection() {
    return this.http.get<SmReportingInfo[]>(
      `${this.atlasSpatialModellerApiUrl}reports`
    );
  }

  pollForReportStatus(reportId: string, modelJobId: string) {
    return this.pollForProgressUntilComplete(reportId, modelJobId).pipe(
      map((statusResponse: ReportStatusResponse) => {
        if (statusResponse.status === 'BAD_REQUEST') {
          throw new Error(
            `Test execution failed. ${statusResponse.errorMessage}`
          );
        }
        if (statusResponse.status === 'FAILED') {
          throw new Error(
            `Test execution failed. Unknown error, please try again.`
          );
        }
        return statusResponse;
      }),
      filter(
        (statusResponse: ReportStatusResponse) =>
          statusResponse.status === 'SUCCEEDED',
        true
      )
    );
  }

  shareSmPowerBIReportData(scenarioId: number, jobId: string) {
    return this.http.post(
      `${this.atlasSpatialModellerApiUrl}scenarios/${scenarioId}/jobs/${jobId}/power-bi-report/shared`,
      {}
    );
  }

  private pollForProgressUntilComplete(reportId: string, modelJobId: string) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.authService.isUserAuthenticated().pipe(
          switchMap((isUserAuthenticated) => {
            return isUserAuthenticated
              ? this.getScenarioReportStatus(reportId, modelJobId)
              : of({
                  reportJobId: reportId,
                  status: 'NOT_AUTHENTICATED' as ModelTestStatus,
                  errorMessage: '',
                  reportUrl: '',
                  supplyPointUid: -1
                });
          })
        )
      ),
      takeWhile(
        (statusResponse: ReportStatusResponse) =>
          statusResponse.status === 'RUNNING' && this.shouldPollingContinue,
        true
      )
    );
  }

  private getScenarioReportStatus(reportId: string, modelJobId: string) {
    return this.http.get<ReportStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}scenarios/${modelJobId}/reports/${reportId}`
    );
  }
}
