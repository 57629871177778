import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { Layer } from 'src/app/shared/atlas-mapping/models/layer';

interface LayerGroup {
  name: string;
  layers: Layer[];
}

@Component({
  selector: 'atlas-layer-group',
  templateUrl: './layer-group.component.html',
  styleUrls: ['./layer-group.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerGroupComponent {
  @Input() group: any;
  @Input() layerExpandedState: boolean | undefined;
  @Input() layersExpandedDictionary = new Map<string, boolean>();

  @Output() layerExpandedStateChanged: EventEmitter<boolean> =
    new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  onLayerToggle(layerId: string, expanded: boolean): void {
    this.layersExpandedDictionary.set(layerId, expanded);

    this.triggerChangeDetection();
  }

  private triggerChangeDetection(): void {
    setTimeout(() => this.changeDetectorRef.detectChanges());
  }

  onOpened() {
    this.layerExpandedStateChanged.emit(true);
  }

  onClosed() {
    this.layerExpandedStateChanged.emit(false);
  }
}
