import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromLocatorStore from 'src/app/locator-store';
import { EditLocationDescriptionFormComponent } from '../edit-location-description-form/edit-location-description-form.component';

import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { Store } from '@ngrx/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { updateLocationNameDialogSaveClicked } from 'src/app/locator-store/actions/locator-library.actions';

@Component({
  selector: 'atlas-edit-location-description-dialog',
  templateUrl: './edit-location-description-dialog.component.html',
  styleUrls: ['./edit-location-description-dialog.component.less']
})
export class EditLocationDescriptionDialogComponent {
  dialogMode: DialogMode;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      location: LocatorLibraryData;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<EditLocationDescriptionDialogComponent>,
    private store$: Store<fromLocatorStore.State>
  ) {}

  onSaveClicked(
    editLibraryFormComponent: EditLocationDescriptionFormComponent
  ) {
    const newLibraryLocationData =
      editLibraryFormComponent.editLocationNameForm.value;

    if (editLibraryFormComponent.editLocationNameForm.valid) {
      this.store$.dispatch(
        updateLocationNameDialogSaveClicked({
          libraryId: newLibraryLocationData.libraryId,
          libraryDataId: newLibraryLocationData.libraryDataId,
          locationRequest: {
            name: newLibraryLocationData.locationName
          }
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: editLibraryFormComponent.editLocationNameForm.controls
          .locationName.value
      });
    }
  }
}
