import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UserTenantsInfo } from '../models/user-tenants-info';

@Injectable({
  providedIn: 'root'
})
export class TenantManagementService {
  setCurrentUserTenantApiUrl = `${environment.baseUrl}api/users/activate-tenant`;
  getAvailableTenantsApiUrl = `${environment.baseUrl}api/users/available-tenants`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  public setCurrentUserTenant(tenantId: number, tenantName: string) {
    const newCurrentUserTenant = { tenantId, tenantName };

    return this.http.put<UserTenantsInfo>(
      this.setCurrentUserTenantApiUrl,
      newCurrentUserTenant
    );
  }

  public getAvailableTenants() {
    return this.http.get<UserTenantsInfo>(this.getAvailableTenantsApiUrl);
  }
}
