import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class LoadingOverlayService {
  loadingSubject$ = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject$.asObservable();

  show() {
    this.loadingSubject$.next(true);
  }

  hide() {
    this.loadingSubject$.next(false);
  }
}
