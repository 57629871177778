import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromProfilerStore from 'src/app/profiler-store';
import * as fromUIStore from 'src/app/core/store';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import * as fromProfiler from 'src/app/profiler-store/reducers/profiler.reducer';
import { getProfilerState } from 'src/app/profiler-store/reducers';

@Component({
  selector: 'atlas-profiler-library-management',
  templateUrl: './profiler-library-management.component.html',
  styleUrls: ['./profiler-library-management.component.less']
})
export class ProfilerLibraryManagementComponent {
  currentLibrary: ProfilerLibrary;
  private subscription = new Subscription();
  @ViewChild('tooltipSpan') tooltipSpan: ElementRef<HTMLElement>;
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  constructor(
    private UIStore$: Store<fromUIStore.State>,
    private profilerState$: Store<fromProfilerStore.State>
  ) {}

  ngOnInit() {
    this.getCurrentLibrary();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getCurrentLibrary() {
    this.subscription.add(
      this.profilerState$.select(getProfilerState).subscribe(
        (profiler: fromProfiler.State) =>
          (this.currentLibrary = {
            id: profiler.id,
            name: profiler.name,
            createDt: profiler.createDt!,
            editedDt: profiler.editedDt!,
            importStatus: profiler.importStatus
          })
      )
    );
  }

  isTooltipVisible() {
    return (
      this.tooltipSpan?.nativeElement.offsetHeight <
      this.tooltipSpan?.nativeElement.scrollHeight
    );
  }

  getTooltipText(): string {
    return this.currentLibrary?.name
      ? this.currentLibrary?.name
      : 'Create a profile to get started';
  }
}
