import { profilerLocationsLayerIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { ThematicPointTileSetLayer } from 'src/app/shared/atlas-mapping/layers/thematic-point-tileset-layer';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { environment } from 'src/environments/environment';
import { ProfilerClassification } from '../model/profiler-base-classification';

export async function showProfilerLibraryLocationsSystemLayer(
  libraryId: number,
  classification: ProfilerClassification | undefined,
  mapService: MapService
) {
  var layer = mapService.getLayer(profilerLocationsLayerIdentifier);

  if (layer) {
    // TODO add it to correct postion based on z-order
    mapService.removeLayer(profilerLocationsLayerIdentifier);
  }
  await addUpdateProfilerLocationsLayer(mapService, libraryId, classification);
}

// dealing with a dynamic tileset we cant change its data so we need to remove it from the deck and recreate it.
async function addUpdateProfilerLocationsLayer(
  mapService: MapService,
  libraryId: number,
  classification: ProfilerClassification | undefined
) {
  var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
    profilerLocationsLayerIdentifier
  );

  if (classification) {
    var mapProps = JSON.parse(
      classification?.mapProperties.replace(/\\"/g, '"').toString()
    );

    let props = {
      id: profilerLocationsLayerIdentifier,
      name: 'Profiled locations',
      description: 'Locations of all records that have been profiled.',
      data: `${environment.baseUrl}api/mapping/profiler/libraries/${libraryId}/classification/${classification.id}/locations`,
      getFillColor: [255, 255, 255, 0], // transparent
      stroked: false,
      getLineColor: [0, 0, 0, 100],
      getPointRadius: 8,
      pointRadiusUnits: 'pixels',
      maxTrigger: 18,
      minTrigger: 11,
      maxZoom: 12,
      ignoreClickEvent: true,
      visible: usersVisiblePreference
    };

    let mergedProps = { ...props, ...mapProps };

    //enforce data and visiblity properties
    (mergedProps.data = `${environment.baseUrl}api/mapping/profiler/libraries/${libraryId}/classification/${classification.id}/locations`),
      (mergedProps.visible = usersVisiblePreference);

    var thematicPointTileset = new ThematicPointTileSetLayer(mergedProps);
    thematicPointTileset.zOrder = 30;
    thematicPointTileset.type = 'ThematicPointTileSet';
    await mapService.addLayer(thematicPointTileset);
  }
}
