import { MapLayerTemplate } from 'src/app/core/models/map-layer-template';
import { AtlasMapMarkerLayer } from '../layers/atlas-map-marker-layer';
import { ThematicAtlasMapMarkerLayer } from '../layers/atlas-thematic-map-marker-layer';
import { BoundaryLayer } from '../layers/boundary-layer';
import { BoundaryTileSetLayer } from '../layers/boundary-tileset-layer';
import { AtlasIconLayer } from '../layers/icon-layer';
import { IconTileSetLayer } from '../layers/icon-tileset-layer';
import { PointLayer } from '../layers/point-layer';
import { PointTileSetLayer } from '../layers/point-tileset-layer';
import { ThematicBoundaryLayer } from '../layers/thematic-boundary-layer';
import { ThematicBoundaryTileSetLayer } from '../layers/thematic-boundary-tileset-layer';
import { ThematicIconLayer } from '../layers/thematic-icon-layer';
import { ThematicIconTileSetLayer } from '../layers/thematic-icon-tileset-layer';
import { ThematicPointLayer } from '../layers/thematic-point-layer';
import { ThematicPointTileSetLayer } from '../layers/thematic-point-tileset-layer';
import { DynamicTilesetService } from '../services/dynamic-tileset.service';

import { MapLayerTypes } from '../types/map-layer.types';
import { getThematicColor, rgbToHex } from './thematic-style-helper';
import { LabelLayer } from '../layers/label-layer';
import { LabelTileSetLayer } from '../layers/label-tileset-layer';
import { AtlasH3HexagonLayer } from '../layers/atlas-h3-hexagon-layer';
import { ThematicH3HexagonTileSetLayer } from '../layers/thematic-h3-hexagon-tileset-layer';
import { ThematicH3HexagonLayer } from '../layers/thematic-h3-hexagon-layer';
import { AtlasLineLayer } from '../layers/atlas-line-layer';
import { LineTileSetLayer } from '../layers/line-tileset-layer';
import { ThematicLineLayer } from '../layers/thematic-line-layer';
import { ThematicLineTileSetLayer } from '../layers/thematic-line-tileset-layer';

export const commonMapLayerProperties = {
  id: '',
  name: '',
  description: '',
  data: [],
  visible: false,
  pickable: true,
  autoHighlight: true,
  ignoreClickEvent: false,
  visibleNotAffectedByTriggerScale: false,
  minTrigger: 0,
  maxTrigger: 20,
  cacheTimeInSeconds: 0,
  disableTooltip: false,
  tooltipItemOrder: [],
  hiddenTooltipColumns: [],
  featureAction: '',
  featureActionUrl: '',
  isTilesetLayer: false
};

const emptyTilesetEndpoint = 'api/mapping/empty-tileset';

export const tilesetMapLayerProperties = {
  data: emptyTilesetEndpoint,
  isTilesetLayer: true,
  cacheTimeInSeconds: 1800
};

export const boundaryMapLayerProperties = {
  highlightLineColor: [50, 50, 50],
  getLineColor: [0, 0, 0, 200],
  getFillColor: [255, 0, 220], // if no scale applied the fill will be pink
  lineWidthMinPixels: 1,
  lineWidthUnits: 'pixels',
  mapLayerType: 'Boundary',
  lds: { mode: 'car', range: 900, range_type: 'time' }, // 900 seconds is 15min
  circle: { unit: 'mile', distance: 2 } // units km & mile
};

export const lineMapLayerProperties = {
  highlightLineColor: [50, 50, 50],
  getLineColor: [255, 0, 220], // if no scale applied the fill will be pink
  lineWidthMinPixels: 1,
  lineWidthUnits: 'pixels',
  mapLayerType: 'Line'
};

export const h3HexagonMapLayerProperties = {
  highlightLineColor: [50, 50, 50],
  getLineColor: [0, 0, 0, 200],
  getFillColor: [255, 0, 220], // if no scale applied the fill will be pink
  lineWidthMinPixels: 1,
  lineWidthUnits: 'pixels',
  mapLayerType: 'H3Hexagon',
  getHexagon: (d: any) => d.h3_id,
  wireframe: false,
  //filled: true
  extruded: false
  // elevationScale: 20
};

export const h3HexagonTilesetMapLayerProperties = {
  highlightLineColor: [50, 50, 50],
  getLineColor: [0, 0, 0, 200],
  getFillColor: [255, 0, 220], // if no scale applied the fill will be pink
  lineWidthMinPixels: 1,
  lineWidthUnits: 'pixels',
  mapLayerType: 'ThematicH3HexagonTileSet',
  wireframe: false,
  extruded: false,
  geoColumn: 'h3',
  formatTiles: 'binary',
  binary: true
};

export const pointMapLayerProperties = {
  getFillColor: [255, 140, 0],
  getLineColor: [50, 50, 50, 100],
  pointRadiusUnits: 'pixels',
  lineWidthUnits: 'pixels',
  lineWidthMinPixels: 1,
  getPointRadius: 3,
  mapLayerType: 'Point',
  radiusAttribute: null
};

export const iconMapLayerProperties = {
  pointType: 'icon',
  getIconSize: () => 24,
  getIconColor: () => [0, 0, 0], // Remember to set mask to true in your icon mapping to enable colouring, if no color supplied defaults to black

  getIcon: () => {
    return {
      url: 'https://insite-dev.app.caci.co.uk/assets/map-icons/material-marker-stroked-24.svg',
      x: 0,
      y: 0,
      width: 24,
      height: 24,
      anchorY: 20,
      mask: true
    };
  },

  iconAnchorY: 20,
  iconHeight: 24,
  iconWidth: 24,
  iconDomain: [],
  icons: [],
  iconAttribute: null,
  iconOther: 'assets/map-icons/material-marker-stroked-24.svg',
  iconSourceUrl: '',
  iconMask: true,
  iconUrl: 'assets/map-icons/material-marker-stroked-24.svg',
  mapLayerType: 'Icon'
};

export const AtlasMapMarkerLayerProperties = {
  pointType: 'icon',
  getIconSize: () => 24,
  getIconColor: () => [0, 0, 0], // Remember to set mask to true in your icon mapping to enable colouring, if no color supplied defaults to black
  iconMapping: {
    marker: {
      x: 0,
      y: 0,
      width: 24,
      height: 24,
      anchorY: 20,
      mask: true
    }
  },
  iconUrl: 'assets/map-icons/atlas-marker-stroked-24.svg',
  mapLayerType: 'InSiteEverywhereMapPin'
};

export const labelMapLayerProperties = {
  mapLayerType: 'Label',
  disableTooltip: true,
  getColor: [0, 0, 0],
  getSize: 32,
  getAngle: 0,
  getTextAnchor: 'middle',
  getAlignmentBaseline: 'center',
  labelAttr: 'name',
  background: true,
  getBackgroundColor: [255, 255, 255, 0] // transparent
};

export function getMapLayerDefaultProperties(
  mapLayerType: MapLayerTypes,
  jwtToken: string
) {
  const mapLayerTypes = {
    Boundary: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...boundaryMapLayerProperties
      };
    },
    Point: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...pointMapLayerProperties
      };
    },
    Icon: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...iconMapLayerProperties
      };
    },
    CustomIcon: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...iconMapLayerProperties
      };
    },
    InSiteEverywhereMapPin: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...AtlasMapMarkerLayerProperties
      };
    },
    Label: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...labelMapLayerProperties
      };
    },
    H3Hexagon: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...h3HexagonMapLayerProperties
      };
    },

    Line: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...lineMapLayerProperties
      };
    },

    LabelTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...labelMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    BoundaryTileSet: () => {
      return {
        ...commonMapLayerProperties,
        ...boundaryMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },

    PointTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...pointMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },

    IconTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...iconMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    LineTileSet: () => {
      return {
        ...commonMapLayerProperties,
        ...lineMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    ThematicBoundary: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...boundaryMapLayerProperties
      };
    },
    ThematicPoint: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...pointMapLayerProperties
      };
    },
    ThematicIcon: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...iconMapLayerProperties
      };
    },
    ThematicInSiteEverywhereMapPin: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...AtlasMapMarkerLayerProperties
      };
    },
    ThematicLine: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...lineMapLayerProperties
      };
    },
    ThematicBoundaryTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...boundaryMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    ThematicPointTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...pointMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    ThematicIconTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...iconMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    ThematicH3HexagonTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...h3HexagonTilesetMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    },
    ThematicH3Hexagon: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...h3HexagonMapLayerProperties
      };
    },
    ThematicLineTileSet: (loadOptions: any) => {
      return {
        ...commonMapLayerProperties,
        ...loadOptions,
        ...lineMapLayerProperties,
        ...tilesetMapLayerProperties
      };
    }
  };

  return mapLayerTypes[mapLayerType](
    buildMapLayerLoadOptionsProperties(jwtToken)
  );
}

export function buildMapLayerLoadOptionsProperties(jwtToken: string) {
  return {
    loadOptions: {
      fetch: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'cache-control': 'max-age=5'
        }
      }
    }
  };
}

export function buildMapLayer(
  mapLayerType: MapLayerTypes,
  template: MapLayerTemplate
) {
  const mapLayerTypes = {
    Boundary: (layerProperties: any) => new BoundaryLayer(layerProperties),
    Point: (layerProperties: any) => new PointLayer(layerProperties),
    Icon: (layerProperties: any) => new AtlasIconLayer(layerProperties),
    CustomIcon: (layerProperties: any) => new AtlasIconLayer(layerProperties),
    InSiteEverywhereMapPin: (layerProperties: any) =>
      new AtlasMapMarkerLayer(layerProperties),
    Label: (layerProperties: any) => new LabelLayer(layerProperties),
    Line: (layerProperties: any) => new AtlasLineLayer(layerProperties),
    H3Hexagon: (layerProperties: any) =>
      new AtlasH3HexagonLayer(layerProperties),
    LabelTileSet: (layerProperties: any) =>
      new LabelTileSetLayer(layerProperties),
    BoundaryTileSet: (layerProperties: any) =>
      new BoundaryTileSetLayer(layerProperties),
    PointTileSet: (layerProperties: any) =>
      new PointTileSetLayer(layerProperties),
    IconTileSet: (layerProperties: any) =>
      new IconTileSetLayer(layerProperties),
    LineTileSet: (layerProperties: any) =>
      new LineTileSetLayer(layerProperties),
    ThematicBoundary: (layerProperties: any) =>
      new ThematicBoundaryLayer(layerProperties),
    ThematicPoint: (layerProperties: any) =>
      new ThematicPointLayer(layerProperties),
    ThematicIcon: (layerProperties: any) =>
      new ThematicIconLayer(layerProperties),
    ThematicInSiteEverywhereMapPin: (layerProperties: any) =>
      new ThematicAtlasMapMarkerLayer(layerProperties),
    ThematicBoundaryTileSet: (layerProperties: any) =>
      new ThematicBoundaryTileSetLayer(layerProperties),
    ThematicPointTileSet: (layerProperties: any) =>
      new ThematicPointTileSetLayer(layerProperties),
    ThematicIconTileSet: (layerProperties: any) =>
      new ThematicIconTileSetLayer(layerProperties),
    ThematicH3HexagonTileSet: (layerProperties: any) =>
      new ThematicH3HexagonTileSetLayer(layerProperties),
    ThematicH3Hexagon: (layerProperties: any) =>
      new ThematicH3HexagonLayer(layerProperties),
    ThematicLine: (layerProperties: any) =>
      new ThematicLineLayer(layerProperties),
    ThematicLineTileSet: (layerProperties: any) =>
      new ThematicLineTileSetLayer(layerProperties)
  };

  return mapLayerTypes[mapLayerType](getLayerProperites(template));
}

export function getLayerProperites(template: MapLayerTemplate) {
  var layerPorperties = {};
  if (template) {
    var props = {
      id: template.identifier,
      name: template.name,
      description: template.description,
      featureAction: template.featureAction,
      featureActionUrl: template.featureActionUrl
    };

    // regex to replace escaped double quotes template.properties.replace(/\\"/g, '"'));
    var templateProps = JSON.parse(template.properties.toString());

    layerPorperties = {
      ...props,
      ...templateProps
    };
  }

  return layerPorperties;
}

export async function getTileSetLayerData(
  dynamicTilesetService: DynamicTilesetService,
  layerProperties: any,
  jwtToken: string,
  apiBaseUrl: string
) {
  // if no layer data get empty tilset json
  if (layerProperties && layerProperties.data.length == 0) {
    layerProperties.data = apiBaseUrl + emptyTilesetEndpoint;
  }

  var dataWithCacheTime = layerProperties.data.includes('?')
    ? `${layerProperties.data}&cachetimeinseconds=${layerProperties.cacheTimeInSeconds}`
    : `${layerProperties.data}?cachetimeinseconds=${layerProperties.cacheTimeInSeconds}`;

  const tileJson = await dynamicTilesetService
    .getDynamicLayerObject(dataWithCacheTime)
    .toPromise();

  if (tileJson.tiles) {
    layerProperties.data = tileJson.tiles[0];

    layerProperties.loadOptions = {
      fetch: {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    };
  }

  return layerProperties;
}

// TEST code possibly remove
export async function getH3TileSetLayerData(
  dynamicTilesetService: DynamicTilesetService,
  layerProperties: any,
  jwtToken: string
) {
  // if no layer data dont try get the tileJson.tiles[0];
  if (layerProperties && layerProperties.data.length == 0) {
    return layerProperties;
  }

  var dataWithCacheTime = layerProperties.data.includes('?')
    ? `${layerProperties.data}&cachetimeinseconds=${layerProperties.cacheTimeInSeconds}`
    : `${layerProperties.data}?cachetimeinseconds=${layerProperties.cacheTimeInSeconds}`;

  const tileJson = await dynamicTilesetService
    .getDynamicLayerObject(dataWithCacheTime)
    .toPromise();

  layerProperties.data = tileJson;

  layerProperties.loadOptions = {
    fetch: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    }
  };

  return layerProperties;
}

export function svgToDataURL(svg: any) {
  //return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
}

// this is for the standard Atals Pin
export function createSvgIcon(fillColour: any) {
  let hexColor = rgbToHex(fillColour[0], fillColour[1], fillColour[2]);

  return `\
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke: #404040;
          stroke-miterlimit: 10;
        }

        .cls-2 {

          fill: ${hexColor};
        }
      </style>
    </defs>
    <g/>
    <g id="New_Map_Pin_Style" data-name="New Map Pin Style">
      <path class="cls-2" d="M21,9.28C21,4.16,16.75,0,11.5,0S2,4.16,2,9.28c0,3.33,1.8,6.24,4.5,7.88l3.56,6.02c.64,1.09,2.25,1.09,2.89,0l3.56-6.02c2.7-1.64,4.5-4.55,4.5-7.88Z"/>
      <path class="cls-1" d="M21,9.28C21,4.16,16.75,0,11.5,0S2,4.16,2,9.28c0,3.33,1.8,6.24,4.5,7.88l3.56,6.02c.64,1.09,2.25,1.09,2.89,0l3.56-6.02c2.7-1.64,4.5-4.55,4.5-7.88Z"/>
    </g>
  </svg>
    `;
}

// this is for the standard Atals Pin
export function createSvgThematicIcon(data: any, layerProperties: any) {
  let getColor: any = getThematicColor(
    layerProperties.iconThematicScale,
    layerProperties.iconThematicScaleConfig
  );

  layerProperties.rgbHexDictionary;

  var color = getColor(data);
  let colorKey =
    color[0].toString() + color[1].toString() + color[2].toString();

  let hexColor = layerProperties.rgbHexDictionary.get(colorKey);
  if (hexColor === undefined) {
    hexColor = rgbToHex(color[0], color[1], color[2]);
    layerProperties.rgbHexDictionary.set(colorKey, hexColor);
  }

  return `\
<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
  <defs>
    <style>
      .cls-1 {
        fill: none;
        stroke: #404040;
        stroke-miterlimit: 10;
      }

      .cls-2 {

        fill: ${hexColor};
      }
    </style>
  </defs>
  <g/>
  <g id="New_Map_Pin_Style" data-name="New Map Pin Style">
    <path class="cls-2" d="M21,9.28C21,4.16,16.75,0,11.5,0S2,4.16,2,9.28c0,3.33,1.8,6.24,4.5,7.88l3.56,6.02c.64,1.09,2.25,1.09,2.89,0l3.56-6.02c2.7-1.64,4.5-4.55,4.5-7.88Z"/>
    <path class="cls-1" d="M21,9.28C21,4.16,16.75,0,11.5,0S2,4.16,2,9.28c0,3.33,1.8,6.24,4.5,7.88l3.56,6.02c.64,1.09,2.25,1.09,2.89,0l3.56-6.02c2.7-1.64,4.5-4.55,4.5-7.88Z"/>
  </g>
</svg>
  `;
}
