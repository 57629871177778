import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Scenario } from 'src/app/core/models/scenario';
import { formValueExistInArrayValidator } from 'src/app/shared/utils/form-validators/form-value-exist-in-array.validator';

@Component({
  selector: 'atlas-add-edit-scenario-form',
  templateUrl: './add-edit-scenario-form.component.html',
  styleUrls: ['./add-edit-scenario-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditScenarioFormComponent implements OnInit {
  private _scenario: Scenario | null = null;
  @Input()
  set scenario(newValue: Scenario | null) {
    this._scenario = newValue;
  }
  get scenario(): Scenario | null {
    return this._scenario;
  }

  private _userScenarios: string[] = [];
  @Input()
  set userScenarios(newValue: string[]) {
    this._userScenarios = [...newValue];
    this.baseScenarios = [...this._userScenarios, 'Master'];
    this.deltaScenarios = this._userScenarios;
    // Add validator to avoid scenarios with the same name
    this.addEditScenarioForm?.controls.scenarioName.setValidators([
      Validators.required,
      formValueExistInArrayValidator(
        this.scenario
          ? this.baseScenarios.filter((item) => item !== this.scenario?.name)
          : this.baseScenarios
      )
    ]);
    this.initializeDeltaScenariosList();
  }
  get userScenarios(): string[] {
    return this._userScenarios;
  }

  @Input() deltaHint =
    'Type to search for other scenarios or select from the list';
  @Input() baseHint =
    'Type to search for other scenarios or select from the list';

  addEditScenarioForm: UntypedFormGroup;
  baseScenarios: string[] = [];
  baseFixedScenarios = ['Master'];
  deltaScenarios: string[];

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const initialValue: Scenario = this.scenario || ({} as Scenario);
    this.addEditScenarioForm = this.createScenarioForm(initialValue);
  }

  createScenarioForm(initialScenarioValue: Scenario) {
    var form = this.fb.group({
      id: initialScenarioValue.id,
      scenarioName: [initialScenarioValue.name, Validators.required],
      baseScenarios: [
        initialScenarioValue.base
          ? [
              ...new Set([
                ...this.baseFixedScenarios,
                ...initialScenarioValue.base.map((b) => b.supplyPointGroup.name)
              ])
            ]
          : this.baseFixedScenarios
      ],
      deltaScenarios: []
    });
    return form;
  }

  emptyScenarioName() {
    if (this.addEditScenarioForm.controls.scenarioName.value) {
      return (
        this.addEditScenarioForm.controls.scenarioName.value?.trim().length == 0
      );
    }

    return this.addEditScenarioForm.controls.scenarioName.errors?.required;
  }

  uniqueScenarioName() {
    return !this.addEditScenarioForm.controls.scenarioName.errors
      ?.valueAlreadyExist;
  }

  invalidForm(): boolean {
    return (
      !(this.addEditScenarioForm && this.addEditScenarioForm.controls) ||
      !this.addEditScenarioForm.dirty ||
      !this.addEditScenarioForm.controls?.scenarioName.valid ||
      !this.addEditScenarioForm.controls?.baseScenarios.valid ||
      !this.addEditScenarioForm.controls?.deltaScenarios.valid ||
      this.emptyScenarioName() ||
      !this.uniqueScenarioName()
    );
  }

  // Initialize the Delta scenarios list removing those scenarios already chosen in Base scenario list
  initializeDeltaScenariosList() {
    const selectedBaseScenarios = this._scenario?.base
      ? this._scenario.base.map((b) => b.supplyPointGroup.name)
      : [];

    this.onBaseScenariosChanged(selectedBaseScenarios);
  }

  onDeltaScenariosChanged(values: any) {
    // Selected Delta Scenarios cannot be present in baseScenarios array
    this.baseScenarios = [
      ...this.userScenarios.filter((item) => !values.includes(item)),
      'Master'
    ];
  }

  onBaseScenariosChanged(values: any) {
    // Selected Base Scenarios cannot be present in deltaScenarios array
    this.deltaScenarios = this.userScenarios.filter(
      (item) => !values.includes(item)
    );
  }
}
