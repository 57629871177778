<div class="name">
  <div class="name-bold">{{ dataSource?.companyName }}</div>
  <div>Total consumption</div>
</div>
<div class="display-flex usage-border">
  <div class="panel-line usage-line"></div>
  <div class="panel usage-panel-background">
    <div>
      <div class="usage-details">
        <div class="usage">
          {{ dataSource?.totalGeocodedRecords ?? 0 | number : '2.' }}
        </div>
        <div class="subscription">
          of {{ dataSource?.annualRecordLimit ?? 0 | number : '2.' }}
        </div>
      </div>
      <div>
        <div class="usage-percentage">
          {{ dataSource?.companyUsage ?? 0 | number : '1.2-2' }}% consumed for
          the period
          <div class="renewal">
            {{ dataSource?.licenseStartDate | date : 'dd MMM yyyy' }} to
            {{ dataSource?.renewalDate | date : 'dd MMM yyyy' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
