import { buildMapLayerLegend } from '../helpers/map-Legend-helper';
import { DynamicLayerService } from '../services/dynamic-layer-service';
import { MapLayerTypes } from '../types/map-layer.types';
import {
  getThematicColor,
  getIconBasedOnAttribute,
  getRadiusBasedOnAttribute,
  getIconSizeOnAttribute
} from '../helpers/thematic-style-helper';
import { PolygonSelectionService } from '../services/polygon-selection-service';
import { MapService } from '../services/map.service';

// Base class for layers
export class Layer {
  id: string = '';
  visible: boolean = true;
  data: string = '';
  apiBaseUrl: string = '';
  jwtToken: string = '';
  userDefinedProps: any = {};
  zOrder: number;
  type: MapLayerTypes;
  featureAction: string = '';
  featureActionUrl: string = '';
  isTilesetLayer: boolean = false;
  group?: string;
  isGroup: boolean = false;

  constructor(...args: any[]) {
    if (args.length > 0) {
      this.userDefinedProps = Object.assign({}, ...args);
    }
  }

  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicLayerService?: DynamicLayerService,
    polygonSelectionService?: PolygonSelectionService
  ) {
    // Implement in child
    return {};
  }

  getLegendInfo(layerProperties: any) {
    // implemented in child
    return {};
  }

  show() {
    this.visible = true;
    return { visible: true };
  }

  hide() {
    this.visible = false;
    return { visible: false };
  }

  UpdateDataSource(endpoint: string) {
    this.data = endpoint;
    return { data: endpoint };
  }

  calculateTriggerScaleText(layerProperties: any) {
    if (
      (layerProperties.minTrigger == 0 && layerProperties.maxTrigger == 20) ||
      layerProperties.minTrigger == undefined ||
      layerProperties.maxTrigger == undefined
    ) {
      return 'Always visible';
    }
    return (
      'This layer is visible from a zoom of ' +
      `<b> &nbsp;
      ${layerProperties.minTrigger}
      </b>` +
      ' - ' +
      `<b>
      ${layerProperties.maxTrigger}
      </b>`
    );
  }

  updateDataProperty(layerProperties: any) {
    if (
      layerProperties &&
      layerProperties.data &&
      !Array.isArray(layerProperties.data) &&
      layerProperties.data.toLowerCase().startsWith('api')
    ) {
      layerProperties.data = this.apiBaseUrl + layerProperties.data;
    }
  }

  ensureLayerHasIdAndDescriptionProperties(layerProperties: any) {
    this.ensureLayerHasIdProperty(layerProperties);
    this.ensureLayerHasDescriptionProperty(layerProperties);
  }

  setJwtTokenAndApiBaseUrl(jwtToken: string, apiBaseUrl: string) {
    this.jwtToken = jwtToken;
    this.apiBaseUrl = apiBaseUrl;
  }

  updateGetIconProperties(layerProperties: any) {
    if ('iconSize' in layerProperties) {
      layerProperties.getIconSize = () => layerProperties.iconSize;
    }

    if ('icon' in layerProperties) {
      layerProperties.getIcon = () => layerProperties.icon;
    }

    if ('iconColor' in layerProperties) {
      layerProperties.getIconColor = () => layerProperties.iconColor;
    }

    if ('iconThematicScale' in layerProperties) {
      layerProperties.getIconColor = getThematicColor(
        layerProperties.iconThematicScale,
        layerProperties.iconThematicScaleConfig
      );
    }
  }

  updateGetFillColorProperty(layerProperties: any) {
    if ('fillThematicScale' in layerProperties) {
      layerProperties.getFillColor = getThematicColor(
        layerProperties.fillThematicScale,
        layerProperties.fillThematicScaleConfig
      );
    }
  }

  updateGetIconProperty(layerProperties: any) {
    if ('iconAttribute' in layerProperties) {
      layerProperties.getIcon = (props: any) => {
        return getIconBasedOnAttribute(
          layerProperties.iconAttribute,
          layerProperties.iconDomain,
          layerProperties.icons,
          layerProperties.iconSourceUrl,
          layerProperties.iconOther,
          layerProperties.iconMask,
          layerProperties.iconAnchorY,
          layerProperties.iconWidth,
          layerProperties.iconHeight,
          props
        );
      };
    }
  }

  updateGetPointRadiusProperty(layerProperties: any) {
    if ('radiusAttribute' in layerProperties) {
      layerProperties.getPointRadius = (props: any) => {
        return getRadiusBasedOnAttribute(
          layerProperties.radiusAttribute,
          props
        );
      };
    }
  }

  updateGetIconSizeProperty(layerProperties: any) {
    if ('iconSizeAttribute' in layerProperties) {
      layerProperties.getIconSize = (props: any) => {
        return getIconSizeOnAttribute(layerProperties.iconSizeAttribute, props);
      };
    }
  }

  updateGetLineColorProperty(layerProperties: any) {
    if ('lineThematicScale' in layerProperties) {
      layerProperties.getLineColor = getThematicColor(
        layerProperties.lineThematicScale,
        layerProperties.lineThematicScaleConfig
      );
    }
  }

  mergeDefaultPropertiesWitUserDefinedProperties(defaultLayerProps: any) {
    let layerProperties = {
      ...defaultLayerProps,
      ...this.userDefinedProps,
      zOrder: this.zOrder,
      type: this.type
    };

    layerProperties.visibleNotAffectedByTriggerScale = layerProperties.visible;

    this.updateDataProperty(layerProperties);

    layerProperties.legendTriggerScaleText =
      this.calculateTriggerScaleText(layerProperties);

    this.ensureLayerHasIdAndDescriptionProperties(layerProperties);

    this.updateGetFillColorProperty(layerProperties);
    this.updateGetIconProperties(layerProperties);
    this.updateGetLineColorProperty(layerProperties);
    this.updateGetIconProperty(layerProperties);
    this.updateGetIconSizeProperty(layerProperties);
    this.updateGetPointRadiusProperty(layerProperties);

    layerProperties.legendInfo = buildMapLayerLegend(
      this.type,
      layerProperties
    );

    layerProperties.isGroup = this.checkIfGroup(layerProperties);

    return layerProperties;
  }

  private checkIfGroup(layerProperties: any) {
    if (layerProperties.group == null) {
      return false;
    }

    if (layerProperties.group == '') {
      return false;
    }

    return true;
  }

  private ensureLayerHasIdProperty(layerProperties: any) {
    if (
      layerProperties != undefined &&
      layerProperties.name != null &&
      layerProperties.id != ''
    ) {
      return;
    }
    throw new Error('Layer id property cannot be null or empty');
  }

  private ensureLayerHasDescriptionProperty(layerProperties: any) {
    if (
      layerProperties != undefined &&
      layerProperties.name != null &&
      layerProperties.name != ''
    ) {
      return;
    }
    throw new Error('Layer description property cannot be null or empty');
  }
}
