<div class="tab-padding">
  <div class="title"></div>
  <div class="seperate">
    <atlas-user-profiled-records-usage-summary
      [dataSource]="(userUsageSummary$ | async)!"
    ></atlas-user-profiled-records-usage-summary>
    <atlas-company-profiled-records-usage-summary
      class="company-usage-padding"
      [dataSource]="(companyUsageSummary$ | async)!"
    ></atlas-company-profiled-records-usage-summary>
  </div>

  <br />

  <span
    class="link"
    *ngIf="(layoutService.isDesktopView$ | async) && showGrid"
    (click)="onToggleGridChart()"
    ><u>View Chart</u></span
  >

  <span
    class="link"
    *ngIf="(layoutService.isDesktopView$ | async) && !showGrid"
    (click)="onToggleGridChart()"
    ><u>View Grid</u></span
  >

  <div class="chart-grid-height">
    <atlas-users-profiled-records-usage-list
      class="title-padding"
      [dataSource]="allUsersUsage"
      *ngIf="(layoutService.isDesktopView$ | async) && showGrid"
    ></atlas-users-profiled-records-usage-list>
    <div
      *ngIf="(layoutService.isDesktopView$ | async) && !showGrid"
      class="title-padding chart-container"
    >
      <atlas-profiled-records-usage-chart></atlas-profiled-records-usage-chart>
    </div>
  </div>
</div>
