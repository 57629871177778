import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { AtlasNumericChipListComponent } from 'src/app/shared/components/atlas-chip-list/atlas-numeric-chip-list.component';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import {
  getShapeRangeLimit,
  getShapeTypeName,
  isShapeDuplicate,
  isShapeSizeExceeded
} from 'src/app/locator/helpers/locator-shape.helper';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults
} from '../../models/locator-shape-defaults';

@Component({
  selector: 'atlas-locator-catchment-chip-list',
  templateUrl:
    '../../../shared/components/atlas-chip-list/atlas-chip-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AtlasLocatorCatchmentChipListComponent
    },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'chip-list-autocomplete-overlay-pane'
      }
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AtlasLocatorCatchmentChipListComponent)
    }
  ],
  styleUrls: [
    '../../../shared/components/atlas-chip-list/atlas-chip-list.component.less'
  ]
})
export class AtlasLocatorCatchmentChipListComponent extends AtlasNumericChipListComponent {
  @Input() currentShapes: LocatorShape[] | null;
  @Input() shapeType: LocatorShapeTypes;
  @Input() isDuplicate: boolean;
  @Input() shapeCircleConfig: CircleShapeDefaults;
  @Input() shapeCarDriveTimeConfig: DriveTimeShapeDefaults;
  @Input() shapeWalkDriveTimeConfig: DriveTimeShapeDefaults;
  @Input() shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults;

  private _driveCatchmentSpeed: string;
  @Input()
  get driveCatchmentSpeed() {
    return this._driveCatchmentSpeed;
  }
  set driveCatchmentSpeed(value: string) {
    this._driveCatchmentSpeed = value;
    this.onValidationChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const parentValidationError = super.validate(control);
    if (parentValidationError) {
      return parentValidationError;
    }
    const numericValues = control.value.map((val: String) => Number(val));
    if (this.isShapeDuplicate(numericValues, this.driveCatchmentSpeed)) {
      this.errorMessage =
        'Shape size already exists. Please choose a different size.';
      return {
        catchmentIsUnique: { noDuplicateCatchment: false }
      };
    }
    if (this.isShapeSizeExceeded(numericValues)) {
      this.errorMessage =
        'You have exceeded the limit of ' +
        this.getShapeRangeLimit() +
        ' units for this catchment';
      return {
        catchmentSizeExceeded: { isShapeSizeExceeded: false }
      };
    }
    if (
      this.shapeType === LocatorShapeTypes.Walk ||
      this.shapeType === LocatorShapeTypes.Car ||
      this.shapeType === LocatorShapeTypes.PublicTransport
    ) {
      if (numericValues.some((value: number) => !Number.isInteger(value))) {
        this.errorMessage = 'Please enter only positive integer values.';
        return {
          invalidValue: { invalid: true }
        };
      }
    }
    return null;
  }

  isShapeDuplicate(sizeValues: number[], driveCatchmentSpeed: string): boolean {
    return isShapeDuplicate(
      sizeValues,
      this.currentShapes,
      this.shapeType,
      driveCatchmentSpeed
    );
  }

  isShapeSizeExceeded(sizeValues: number[]) {
    return isShapeSizeExceeded(
      this.shapeCircleConfig,
      this.shapeCarDriveTimeConfig,
      this.shapeWalkDriveTimeConfig,
      this.shapePublicTransportDriveTimeConfig,
      this.shapeType,
      sizeValues
    );
  }

  getShapeRangeLimit() {
    return getShapeRangeLimit(
      this.shapeCircleConfig,
      this.shapeCarDriveTimeConfig,
      this.shapeWalkDriveTimeConfig,
      this.shapePublicTransportDriveTimeConfig,
      this.shapeType
    );
  }
}
