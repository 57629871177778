import { SharedReportViewerToken } from '../models/shared-report-viewer-token';
import { PowerBiReportViewerService } from '../services/report-viewer.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';

export function getSelectedPbiReportUrl(
  powerBiReportViewerService: PowerBiReportViewerService,
  router: Router,
  workspaceId: string,
  powerBiReportId: string,
  params: any[]
) {
  // Get the embed config from the service and set the reportConfigResponse
  return powerBiReportViewerService
    .getSharedReportToken(workspaceId, powerBiReportId, params)
    .pipe(
      switchMap((sharedReportToken: SharedReportViewerToken) => {
        const pbiReportViewerBaseUrl = `${environment.powerBiReportingAppBaseUrl}report-viewer`;
        return of(
          getPbiReportUrl(
            router,
            sharedReportToken.token,
            pbiReportViewerBaseUrl
          )
        );
      }),
      catchError(handleError)
    );
}

function getPbiReportUrl(
  router: Router,
  sharedReportToken: string,
  reportingAppBaseUrl: string
) {
  const urlParams = router.serializeUrl(
    router.createUrlTree(['.'], {
      queryParams: {
        token: sharedReportToken
      }
    })
  );

  const pbiReportUrl = `${reportingAppBaseUrl}${urlParams}`;
  return pbiReportUrl;
}

function handleError(error: HttpErrorResponse) {
  // Return an observable with a user-facing error message.
  return throwError(() => new Error('Report not loaded, ' + error.message));
}
