import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';

@Directive({
  selector: '[atlasDxiColumnUnScheduledTemplate]',
})
export class AtlasDxiColumnUnScheduledTemplateDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.cellTemplate = function (cellElement: any, cellInfo: any) {
      cellElement.append(cellInfo.text);
      styleUnScheduled(cellInfo.text, cellElement);
    };
  }
}

function styleUnScheduled(text: any, cellElement: any) {
  // tslint:disable-next-line: radix

  if (text.length > 0) {
    if (parseInt(text) > 0) {
      cellElement.className = 'atlas-dxi-unscheduled-column-red';
    } else {
      cellElement.className = 'atlas-dxi-unscheduled-column-green';
    }
  }
}
