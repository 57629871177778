<atlas-panel-header>
  <div header>Properties</div>
  <div description>Swipe left to access the Reports panel</div>
</atlas-panel-header>

<mat-divider *ngIf="(canShowPropertiesPanel$ | async)"></mat-divider>

<div
  class="location-info-container"
  *ngIf="
    (canShowPropertiesPanel$ | async) && !(isProfilerFeatureSelected$ | async)
  "
>
  <atlas-location-panel
    [address]="(selectedPoint$ | async)?.address"
    [selectedLocation]="(selectedPoint$ | async)?.location"
    [isSupplyPoint]="isSupplyPoint$ | async"
    [isSupplyPointClosed]="isSupplyPointClosed$ | async"
    [isNetworkPlanningReadonlySystem]="isNetworkPlanningReadonlySystem$ | async"
    [selectedSupplyPoint]="originalSelectedSupplyPoint$ | async"
    [areMultipleLocationsSelected]="areMultipleLocationsSelected$ | async"
  ></atlas-location-panel>
</div>

<mat-divider></mat-divider>

<div>
  <div *ngIf="(canShowPropertiesPanel$ | async)">
    <atlas-spatial-modeller-properties
      *ngIf="isSMFeatureSelected$ | async"
    ></atlas-spatial-modeller-properties>
    <atlas-locator-properties
      *ngIf="isLocatorFeatureSelected$ | async"
      [areMultipleLocationsSelected]="areMultipleLocationsSelected$ | async"
    ></atlas-locator-properties>
  </div>
  <atlas-profiler-properties
    *ngIf="
      (isProfilerFeatureSelected$ | async) &&
      (profilerStatus$ | async) === 'ReportAggregateCompleted'
    "
  ></atlas-profiler-properties>
</div>
