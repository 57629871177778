import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import * as fromServices from './services';
import * as fromComponents from './components';
import { MaterialComponentsModule } from '../shared/material/material-components.module';
import { DevExpressComponentsModule } from '../shared/dev-express/dev-express-components.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    DevExpressComponentsModule
  ],
  exports: [...fromComponents.components],
  providers: [...fromServices.services]
})
export class AtlasLocatorModule {}
