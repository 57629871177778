import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClearStateService } from 'src/app/core/services/clear-state.service';

@Component({
  selector: 'atlas-release-notes-workspace',
  templateUrl: './release-notes-workspace.component.html',
  styleUrls: ['./release-notes-workspace.component.less']
})
export class ReleaseNotesWorkspaceComponent {
  constructor(
    private route: Router,
    private clearStateService: ClearStateService
  ) {}
  onBack() {
    this.clearStateService.clearFeatureState();
    this.route.navigate(['/home']);
  }
}
