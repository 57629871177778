import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppFeatures } from '../models/feature';

@Injectable({
  providedIn: 'root'
})
export class AppFeatureService {

  featureApiUrl = `${environment.baseUrl}api/features`;

  constructor(private http: HttpClient) {}

  public getAppFeatures(): Observable<AppFeatures> {
    return this.http.get<AppFeatures>(this.featureApiUrl);
  }

}
