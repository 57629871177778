import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Part } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid'
import { environment } from 'src/environments/environment';
import * as fromProfilerStore from 'src/app/profiler-store';
import {
  abortMultipartUpload,
  fileUploadError,
  fileUploadUpdateFileKey,
  initiateMultipartUploadAttempt,
  metaDataUploadAttempt,
  multipartUploadComplete
} from 'src/app/profiler-store/actions/profiler-import.actions';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private atlasProfilerImportUrl = `${environment.baseUrl}api/profiler/import`;
  private guidFilename: string;

  constructor(
    private http: HttpClient,
    private store$: Store<fromProfilerStore.State>) {}

  public uploadFile(file: File) {
    this.guidFilename = `${uuidv4()}.csv`;
    this.store$.dispatch(fileUploadUpdateFileKey({ fileKey: this.guidFilename }));

    if (file.size <= singleFileUploadSize) {
      this.uploadFileInOneGo(file);
    }
    else {
      this.store$.dispatch(
        initiateMultipartUploadAttempt({ file: file, fileKey: this.guidFilename })
      );
    }
  }

  public async uploadFileInOneGo(file: File) {
    try {
      const fileData = await this.readFileAsText(file);
      const response: any = await this.getPresignedUrl(this.guidFilename);
      await this.uploadFileAsText(response.presignedUrl, fileData);
      this.store$.dispatch(metaDataUploadAttempt({ fileKey: this.guidFilename }));
    } 
    catch (error) {
      this.handleUploadError(file.name, error);
    }
  }

  public async uploadFileMultiPart(file: File, uploadId: string) {
    try {
      let eTagParts: Part[] = [];
      const partCount = Math.floor(file.size / multipartFileUploadMinimumSize);
      const uploadPromises: Promise<void>[] = [];
      const partSize = Math.ceil(file.size / partCount);

      // Divide file by partCount and upload each part in parallel.
      for (let i = 0; i < partCount; i++) {
        const start = i * partSize;
        const end = start + partSize;
        const partNumber = i + 1;

        // Set up all the uploads as async functions that return a promise.
        uploadPromises.push(
          (async () => {
            const response: any = await this.getMultipartPresignedUrl(this.guidFilename, uploadId, partNumber);
            const fileDataChunk = await this.readFileChunk(file, start, end);
            const uploadResponse: any = await this.uploadFileAsBuffer(response.presignedUrl, fileDataChunk);
            const etag = uploadResponse.headers.get('ETag')!.replace(/"/g, '');
            eTagParts.push({ ETag: etag, PartNumber: partNumber });
          })()
        )
      }

      // Invoke all the upload functions and wait for completion.
      await Promise.all(uploadPromises);

      this.store$.dispatch(multipartUploadComplete({
        uploadId,
        filename: this.guidFilename,
        parts: eTagParts
      }))

    }
    catch (err) {
      this.store$.dispatch(abortMultipartUpload({
        uploadId,
        filename: this.guidFilename,
        errorMessage: err
      }))
    }
  }

  private async getPresignedUrl(filename: string) {
    return this.http.get<{ presignedUrl: string }>(`${this.atlasProfilerImportUrl}/presigned-url/${filename}`).toPromise();
  }

  private async getMultipartPresignedUrl(filename: string, uploadId: string, partNumber: number) {
    return await this.http.get<{ presignedUrl: string }>(
      `${this.atlasProfilerImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`).toPromise();
  }

  private async uploadFileAsBuffer(url: string, data: ArrayBuffer) {
    const headers = { 'Content-Type': 'application/octet-stream' };
    return await this.http.put(url, data, { headers, observe: 'response' }).toPromise();
  }

  private async uploadFileAsText(url: string, fileData: any) {
    const headers = { 'Content-Type': 'text/csv' };
    await this.http.put(url, fileData, { headers }).toPromise();
  }

  private async readFileAsText(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.onerror = (e: any) => reject(e.target.error);
      reader.readAsText(file);
    });
  }

  private async readFileChunk(file: File, start: number, end: number): Promise<ArrayBuffer> {
    const fileSlice = file.slice(start, end);
    return await fileSlice.arrayBuffer();
  }

  private handleUploadError(filename: string, error: any) {
    this.store$.dispatch(fileUploadError({ fileKey: filename, errors: error }));
  }

  // Example code to get the progress of the file upload, leaving here for reference and future comeback.
  // The callback method example is given below, it calculates the upload progress.
  // 
  // Callback method:
  // const progressCallback = (event: ProgressEvent) => {
  //   const progress = (event.loaded / event.total) * 100;
  //   console.log(`Overall Progress: ${progress.toFixed(2)}%`);
  // };
  // 
  // Example call to the upload method with callback.
  // const uploadResponse = await this.uploadFileAsBuffer(response.presignedUrl, fileDataChunk, progressCallback);
  // 
  // private async uploadFileAsBuffer(url: string, data: ArrayBuffer, progressCallback?: (event: ProgressEvent) => void) {
  //   let headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
  //   let options: { headers: HttpHeaders, observe: 'events', reportProgress?: boolean } = {
  //     headers: headers,
  //     observe: 'events', // Use 'events' to capture progress events
  //   };
  
  //   if (progressCallback) {
  //     options.reportProgress = true;
  //   }
  
  //   return await this.http.put(url, data, options)
  //     .pipe(
  //       tap((event:any) => {
  //         if (progressCallback && event.type === HttpEventType.UploadProgress) {
  //           progressCallback(event);
  //         }
  //       }),
  //       last(), // Take the last emitted event (response)
  //     )
  //     .toPromise();
  // }


}




