import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';

import * as fromProfiler from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { swapProfilerLibraryAttempt } from 'src/app/profiler-store/actions/profiler-library.actions';
import { DeleteProfilerLibraryConfirmationDialogComponent } from '../delete-profiler-library-confirmation-dialog/delete-profiler-library-confirmation-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'atlas-swap-profiler-library-dialog',
  templateUrl: './swap-profiler-library-dialog.component.html',
  styleUrls: ['./swap-profiler-library-dialog.component.less']
})
export class SwapProfilerLibraryDialogComponent {
  dialogMode: DialogMode;
  userLibraries: ProfilerLibrary[] = [];
  private subscription = new Subscription();
  constructor(
    private profilerService: ProfilerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      currentLibraryId: number;
    },
    private store$: Store<fromProfiler.State>,
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<SwapProfilerLibraryDialogComponent>
  ) {}

  ngOnInit() {
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllLibraries() {
    this.subscription.add(
      this.profilerService
        .getAllProfilerLibraries()
        .subscribe((libraries: ProfilerLibrary[]) => {
          this.userLibraries = libraries;
        })
    );
  }

  onSwitchClicked(libraryId: number) {
    this.store$.dispatch(
      swapProfilerLibraryAttempt({
        id: libraryId
      })
    );

    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  onDeleteClicked(event: any) {
    this.dialogService.show(
      DeleteProfilerLibraryConfirmationDialogComponent,
      {
        width: DialogWidth.Small,
        panelClass: 'dialog-95vw-width',
        data: {
          library: event
        }
      },
      (id: number) =>
        (this.userLibraries = this.userLibraries.filter(
          (library) => library.id !== id
        ))
    );
  }
}
