import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';

@Directive({
  selector: '[atlasDxiColumnValidationTemplate]',
})
export class AtlasDxiColumnValidationTemplateDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.cellTemplate = function (cellElement: any, cellInfo: any) {
      cellElement.append(cellInfo.text);
      styleValidation(cellInfo.text, cellElement);
    };
  }
}

function styleValidation(text: any, cellElement: any) {
  cellElement.className = 'atlas-dxi-validation-column-red';
}
