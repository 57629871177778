import { Component, Input } from '@angular/core';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';

@Component({
  selector: 'atlas-locator-reports-stats',
  templateUrl: './locator-reports-stats.component.html',
  styleUrls: ['./locator-reports-stats.component.less']
})
export class LocatorReportsStatsComponent {
  @Input() queryItem: LocatorLofiQueryResult;
  constructor() {}
}
