import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { EditProfilerLibraryFormComponent } from '../edit-profiler-library-form/edit-profiler-library-form.component';
import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { updateProfilerLibraryDialogSaveClicked } from 'src/app/profiler-store/actions/profiler-library.actions';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
@Component({
  selector: 'atlas-edit-profiler-library-dialog',
  templateUrl: './edit-profiler-library-dialog.component.html'
})
export class EditProfilerLibraryDialogComponent {
  dialogMode: DialogMode;
  userProfilerLibraries: string[] = [];
  private subscription = new Subscription();
  constructor(
    private profilerService: ProfilerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      library: ProfilerLibrary;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<EditProfilerLibraryDialogComponent>,
    private store$: Store<fromProfilerStore.State>
  ) {}

  ngOnInit() {
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllLibraries() {
    this.subscription.add(
      this.profilerService
        .getAllProfilerLibraries()
        .subscribe((libraries: ProfilerLibrary[]) => {
          this.userProfilerLibraries = libraries
            .filter((library) => this.data.library.name !== library.name)
            .map((library) => library.name);
        })
    );
  }

  onSaveClicked(editLibraryFormComponent: EditProfilerLibraryFormComponent) {
    const newLibrary = editLibraryFormComponent.editProfilerLibraryForm.value;
    if (editLibraryFormComponent.editProfilerLibraryForm.valid) {
      this.store$.dispatch(
        updateProfilerLibraryDialogSaveClicked({
          id: newLibrary.id,
          libraryRequest: {
            name: newLibrary.libraryName
          }
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: editLibraryFormComponent.editProfilerLibraryForm.controls
          .libraryName.value
      });
    }
  }
}
