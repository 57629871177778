import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { LocatorService } from 'src/app/locator/services/locator.service';
import * as fromLocatorStore from 'src/app/locator-store';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { AddEditLibraryFormComponent } from '../add-edit-library-form/add-edit-library-form.component';
import { Store } from '@ngrx/store';
import { createLibraryDialogSaveClicked } from 'src/app/locator-store/actions/locator-library.actions';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'atlas-add-library-dialog',
  templateUrl: './add-library-dialog.component.html',
  styleUrls: ['./add-library-dialog.component.less']
})
export class AddLibraryDialogComponent implements OnInit {
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  private subscription = new Subscription();
  constructor(
    private locatorService: LocatorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      library: LocatorLibrary;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<AddLibraryDialogComponent>,
    private store$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit() {
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllLibraries() {
    this.subscription.add(
      this.locatorService
        .getAllLibraries()
        .subscribe((libraries: LocatorLibrary[]) => {
          this.userLibraries = libraries.map((library) => library.name);
        })
    );
  }

  onSaveClicked(addLibraryFormComponent: AddEditLibraryFormComponent) {
    const newLibrary = addLibraryFormComponent.addEditLibraryForm.value;
    if (addLibraryFormComponent.addEditLibraryForm.valid) {
      this.store$.dispatch(
        createLibraryDialogSaveClicked({
          libraryRequest: {
            name: newLibrary.libraryName
          }
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: addLibraryFormComponent.addEditLibraryForm.controls.libraryName
          .value
      });
    }
  }
}
