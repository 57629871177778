<div class="buttons-container">
  <!-- Aria-modal expected on modal -->
  <!-- https://stackoverflow.com/questions/62677291/aria-hidden-elements-do-not-contain-focusable-elements-issue-when-modal-is-sho -->
  <button
    matSuffix
    mat-flat-button
    color="primary"
    aria-modal="true"
    aria-label="add catchment"
    [matMenuTriggerFor]="addCatchmentMenu"
    [disabled]="
      (generatingCatchmentInProgress$ | async) ||
      (isCatchmentReportingReadonlySystem$ | async)
    "
  >
    <ng-container
      *ngIf="!(generatingCatchmentInProgress$ | async); else loading"
    >
      <div class="add-catchment-button">
        <mat-icon>add</mat-icon>
        <div class="text">Add Catchment</div>
      </div>
    </ng-container>
    <ng-template #loading>
      <mat-spinner
        class="update-button-spinner"
        style="width: 120px"
        diameter="24"
        strokeWidth="2"
      >
      </mat-spinner>
    </ng-template>
  </button>

  <mat-menu #addCatchmentMenu="matMenu">
    <button
      mat-menu-item
      (click)="onAddCatchment(locatorShapeType.Circle)"
      [disabled]="isDisabled(this.locatorShapeDefaults?.circle)"
    >
      <mat-icon class="atlas-icon icon-circle"></mat-icon>
      <span>Circle</span>
    </button>
    <button
      mat-menu-item
      (click)="onAddCatchment(locatorShapeType.Car)"
      [disabled]="isDisabled(this.locatorShapeDefaults?.car)"
    >
      <mat-icon>directions_car</mat-icon>
      <span>Drive</span>
    </button>
    <button
      mat-menu-item
      (click)="onAddCatchment(locatorShapeType.PublicTransport)"
      [disabled]="isDisabled(this.locatorShapeDefaults?.publicTransport)"
    >
      <mat-icon>train</mat-icon>
      <span>Public Transport</span>
    </button>
    <button
      mat-menu-item
      (click)="onAddCatchment(locatorShapeType.Walk)"
      [disabled]="isDisabled(this.locatorShapeDefaults?.walk)"
    >
      <mat-icon>directions_walk</mat-icon>
      <span>Walk</span>
    </button>
    <button
      *ngIf="!areMultipleLocationsSelected"
      mat-menu-item
      (click)="onAddPolygon()"
      [disabled]="isDisabled(this.locatorShapeDefaults?.polygon)"
    >
      <mat-icon class="material-symbols-outlined"> activity_zone </mat-icon>
      <span>Polygon</span>
    </button>
  </mat-menu>

  <button
    *ngIf="
      selectedShape &&
      selectedShape.type !== locatorShapeType.FreeForm &&
      selectedShape.type !== locatorShapeType.FreeFormLocked
    "
    matSuffix
    mat-flat-button
    class="secondary-button"
    aria-label="edit catchment"
    (click)="onEditCatchment()"
    [disabled]="
      !enableEditMode || (isCatchmentReportingReadonlySystem$ | async)
    "
  >
    <mat-icon>edit</mat-icon>
    Edit Catchment
  </button>

  <button
    *ngIf="selectedShape && selectedShape.type === locatorShapeType.FreeForm"
    matSuffix
    mat-flat-button
    class="secondary-button"
    aria-label="edit catchment"
    [disabled]="
      !enableEditMode || (isCatchmentReportingReadonlySystem$ | async)
    "
    [matMenuTriggerFor]="editCatchmentMenu"
  >
    <mat-icon>edit</mat-icon>
    Edit Catchment
  </button>
  <mat-menu #editCatchmentMenu="matMenu">
    <button mat-menu-item (click)="onEditDescription()">
      Edit Description
    </button>
    <button mat-menu-item (click)="onEditPolygon()">Edit Shape</button>
  </mat-menu>

  <button
    *ngIf="
      selectedShape && selectedShape.type === locatorShapeType.FreeFormLocked
    "
    matSuffix
    mat-flat-button
    class="secondary-button"
    aria-label="edit catchment"
    [disabled]="true"
    [matMenuTriggerFor]="editCatchmentMenu"
  >
    <mat-icon>edit</mat-icon>
    Edit Catchment
  </button>
  <mat-menu #editCatchmentMenu="matMenu">
    <button mat-menu-item (click)="onEditDescription()">
      Edit Description
    </button>
  </mat-menu>
</div>
