import { createAction, props } from '@ngrx/store';
import { Stats } from 'src/app/spatial-modeller/models/stats';

export const spatialModellerGetStatsAttempt = createAction(
  '[Spatial Modeller Test Effect] Get Stats Attempt',
  props<{ testId: number }>()
);

export const spatialModellerGetStatsSucceded = createAction(
  '[Spatial Modeller Stats Effect] Get Stats Succeded',
  props<{ stats: Stats[] }>()
);
