<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(addLibraryForm)"
  [saveDisabled]="
    addLibraryForm.invalidForm() ||
    (hasProfilerLimitBeenExceededResult$ | async)!
  "
  class="add-edit-dialog-content"
>
  <atlas-add-profiler-library-form
    #addLibraryForm
    formContent
    [hasProfilerLimitBeenExceededResult]="
      (hasProfilerLimitBeenExceededResult$ | async)!
    "
    [library]="data.library"
    [userLibraries]="userLibraries"
  >
  </atlas-add-profiler-library-form>
</atlas-add-edit-dialog>
