import { createReducer, on } from '@ngrx/store';
import { UsageSummary } from '../../models/usage-summary';
import {
  clearUsage,
  getUsageSummarySucceeded,
  hasGeocodingCompanyLimitBeenExceededErrorOccurred,
  hasGeocodingCompanyLimitBeenExceededResult,
  hasGeocodingLicenseExpiredResult
} from '../actions/usage.actions';

export interface State {
  usageSummary: UsageSummary | null;
  hasCompanyGeocodingLimitBeenExceeded: boolean;
  hasCompanyGeocodingLicenseExpired: boolean;
}

const initialState: State = {
  usageSummary: null,
  hasCompanyGeocodingLimitBeenExceeded: false,
  hasCompanyGeocodingLicenseExpired: false
};

export const usageReducer = createReducer(
  initialState,

  on(getUsageSummarySucceeded, (state, { usageSummary }) => {
    const today = new Date();
    return {
      ...state,
      usageSummary: usageSummary
    };
  }),
  on(clearUsage, (state) => {
    return {
      ...state,
      usageSummary: null,
      hasCompanyGeocodingLimitBeenExceeded: false
    };
  }),
  on(hasGeocodingCompanyLimitBeenExceededResult, (state, { result }) => {
    return {
      ...state,
      hasCompanyGeocodingLimitBeenExceeded: result
    };
  }),
  on(hasGeocodingLicenseExpiredResult, (state, { result }) => {
    return {
      ...state,
      hasCompanyGeocodingLicenseExpired: result
    };
  }),
  on(hasGeocodingCompanyLimitBeenExceededErrorOccurred, (state, {}) => {
    return {
      ...state,
      hasCompanyGeocodingLimitBeenExceeded: true // set this flag to true to avoid unexpected charges from Geocoder provider
    };
  })
);
