import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { systemLayerIdentifiers } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';

@Component({
  selector: 'atlas-layer-legend',
  templateUrl: './layer-legend.component.html',
  styleUrls: ['../less/legend-expander.less']
})
export class LayerLegendComponent {
  @Input() layer: any;
  @Input() layerExpandedState: boolean | undefined;
  @Output() layerExpandedStateChanged: EventEmitter<boolean> =
    new EventEmitter();

  constructor(
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService
  ) {}

  onVisibilityChange(evt: any, layerId: string) {
    var layerPropertiesChanging = { visible: evt.checked };
    this.mapService.updateLayer(layerId, layerPropertiesChanging);
    !evt.checked
      ? this.pinDropAndSelectionService.removeSelectionIfMatchingLayer(layerId)
      : this.mapService.syncTriggerScale(layerId);

    this.mapService.saveLayerVisiblityInStorage(layerId, evt.checked);
  }

  onOpened() {
    this.layerExpandedStateChanged.emit(true);
  }

  onClosed() {
    this.layerExpandedStateChanged.emit(false);
  }

  isSystemLayer(layerId: string) {
    return systemLayerIdentifiers.includes(layerId);
  }
}
