<div
  class="button-container"
  *ngIf="!(isSelectedPointEmpty$ | async) && (reportCount$ | async)! === 1"
>
  <button
    class="atlas-icon-button"
    mat-flat-button
    color="primary"
    aria-label="Download report"
    (click)="onFileDownloadClicked()"
    matTooltip="Download report"
    [matTooltipShowDelay]="1000"
    [matTooltipHideDelay]="1000"
    [disabled]="isReportInProgress$ | async"
  >
    <mat-spinner
      class="icon-button-spinner"
      *ngIf="isReportInProgress$ | async"
      diameter="24"
      strokeWidth="2"
    >
    </mat-spinner>

    <mat-icon *ngIf="!(isReportInProgress$ | async)">download</mat-icon>
  </button>
</div>

<div
  class="button-container"
  *ngIf="!(isSelectedPointEmpty$ | async) && (reportCount$ | async)! !== 1"
>
  <button
    mat-flat-button
    color="primary"
    class="launch-button"
    (click)="onLaunchReporting()"
    [disabled]="(reportCount$ | async)! === 0 || (isReportInProgress$ | async)"
  >
    <mat-spinner
      class="icon-button-spinner"
      *ngIf="isReportInProgress$ | async"
      diameter="24"
      strokeWidth="2"
    >
    </mat-spinner>
    <span *ngIf="!(isReportInProgress$ | async)"> Launch Reporting </span>
  </button>
</div>
