import { createAction, props } from '@ngrx/store';
import { LocatorSummaryStatsStatus } from 'src/app/locator/models/locator-summary-stats-status';

export const pollLocatorSummaryStatsStatusStarted = createAction(
  '[Locator SummaryStats Status Effects] Poll Locator SummaryStats Started',
  props<{
    libraryId: number;
  }>()
);

export const pollLocatorSummaryStatsStatusInProgress = createAction(
  '[Locator Summary Stats Effects] Poll Locator Summary Stats In Progress',
  props<{
    locatorSummaryStatsStatus: LocatorSummaryStatsStatus;
  }>()
);

export const pollLocatorSummaryStatsStatusCompleted = createAction(
  '[Locator Summary Stats Effects] Poll Locator Summary Stats Completed',
  props<{
    locatorSummaryStatsStatus: LocatorSummaryStatsStatus;
  }>()
);

export const pollLocatorSummaryStatsStatusFailed = createAction(
  '[Locator Summary Stats Effects] Poll Locator Summary Stats Failed',
  props<{
    locatorSummaryStatsStatus: LocatorSummaryStatsStatus;
  }>()
);

export const getLocatorSummaryStatsStatusSucceeded = createAction(
  '[Locator Summary Stats Effects] Get Locator Summary Stats Succeeded',
  props<{ locatorSummaryStatsStatus: LocatorSummaryStatsStatus }>()
);

export const triggerCreateSummaryStatsAttempt = createAction(
  '[Locator SummaryStats UI Button] Trigger Create Summary Stats Attempt'
);

export const triggerCreateSummaryStatsSucceeded = createAction(
  '[Locator Summary Stats Effects] Trigger Create Summary Stats Succeeded'
);

export const triggerCreateSummaryStatsErrorOccurred = createAction(
  '[Locator Summary Stats Effects] Trigger Create Summary Stats Error Occurred',
  props<{ errorOn: string; error: string }>()
);
