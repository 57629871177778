import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AtlasSnackbarComponent } from '../components/atlas-snackbar/atlas-snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private matSnackbar: MatSnackBar, private zone: NgZone) {}

  show(snackbarText: string, snackbarHeader?: string) {
    this.zone.run(() => {
      this.matSnackbar.openFromComponent(AtlasSnackbarComponent, {
        data: { header: snackbarHeader, text: snackbarText },
        panelClass: 'atlas-snackbar',
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        duration: 5000,
      });
    });
  }

  error(snackbarHeader: string, snackbarText: string) {
    this.zone.run(() => {
      if (snackbarText) {
        this.matSnackbar.openFromComponent(AtlasSnackbarComponent, {
          data: { header: snackbarHeader, text: snackbarText },
          panelClass: 'atlas-snackbar-error',
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
        });
      }
    });
  }

  // https://coditty.com/code/angular-material-display-multiple-snackbars-messages-in-sequence
  showMultiple(messages: string[], snackbarHeader?: string) {
    var timeOut = 3500;

    if (messages instanceof Array) {
      messages.forEach((message, index) => {
        setTimeout(() => {
          this.zone.run(() => {
            this.matSnackbar.openFromComponent(AtlasSnackbarComponent, {
              data: { header: snackbarHeader, text: message },
              panelClass: 'atlas-snackbar',
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              duration: timeOut,
            });
          });
        }, index * (timeOut + 500)); // 500 - timeout between two messages
      });
    }
  }

  closeSnackBar() {
    this.zone.run(() => {
      this.matSnackbar.dismiss();
    });
  }
}
