<div *ngIf="areTestStatsAvailable$ | async">
  <div>
    @if(isSimpleStatsView$ | async){
    <div>
      <ng-template ngFor let-i="index" let-statsItem [ngForOf]="stats$ | async">
        <div>
          <atlas-simple-stats-page
            [value]="statsItem.value"
            [name]="statsItem.name"
          ></atlas-simple-stats-page>
        </div>
      </ng-template>
    </div>
    } @else {
    <div>
      <atlas-standard-stats-page
        [queryItem]="stats$ | async"
      ></atlas-standard-stats-page>
    </div>
    }
  </div>
</div>
