import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BaseMapLayersType } from '../models/base-map-layer-types';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class BaseMapLayerService {
  baseMapLayerType$ = new BehaviorSubject<BaseMapLayersType>(
    this.getBaseMapLayerType() ? this.getBaseMapLayerType() : 'Positron'
  );

  constructor(private localStorageService: LocalStorageService) {}

  setBaseMapLayerStyle(newBaseMapLayerType: BaseMapLayersType) {
    if (
      this.localStorageService.set('base-map-layer-type', newBaseMapLayerType)
    ) {
      this.baseMapLayerType$.next(newBaseMapLayerType);
    }
  }

  private getBaseMapLayerType(): BaseMapLayersType {
    return this.localStorageService.get('base-map-layer-type');
  }
}
