import { Component, Input } from '@angular/core';
import { CompanyModelUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-company-model-usage-summary',
  templateUrl: './company-model-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class CompanyModelUsageSummaryComponent {
  @Input()
  dataSource: CompanyModelUsage | null;
}
