import { createAction, props } from '@ngrx/store';
import { Point } from '../../models/point.model';
import { SearchResult } from '../../models/search-result';
import { SearchResultGroup } from '../../models/search-result-group';

export const searchLocationAttempt = createAction(
  '[Gazetter Component] Search Location Attempt',
  props<{ searchedText: string }>()
);

export const searchLocationSucceded = createAction(
  '[Gazetter Component] Search Location Succeded',
  props<{ foundLocations: SearchResultGroup[] }>()
);

export const searchLocationFailed = createAction(
  '[Gazetteer Component] Search Location Failed',
  props<{ errorOn: string; error: string }>()
);

export const selectLocationByNameAttempt = createAction(
  '[Gazetteer Component] Select Location by name Attempt',
  props<{ location: SearchResult }>()
);

export const selectLocationByCoordinatesAttempt = createAction(
  '[Gazetteer Component] Select Location by coordinates Attempt',
  props<{ location: SearchResult }>()
);

export const selectLocationSucceded = createAction(
  '[Gazetteer Component] Select Location Succeded',
  props<{ location: Point }>()
);

export const selectLocationFailed = createAction(
  '[Gazetteer Component] Select Location Failed',
  props<{ errorOn: string; error: string }>()
);

export const searchTextClearAttempt = createAction(
  '[Gazetteer Component] Search Text Cleared Attempt'
);

export const searchTextCleared = createAction(
  '[Gazetteer Effect] Search Text Cleared'
);

export const mapPinCleared = createAction(
  '[Gazetteer Component] Map Pin Cleared'
);

export const allowManualDropPinClicked = createAction(
  '[Gazetteer Component] Manual add pin button clicked'
);

export const allowManualDropSet = createAction(
  '[Gazetteer Effect] Manual pin drop set true on map service'
);

export const deactivateManualDropPinClicked = createAction(
  '[Gazetteer Component] Manual add pin button deactivated'
);

export const deactivateManualDropSet = createAction(
  '[Gazetteer Effect] Manual pin drop set false on map service'
);

export const manualPinDropSucceded = createAction(
  '[Map component] Manual pin dropped in the map',
  props<{ location: Point }>()
);

export const manualPinDragSucceded = createAction(
  '[Map component] Manual pin dragged in the map',
  props<{ location: Point }>()
);

export const findMyLocationClicked = createAction(
  '[Gazetteer Component] Find my location clicked',
  props<{ location: Point }>()
);

export const findMyLocationSucceded = createAction(
  '[Gazetteer Effect] Add find my location layer using map service',
  props<{ location: Point }>()
);

export const reverseLocationAttempt = createAction(
  '[Gazetteer Effect] Reverse location attempt',
  props<{ location: Point; requestFrom: 'LocatorLocationList' | null }>()
);

export const reverseLocationSucceded = createAction(
  '[Gazetteer Effect] Reverse Location Succeded having lat/long',
  props<{
    address: string;
    location: Point;
    requestFrom: 'LocatorLocationList' | null;
  }>()
);

export const reverseLocationFailed = createAction(
  '[Gazetteer Effect] Reverse location failed',
  props<{ errorOn: string; error: string }>()
);

export const existingPinDragSucceded = createAction(
  '[Map component] Existing pin drag end succeeded',
  props<{ location: Point }>()
);

export const selectNewLocationForSelectionPin = createAction(
  '[Gazetteer Component] Select new location for selected pin',
  props<{ location: SearchResult }>()
);

export const selectNewLocationForSelectionPinByCoordinates = createAction(
  '[Gazetteer Component] Select new location for selected pin by coordinates',
  props<{ location: SearchResult }>()
);

export const selectNewLocationForSelectionPinSucceeded = createAction(
  '[Gazetteer Component] Select new location for selected pin Succeeded',
  props<{ location: Point }>()
);

export const defineNewLocationDialogButtonClicked = createAction(
  '[Define New Location Dialog] Define New Location Dialog Button Clicked',
  props<{ location: Point }>()
);

export const defineNewLocationSucceeded = createAction(
  '[Gazetteer Effect] Define New Location Coordinates Succeeded',
  props<{ location: Point }>()
);
