<div class="zoom-container" [ngClass]="{ 'screenshot-active': showButtons }">
  <button (click)="onZoomOutClicked()" class="zoom-buttons zoom-out">
    <mat-icon>remove</mat-icon>
  </button>
  <button class="zoom-buttons zoom-indicator">
    {{ this.mapService.zoom | number : '.0-0' }}
  </button>
  <button (click)="onZoomInClicked()" class="zoom-buttons zoom-in">
    <mat-icon>add</mat-icon>
  </button>
</div>

<div *ngIf="layoutService.isDesktopView$ | async">
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="icon copy-icon button"
    (click)="onCopydMapClicked()"
    *ngIf="showButtons"
  >
    <span class="material-symbols-outlined">content_copy</span>
  </button>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="icon download-icon button"
    (click)="onDownloadMapClicked()"
    *ngIf="showButtons"
  >
    <span class="material-symbols-outlined">download_2</span>
  </button>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="icon sceenshot-icon button"
    (click)="onScreenshotClicked()"
  >
    <span class="material-symbols-outlined">screenshot_region</span>
  </button>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    class="close-icon button"
    (click)="onCloseClicked()"
    *ngIf="showButtons"
  >
    <span class="material-symbols-outlined">Close</span>
  </button>
</div>
