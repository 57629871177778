import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  getLocatorImportLibraryDataSucceded,
  swapLibrarySucceeded
} from '../actions/locator-library.actions';
import {
  pollLocatorImportLibraryDataStatusStarted,
  pollLocatorImportLibraryDataStatusInProgress,
  pollLocatorImportLibraryDataStatusCompleted,
  pollLocatorImportLibraryDataStatusFailed
} from '../actions/locator-import-status.actions';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromLocatorStore from 'src/app/locator-store';
import { locatorFileImportHeaderValidationSuccess } from '../actions/locator-import-validation.actions';
import { of } from 'rxjs';

@Injectable()
export class LocatorImportStatusEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private locatorService: LocatorService
  ) {}

  startLocatorStatusImportPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(swapLibrarySucceeded),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getLocatorImportDataStatus)
      ),
      filter(
        ([_, locatorImportStatus]) =>
          locatorImportStatus?.status !== 'ImportLocatorLibraryDataCompleted'
      ),
      switchMap(([{ library }, _]) =>
        of(pollLocatorImportLibraryDataStatusStarted({ libraryId: library.id }))
      )
    )
  );

  restartPollingAfterReuploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locatorFileImportHeaderValidationSuccess),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      map(([_, libraryId]) => {
        return pollLocatorImportLibraryDataStatusStarted({ libraryId });
      })
    )
  );

  getLocatorImportLibraryDataSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocatorImportLibraryDataSucceded),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getLocatorImportDataStatus)
      ),
      filter(
        ([_, locatorImportStatus]) =>
          locatorImportStatus != null &&
          locatorImportStatus.status !== 'ImportLocatorLibraryDataCompleted'
      ),
      map(([_, locatorImportStatus]) =>
        pollLocatorImportLibraryDataStatusStarted({
          libraryId: locatorImportStatus!.libraryId
        })
      )
    )
  );

  pollLocatorImportStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollLocatorImportLibraryDataStatusStarted),
      switchMap(({ libraryId }) =>
        this.locatorService
          .pollForLocatorImportLibraryDataStatus(libraryId)
          .pipe(
            withLatestFrom(
              this.store$.select(fromLocatorStore.getLocatorLibraryId)
            ),
            filter(
              ([locatorImportStatus, currentLibraryId]) =>
                locatorImportStatus?.libraryId === currentLibraryId
            ),
            map(([locatorImportStatus, _]) => {
              if (
                locatorImportStatus.status ===
                'ImportLocatorLibraryDataCompleted'
              ) {
                return pollLocatorImportLibraryDataStatusCompleted({
                  locatorImportStatus
                });
              } else {
                return locatorImportStatus.errorMessage != null
                  ? pollLocatorImportLibraryDataStatusFailed({
                      locatorImportStatus
                    })
                  : pollLocatorImportLibraryDataStatusInProgress({
                      locatorImportStatus
                    });
              }
            })
          )
      )
    )
  );
}
