import { Directive, Host } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
// you can see the all devexpress datagrid options https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration
@Directive({
  selector: '[atlasDxDataGridDefaultOptions]',
})
export class DxDataGridDefaultOptionsDirective {
  constructor(@Host() dataGridHost: DxDataGridComponent) {
    dataGridHost.height = '100%';
    dataGridHost.width = '100%';

    dataGridHost.loadPanel = {
      enabled: true,
    };
    dataGridHost.scrolling = {
      mode: 'virtual',
    };

    dataGridHost.focusedRowEnabled = true;
    dataGridHost.autoNavigateToFocusedRow = true; // Uncomment this when v19.2 is released

    dataGridHost.filterSyncEnabled = true;
    dataGridHost.showBorders = true;
    dataGridHost.allowColumnReordering = true;
    dataGridHost.allowColumnResizing = true; // not working in latest v 24 need to check why

    dataGridHost.columnResizingMode = 'nextColumn'; // 'nextColumn' | 'widget'

    dataGridHost.columnFixing = {
      enabled: true,
    };
    dataGridHost.searchPanel = {
      visible: true,
      placeholder: 'Search...',
    };
    dataGridHost.headerFilter = {
      visible: true,
    };
    dataGridHost.filterPanel = {
      visible: false,
    };
    dataGridHost.filterRow = {
      visible: true,
      applyFilter: 'auto',
    };
    dataGridHost.columnChooser = {
      enabled: true,
      mode: 'select', // 'dragAndDrop' | 'select'
      search: {
        enabled: true
      }
    };
    dataGridHost.noDataText = 'No data';
    dataGridHost.rowAlternationEnabled = false;
    dataGridHost.selection = {
      mode: 'single', // 'multiple' | 'none' | 'single'
      selectAllMode: 'allPages', // 'allPages' , 'page'
      showCheckBoxesMode: 'onClick', // 'none', 'onClick', 'onLongTap', 'always'
    };
  }
}
