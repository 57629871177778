import {
  getMapLayerDefaultProperties,
  getTileSetLayerData
} from '../helpers/map-layer-helper';
import { Layer } from '../models/layer';
import { MVTLayer } from '@deck.gl/geo-layers';
import { DynamicTilesetService } from '../services/dynamic-tileset.service';
import { MapService } from '../services/map.service';

// zoom level may be controlled with the min zoom level needs experimentation
// https://deck.gl/docs/api-reference/geo-layers/tile-layer

export class ThematicPointTileSetLayer extends Layer {
  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicTilesetService: DynamicTilesetService
  ) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties(
      'ThematicPointTileSet',
      jwtToken
    );

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    layerProperties = await getTileSetLayerData(
      dynamicTilesetService,
      layerProperties,
      jwtToken,
      apiBaseUrl
    );

    return new MVTLayer(layerProperties);
  }
}
