<form [formGroup]="defineNewLocationForm" autocomplete="off">
  <div class="description-text">
    Please enter your latitude and longitude using the WGS84 projection e.g.
    41.40338, 2.17403
  </div>
  <mat-form-field
    appearance="outline"
    hideRequiredMarker="true"
    class="input-field"
  >
    <mat-label>Latitude</mat-label>
    <input matInput type="text" formControlName="latitude" />
  </mat-form-field>
  @if(isLatitudeValid()){
  <div class="error-message">
    <span>
      {{
        getErrorMessage(
          defineNewLocationForm.controls.latitude.errors,
          'Latitude'
        )
      }}
    </span>
  </div>
  }
  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label>Longitude</mat-label>
    <input matInput type="text" formControlName="longitude" />
  </mat-form-field>
  @if(isLongitudeValid()){
  <div class="error-message">
    <span>
      {{
        getErrorMessage(
          defineNewLocationForm.controls.longitude.errors,
          'Longitude'
        )
      }}
    </span>
  </div>
  }
</form>
