<form [formGroup]="locatorReportingForm" autocomplete="off">
  <div class="form-controls-padding" style="margin-top: 10px">
    @if(selectedShapes.length===1){
    <div class="text">
      You are currently generating a report for “{{ selectedShapes[0].name }}”
      in "{{ selectedLocationData.name }}”
    </div>
    } @else{
    <div class="text">
      You are generating a report for all catchments for "{{
        selectedLocationData.name
      }}”
    </div>
    }
    <div>
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>Report names</mat-label>
        <input
          matInput
          type="text"
          formControlName="filter"
          placeholder="Search reports"
          (keyup)="onFilterChanged($event)"
        />

        <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div>
      <div class="reports-label">Reports</div>
      <div class="scrollable-list">
        <mat-selection-list
          #pbiReports
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (selectionChange)="onSelectionChange($event)"
        >
          <mat-list-option
            *ngFor="let pbiReport of dataSource"
            [value]="pbiReport"
          >
            <span class="report-name">{{ pbiReport.name }}</span>
            <div>
              <span class="report-description">{{
                pbiReport.description
              }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</form>
