<div style="padding-bottom: 15px">
  <div class="layer-panel-body" *ngIf="layers$ | async as layers">
    <atlas-layer-legend
      *ngFor="let layer of layers | reverseIterable"
      [layer]="layer"
      [layerExpandedState]="layersExpandedDictionary.get(layer.id)"
      (layerExpandedStateChanged)="
        onLayerExpandedStateChanged(layer.id, $event)
      "
    >
    </atlas-layer-legend>
    <div>
      <atlas-base-map-layer-collection
        [initialBaseMapLayerType]="initialBaseMapLayerType"
      ></atlas-base-map-layer-collection>
    </div>
  </div>
</div>
