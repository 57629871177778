import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { formValueContainsLowerCaseValidator } from 'src/app/shared/utils/form-validators/form-value-contains-lower-case.validator';
import { formValueContainsNumberValidator } from 'src/app/shared/utils/form-validators/form-value-contains-number.validator';
import { formValueContainsSpecialCharValidator } from 'src/app/shared/utils/form-validators/form-value-contains-special-char.validator';
import { formValueContainsUpperCaseValidator } from 'src/app/shared/utils/form-validators/form-value-contains-upper-case.validator';
import { formValueMinLengthValidator } from 'src/app/shared/utils/form-validators/form-value-min-length.validator';
import {
  formControlValuesMatchingValidator,
  formGroupControlValuesMatchingValidator
} from 'src/app/shared/utils/form-validators/form-values-matching-validator';
import { AuthService } from '../../services/auth.service';
import {
  hasLowerCase,
  hasMinLength,
  hasNumber,
  hasSpecialChar,
  hasUpperCase
} from '../utils/password-form-control-errors';

@Component({
  selector: 'atlas-first-time-login',
  templateUrl: './first-time-login.component.html',
  styleUrls: ['./first-time-login.component.less']
})
export class FirstTimeLoginComponent {
  hide = true;
  hideConfirm = true;

  firstTimeLoginForm = this.fb.group(
    {
      verificationCode: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          formValueMinLengthValidator(8),
          formValueContainsNumberValidator(),
          formValueContainsUpperCaseValidator(),
          formValueContainsLowerCaseValidator(),
          formValueContainsSpecialCharValidator()
        ])
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          formControlValuesMatchingValidator('password', 'confirmPassword')
        ])
      ]
    },
    {
      validators: formGroupControlValuesMatchingValidator(
        'password',
        'confirmPassword'
      )
    }
  );

  constructor(
    private fb: UntypedFormBuilder,
    public authService: AuthService
  ) {}

  onChangeFirstTimePassword() {
    this.authService.setPasswordAfterFirstTimeLogin(
      this.firstTimeLoginForm.controls.password.value
    );
  }

  invalidForm() {
    return (
      this.emptyPassword() ||
      !this.hasMinLength() ||
      !this.hasNumber() ||
      !this.hasLowerCase() ||
      !this.hasUpperCase() ||
      !this.hasSpecialChar() ||
      this.firstTimeLoginForm.errors?.missMatch
    );
  }

  emptyPassword() {
    return this.firstTimeLoginForm.controls.password.errors?.required;
  }

  hasMinLength() {
    return hasMinLength(this.firstTimeLoginForm.controls.password);
  }

  hasNumber() {
    return hasNumber(this.firstTimeLoginForm.controls.password);
  }

  hasLowerCase() {
    return hasLowerCase(this.firstTimeLoginForm.controls.password);
  }

  hasUpperCase() {
    return hasUpperCase(this.firstTimeLoginForm.controls.password);
  }

  hasSpecialChar() {
    return hasSpecialChar(this.firstTimeLoginForm.controls.password);
  }

  showEmptyConfirmPasswordError() {
    return (
      this.emptyPassword() &&
      this.firstTimeLoginForm.controls.confirmPassword?.errors?.required
    );
  }

  invalidConfirmPassword() {
    return (
      this.firstTimeLoginForm.invalid &&
      (this.firstTimeLoginForm.controls.confirmPassword.dirty ||
        this.firstTimeLoginForm.controls.confirmPassword.touched) &&
      this.firstTimeLoginForm.errors?.missMatch
    );
  }

  onGoBackToLogin() {
    this.authService.removeErrorMessage();
    this.authService.setAuthAction('login');
  }
}
