<span class="snackbar-text">
  <b>{{ data.header }}</b>
  <div>{{ data.text }}</div>
</span>

<button
  matSuffix
  mat-icon-button
  color="primary"
  aria-label="Main menu"
  (click)="snackBarRef.dismiss()"
  class="snack-bar-icon-button snackbar-close"
>
  <mat-icon>close</mat-icon>
</button>
