import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromServices from './services';
import * as fromComponents from './components';
import { MaterialComponentsModule } from '../shared/material/material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    RouterModule,
  ],
  exports: [...fromComponents.components],
  providers: [...fromServices.services],
})
export class AuthModule {}
