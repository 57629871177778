import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  catchError,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ImportService } from 'src/app/profiler/services/import.service';
import {
  abortMultipartUpload,
  exportImportedPostcodesWithAcornTypeAttempt,
  exportImportedPostcodesWithAcornTypeSucceeded,
  fileImportAttempt,
  fileUploadCompleteStatusSuccess,
  fileUploadError,
  fileUploadUpdateFileKey,
  hasProfilerLimitBeenExceededAttempt,
  hasProfilerLimitBeenExceededResult,
  initiateMultipartUploadAttempt,
  initiateMultipartUploadSuccess,
  metaDataUploadAttempt,
  metaDataUploadSuccess,
  multipartUploadComplete
} from '../actions/profiler-import.actions';
import { UploadService } from 'src/app/profiler/services/upload.service';
import {
  fileImportHeaderValidationFailed,
  fileImportHeaderValidationSuccess
} from '../actions/profiler-import-validation.actions';
import { ImportValidationService } from 'src/app/profiler/services/import-validation.service';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import { getImportStatus } from '../reducers/profiler.reducer';
import { getProfilerImportStatus } from '../selectors';

@Injectable()
export class ProfilerImportEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private localStorageService: LocalStorageService,
    private importService: ImportService,
    private uploadService: UploadService,
    private importValidationService: ImportValidationService
  ) {}

  fileUploadHeaderValidationAttempt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileImportAttempt),
        map((action) =>
          this.importValidationService.validateFileHeaders(action.file)
        )
      ),
    { dispatch: false }
  );

  fileUploadEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileImportHeaderValidationSuccess),
        map((action) => {
          this.uploadService.uploadFile(action.file);
        })
      ),
    { dispatch: false }
  );

  fileUploadUpdateFileKeyEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileUploadUpdateFileKey),
        switchMap((action) =>
          this.importService.fileUploadUpdateFileKey(
            this.getProfilerLibraryId(),
            action.fileKey
          )
        )
      ),
    { dispatch: false }
  );

  metadataUploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(metaDataUploadAttempt),
      switchMap((file) =>
        this.importService
          .uploadMetaDataConfig(file.fileKey, this.getProfilerLibraryId())
          .pipe(
            map(() => metaDataUploadSuccess({ fileKey: file.fileKey })),
            catchError((error) =>
              of(fileUploadError({ fileKey: file.fileKey, errors: error }))
            )
          )
      )
    )
  );

  fileUploadCompleteStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(metaDataUploadSuccess),
      switchMap((file) =>
        this.importService
          .fileUploadCompleteStatus(this.getProfilerLibraryId())
          .pipe(
            map(() => fileUploadCompleteStatusSuccess()),
            catchError((error) =>
              of(fileUploadError({ fileKey: file.fileKey, errors: error }))
            )
          )
      )
    )
  );

  fileUploadError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileUploadError, fileImportHeaderValidationFailed),
        switchMap((error) =>
          this.importService.fileUploadFailedStatus(
            this.getProfilerLibraryId(),
            error.errors
          )
        )
      ),
    { dispatch: false }
  );

  initiateMultipartUploadAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateMultipartUploadAttempt),
      switchMap((action) =>
        this.importService.initiateMultipartUpload(action.fileKey).pipe(
          map((result) =>
            initiateMultipartUploadSuccess({
              uploadId: result.uploadId,
              file: action.file
            })
          )
        )
      )
    )
  );

  initiateMultipartUploadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateMultipartUploadSuccess),
        switchMap((action) =>
          this.uploadService.uploadFileMultiPart(action.file, action.uploadId)
        )
      ),
    { dispatch: false }
  );

  multipartUploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(multipartUploadComplete),
      switchMap((action) =>
        this.importService
          .completeMultipartUpload(
            this.getProfilerLibraryId(),
            action.filename,
            action.uploadId,
            action.parts
          )
          .pipe(map(() => metaDataUploadAttempt({ fileKey: action.filename })))
      )
    )
  );

  abortMultipartUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(abortMultipartUpload),
      switchMap((action) =>
        this.importService
          .cancelMultipartUpload(action.filename, action.uploadId)
          .pipe(
            map(() =>
              fileUploadError({
                fileKey: action.filename,
                errors: action.errorMessage
              })
            )
          )
      )
    )
  );

  hasProfilerLimitBeenExceededAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hasProfilerLimitBeenExceededAttempt),
      switchMap(() =>
        this.importService.hasProfilerLimitBeenExceeded().pipe(
          map((hasExcceeded) =>
            hasProfilerLimitBeenExceededResult({
              result: hasExcceeded.result
            })
          )
        )
      )
    )
  );

  exportImportedPostcodesWithAcornTypeAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exportImportedPostcodesWithAcornTypeAttempt),
      withLatestFrom(this.store$.select(getProfilerImportStatus)),
      switchMap(([_, importStatus]) =>
        this.importService
          .getExportedPostcodeWithAcornUrl(
            importStatus!.fileKey,
            importStatus?.libraryId
          )
          .pipe(
            map(({ presignedUrl }) =>
              exportImportedPostcodesWithAcornTypeSucceeded({
                exportedFileUrl: presignedUrl.result
              })
            )
          )
      )
    )
  );

  exportImportedPostcodesWithAcornTypeSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportImportedPostcodesWithAcornTypeSucceeded),
        withLatestFrom(this.store$.select(getProfilerImportStatus)),
        tap(([{ exportedFileUrl }, importStatus]) => {
          const link = document.createElement('a');
          link.href = exportedFileUrl;
          link.download = `exported_${importStatus?.fileKey}`;
          link.click();
        })
      ),
    { dispatch: false }
  );

  private getProfilerLibraryId() {
    var id = this.localStorageService.get('profiler-library-id');
    return id ? id : 0;
  }
}
