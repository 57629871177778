import { Component, Input } from '@angular/core';
import { escapeLabel, formatLabel, ScaleType } from '@swimlane/ngx-charts';

import { Color, colorSets } from '@swimlane/ngx-charts';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
@Component({
  selector: 'atlas-profiler-reports-graph',
  templateUrl: './profiler-reports-graph.component.html',
  styleUrls: ['./profiler-reports-graph.component.less']
})
export class ProfilerReportsGraphComponent {
  @Input() queryItem: ProfilerLofiQueryResult;
  @Input() graphConfig: any;
  @Input() pieGridView: [number, number] = [250, 620];
  @Input() numberCardView: [number, number] = [300, 620];
  @Input() height: number;

  allowedCharts = [
    'bar-horizontal',
    'bar-vertical',
    'pie-chart',
    'advanced-pie-chart',
    'pie-grid',
    'tree-map',
    'number-card'
  ];
  constructor() {}

  schemeType = ScaleType.Ordinal;

  setColorScheme(name: string): string | Color {
    return colorSets.find((s: any) => s.name === name) as Color;
  }

  pieTooltipText(d: any) {
    return `
      <span class="tooltip-label">${d.data.name}</span>
      <span class="tooltip-val">${d.data.value}</span>
    `;
  }
}
