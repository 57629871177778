import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  deleteSupplyPointClicked,
  deleteSupplyPointSucceeded,
  saveSupplyPointClicked,
  saveSupplyPointSucceeded,
  closeSupplyPointSucceeded,
  closeSupplyPointClicked,
  savePendingSupplyPointClicked,
  discardPendingSupplyPointClicked,
  reopenSupplyPointClicked,
  reopenSupplyPointSucceeded
} from '../actions/supply-point.actions';
import {
  pendingUnsavedSupplyPointReset,
  spatialModellerModelLocationSucceedFromSPClose,
  spatialModellerModelLocationSucceedFromSPReOpen,
  spatialModellerModelLocationSucceedFromSPSave
} from '../actions/spatial-modeller-test.actions';
import { ScenarioService } from 'src/app/spatial-modeller/services/scenario.service';
import * as fromSmStore from 'src/app/spatial-modeller-store/actions';
import {
  getIsSupplyPoint,
  getNextSupplyPointInfoToSelect,
  getPendingUnsavedSupplyPoint,
  getScenarioId,
  getSpatialModellerSupplyPoint
} from '../selectors';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { loadSupplyPointSucceeded } from '../actions/spatial-modeller-test.actions';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { SpatialModellerDataService } from 'src/app/spatial-modeller/services/spatial-modeller-data.service';
import {
  DropPinAction,
  SupplyPointSelectionAction
} from 'src/app/shared/atlas-gazetteer/helpers/search-helper';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { selectLocationSucceded } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { hidePinDropLayerIdentifier } from 'src/app/shared/atlas-mapping/helpers/system-layers-helper';
@Injectable()
export class SupplyPointEffects {
  constructor(
    private actions$: Actions,
    private scenarioDataService: ScenarioService,
    private spatialModellerDataService: SpatialModellerDataService,
    private store$: Store<State>,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private mapService: MapService
  ) {}

  saveSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSupplyPointClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerSupplyPoint)
      ),
      switchMap(([{ executeModel }, scenarioId, supplyPoint]) => {
        return this.scenarioDataService
          .saveSupplyPoint(scenarioId, supplyPoint!)
          .pipe(
            map((savedSupplyPoint) => {
              this.selectSavedDeltaSupplyPoint(
                scenarioId,
                supplyPoint!.location,
                savedSupplyPoint
              );
              return saveSupplyPointSucceeded({
                supplyPoint: savedSupplyPoint,
                location: supplyPoint!.location,
                executeModel
              });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error save location',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  deleteSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSupplyPointClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerSupplyPoint)
      ),
      switchMap(([_, scenarioId, supplyPoint]) => {
        return this.scenarioDataService
          .deleteSupplyPoint(
            scenarioId,
            supplyPoint ? supplyPoint.supplyKey : 0
          )
          .pipe(
            map(() => {
              this.pinDropAndSelectionService.refreshBaseAndDeltaLayersScenarioData(
                scenarioId
              );
              return deleteSupplyPointSucceeded();
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error delete location',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  closeSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeSupplyPointClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerSupplyPoint)
      ),
      switchMap(([_, scenarioId, supplyPoint]) => {
        return this.scenarioDataService
          .closeSupplyPoint(scenarioId, supplyPoint!)
          .pipe(
            switchMap(async (savedSupplyPoint) => {
              await this.selectSavedDeltaSupplyPoint(
                scenarioId,
                supplyPoint!.location,
                savedSupplyPoint
              );
              return closeSupplyPointSucceeded({
                supplyPoint: savedSupplyPoint,
                location: supplyPoint!.location
              });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error Close location',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  reopenSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reopenSupplyPointClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerSupplyPoint)
      ),
      switchMap(([_, scenarioId, supplyPoint]) => {
        return this.scenarioDataService
          .reopenSupplyPoint(scenarioId, supplyPoint!)
          .pipe(
            map((savedSupplyPoint) => {
              this.selectSavedDeltaSupplyPoint(
                scenarioId,
                supplyPoint!.location,
                savedSupplyPoint
              );
              return reopenSupplyPointSucceeded({
                supplyPoint: savedSupplyPoint,
                location: supplyPoint!.location
              });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error Reopen location',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  discardPendingSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(discardPendingSupplyPointClicked),
      withLatestFrom(this.store$.select(getNextSupplyPointInfoToSelect)),
      switchMap(([_, nextSupplyPointInfoToSelect]) => {
        return this.handleDiscardPendingSupplyPoint(
          nextSupplyPointInfoToSelect
        );
      })
    )
  );

  savePendingSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePendingSupplyPointClicked),
      withLatestFrom(
        this.store$.select(getScenarioId),
        this.store$.select(getSpatialModellerSupplyPoint)
      ),
      switchMap(([_, scenarioId, supplyPoint]) => {
        return this.scenarioDataService
          .saveSupplyPoint(scenarioId, supplyPoint!)
          .pipe(
            map((savedSupplyPoint) => {
              this.selectSavedDeltaSupplyPoint(
                scenarioId,
                supplyPoint!.location,
                savedSupplyPoint
              );
              return saveSupplyPointSucceeded({
                supplyPoint: savedSupplyPoint,
                location: supplyPoint!.location,
                executeModel: true
              });
            }),
            catchError((error) =>
              of(
                fromSmStore.ScenarioActions.scenarioErrorOccurred({
                  errorOn: 'Error save location',
                  error: error
                })
              )
            )
          );
      })
    )
  );

  triggerSaveSuppyyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationSucceedFromSPSave),
      withLatestFrom(
        this.store$.select(getIsSupplyPoint),
        this.store$.select(getPendingUnsavedSupplyPoint)
      ),
      switchMap(([_, isSupplyPoint, isPendingToSave]) => {
        const isUntouchedTemporaryLocation = !isSupplyPoint && !isPendingToSave; // untouched temporary supply point
        return of(
          saveSupplyPointClicked({
            executeModel: !isUntouchedTemporaryLocation
          })
        );
      })
    )
  );

  triggerCloseSupplyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationSucceedFromSPClose),
      switchMap((uid) => {
        return of(closeSupplyPointClicked());
      })
    )
  );

  triggerReOpenSuppyyPoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerModelLocationSucceedFromSPReOpen),
      switchMap((uid) => {
        return of(reopenSupplyPointClicked());
      })
    )
  );

  dispatchSelectLocationSucceded(location: Point) {
    DropPinAction(this.mapService, this.pinDropAndSelectionService, location);
    return selectLocationSucceded({ location });
  }

  handleDiscardPendingSupplyPoint(
    nextSupplyPointInfoToSelect: {
      location: Point | null;
      supplyPointId: number | null;
    } | null
  ) {
    if (nextSupplyPointInfoToSelect?.supplyPointId) {
      return this.spatialModellerDataService
        .getSupplyPointValues(nextSupplyPointInfoToSelect!.supplyPointId!)
        .pipe(
          map((newSupplyPoint: SupplyPoint) => {
            this.pinDropAndSelectionService.updateDraggableSelectionLayerLocation(
              nextSupplyPointInfoToSelect!.location!
            );
            return loadSupplyPointSucceeded({
              supplyPoint: newSupplyPoint,
              location: nextSupplyPointInfoToSelect!.location!
            });
          })
        );
    } else if (nextSupplyPointInfoToSelect?.location) {
      return of(
        this.dispatchSelectLocationSucceded(
          nextSupplyPointInfoToSelect?.location!
        )
      );
    }
    return of(pendingUnsavedSupplyPointReset());
  }

  selectSavedDeltaSupplyPoint(
    scenarioId: number,
    location: Point,
    deltaSupplyPoint: SupplyPoint
  ) {
    this.pinDropAndSelectionService.refreshBaseAndDeltaLayersScenarioData(
      scenarioId
    );
    hidePinDropLayerIdentifier(this.mapService);
    setTimeout(
      () =>
        SupplyPointSelectionAction(
          this.mapService,
          this.pinDropAndSelectionService,
          location,
          deltaSupplyPoint.supplyKey.toString(),
          ''
        ),
      1500
    );
  }
}
