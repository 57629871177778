import { createAction, props } from '@ngrx/store';
import { ProfilerImportStatus } from 'src/app/profiler/model/profiler-import-status';

export const PollProfilerImportStatusStarted = createAction(
  '[Profiler Import Status Effects] Poll Profiler Import Status Started',
  props<{
    libraryId: number;
  }>()
);

export const PollProfilerImportStatusInProgress = createAction(
  '[Profiler Import Status Effects] Poll Profiler Import Status In Progress',
  props<{
    importStatus: ProfilerImportStatus;
  }>()
);

export const PollProfilerImportStatusCompleted = createAction(
  '[Profiler Import Status Effects] Poll Profiler Import Status Completed',
  props<{
    importStatus: ProfilerImportStatus;
  }>()
);

export const PollProfilerImportStatusFailed = createAction(
  '[Profiler Import Status Effects] Poll Profiler Import Status Failed',
  props<{
    importStatus: ProfilerImportStatus;
  }>()
);
