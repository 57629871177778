<link
  [href]="themeService.appThemeUrl$ | async"
  type="text/css"
  rel="stylesheet"
/>
<link
  [href]="themeService.appOverrideThemeUrl$ | async"
  type="text/css"
  rel="stylesheet"
/>
<router-outlet> </router-outlet>
