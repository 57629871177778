<div>
  <ng-template ngFor let-i="index" let-statsItem [ngForOf]="queryItem">
    <div *ngIf="i === 0">
      <atlas-stats-item
        [iconName]="statsItem.iconName"
        [value]="statsItem.value"
        [name]="statsItem.name"
        [isMainStats]="true"
      >
      </atlas-stats-item>
    </div>
  </ng-template>
  <div class="secondary-stats-panel">
    <ng-template ngFor let-i="index" let-statsItem [ngForOf]="queryItem">
      <div *ngIf="i > 0">
        <atlas-stats-item
          [iconName]="statsItem.iconName"
          [value]="statsItem.value"
          [name]="statsItem.name"
          [isMainStats]="false"
          [isOddIndex]="i % 2 === 1"
        >
        </atlas-stats-item>
      </div>
    </ng-template>
  </div>
</div>
