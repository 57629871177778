<div style="padding: 20px">
  <h1>Style guide</h1>
  <hr />
  <div style="padding: 20px">
    <atlas-style-guide-palette></atlas-style-guide-palette>
  </div>
  <br />

  <div style="padding: 20px">
    <atlas-style-guide-typography> </atlas-style-guide-typography>
  </div>
  <br />
</div>
