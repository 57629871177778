import {
  createFeatureSelector,
  ActionReducerMap,
  createSelector,
} from '@ngrx/store';
import * as fromError from 'src/app/core/store/reducers/errors.reducer';
import * as fromRouter from '@ngrx/router-store';

export interface ErrorState {
  errors: fromError.ErrorState;
}

export interface State {
  errors: fromError.ErrorState;
  router: fromRouter.RouterReducerState<any>;
}

export const reducers: ActionReducerMap<State, any> = {
  errors: fromError.reducer,
  router: fromRouter.routerReducer,
};

export const selectErrorState =
  createFeatureSelector<fromError.ErrorState>('errors');

export const getErrorMessage = createSelector(selectErrorState, (state) => {
  if (state && state.errorInfo) {
    return state.errorInfo.message;
  }

  return '';
});

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState<any>>('router');

export const { selectCurrentRoute: getCurrentRoute } =
  fromRouter.getRouterSelectors(selectRouter);

export const getCurrentRoutePath = createSelector(
  getCurrentRoute,
  (route) => route.routeConfig.path
);

export * from 'src/app/core/store/actions';
