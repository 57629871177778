import { ProfilerImportEffects } from './profiler-import.effects';
import { ProfilerLibraryEffects } from './profiler-library.effects';
import { ProfilerReportingEffects } from './profiler-reporting.effects';
import { ProfilerImportStatusEffects } from './profiler-import-status.effects';

import { SnackbarEffects } from './snack-bar.effects';

export const effects = [
  ProfilerLibraryEffects,
  ProfilerReportingEffects,
  ProfilerImportEffects,
  ProfilerImportStatusEffects,
  SnackbarEffects
];
