import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUIStore from 'src/app/core/store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { searchTextClearAttempt } from '../../store/actions/gazetteer.actions';

@Component({
  selector: 'atlas-clear-search',
  templateUrl: './atlas-clear-search.component.html',
  styleUrls: ['./atlas-clear-search.component.less']
})
export class ClearSearchComponent {
  @Output() clearSearcheClicked = new EventEmitter();

  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );

  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  onClearSearch() {
    this.clearSearcheClicked.emit();
    this.gazetteerStore$.dispatch(searchTextClearAttempt());
  }
}
