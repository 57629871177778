import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { getUserTenantsInfoSucceeded } from 'src/app/core/store';
import { AppFeatureService } from '../../services/feature-service.service';
import { AppFeatures } from '../../models/feature';
import { loadAppFeaturesSucceeded } from '../actions/app-feature-ui.actions';
import { LocalStorageService } from '../../services/local-storage.service';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';

@Injectable()
export class AppFeatureUIEffects {
  constructor(
    private actions$: Actions,
    private appFeatureService: AppFeatureService
  ) {}

  getAppFeatures$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserTenantsInfoSucceeded),
      switchMap(() =>
        this.appFeatureService.getAppFeatures().pipe(
          map((appFeatures: AppFeatures) => {
            return loadAppFeaturesSucceeded({ appFeatures });
          })
        )
      )
    )
  );
}
