import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SwitchTenantConfirmationDialogComponent } from '../switch-tenant-confirmation-dialog/switch-tenant-confirmation-dialog-component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogService } from 'src/app/core/services/dialog.service';
import * as fromStore from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { GeneralSettings } from 'src/app/core/models/general-settings';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TooltipLayouts } from 'src/app/core/enums/tooltip-layout.enum';
import {
  getDefaultDriveCatchmentSpeed,
  getHideLegendOnMapDownload,
  getDisableZoomToExtent
} from 'src/app/core/store';
import {
  DriveCatchmentSpeedNames,
  DriveCatchmentSpeeds
} from 'src/app/core/enums/drive-catchment-speeds.enum';
import { IsolineProviderService } from '../../services/isoline-provider.service';

@Component({
  selector: 'atlas-general-settings-tab-content',
  templateUrl: './general-settings-tab-content.component.html',
  styleUrls: ['./general-settings-tab-content.component.less']
})
export class GeneralSettingsTabContentComponent implements OnInit {
  @ViewChild('switchTenantComboBox')
  switchTenantComboBox: MatSelect;

  private subscription: Subscription = new Subscription();
  availableTenants: KeyValue<
    number,
    {
      tenantName: string;
      name: string;
    }
  >[];
  selectedTenantId: number;
  selectedSystemName: string;
  generalUserSettings: GeneralSettings | null;
  driveCatchmentSpeedList = DriveCatchmentSpeedNames;

  selectedTenantId$ = this.store$.select(fromStore.getTenantId);

  selectedSystemName$ = this.store$.select(fromStore.getSystemName);

  availableTenants$ = this.store$.select(fromStore.getAvailableTenants);

  toolTipLayoutDefault$ = this.store$.select(fromStore.getDefaultTooltipLayout);

  generalUserSettings$ = this.store$.select(fromStore.getUserGeneralSettings);

  driveCatchmentSpeedDefault$ = this.store$.select(
    getDefaultDriveCatchmentSpeed
  );

  disableZoomToExtent$ = this.store$.select(getDisableZoomToExtent);

  hideLegendOnMapDownload$ = this.store$.select(getHideLegendOnMapDownload);

  isSMFeatureAllowed$ = this.store$.select(fromStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.store$.select(
    fromStore.isLocatorFeatureAllowed
  );

  driveCatchmentSpeedDefault: DriveCatchmentSpeeds;
  toolTipLayoutDefault: TooltipLayouts;
  disableZoomToExtent: boolean;
  hideLegendOnMapDownload: boolean;
  generalUserSettingsForm: UntypedFormGroup;

  get toolTipLayouts(): typeof TooltipLayouts {
    return TooltipLayouts;
  }

  isolineProvider: string;

  constructor(
    private dialogService: DialogService,
    private store$: Store<fromStore.State>,
    private fb: UntypedFormBuilder,
    private isolineProviderService: IsolineProviderService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedTenantId$.subscribe((selectedTenantId) => {
        this.selectedTenantId = selectedTenantId;
      })
    );

    this.subscription.add(
      this.selectedSystemName$.subscribe((selectedSystemName) => {
        this.selectedSystemName = selectedSystemName;
      })
    );

    this.subscription.add(
      this.availableTenants$.subscribe((availableTenants) => {
        this.availableTenants = availableTenants;
      })
    );

    this.subscription.add(
      this.toolTipLayoutDefault$.subscribe((toolTipLayoutDefault) => {
        this.toolTipLayoutDefault = toolTipLayoutDefault;
      })
    );

    this.subscription.add(
      this.driveCatchmentSpeedDefault$.subscribe((driveCatchmentSpeed) => {
        this.driveCatchmentSpeedDefault = driveCatchmentSpeed;
      })
    );

    this.subscription.add(
      this.disableZoomToExtent$.subscribe((disableZoomToExtent) => {
        this.disableZoomToExtent = disableZoomToExtent;
      })
    );

    this.subscription.add(
      this.hideLegendOnMapDownload$.subscribe((hideLegendOnMapDownload) => {
        this.hideLegendOnMapDownload = hideLegendOnMapDownload;
      })
    );

    this.subscription.add(
      this.generalUserSettings$.subscribe((settings) => {
        this.generalUserSettings = settings;
        this.generalUserSettingsForm = this.createForm(
          this.generalUserSettings
        );
      })
    );

    this.subscription.add(
      this.isolineProviderService
        .getIsolineProvider()
        .subscribe(
          (isolineProvider) => (this.isolineProvider = isolineProvider)
        )
    );
  }

  createForm(initialSettingsValue: GeneralSettings | null) {
    const toolTipInitialValue =
      initialSettingsValue && initialSettingsValue?.tooltipLayout != null // !=null needed since the value of the tooltipLayout can be 0, which is a falsy value
        ? initialSettingsValue.tooltipLayout
        : this.toolTipLayoutDefault;

    const driveCatchmentSpeedInitalValue =
      initialSettingsValue && initialSettingsValue?.driveCatchmentSpeed
        ? this.driveCatchmentSpeedList[initialSettingsValue.driveCatchmentSpeed]
        : this.driveCatchmentSpeedList[this.driveCatchmentSpeedDefault];

    const zoomToExtentInitalValue = !this.disableZoomToExtent;

    const displayLegendOnMapDownload = !this.hideLegendOnMapDownload;

    return this.fb.group({
      toolTipLayout: [toolTipInitialValue],
      driveCatchmentSpeed: [driveCatchmentSpeedInitalValue.key],
      disableZoomToExtent: [zoomToExtentInitalValue],
      hideLegendOnMapDownload: [displayLegendOnMapDownload]
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onDropDownSelectionChanged(selectedTenantId: number) {
    if (this.selectedTenantId !== selectedTenantId) {
      const selectedTenant = this.availableTenants.find(
        (item) => item.key === selectedTenantId
      )?.value;

      const selectedTenantName = selectedTenant?.tenantName;
      const selectedSystemName = selectedTenant?.name;

      this.dialogService.show(
        SwitchTenantConfirmationDialogComponent,
        {
          width: DialogWidth.Small,
          panelClass: 'dialog-95vw-width',
          data: {
            tenantId: selectedTenantId,
            tenantName: selectedTenantName,
            selectedSystemName: selectedSystemName
          },
          disableClose: true
        },
        null,
        (cancelClick: { switchTenantCancelled: boolean }) => {
          if (cancelClick?.switchTenantCancelled) {
            this.switchTenantComboBox.value = this.selectedTenantId;
          }
        }
      );
    }
  }

  isSingleOrNoAvailableTenants() {
    return (
      this.availableTenants.length === 0 ||
      (this.availableTenants.length === 1 &&
        this.availableTenants[0].key === this.selectedTenantId)
    );
  }

  isIsolineProviderTravelTime() {
    return this.isolineProvider?.toLowerCase() === 'traveltime';
  }

  isSaveDisabled() {
    return !this.generalUserSettingsForm.dirty;
  }

  saveGeneralUserSettings() {
    const editedGeneralSettings: GeneralSettings = {
      tooltipLayout: this.generalUserSettingsForm.get('toolTipLayout')?.value,
      driveCatchmentSpeed: this.generalUserSettingsForm.get(
        'driveCatchmentSpeed'
      )?.value,
      disableZoomToExtent: !this.generalUserSettingsForm.get(
        'disableZoomToExtent'
      )?.value,
      hideLegendOnMapDownload: !this.generalUserSettingsForm.get(
        'hideLegendOnMapDownload'
      )?.value
    };
    this.store$.dispatch(
      fromStore.updateGeneralUserSettingsOnSaveClicked({
        generalSettings: editedGeneralSettings
      })
    );
  }
}
