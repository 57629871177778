import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Scenario } from '../../../models/scenario';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { AddEditScenarioFormComponent } from '../add-edit-scenario-form/add-edit-scenario-form.component';
import { Subscription } from 'rxjs';
import { ScenarioService } from 'src/app/spatial-modeller/services/scenario.service';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { createScenarioDialogSaveClicked } from 'src/app/spatial-modeller-store/actions/scenario.actions';

@Component({
  selector: 'atlas-add-scenario-dialog',
  templateUrl: './add-scenario-dialog.component.html',
  styleUrls: ['./add-scenario-dialog.component.less'],
})
export class AddScenarioDialogComponent implements OnInit, OnDestroy {
  dialogMode: DialogMode;
  userScenarios: string[] = [];
  private subscription = new Subscription();

  constructor(
    private scenarioService: ScenarioService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      scenario: Scenario;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<AddScenarioDialogComponent>,
    private store$: Store<fromSmStore.State>
  ) {}

  ngOnInit() {
    this.getAllScenarios();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getAllScenarios() {
    this.subscription.add(
      this.scenarioService
        .getAllScenarios()
        .subscribe((scenarios: Scenario[]) => {
          this.userScenarios = scenarios.map((scenario) => scenario.name);
        })
    );
  }

  onSaveClicked(addScenarioFormComponent: AddEditScenarioFormComponent) {
    const newScenario = addScenarioFormComponent.addEditScenarioForm.value;
    if (addScenarioFormComponent.addEditScenarioForm.valid) {
      this.store$.dispatch(
        createScenarioDialogSaveClicked({
          scenarioRequest: {
            name: newScenario.scenarioName,
            baseNames: newScenario.baseScenarios,
            deltaNames: newScenario.deltaScenarios,
          },
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: addScenarioFormComponent.addEditScenarioForm.controls.scenarioName
          .value,
      });
    }
  }
}
