<atlas-single-button-dialog
  buttonText="Confirm"
  headerText="InSite has been updated"
  [primaryButton]="true"
  (buttonClicked)="handleButtonClicked()"
>
  <form formContent>
    <div style="margin-top: 10px" formContent>
      <label class="label">Release Notes Overview</label>
      <ul>
        @for (feature of releaseNoteFeatures; track feature) {
        <li>{{ feature }}</li>
        } @empty {
        <li>
          There have been no listed updates. Please check with system
          administrator.
        </li>
        }
      </ul>
      <p class="message">
        Detailed notes can be found in the
        <a
          class="link"
          style="cursor: pointer"
          (click)="handleWhatsNewClicked()"
          >What's new area</a
        >.
      </p>
      <p class="message">
        Further support can be found in our resources
        <a class="link" style="cursor: pointer" (click)="navigateToResources()"
          >here</a
        >.
      </p>
    </div>
  </form>
</atlas-single-button-dialog>
