import { Component, Input } from '@angular/core';
import { CompanyCatchmentUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-company-catchment-usage-summary',
  templateUrl: './company-catchment-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class CompanyCatchmentUsageSummaryComponent {
  @Input()
  dataSource: CompanyCatchmentUsage | null;
}
