<form [formGroup]="locatorImportLibraryDataForm" autocomplete="off">
  <div class="header-padding text">
    Select a .csv file to upload a location data file.
  </div>
  <div class="file-container">
    <div class="file-button-padding">
      <input
        type="file"
        (change)="onFileSelected($event)"
        accept=".csv"
        class="file file-button-padding"
        #fileUpload
      />
      <button (click)="fileUpload.click()" class="file-button">
        Select File
      </button>
    </div>
    <div class="link">
      <a (click)="onDownloadClicked()">Download template</a>
    </div>
  </div>

  <mat-form-field appearance="outline" hideRequiredMarker="true">
    <mat-label>File location</mat-label>
    <input
      matInput
      type="text"
      [value]="fileName || 'File location'"
      readonly
    />
  </mat-form-field>
  <div class="radio-buttons-container">
    <div>
      <mat-radio-group
        aria-label="Select an option"
        formControlName="geocodingSelection"
      >
        <mat-radio-button
          [value]="false"
          class="radio-button-margin-left radio-button-margin-right"
          ><mat-label>Latitude and Longitude</mat-label>
        </mat-radio-button>

        <mat-radio-button [value]="true"
          ><mat-label>Geocode</mat-label></mat-radio-button
        >
      </mat-radio-group>
    </div>
    @if(locatorImportLibraryDataForm.controls.geocodingSelection.value){
    <div class="warning-text">
      <span> Automated geocoding will be used to provide coordinates. </span>
    </div>
    }
  </div>
</form>
