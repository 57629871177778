import { Component, Input } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { EditProfilerLibraryDialogComponent } from '../edit-profiler-library-dialog/edit-profiler-library-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';

@Component({
  selector: 'atlas-edit-profiler-library',
  templateUrl: './edit-profiler-library.component.html',
  styleUrls: ['../../less/library-management-button.component.less']
})
export class EditProfilerLibraryComponent {
  @Input() currentLibrary: ProfilerLibrary;
  constructor(public dialogService: DialogService) {}

  onOpenEditLibraryDialog() {
    this.dialogService.show(EditProfilerLibraryDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: 'Profile',
        mode: DialogMode.Add,
        headerPrefix: 'Edit',
        library: this.currentLibrary,
        affirmativeButtonText: 'Update'
      },
      disableClose: true
    });
  }
}
