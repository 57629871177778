<dx-data-grid
  style="padding-top: 10px"
  class="standard-grid"
  [dataSource]="dataSource"
  keyExpr="email"
  atlasDxDataGridDefaultOptions
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>

  <dxi-column dataField="fullName" caption="Name"></dxi-column>
  <dxi-column dataField="email"></dxi-column>
  <dxi-column
    dataField="totalImportedRows"
    [allowFiltering]="true"
    [alignment]="'left'"
    [format]="{ style: 'decimal', useGrouping: true }"
  >
  </dxi-column>

  <dxi-column
    dataField="percentageOfImportedRows"
    caption="Imported Rows %"
    [allowFiltering]="true"
    [alignment]="'left'"
    [calculateCellValue]="formatImportedRowsPercentage"
  >
  </dxi-column>

  <div>
    <atlas-dx-grid-header gridTitle="All Users"></atlas-dx-grid-header>
  </div>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
