<div
  *ngIf="
    !(isSelectedPointEmpty$ | async) && (layoutService.isDesktopView$ | async)
  "
>
  <div *ngIf="!hidden" class="icon-container">
    <button
      class="atlas-icon-button atlas-icon icon-street-view icon secondary-button"
      mat-flat-button
      aria-label="Street view"
      (click)="showImage()"
    ></button>
  </div>
  <div class="image-container" *ngIf="hidden">
    <div class="image">
      <img
        src="https://maps.googleapis.com/maps/api/streetview?size=298x169&amp;location={{
          selectedLocation?.latitude
        }},{{ selectedLocation?.longitude }}&amp;fov=90&amp;heading={{
          heading
        }}&amp;pitch=
        &amp;key=AIzaSyDJ5t3DeSSing-sNB6Ep-pcUP_8l3hZajs&amp;"
      />
      <button class="left swipe" (click)="swipeLeft()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="right swipe" (click)="swipeRight()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <mat-icon class="image-close" (click)="hideImage()">close</mat-icon>
    <span class="image-number">{{ imageNumber }}/4</span>

    <button
      class="google-maps secondary-button"
      (click)="onStreetViewClicked()"
    >
      Explore in Google Maps
    </button>
  </div>
</div>
