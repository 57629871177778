import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Scenario } from 'src/app/core/models/scenario';
import { InfoBoxComponent } from 'src/app/shared/components/atlas-info-box/atlas-info-box.component';
import {
  applyFilter,
  applySort,
  ListSortOptions,
  SearchAndSortValues,
  SortValuesArray
} from 'src/app/shared/utils/list-utils';

@Component({
  selector: 'atlas-swap-scenario-form',
  styleUrls: ['swap-scenario-form.component.less'],
  templateUrl: 'swap-scenario-form.component.html'
})
export class SwapScenarioFormComponent implements OnInit {
  private _userScenarios: Scenario[] = [];
  private selectedSortOption: ListSortOptions = 'EditedDesc';

  @ViewChild('removeInfoBox') removeInfoBox: ElementRef<InfoBoxComponent>;
  infoBoxContent: string;
  infoButton: HTMLElement | null;

  @Output() switchClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Input() currentScenarioId: number;

  @Input()
  set userScenarios(newValue: Scenario[]) {
    this._userScenarios = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
    this.scenariosLoading = false;
  }
  get userScenarios(): Scenario[] {
    return this._userScenarios;
  }
  swapScenarioForm: UntypedFormGroup;

  dataSource: Scenario[];
  scenariosLoading = true;

  sortOptions = SortValuesArray;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: '',
      sort: 'EditedDesc'
    } as SearchAndSortValues;
    this.swapScenarioForm = this.createForm(initialValue);

    this.scenariosLoading = true;
  }

  createForm(initialScenarioValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialScenarioValue.filter],
      sort: [initialScenarioValue.sort]
    });
  }

  onSwitchToScenario(scenarioId: number): void {
    this.switchClicked.emit(scenarioId);
  }

  onSortChanged(evt: any) {
    this.selectedSortOption = evt.value;
    this.dataSource = applySort(evt.value, this.dataSource);
    this.closeInfoBox();
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._userScenarios);

    this.closeInfoBox();
  }

  onDeleteScenario(scenarioId: number) {
    this.deleteClicked.emit(
      this.dataSource.find((scenario) => scenario.id === scenarioId)
    );
  }

  isInfoButtonVisible(scenario: Scenario) {
    return (
      scenario.id === this.currentScenarioId ||
      (scenario.id != this.currentScenarioId &&
        scenario.dependentScenarios.length > 0)
    );
  }

  openInfoBox(event: { scenarioId: number; infoButtonElement: HTMLElement }) {
    this.infoBoxContent =
      event.scenarioId === this.currentScenarioId
        ? 'Current scenario can not be deleted.'
        : this.getDeleteScenarioInfo(event.scenarioId);
    this.infoButton = event.infoButtonElement;
  }

  private getDeleteScenarioInfo(scenarioId: number) {
    var scenario = this.userScenarios.find(
      (scenario) => scenario.id === scenarioId
    );

    var firstParagraph = `<span>${scenario?.name} is referenced by other scenarios so it cannot be deleted.
                          To delete this scenario you must first remove it from the following scenarios: </span>`;
    var dependentScenarioList = scenario
      ? scenario.dependentScenarios
          .map((dependentScenario) => `<li>${dependentScenario.value}</li>`)
          .join('')
      : '';

    return `${firstParagraph}<ul style="padding: 0;margin-left:15px">${dependentScenarioList}</ul>`;
  }

  private closeInfoBox() {
    this.removeInfoBox.nativeElement.close();
  }
}
