import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { selectionLayerIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';

import { BaseMapLayerService } from 'src/app/shared/atlas-mapping/services/base-map-layer.service';
import { BaseMapLayersType } from 'src/app/shared/atlas-mapping/models/base-map-layer-types';
import { Layer } from 'src/app/shared/atlas-mapping/models/layer';

interface LayerGroup {
  id: string;
  isGroup: boolean;
  name: string;
  layers: Layer[];
  zOrder: number;
}

@Component({
  selector: 'atlas-layer-management',
  templateUrl: './layer-management.component.html',
  styleUrls: ['./layer-management.component.less']
})
export class LayerManagementComponent implements OnInit {
  layersExpandedDictionary = new Map<string, boolean>();
  mixedLayers$: Observable<(Layer | LayerGroup)[]>;
  initialBaseMapLayerType: BaseMapLayersType;

  constructor(
    private mapService: MapService,
    private baseMapLayerService: BaseMapLayerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.mixedLayers$ = this.mapService.layers$.pipe(
      map((layers) => this.processLayers(layers)),
      tap(() => this.triggerChangeDetection())
    );
  }

  async ngOnInit() {
    this.loadSelectedBaseMapLayer();
  }

  private processLayers(layers: Layer[]): (Layer | LayerGroup)[] {
    this.initializeExpandedState(layers);
    const groupedLayers = this.groupLayers(layers);

    return this.flattenAndSortLayers(groupedLayers);
  }

  private initializeExpandedState(layers: Layer[]): void {
    layers.forEach((layer) => {
      if (!this.layersExpandedDictionary.has(layer.id)) {
        this.layersExpandedDictionary.set(layer.id, false);
      }
    });
  }

  private groupLayers(layers: any[]): { [key: string]: Layer[] } {
    return layers.reduce((acc, layer) => {
      const groupKey = layer.props.group || 'ungrouped';
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(layer);
      return acc;
    }, {} as { [key: string]: Layer[] });
  }

  private flattenAndSortLayers(groups: {
    [key: string]: Layer[];
  }): (Layer | LayerGroup)[] {
    const flattened = Object.entries(groups).map(([groupName, layers]) => {
      if (groupName === 'ungrouped') return layers;

      const maxZOrder = Math.max(
        ...layers.map((layer: any) => layer.props.zOrder || 0)
      );

      return {
        id: groupName,
        isGroup: true,
        name: groupName,
        layers: layers.sort(this.compareLayersByZIndex),
        zOrder: maxZOrder
      } as LayerGroup;
    });

    return flattened.flat().sort((a, b) => this.compareZOrder(a, b));
  }

  private compareLayersByZIndex(a: any, b: any): number {
    return (b.props.zOrder || 0) - (a.props.zOrder || 0);
  }

  private compareZOrder(a: any, b: any): number {
    return (
      (b.zOrder || b.props.zOrder || 0) - (a.zOrder || a.props.zOrder || 0)
    );
  }

  private getMaxZIndex(item: Layer | LayerGroup): number {
    if (this.isLayerGroup(item)) {
      return Math.max(...item.layers.map((layer) => layer.zOrder || 0));
    }
    return item.zOrder || 0;
  }

  private isLayerGroup(item: Layer | LayerGroup): item is LayerGroup {
    return item.isGroup === true;
  }

  private triggerChangeDetection(): void {
    setTimeout(() => this.changeDetectorRef.detectChanges());
  }

  private loadSelectedBaseMapLayer(): void {
    this.baseMapLayerService.baseMapLayerType$
      .pipe(take(1))
      .subscribe((type) => (this.initialBaseMapLayerType = type));
  }

  onLayerExpandedStateChanged(layerId: string, expanded: boolean): void {
    this.layersExpandedDictionary.set(layerId, expanded);
    this.triggerChangeDetection();
  }

  onLayerGroupExpandedStateChanged(expanded: boolean): void {
    this.triggerChangeDetection();
  }
}
