import { Component, Input } from '@angular/core';
import { BaseMapLayersType } from 'src/app/shared/atlas-mapping/models/base-map-layer-types';

@Component({
  selector: 'atlas-base-map-layer-item',
  templateUrl: './base-map-layer-item.component.html',
  styleUrls: ['./base-map-layer-item.component.less'],
})
export class BaseMapLayerItemComponent {
  @Input() baseMapLayerName: string;
  @Input() baseMapLayerType: BaseMapLayersType;

  constructor() {}
}
