type FeatureAction = {
  actionType: string;
  updatables: string[];
};

export const featureActions: Record<string, FeatureAction> = {
  selectedLibrary: {
    actionType: 'SelectedLibrary',
    updatables: ['libraryId']
  },
  selectedLocation: {
    actionType: 'SelectedLocation',
    updatables: ['locationId']
  },
  selectedShape: {
    actionType: 'SelectedShape',
    updatables: ['shapeId']
  },
  selectedScenario: {
    actionType: 'SelectedScenario',
    updatables: ['scenarioId']
  },
  selectedSupplyPoint: {
    actionType: 'SelectedSupplyPoint',
    updatables: ['latitude', 'longitude']
  }
};
