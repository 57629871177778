import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';

@Component({
  selector: 'atlas-dynamic-form-validation',
  templateUrl: './atlas-dynamic-form-validation.component.html',
  styleUrls: ['./atlas-dynamic-form-validation.component.less']
})
export class AtlasDynamicFormValidationComponent {

  @Input() formGroup: UntypedFormGroup;
  @Input() control: SupplyPointAttribute;

  private get formControl() { return this.formGroup.get(this.control.name)};

  constructor() { }

  requiredError() {
    return this.formControl?.invalid &&
      (this.formControl?.dirty || this.formControl?.touched) &&
      this.formControl?.errors?.['required']
  }

  minLengthError() {
    return this.formControl?.invalid &&
      (this.formControl?.dirty || this.formControl?.touched) &&
      this.formControl?.errors?.['minlength'];
  }

  maxLengthError() {
    return this.formControl?.invalid &&
      (this.formControl?.dirty || this.formControl?.touched) &&
      this.formControl?.errors?.['maxlength'];
  }

  minError() {
    return this.formControl?.invalid &&
      (this.formControl?.dirty || this.formControl?.touched) &&
      this.formControl?.errors?.['min'];
  }

  maxError() {
    return this.formControl?.invalid &&
      (this.formControl?.dirty || this.formControl?.touched) &&
      this.formControl?.errors?.['max'];
  }

}
