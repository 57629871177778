import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { LocatorShapeDefaults } from 'src/app/core/models/locator-shape-defaults';
import * as fromUserSettings from 'src/app/core/store';
import {
  getLocatorShapesDefaults,
  updateLocatorUserSettingsOnSaveClicked
} from 'src/app/core/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { getDefaultDistanceUnit } from 'src/app/core/store/selectors/app-feature-ui.selectors';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { getShapeUnit } from 'src/app/shared/utils/shape-utils';
import {
  kilometersToMiles,
  milesToKilometers
} from 'src/app/shared/utils/distance-converter-utils';
import { isShapeDisabled } from 'src/app/locator/helpers/locator-shape.helper';

@Component({
  selector: 'atlas-catchment-reporting-settings-tab-content',
  templateUrl: './catchment-reporting-settings-tab-content.component.html',
  styleUrls: ['./catchment-reporting-settings-tab-content.component.less']
})
export class CatchmentReportingSettingsTabContentComponent
  implements OnInit, OnDestroy
{
  locatorSettingsFormGroup: FormGroup;
  locatorUserSettings$ = this.userSettingsStore$.select(
    fromUserSettings.getUserLocatorSettings
  );

  locatorShapesDefaults$ = this.store$.select(getLocatorShapesDefaults);
  defaultDistanceUnit$ = this.store$.select(getDefaultDistanceUnit);

  locatorShapeDefaults: LocatorShapeDefaults | null;
  locatorShapeSettingsToUse: LocatorShapeDefaults | null;

  driveUnit: string | null = null;
  walkUnit: string | null = null;
  circleUnit: string | null = null;
  polygonUnitToShow: string;
  publicTransportUnit: string;

  private subscription = new Subscription();
  maxCarRangeLimit: number;
  maxWalkRangeLimit: number;
  maxCircleRangeLimit: number;
  maxPublicTransportRangeLimit: number;
  minRangeLimit: number = 1;
  minCircleRangeLimit: number = 0;
  positiveIntegerPattern = new RegExp(`^[1-9]\\d*$`);

  constructor(
    private userSettingsStore$: Store<fromUserSettings.State>,
    private store$: Store<fromLocatorStore.State>,
    private fb: UntypedFormBuilder
  ) {
    this.locatorSettingsFormGroup = this.fb.group({
      carCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ],
      circleCatchmentSizeControl: [
        '',
        [Validators.required, Validators.min(this.minCircleRangeLimit)]
      ],
      walkCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ],
      polyCatchmentSizeControl: [
        null,
        Validators.compose([Validators.required, Validators.min(0.1)])
      ],
      publicTransportCatchmentSizeControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(this.minRangeLimit),
          Validators.pattern(this.positiveIntegerPattern)
        ])
      ]
    });
  }

  ngOnInit() {
    this.subscription.add(
      this.locatorUserSettings$
        .pipe(
          withLatestFrom(this.locatorShapesDefaults$),
          map(([userDefaults, appDefaults]) => {
            if (userDefaults != null) {
              const locatorShapeSettingsToUse: LocatorShapeDefaults = {
                car: null,
                walk: null,
                circle: null,
                polygon: null,
                publicTransport: null
              };
              locatorShapeSettingsToUse.car =
                userDefaults.car == null ? appDefaults!.car : userDefaults.car;
              locatorShapeSettingsToUse.walk =
                userDefaults.walk == null
                  ? appDefaults!.walk
                  : userDefaults.walk;
              locatorShapeSettingsToUse.circle =
                userDefaults.circle == null
                  ? appDefaults!.circle
                  : userDefaults.circle;
              locatorShapeSettingsToUse.polygon =
                userDefaults.polygon == null
                  ? appDefaults!.polygon
                  : userDefaults.polygon;
              locatorShapeSettingsToUse.publicTransport =
                userDefaults.publicTransport == null
                  ? appDefaults!.publicTransport
                  : userDefaults.publicTransport;
              return locatorShapeSettingsToUse;
            } else {
              return appDefaults;
            }
          })
        )
        .subscribe((defaults) => {
          this.locatorShapeSettingsToUse = defaults;
          this.locatorSettingsFormGroup.patchValue({
            carCatchmentSizeControl: defaults?.car?.timeInSeconds! / 60,
            circleCatchmentSizeControl: defaults?.circle?.radius,
            walkCatchmentSizeControl: defaults?.walk?.timeInSeconds! / 60,
            publicTransportCatchmentSizeControl:
              defaults?.publicTransport?.timeInSeconds! / 60
          });
        })
    );

    this.subscription.add(
      this.locatorShapesDefaults$.subscribe((defaults) => {
        this.locatorShapeDefaults = defaults;
        this.maxCarRangeLimit = defaults?.car?.rangeLimit!;
        this.maxCircleRangeLimit = defaults?.circle?.rangeLimit!;
        this.maxWalkRangeLimit = defaults?.walk?.rangeLimit!;
        this.maxPublicTransportRangeLimit =
          defaults?.publicTransport?.rangeLimit!;
      })
    );

    this.subscription.add(
      this.defaultDistanceUnit$.subscribe((defaultDistanceUnit) => {
        this.driveUnit = getShapeUnit(
          LocatorShapeTypes.Car,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.walkUnit = getShapeUnit(
          LocatorShapeTypes.Walk,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.circleUnit = getShapeUnit(
          LocatorShapeTypes.Circle,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.publicTransportUnit = getShapeUnit(
          LocatorShapeTypes.PublicTransport,
          this.locatorShapeDefaults,
          defaultDistanceUnit
        );
        this.polygonUnitToShow = defaultDistanceUnit;
        this.locatorSettingsFormGroup.patchValue({
          // we need to know the distance unit to see if the convertion to miles is needed.
          // Polygon radius is always stored in KM
          polyCatchmentSizeControl:
            this.polygonUnitToShow === 'mile' &&
            this.locatorShapeSettingsToUse?.polygon
              ? kilometersToMiles(
                  this.locatorShapeSettingsToUse?.polygon.radiusInKilometers
                )
              : this.locatorShapeSettingsToUse?.polygon?.radiusInKilometers
        });
      })
    );

    this.disableFormControl(
      'carCatchmentSizeControl',
      isShapeDisabled(this.locatorShapeDefaults?.circle)
    );
    this.disableFormControl(
      'circleCatchmentSizeControl',
      isShapeDisabled(this.locatorShapeDefaults?.circle)
    );
    this.disableFormControl(
      'walkCatchmentSizeControl',
      isShapeDisabled(this.locatorShapeDefaults?.walk)
    );
    this.disableFormControl(
      'polygonCatchmentSizeControl',
      isShapeDisabled(this.locatorShapeDefaults?.polygon)
    );
    this.disableFormControl(
      'publicTransportCatchmentSizeControl',
      isShapeDisabled(this.locatorShapeDefaults?.publicTransport)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  saveSettings() {
    const editedLocatorSettings: LocatorShapeDefaults = {
      car: {
        rangeLimit: this.maxCarRangeLimit,
        timeInSeconds:
          this.locatorSettingsFormGroup.get('carCatchmentSizeControl')!.value *
          60,
        distance: 0,
        isDisabled: this.locatorShapeDefaults?.car?.isDisabled!,
        driveCatchmentSpeed:
          this.locatorShapeDefaults?.car?.driveCatchmentSpeed!
      },
      circle: {
        radius: this.locatorSettingsFormGroup.get('circleCatchmentSizeControl')!
          .value,
        isDisabled: this.locatorShapeDefaults?.circle?.isDisabled!,
        rangeLimit: this.maxCircleRangeLimit
      },
      walk: {
        timeInSeconds:
          this.locatorSettingsFormGroup.get('walkCatchmentSizeControl')!.value *
          60,
        isDisabled: this.locatorShapeDefaults?.walk?.isDisabled!,
        rangeLimit: this.maxWalkRangeLimit,
        distance: 0,
        driveCatchmentSpeed:
          this.locatorShapeDefaults?.walk?.driveCatchmentSpeed!
      },
      polygon: {
        radiusInKilometers:
          this.polygonUnitToShow === 'mile' &&
          this.locatorShapeSettingsToUse?.polygon
            ? milesToKilometers(
                this.locatorSettingsFormGroup.get('polyCatchmentSizeControl')!
                  .value
              )
            : this.locatorSettingsFormGroup.get('polyCatchmentSizeControl')!
                .value,
        isDisabled: this.locatorShapeDefaults?.polygon?.isDisabled!,
        steps: this.locatorShapeDefaults?.polygon?.steps!
      },
      publicTransport: isShapeDisabled(
        this.locatorShapeDefaults?.publicTransport
      )
        ? null
        : {
            rangeLimit: this.maxPublicTransportRangeLimit,
            timeInSeconds:
              this.locatorSettingsFormGroup.get(
                'publicTransportCatchmentSizeControl'
              )!.value * 60,
            distance: 0,
            isDisabled: this.locatorShapeDefaults?.publicTransport?.isDisabled!,
            driveCatchmentSpeed:
              this.locatorShapeDefaults?.publicTransport?.driveCatchmentSpeed!
          }
    };
    this.store$.dispatch(
      updateLocatorUserSettingsOnSaveClicked({
        locator: editedLocatorSettings
      })
    );
    this.locatorSettingsFormGroup.markAsPristine();
  }

  emptyCarCatchmentError() {
    return this.locatorSettingsFormGroup.controls.carCatchmentSizeControl.errors
      ?.required;
  }
  emptyWalkCatchmentError() {
    return this.locatorSettingsFormGroup.controls.walkCatchmentSizeControl
      .errors?.required;
  }
  emptyCircleCatchmentError() {
    return this.locatorSettingsFormGroup.controls.circleCatchmentSizeControl
      .errors?.required;
  }
  emptyPublicTransportCatchmentError() {
    return this.locatorSettingsFormGroup.controls
      .publicTransportCatchmentSizeControl.errors?.required;
  }
  emptyPolyCatchmentError() {
    return this.locatorSettingsFormGroup.controls.polyCatchmentSizeControl
      .errors?.required;
  }

  minCarCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('carCatchmentSizeControl')
      ?.hasError('min');
  }

  minWalkCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('walkCatchmentSizeControl')
      ?.hasError('min');
  }

  minCircleCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('circleCatchmentSizeControl')
      ?.hasError('min');
  }
  minPublicTransportCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('publicTransportCatchmentSizeControl')
      ?.hasError('min');
  }

  minPolyCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('polyCatchmentSizeControl')
      ?.hasError('min');
  }

  maxCarCatchmentSizeError(): boolean {
    const control = this.locatorSettingsFormGroup.get(
      'carCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxCarRangeLimit;
  }

  maxWalkCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'walkCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxWalkRangeLimit;
  }

  maxCircleCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'circleCatchmentSizeControl'
    );
    return control !== null && control.value > this.maxCircleRangeLimit;
  }

  maxPublicTransportCatchmentSizeError() {
    const control = this.locatorSettingsFormGroup.get(
      'publicTransportCatchmentSizeControl'
    );
    return (
      control !== null && control.value > this.maxPublicTransportRangeLimit
    );
  }

  positiveIntegersCircleCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('circleCatchmentSizeControl')
      ?.hasError('pattern');
  }
  positiveIntegersWalkCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('walkCatchmentSizeControl')
      ?.hasError('pattern');
  }

  positiveIntegersCarCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('carCatchmentSizeControl')
      ?.hasError('pattern');
  }

  positiveIntegersPublicTransportCatchmentSizeError() {
    return this.locatorSettingsFormGroup
      .get('publicTransportCatchmentSizeControl')
      ?.hasError('pattern');
  }

  invalidForm() {
    return (
      this.emptyCarCatchmentError() ||
      this.emptyWalkCatchmentError() ||
      this.emptyCircleCatchmentError() ||
      this.emptyPolyCatchmentError() ||
      this.emptyPublicTransportCatchmentError() ||
      this.minCarCatchmentSizeError() ||
      this.minCircleCatchmentSizeError() ||
      this.minWalkCatchmentSizeError() ||
      this.minPolyCatchmentSizeError() ||
      this.minPublicTransportCatchmentSizeError() ||
      this.maxCarCatchmentSizeError() ||
      this.maxCircleCatchmentSizeError() ||
      this.maxWalkCatchmentSizeError() ||
      this.maxPublicTransportCatchmentSizeError() ||
      this.positiveIntegersCarCatchmentSizeError() ||
      this.positiveIntegersWalkCatchmentSizeError() ||
      this.positiveIntegersPublicTransportCatchmentSizeError() ||
      !this.locatorSettingsFormGroup.dirty
    );
  }

  disableFormControl(controlName: string, isDisabled: boolean) {
    const control = this.locatorSettingsFormGroup.get(controlName);
    if (control && isDisabled) {
      control.disable();
    }
  }
}
