<mat-form-field
  class="chip-list"
  appearance="outline"
  (click)="chipInputListFocus()"
>
  <mat-label>{{ chipListTitle }}</mat-label>
  <mat-chip-grid #chipList aria-label="selection" [disabled]="disabled">
    <mat-chip-row
      *ngFor="let item of addedItems"
      (removed)="remove(item)"
      selectable="true"
      removable="true"
      [disabled]="areFixedItems(item)"
    >
      {{ item }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      [placeholder]="placeholder"
      #chipListInput
      class="chip-list-input"
      [formControl]="chipListCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    class="chip-list-autocomplete"
    autoActiveFirstOption
    (optionSelected)="selected($event)"
  >
    <ng-container *ngFor="let item of filteredItems$ | async">
      <mat-option *ngIf="!addedItems?.includes(item)" [value]="item">
        {{ item }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-hint align="end" class="chip-list-show-error">{{
    errorMessage
  }}</mat-hint>
</mat-form-field>
