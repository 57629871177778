import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { UsageWorkspaceComponent } from './components/usage-workspace/usage-workspace.component';
import { SettingsWorkspaceComponent } from './components/settings-workspace/settings-workspace.component';
import { HelpWorkspaceComponent } from './components/help-workspace/help-workspace.component';
import { ReleaseNotesWorkspaceComponent } from './components/release-notes-workspace/release-notes-workspace.component';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'usage',
    component: UsageWorkspaceComponent
  },
  {
    canActivate: [AuthGuard],
    path: 'settings',
    component: SettingsWorkspaceComponent
  },
  {
    canActivate: [AuthGuard],
    path: 'help',
    component: HelpWorkspaceComponent
  },
  {
    canActivate: [AuthGuard],
    path: 'release-notes',
    component: ReleaseNotesWorkspaceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtlasReportingRoutingModule {}
