import { Component, Input } from '@angular/core';
import { CompanyGeocoderRecordsUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-company-geocoded-records-usage-summary',
  templateUrl: './company-geocoded-records-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class CompanyGeocodedRecordsUsageSummaryComponent {
  @Input()
  dataSource: CompanyGeocoderRecordsUsage | null;
}
