<div class="container">
  <div class="header-padding text">Import overview</div>
  <div class="summary-container">
    <div class="summary-item">
      <span class="summary-item-title">Records Uploaded</span>
      <div class="count-text">{{ processedRecords | number : '1.0-0' }}</div>
    </div>
    <div class="summary-item">
      <span class="summary-item-title">Imported successfully</span>
      <div class="count-text">{{ importedRecords | number : '1.0-0' }}</div>
    </div>
  </div>
</div>
