import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atlas-layer-description',
  templateUrl: './layer-description.component.html',
  styleUrls: ['./layer-description.component.less']
})
export class LayerDescriptionComponent implements OnInit {
  @Input() layer: any;

  constructor() {}

  ngOnInit(): void {}
}
