import { Observable } from "rxjs"
import { filter, map, withLatestFrom } from "rxjs/operators"

// Helper observable function that can be used inside createEffect to determine if
// the effect should continue or not based on the condition$ passed in. For example
// usage see AppFeatureStateService
export function allowWhenCondition(condition$: Observable<boolean>) {
  return function<T>(source$: Observable<T>): Observable<T> {
    return source$.pipe(
      withLatestFrom(condition$),
      filter(([value, condition]) => condition),
      map(([value]) => value),
    )
  }
}