import { Layer } from '../models/layer';
import { GeoJsonLayer } from '@deck.gl/layers';

import {
  createSvgThematicIcon,
  getMapLayerDefaultProperties,
  svgToDataURL
} from '../helpers/map-layer-helper';

// Due to an issue showing SVG stroke lines with deck gl and the mask property.
// Atlas markers will have the SVG read and edited to allow stroke lines.
// This is to be done just for the Atlas map Markers. The existing icon layers can be used for other icons

export class ThematicAtlasMapMarkerLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties(
      'ThematicInSiteEverywhereMapPin',
      jwtToken
    );

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    layerProperties.rgbHexDictionary = new Map<string, any>();

    layerProperties.getIcon = (data: any) => ({
      url: svgToDataURL(createSvgThematicIcon(data, layerProperties)),
      width: 24,
      height: 24,
      anchorY: 20
    });

    layerProperties.dataComparator = (newData: any, oldData: any) => {
      // https://deck.gl/docs/api-reference/core/layer#datacomparator
      return;
    };

    return new GeoJsonLayer(layerProperties);
  }
}
