import { Subject } from 'rxjs';

import { EditableGeoJsonLayer } from '@nebula.gl/layers/dist';
import { ViewMode } from '@nebula.gl/edit-modes/dist';

import { Layer } from '../models/layer';
import { PolygonSelectionService } from '../services/polygon-selection-service';

import { DynamicTilesetService } from '../services/dynamic-tileset.service';
import { LegendItem } from '../models/legend-item';
import { MapService } from '../services/map.service';

export class AtlasEditPolygonLayer extends Layer {
  id = 'Edit_POLYGON_LAYER';
  visible = false;
  dataLoaded = new Subject();

  selectedFeatureIndexes = [];

  myFeatureCollection = {
    type: 'FeatureCollection',
    features: []
  };

  geojsonData: any = null;

  // https://nebula.gl/docs/api-reference/layers/editable-geojson-layer
  // https://github.com/uber/nebula.gl/blob/master/docs/api-reference/layers/editable-geojson-layer.md
  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicTilesetService: DynamicTilesetService,
    polygonSelectionService: PolygonSelectionService
  ) {
    //this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultLayerProps = {
      selectedFeatureIndexes: this.selectedFeatureIndexes,
      mode: ViewMode,
      visible: this.visible,

      // caci props
      ignoreClickEvent: false,
      visibleNotAffectedByTriggerScale: false,
      minTrigger: 0,
      maxTrigger: 20,
      cacheTimeInSeconds: 0,
      tooltipItemOrder: [],
      hiddenTooltipColumns: [],

      // Interactive props
      pickable: true,
      autoHighlight: true,

      // Styles
      filled: true,
      getEditHandlePointColor: [25, 125, 255],
      pointRadiusMinPixels: 2,
      pointRadiusScale: 2000,
      extruded: true,
      getElevation: 1000,
      getLineColor: [25, 125, 255],
      getFillColor: [25, 125, 255, 100],

      onSelect: (event: any) => {
        // console.log('onselect edit layer', event);
      },

      onEdit: ({
        updatedData,
        editType,
        featureIndexes,
        editContext
      }: {
        updatedData: any;
        editType: any;
        featureIndexes: any;
        editContext: any;
      }) => {
        this.myFeatureCollection = updatedData;
        this.selectedFeatureIndexes = editContext.positionIndexes;

        polygonSelectionService.polgonShapeEdited(
          updatedData,
          editType,
          featureIndexes,
          editContext
        );
        // deck.setProps({ layers: getLayers() });
      }
    };

    let layerProperties = {
      ...defaultLayerProps,
      ...this.userDefinedProps,
      zOrder: this.zOrder,
      type: this.type,
      legendInfo: this.getLegendInfo(),
      legendTriggerScaleText: 'Always visible'
    };

    return new EditableGeoJsonLayer(layerProperties);
  }

  getLegendInfo(): LegendItem[] {
    return [
      {
        mapLayerType: 'Boundary',
        fillColor: [255, 255, 255, 0], // transparent
        lineColor: [0, 0, 0],
        lineWidth: 3,
        iconColor: [],
        text: '',
        iconUrl: '',
        iconHeight: 24,
        iconWidth: 24,
        iconFilter: '',
        labelBackground: []
      }
    ];
  }
}
