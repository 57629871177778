import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';

import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { SwapScenarioDialogComponent } from '../swap-scenario-dialog/swap-scenario-dialog.component';
import { getScenarioId } from 'src/app/spatial-modeller-store/selectors';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';

@Component({
  selector: 'atlas-swap-scenario',
  templateUrl: './swap-scenario.component.html',
  styleUrls: ['../less/scenario-management-button.component.less']
})
export class SwapScenarioComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private scenarioId$ = this.store$.select(getScenarioId);
  private currentScenarioId: number;
  @Input() disabled: boolean | null = false;
  @Output() swapScenarioClicked = new EventEmitter();

  constructor(
    public dialogService: DialogService,
    private store$: Store<fromScenarioStore.State>
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.subscription.add(
      this.scenarioId$.subscribe((id) => {
        this.currentScenarioId = id;
      })
    );
  }

  onSwapScenario() {
    this.dialogService.show(SwapScenarioDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        headerText: 'Switch scenario',
        currentScenarioId: this.currentScenarioId
      },
      disableClose: true
    });
  }
}
