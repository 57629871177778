import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeatureUpdateResponse } from '../models/feature-update-response';

@Injectable({
  providedIn: 'root'
})
export class FeatureUpdateService {
  featureUpdateApiUrl = `${environment.baseUrl}api/users/feature-update-accepted`;

  constructor(private http: HttpClient) {}

  public updateUserFeatureAccepted() {
    return this.http.put(this.featureUpdateApiUrl, {});
  }

  public hasUserAcceptedFeatureUpdate(): Observable<FeatureUpdateResponse> {
    return this.http.get<FeatureUpdateResponse>(this.featureUpdateApiUrl);
  }
}
