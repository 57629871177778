import { createSelector } from '@ngrx/store';
import * as fromCore from '../reducers';
import {
  getDefaultHideLegendOnMapDownload,
  getDefaultDisableZoomToExtent,
  getDefaultIsSimpleStatsView,
  getDefaultMultiCatchmentMode,
  getDefaultMultipleCatchmentsSelection
} from './app-feature-ui.selectors';

export const getUserLocatorSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.locator
);

export const getUserGeneralSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.general
);

export const getUserCatchmentReportingSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.catchmentReporting
);

export const getDisableZoomToExtent = createSelector(
  fromCore.getUserSettingsState,
  getDefaultDisableZoomToExtent,
  (state, defaultDisableZoomToExtent) =>
    state?.general && state?.general?.disableZoomToExtent !== null
      ? state.general.disableZoomToExtent
      : defaultDisableZoomToExtent
);

export const getHideLegendOnMapDownload = createSelector(
  fromCore.getUserSettingsState,
  getDefaultHideLegendOnMapDownload,
  (state, defaultHideLegendOnMapDownload) =>
    state?.general && state?.general?.hideLegendOnMapDownload !== null
      ? state.general.hideLegendOnMapDownload
      : defaultHideLegendOnMapDownload
);

export const getIsSimpleStatsView = createSelector(
  fromCore.getUserSettingsState,
  getDefaultIsSimpleStatsView,
  (state, defaultIsSimpleStatsView) =>
    state?.general && state?.general?.isSimpleStatsView !== null
      ? state.general.isSimpleStatsView
      : defaultIsSimpleStatsView
);

export const getMultiCatchmentMode = createSelector(
  fromCore.getUserSettingsState,
  getDefaultMultiCatchmentMode,
  (state, defaultMultiCatchmentMode) =>
    state?.catchmentReporting &&
    state?.catchmentReporting?.multiCatchmentMode !== null &&
    state?.catchmentReporting.multiCatchmentMode !== undefined
      ? state.catchmentReporting.multiCatchmentMode
      : defaultMultiCatchmentMode
);

export const getMultipleCatchmentsSelection = createSelector(
  fromCore.getUserSettingsState,
  getDefaultMultipleCatchmentsSelection,
  (state, defaultMultipleCatchmentsSelection) =>
    state?.catchmentReporting &&
    state?.catchmentReporting?.multipleCatchmentsSelection !== null
      ? state.catchmentReporting.multipleCatchmentsSelection
      : defaultMultipleCatchmentsSelection
);

export const getUserNetworkPlanningSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning
);

export const getUserNetworkPlanningSettingsLiveModeDisabled = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning?.liveModeDisabled
);

export const getUserSettings = createSelector(
  getUserGeneralSettings,
  getUserLocatorSettings,
  getUserNetworkPlanningSettings,
  getUserCatchmentReportingSettings,
  (general, locator, networkPlanning, catchmentReporting) => {
    return { general, locator, networkPlanning, catchmentReporting };
  }
);
