import { Component, Input } from '@angular/core';
import { BaseMapLayersType } from 'src/app/shared/atlas-mapping/models/base-map-layer-types';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';

@Component({
  selector: 'atlas-base-map-layer-collection',
  templateUrl: './base-map-layer-collection.component.html',
  styleUrls: ['./base-map-layer-collection.component.less'],
})
export class BaseMapLayerCollectionComponent {
  @Input() initialBaseMapLayerType: BaseMapLayersType;

  baseMapLayers: [string, BaseMapLayersType][] = Array.from(
    this.mapService.baseMapLayers.entries()
  );

  constructor(private mapService: MapService) {}

  baseMapLayerValueChanged(event: any) {
    this.mapService.changeBaseMapLayer(event.value);
  }
}
