import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/*
  this to be used as a routing guard to prevent the user from navigating to pages without logining on

*/

@Injectable({ providedIn: 'root' })
export class EmailGuard  {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isEmailAvailable();
  }
}
