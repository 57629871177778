import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { LocatorSummaryStatsStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';

@Component({
  selector: 'atlas-locator-library-summary-stats-step-1',
  templateUrl: './locator-library-summary-stats-step-1.component.html',
  styleUrls: ['./locator-library-summary-stats-step-1.component.less']
})
export class LocatorLibrarySummaryStatsStep1 {
  mode: ProgressSpinnerMode = 'indeterminate';

  @Input() errorsProcessingData = false;

  private _summaryStatsProcessStepState: LocatorSummaryStatsStatusProgressStep[];
  @Input()
  set summaryStatsProcessStepState(
    newValue: LocatorSummaryStatsStatusProgressStep[]
  ) {
    this._summaryStatsProcessStepState = newValue;
    if (this._summaryStatsProcessStepState[0].state === 'Completed') {
      this.percentage = 100;
      this.mode = 'determinate';
    } else {
      this.percentage = 0;
      this.mode = 'indeterminate';
    }
  }
  get summaryStatsProcessStepState(): LocatorSummaryStatsStatusProgressStep[] {
    return this._summaryStatsProcessStepState;
  }

  percentage: number = 0;

  constructor() {}
}
