import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as fromLocatorStore from 'src/app/locator-store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { LocatorStatusError } from 'src/app/locator/models/locator-import-status-error';
import { LocatorSummaryStatsStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';
import { LocatorSummaryStatsUiDialogSteps } from 'src/app/locator/helpers/locator-status-helper';
import { getSelectedPbiReportUrl } from 'src/app/core/helpers/power-bi-reports.helper';
import { PowerBiReportViewerService } from 'src/app/core/services/report-viewer.service';
import { Router } from '@angular/router';
import { logPowerBiReportAttempt } from 'src/app/locator-store/actions/locator-shape.actions';

@Component({
  selector: 'atlas-locator-library-summary-stats-dialog',
  templateUrl: './locator-library-summary-stats-dialog.component.html',
  styleUrls: ['./locator-library-summary-stats-dialog.component.less']
})
export class LocatorLibrarySummaryStatsDialogComponent implements OnInit {
  dialogMode: DialogMode;
  currentStage: number = 1;
  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;

  locatorSummaryStatsStepState$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorLibrarySummaryStatsProgressSteps
  );
  locatorSummaryStatsStatus$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorLibrarySummaryStatsStatus
  );

  summaryStatsProcessStepState: LocatorSummaryStatsStatusProgressStep[];
  screenToShow: LocatorSummaryStatsUiDialogSteps;

  locatorSummaryStatsStatusError: LocatorStatusError = {
    status: 'SummaryStatsStarted',
    errors: []
  };

  private powerBiWorkspaceId = 'c0732fd8-8ef4-4a3f-88bb-f1a07cd78405'; // Temporary
  private powerBiReportId = 'ff9e13aa-6b71-44e2-bf6f-38fbba10e01d'; // Temporary

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
    },
    private locatorStore$: Store<fromLocatorStore.State>,
    private powerBiReportViewerService: PowerBiReportViewerService,
    private router: Router
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
  }

  ngOnInit() {
    this.subscription.add(
      this.locatorSummaryStatsStepState$.subscribe(
        (summaryStatsProcessStepState) => {
          this.summaryStatsProcessStepState = summaryStatsProcessStepState;
          this.screenToShow = 'generating_summary_stats';
          this.currentStage = this.getCurrentStage(this.screenToShow);
        }
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onNextClicked() {
    if (this.currentStage === 1) {
      this.screenToShow = 'show_summary_and_report';
      this.currentStage = this.getCurrentStage(this.screenToShow);
      this.affirmativeButtonText = 'Launch report';
    } else {
      this.launchReport();
    }
  }

  getCurrentStage(
    currentScreen: LocatorSummaryStatsUiDialogSteps | 'summary'
  ): number {
    if (currentScreen === 'generating_summary_stats') {
      return 1;
    }
    return 2;
  }

  isAffirmativeButtonDisabled() {
    return (
      this.locatorSummaryStatsStatusError.errors.length > 0 ||
      this.summaryStatsProcessStepState[0]?.state === 'Processing'
    );
  }

  isCancelButtonDisabled() {
    return this.summaryStatsProcessStepState[0]?.state === 'Processing';
  }

  launchReport() {
    getSelectedPbiReportUrl(
      this.powerBiReportViewerService,
      this.router,
      this.powerBiWorkspaceId,
      this.powerBiReportId,
      [this.data.libraryId]
    ).subscribe((pbiReportUrl) => {
      window.open(pbiReportUrl, '_blank');
      this.locatorStore$.dispatch(logPowerBiReportAttempt());

      pbiReportUrl;
    });
  }
}
