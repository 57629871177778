import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromUIStore from 'src/app/core/store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromProfilerStore from 'src/app/profiler-store';

@Component({
  selector: 'atlas-reports-panel',
  templateUrl: './reports-panel.component.html',
  styleUrls: ['./reports-panel.component.less']
})
export class ReportsPanelComponent {
  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );
  profilerStatus$ = this.profilerStore$.select(
    fromProfilerStore.getImportStatusMessage
  );

  constructor(
    private UIStore$: Store<fromUIStore.State>,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private profilerStore$: Store<fromProfilerStore.State>
  ) {}
}
