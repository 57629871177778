import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const isProfilerFeatureAllowed = createSelector(
  fromFeature.getProfilerUIState,
  (state) => state?.profilerFeatureAllowed
);

export const isProfilerFeatureSelected = createSelector(
  fromFeature.getProfilerUIState,
  (state) => state?.featureSelected
);

export const getHasProfilerFeatureLoaded = createSelector(
  fromFeature.getProfilerUIState,
  (state) => state?.hasProfilerFeatureLoaded
);
