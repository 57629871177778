import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';
import { SupplyPointAttributeKeyValue } from 'src/app/spatial-modeller/models/supply-point-attribute-key-value';
import { FormControlTypes } from '../../models/dynamic-form';

@Component({
  selector: 'atlas-dynamic-form',
  templateUrl: './atlas-dynamic-form.component.html',
  styleUrls: ['./atlas-dynamic-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasDynamicFormComponent implements OnDestroy {
  formControlTypes: any = FormControlTypes;

  dynamicForm: SupplyPointAttribute[];

  modelExecutedForAttributes: boolean = true;

  @Input() isTemporary: boolean | null = true;

  @Input()
  set dynamicFormJson(attributes: SupplyPointAttribute[] | null) {
    if (attributes && attributes.length > 0) {
      this.dynamicForm = this.removeLocation(attributes);
      this.dynamicForm = this.removeNonVisibleAttributes(this.dynamicForm);
      this.createForm(this.dynamicForm);
    }
  }

  private _isFormReadOnly: boolean | null = false;
  @Input()
  set isFormReadOnly(readOnly: boolean | null) {
    this._isFormReadOnly = readOnly;
    readOnly ? this.makeFormReadOnly() : this.resetFormControlState();
  }
  get isFormReadOnly(): boolean | null {
    return this._isFormReadOnly;
  }

  @Input()
  set formValues(values: any) {
    this.unsubscribeToValueChanged();
    this.formValuesMap = new Map(
      // We have to lowercase the key here because this comes from
      // the database and the casing could be different. We just lowercase
      // all the keys to avoid comparison issues when searching and matching
      // on the key.
      Object.entries<string>(values).map((x) => [x[0].toLowerCase(), x[1]])
    );

    if (this.dynamicFormGroup.pristine) {
      this.updateFormValues();
    }

    if (this.valueChangedSubscription.closed) {
      this.subscribeToValueChanged();
    }
  }

  @Output() valueChanged = new EventEmitter<any>();

  dynamicFormGroup: UntypedFormGroup = this.fb.group({});
  private formValuesMap: Map<
    string,
    string | number | Array<SupplyPointAttributeKeyValue>
  >;
  private valueChangedSubscription: Subscription;

  constructor(private fb: UntypedFormBuilder) {}

  createForm(form: SupplyPointAttribute[]) {
    this.valueChangedSubscription?.unsubscribe();

    const previousDynamicFormGroupControls = [
      ...Object.keys(this.dynamicFormGroup.controls)
    ];

    // Remove controls not valid for the current array of supply point attributes
    if (Object.keys(this.dynamicFormGroup.controls).length > 0) {
      for (const formGroupControlKey of Object.keys(
        this.dynamicFormGroup.controls
      )) {
        if (form.every((attribute) => attribute.name !== formGroupControlKey)) {
          // The formGroup has a control which name is not included in the array of Supply Point Attributes
          this.dynamicFormGroup.removeControl(formGroupControlKey);
        }
      }
    }

    for (const control of form) {
      let formControl = new UntypedFormControl();
      if (control.isReadOnly) formControl.disable();
      this.addValue(control, formControl);
      this.addValidators(control, formControl);
      this.dynamicFormGroup.addControl(control.name, formControl);
    }

    // if the number of attributes are different (user has selected a different fascia),
    // then the form is marked as pristine (values will be updated).
    // Otherwise, we don't update the values coming from the state
    // to allow the user to modified the content of the textboxes freely (Atlas-778)
    if (
      JSON.stringify(Object.keys(this.dynamicFormGroup.controls)) !==
      JSON.stringify(previousDynamicFormGroupControls)
    ) {
      this.dynamicFormGroup.markAsPristine();
    }
    this.subscribeToValueChanged();
  }

  ngOnDestroy(): void {
    this.unsubscribeToValueChanged();
  }

  onReset() {
    if (this.valueChangedSubscription.closed) {
      this.subscribeToValueChanged();
    }
    if (this.isTemporary) {
      for (const control of this.dynamicForm) {
        let formControl = this.dynamicFormGroup.get(
          `${control.name}`
        ) as UntypedFormControl;

        if (this.getValue(control.name) !== control.defaultValue) {
          this.setDefaultValue(control, formControl);
        }
      }
    }
    this.modelExecutedForAttributes = true;
    this.dynamicFormGroup.markAsPristine();
  }

  markFormAsPristine() {
    this.dynamicFormGroup.markAsPristine();
    this.dynamicFormGroup.markAsUntouched();
  }

  private setDefaultValue(
    jsonControl: SupplyPointAttribute,
    formControl: UntypedFormControl
  ) {
    this.setValue(jsonControl, formControl, jsonControl.defaultValue, true);
  }

  private addValue(
    jsonControl: SupplyPointAttribute,
    formControl: UntypedFormControl
  ) {
    let value = this.getValue(jsonControl.name);
    let valueToset =
      value === undefined || value === null
        ? jsonControl.defaultValue
        : value.toString();

    this.setValue(jsonControl, formControl, valueToset);
  }

  private setValue(
    jsonControl: SupplyPointAttribute,
    formControl: UntypedFormControl,
    valueToset: string,
    emitEvent: boolean = false
  ) {
    switch (jsonControl.displayType) {
      case this.formControlTypes.List:
        this.setValuesForListSelection(valueToset, formControl, emitEvent);
        break;
      case this.formControlTypes.Checkbox:
      case this.formControlTypes.Slider:
        this.setValuesForBooleanTypes(valueToset, formControl, emitEvent);
        break;
      default:
        formControl.setValue(valueToset, { emitEvent });
        break;
    }
  }

  private getValue(controlName: string) {
    let value = undefined;
    if (this.formValuesMap) {
      value = this.formValuesMap.get(controlName);
      if (value === undefined) {
        let attribs = this.formValuesMap.get(
          'attributes'
        ) as Array<SupplyPointAttributeKeyValue>;
        return attribs.find((x) => x.key === controlName)?.value;
      }
    }
    return value;
  }

  private setValuesForListSelection(
    value: string,
    formControl: UntypedFormControl,
    emitEvent: boolean = false
  ) {
    let selectedList = value.split(',').map((n) => parseInt(n));
    formControl.setValue(selectedList, { emitEvent });
  }

  private setValuesForBooleanTypes(
    value: string,
    formControl: UntypedFormControl,
    emitEvent: boolean = false
  ) {
    formControl.setValue(value === 'true', { emitEvent });
  }

  private addValidators(
    jsonControl: SupplyPointAttribute,
    formControl: UntypedFormControl
  ) {
    const validatorsToAdd = [];

    if (jsonControl.isMandatory) {
      validatorsToAdd.push(Validators.required);
    }

    if (jsonControl.strMinLength) {
      validatorsToAdd.push(Validators.minLength(jsonControl.strMinLength));
    }

    if (jsonControl.strMaxLength) {
      validatorsToAdd.push(Validators.maxLength(jsonControl.strMaxLength));
    }

    if (jsonControl.minValue !== null) {
      validatorsToAdd.push(Validators.min(jsonControl.minValue as number));
    }

    if (jsonControl.maxValue !== null) {
      validatorsToAdd.push(Validators.max(jsonControl.maxValue as number));
    }

    formControl.setValidators(validatorsToAdd);
  }

  private removeLocation(attributes: SupplyPointAttribute[]) {
    return attributes.filter((property) => property.name != 'location');
  }

  private removeNonVisibleAttributes(attributes: SupplyPointAttribute[]) {
    return attributes.filter((property) => property.isVisible);
  }

  private makeFormReadOnly() {
    // formValues is only set when an existing supply point is selected
    // and the form is read only so there is no need to listen for the form
    // change events. Otherwise setting the form values will trigger
    // unnecessary change events.
    this.valueChangedSubscription?.unsubscribe();
    if (this.dynamicForm) {
      for (const control of this.dynamicForm) {
        let formControl = this.dynamicFormGroup.get(
          `${control.name}`
        ) as UntypedFormControl;
        formControl.disable();
      }
    }
  }

  private resetFormControlState() {
    this.valueChangedSubscription?.unsubscribe();
    if (this.dynamicFormGroup && this.dynamicForm) {
      for (const control of this.dynamicForm) {
        let formControl = this.dynamicFormGroup.get(
          `${control.name}`
        ) as UntypedFormControl;
        control.isReadOnly ? formControl.disable() : formControl.enable();
      }
    }
    if (this.valueChangedSubscription.closed) {
      this.subscribeToValueChanged();
    }
  }

  private updateFormValues() {
    if (this.dynamicFormGroup && this.dynamicForm) {
      for (const control of this.dynamicForm) {
        let formControl = this.dynamicFormGroup.get(
          control.name
        ) as UntypedFormControl;
        this.addValue(control, formControl);
      }
    }
  }

  private subscribeToValueChanged() {
    this.valueChangedSubscription = this.dynamicFormGroup.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.dynamicFormGroup?.valid) {
          // disabled control values are not added using this.dynamicFormGroup.value
          for (const prop in this.dynamicFormGroup.controls) {
            this.dynamicFormGroup.value[prop] =
              this.dynamicFormGroup.controls[prop].value;
          }

          this.valueChanged.emit(this.dynamicFormGroup.value);
          this.modelExecutedForAttributes = false;
        }
      });
  }

  private unsubscribeToValueChanged() {
    this.valueChangedSubscription?.unsubscribe();
  }
}
