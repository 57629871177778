import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { updateFeatureUpdateAcceptedAttempt } from 'src/app/reducers';
import { ClearStateService } from '../../services/clear-state.service';

@Component({
  selector: 'atlas-feature-update-dialog',
  templateUrl: './feature-update-dialog.component.html',
  styleUrl: './feature-update-dialog.component.less'
})
export class FeatureUpdateDialogComponent implements OnInit {
  releaseNoteFeatures: string[] = [];
  constructor(
    private store$: Store,
    private dialogRef: MatDialogRef<FeatureUpdateDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      releaseNotes: string[];
    },
    private clearStateService: ClearStateService
  ) {}

  ngOnInit(): void {
    this.releaseNoteFeatures = this.data.releaseNotes;
  }

  navigateToResources() {
    const resourcesURL = 'https://help.insite.caci.co.uk/OXvHtUGibcp55twDVc8j';
    window.open(resourcesURL, '_blank');
  }

  handleWhatsNewClicked() {
    this.handleButtonClicked();
    this.dialogRef.close();

    this.router.navigate(['/reporting/release-notes']);
    this.clearStateService.resetApplication(false);
  }

  handleButtonClicked() {
    this.store$.dispatch(updateFeatureUpdateAcceptedAttempt());
  }
}
