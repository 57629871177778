import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromLocatroStore from 'src/app/locator-store';
import { deleteLibraryLocationShapeAttempt } from 'src/app/locator-store/actions/locator-shape.actions';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { DeleteLocationConfirmationDialogComponent } from '../../delete-location-confirmation-dialog/delete-location-confirmation-dialog.component';
import { deleteUserSettingsCatchment } from 'src/app/reporting/store/actions/multi-catchment.actions';
import { MultiCatchment } from 'src/app/core/models/multi-catchment';

@Component({
  selector: 'atlas-delete-catchment-confirmation-dialog',
  templateUrl: './delete-catchment-confirmation-dialog.component.html',
  styleUrls: ['./delete-catchment-confirmation-dialog.component.less']
})
export class DeleteCatchmentConfirmationDialogComponent {
  isShared: boolean;
  message: string;
  get headerText() {
    return 'Delete Catchment';
  }

  constructor(
    private store$: Store<fromLocatroStore.State>,
    private dialogRef: MatDialogRef<DeleteLocationConfirmationDialogComponent>,
    private locatorService: LocatorService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shape: LocatorShape;
      libraryId: number;
      isSettings: boolean;
      updatedCatchments: MultiCatchment[];
    }
  ) {
    if (!this.data.isSettings) {
      this.locatorService
        .checkShapeReportDataShared(
          this.data.libraryId,
          this.data.shape.libraryDataId,
          this.data.shape.id
        )
        .subscribe((isShared) => {
          this.isShared = isShared;
        });
    }
  }

  onDeleteClicked() {
    if (!this.data.isSettings) {
      this.store$.dispatch(
        deleteLibraryLocationShapeAttempt({
          shapeId: this.data.shape.id
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: this.data.shape.id
      });
    } else {
      this.store$.dispatch(
        deleteUserSettingsCatchment({
          catchments: this.data.updatedCatchments
        })
      );
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: this.data.shape.name
      });
    }
  }
}
