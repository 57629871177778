import { createAction, props } from '@ngrx/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { ModelLocationResponse } from 'src/app/spatial-modeller/models/model-location-response';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';
import { SmTestUserActionSources } from 'src/app/spatial-modeller/models/test-action-sources';
import { TestStatusResponse } from 'src/app/spatial-modeller/models/test-status-response';

export const spatialModellerTestStarted = createAction(
  '[Gazzetter Component] Model Execution Request Sent to Backend'
);

export const spatialModellerTestAfterLocationGeneratedStarted = createAction(
  '[Atlas Backend API] Model Execution Request Sent to Backend after locatin generated',
  props<{ uId: string }>()
);

export const spatialModellerTestPollingStatus = createAction(
  '[Atlas Backend API] Polling Model Execution Status From Backend',
  props<{ testStatusResponse: TestStatusResponse }>()
);

export const spatialModellerTestResultUpdate = createAction(
  '[Atlas Backend API] Update Model Execution Result Aster Polling In Backend',
  props<{ testStatusResponse: TestStatusResponse }>()
);

export const spatialModellerTestCompleted = createAction(
  '[Atlas Backend API] Model Execution Completed Successfully',
  props<{ testId: number }>()
);

export const spatialModellerTestErrorOccurred = createAction(
  '[Atlas Backend API] Model Execution Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const initialiseSpatialModellerSucceeded = createAction(
  '[Spatial Modeller Store] Initalise Spatial Modeller Succeeded',
  props<{ supplyPointAttributes: SupplyPointAttribute[] }>()
);

export const generateNewSupplyPointClicked = createAction(
  '[Dynamic Form Component] Initalise new supply point clicked',
  props<{ supplyPointValues: any }>()
);

export const updateSupplyPointValues = createAction(
  '[Dynamic Form Component] Update supply point values',
  props<{ supplyPointValues: any }>()
);

export const supplyPointClicked = createAction(
  '[Map Component] Supply point selected on map',
  props<{ id: number; location: Point }>()
);

export const supplyPointValuesResetAttempt = createAction(
  '[Dynamic Form Component] Supply Point Values Reset Attempt',
  props<{ location: Point }>()
);

export const loadSupplyPointSucceeded = createAction(
  '[Map Component] Supply point get succeeded',
  props<{ supplyPoint: SupplyPoint; location: Point }>()
);

export const supplyPointSelected = createAction(
  '[Spatial Modeller Store Effect] Supply point selected',
  props<{ location: Point }>()
);

export const manualPinClicked = createAction(
  '[Map Component] Manual pin drop selected on map',
  props<{ location: Point }>()
);

export const supplyPointDeselectAttempt = createAction(
  '[Map Component] Supply point Attempt'
);

export const supplyPointDeselected = createAction(
  '[Spatial Modeller Store Effect] Supply point deselected'
);

export const legendLayerTurnedOff = createAction(
  '[Layer Component] Layer turned off'
);

export const unsavedSupplyPointDeselected = createAction(
  '[Layer Component] Unsaved Supply Point Deselected',
  props<{
    nextSelectedSupplyPointInfo: {
      newSelectedSupplyPointId: number | null;
      newSelectedLocation: Point | null;
    } | null;
  }>()
);

export const pendingUnsavedSupplyPointReset = createAction(
  '[Spatial Modeller Store Effect] Pending Unsaved Supply Point Reset'
);

export const spatialModellerModelLocationStarted = createAction(
  '[Properties Component] Model Location Request Sent to Backend'
);

export const spatialModellerModelLocationPollingStatus = createAction(
  '[Atlas Backend API] Polling Model Location Status From Backend',
  props<{
    supplyPoint: SupplyPoint;
    modelLocationStatusResponse: ModelLocationResponse;
    source: SmTestUserActionSources;
  }>()
);

export const spatialModellerModelLocationSucceed = createAction(
  '[Atlas Backend API] Polling Model Location Status From Backend Succeeded',
  props<{ uId: string }>()
);

export const spatialModellerModelLocationErrorOccurred = createAction(
  '[Atlas Backend API] Model Location Execution Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const spatialModellerModelLocationStartedFromSPSave = createAction(
  '[Properties Component] Model Location Request Sent to Backend from Save Click'
);

export const spatialModellerModelLocationStartedFromSPUntouchedTemporarySave =
  createAction(
    '[Properties Component] Model Location Request Sent to Backend from Save Click From an Untouched Temporary Supply Point'
  );

export const spatialModellerModelLocationSucceedFromSPSave = createAction(
  '[Atlas Backend API] Polling Model Location Status From Backend Succeeded from Save Click',
  props<{ uId: string }>()
);

export const spatialModellerModelLocationStartedFromSPClose = createAction(
  '[Properties Component] Model Location Request Sent to Backend from Close Click'
);

export const spatialModellerModelLocationSucceedFromSPClose = createAction(
  '[Atlas Backend API] Polling Model Location Status From Backend Succeeded from Close Click',
  props<{ uId: string }>()
);

export const spatialModellerModelLocationStartedFromSPReOpen = createAction(
  '[Properties Component] Model Location Request Sent to Backend from Re Open Click'
);

export const spatialModellerModelLocationSucceedFromSPReOpen = createAction(
  '[Atlas Backend API] Polling Model Location Status From Backend Succeeded from Re Open Click',
  props<{ uId: string }>()
);

export const spatialModellerModelLocationPollingCancelled = createAction(
  '[Spatial Modeller Store Effect] Spatial Modeller Model Location Polling Cancelled',
  props<{
    supplyPoint: SupplyPoint;
    source: SmTestUserActionSources;
  }>()
);

export const spatialModellerOpenRetryDialogAfterModelLocationFail =
  createAction(
    '[Spatial Modeller Store Effect] Spatial Modeller Model Open Retry Dialog After Model Location Fail',
    props<{
      source: SmTestUserActionSources;
    }>()
  );
