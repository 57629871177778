import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Subscription, withLatestFrom } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';
import { NetworkPlanningSettings } from 'src/app/core/models/network-planning-settings';
import { getSpatialModellerLiveModeDisabled } from 'src/app/core/store';

@Component({
  selector: 'atlas-network-planning-settings-tab-content',
  templateUrl: './network-planning-settings-tab-content.component.html',
  styleUrls: ['./network-planning-settings-tab-content.component.less']
})
export class NetworkPlanningSettingsTabContentComponent
  implements OnInit, OnDestroy
{
  spatialModellerLiveModeDisabled: boolean;
  isChanged: boolean = false;
  currentNetworkPlanningSettings: NetworkPlanningSettings | null;

  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  private subscription: Subscription = new Subscription();

  constructor(
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.getUserNetworkPlanningSettings)
        .pipe(
          withLatestFrom(
            this.UIStore$.select(getSpatialModellerLiveModeDisabled)
          ),
          map(([userNetworkPlanningSettings, smLiveModeDisabled]) => {
            if (
              userNetworkPlanningSettings &&
              userNetworkPlanningSettings.liveModeDisabled !== null
            ) {
              this.spatialModellerLiveModeDisabled =
                userNetworkPlanningSettings.liveModeDisabled;
              this.currentNetworkPlanningSettings = userNetworkPlanningSettings;
            } else {
              this.spatialModellerLiveModeDisabled = smLiveModeDisabled;
            }
          })
        )
        .subscribe()
    );
  }

  onToggleChange(event: MatSlideToggleChange): void {
    this.spatialModellerLiveModeDisabled = !event.checked;
    this.isChanged = true;
  }

  updateLiveMode(): void {
    let updatedSettings: NetworkPlanningSettings;

    if (this.currentNetworkPlanningSettings) {
      updatedSettings = {
        ...this.currentNetworkPlanningSettings,
        liveModeDisabled: this.spatialModellerLiveModeDisabled
      };
    } else {
      updatedSettings = {
        ...new NetworkPlanningSettings(),
        liveModeDisabled: this.spatialModellerLiveModeDisabled
      };
    }
    this.UIStore$.dispatch(
      fromUIStore.updateNetworkPlanningUserSettingsOnSaveClicked({
        networkPlanning: updatedSettings
      })
    );
    this.isChanged = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
