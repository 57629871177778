import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IsolineProviderService {
  private isolineProviderApiUrl = `${environment.baseUrl}api/lds/provider`;
  constructor(private http: HttpClient) {}

  getIsolineProvider() {
    return this.http.get(`${this.isolineProviderApiUrl}`, {
      responseType: 'text'
    });
  }
}
