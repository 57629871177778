import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialComponentsModule } from '../material/material-components.module';
import * as fromComponents from './components';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [CommonModule, ReactiveFormsModule, MaterialComponentsModule],
  exports: [...fromComponents.components]
})
export class AtlasDialogModule {}
