import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';

import { FeatureUpdateService } from '../../services/feature-update.service';
import {
  updateFeatureUpdateAcceptedAttempt,
  updateFeatureUpdateAcceptedError,
  checkFeatureUpdateAccepted,
  showFeatureUpdateDialog
} from '../actions/feature-update.actions';
import { DialogService } from '../../services/dialog.service';
import { FeatureUpdateDialogComponent } from '../../components/feature-update-dialog/feature-update-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { getUserTenantsInfoAttempt } from '../actions';

@Injectable()
export class FeatureUpdateEffects {
  constructor(
    private actions$: Actions,
    private featureUpdateService: FeatureUpdateService,
    private dialogService: DialogService
  ) {}

  checkFeatureUpdateAccepted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkFeatureUpdateAccepted),
      switchMap(() =>
        this.featureUpdateService.hasUserAcceptedFeatureUpdate().pipe(
          map(({ hasUserSeenFeatureUpdate, releaseNotes }) => {
            return hasUserSeenFeatureUpdate || !releaseNotes
              ? getUserTenantsInfoAttempt()
              : showFeatureUpdateDialog({
                  releaseNotes: releaseNotes.features
                });
          })
        )
      )
    )
  );

  acknowledgeFeatureUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFeatureUpdateAcceptedAttempt),
      switchMap(() =>
        this.featureUpdateService.updateUserFeatureAccepted().pipe(
          map(() => {
            return getUserTenantsInfoAttempt();
          })
        )
      ),
      catchError(() =>
        of(
          updateFeatureUpdateAcceptedError({
            errorOn: 'Feature update',
            error: 'Error saving your feature update acknowledgement'
          })
        )
      )
    )
  );

  showFeatureUpdateDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showFeatureUpdateDialog),
        map(({ releaseNotes }) => {
          this.dialogService.show(FeatureUpdateDialogComponent, {
            width: DialogWidth.Small,
            panelClass: 'dialog-full-width-height',
            disableClose: true,
            data: {
              releaseNotes
            }
          });
        })
      ),
    { dispatch: false }
  );
}
