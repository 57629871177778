<atlas-add-edit-dialog
  [saveButtonText]="affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  [closeOnSave]="screenToShow === 'summary'"
  (saveClicked)="onLocatorImportDataClicked(locatorImportLibraryDataForm)"
  [saveDisabled]="isAffirmativeButtonDisabled(locatorImportLibraryDataForm)"
  [cancelDisabled]="isCancelButtonDisabled()"
  [showProgressBar]="true"
  [noOfStages]="3"
  [currentStage]="currentStage"
  class="add-edit-dialog-content"
>
  <atlas-locator-import-library-data-form
    #locatorImportLibraryDataForm
    formContent
    [hasLocatorLibraryLocationsLimitBeenExceeded]="
      hasLocatorLibraryLocationsLimitBeenExceeded
    "
    [libraryId]="data.libraryId"
    [tenantImportUsingGeocoding]="data.tenantImportUsingGeocoding"
    [style.display]="screenToShow === 'select_csv_file' ? 'block' : 'none'"
    (selectedFileChanged)="onSelectedFileChanged($event)"
  >
  </atlas-locator-import-library-data-form>

  @if(screenToShow==='processing_data'){
  <atlas-locator-import-library-data-form-2
    [totalRecords]="totalRecords"
    [processedRecords]="processedRecords"
    [errorsProcessingData]="importProcessStepState[1].state === 'Error'"
    formContent
  >
  </atlas-locator-import-library-data-form-2>
  } @if(screenToShow==='summary'){
  <atlas-locator-import-library-data-form-3
    [processedRecords]="processedRecords"
    [importedRecords]="importedRecords"
    formContent
  >
  </atlas-locator-import-library-data-form-3>
  }
  <atlas-steps-dialog-errors
    formValidation
    [dialogStatusError]="locatorImportStatusError"
  >
  </atlas-steps-dialog-errors>
</atlas-add-edit-dialog>
