import { GeoJsonLayer } from '@deck.gl/layers';
import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';
import { Layer } from '../models/layer';

export class AtlasIconLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('Icon', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    return new GeoJsonLayer(layerProperties);
  }
}
