import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Scenario } from 'src/app/core/models/scenario';

interface moveScenario {
  scenario: Scenario | null;
}
@Component({
  selector: 'atlas-move-supply-points-to-scenario',
  templateUrl: './move-supply-points-to-scenario.component.html',
  styleUrls: ['./move-supply-points-to-scenario.component.less']
})
export class MoveSupplyPointsToScenarioComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder) {}

  private _userScenarios: Scenario[] = [];

  @Output() backButtonClicked: EventEmitter<Event> = new EventEmitter();

  @Output() moveButtonClicked = new EventEmitter();

  @Input() hasItemsToMove: boolean;

  @Input()
  set userScenarios(newValue: Scenario[]) {
    this._userScenarios = [...newValue];
    this.dataSource = [...newValue];
  }
  get userScenarios(): Scenario[] {
    return this._userScenarios;
  }

  dataSource: Scenario[];
  moveForm: UntypedFormGroup;

  ngOnInit(): void {
    const initialValue: moveScenario = {
      scenario: null
    } as moveScenario;

    this.moveForm = this.createForm(initialValue);
  }

  createForm(initialScenarioValue: moveScenario) {
    return this.fb.group({
      scenario: [initialScenarioValue.scenario, Validators.required]
    });
  }

  validateScenarioName(scenario: Scenario) {
    if (scenario.id !== undefined) {
      return true;
    }

    var text = String(scenario);

    var match = this._userScenarios.find((s) =>
      s.name.toLowerCase().includes(text.trim().toLowerCase())
    );

    return match === undefined;
  }

  displayFn(scenario: Scenario): string {
    return scenario && scenario.name ? scenario.name : '';
  }

  onFilterChanged(event: Event) {
    this.applyFilter(event);
  }

  onBack(event: Event) {
    this.backButtonClicked.emit(event);
  }

  onMove(event: Event) {
    this.moveButtonClicked.emit(this.moveForm.controls.scenario.value);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = this._userScenarios.filter((s) =>
      s.name.toLowerCase().includes(filterValue.trim().toLowerCase())
    );
  }
}
