<mat-accordion multi class="legend" [hidden]="isSystemLayer(layer.id)">
  <mat-expansion-panel
    [expanded]="layerExpandedState"
    (opened)="onOpened()"
    (closed)="onClosed()"
  >
    <mat-expansion-panel-header
      class="legend-headers-align"
      collapsedHeight="40px"
      expandedHeight="40px"
    >
      <mat-panel-title>
        {{ layer.props.name }}
      </mat-panel-title>

      <mat-slide-toggle
        (change)="onVisibilityChange($event, layer.id)"
        (click)="$event.stopPropagation()"
        [checked]="layer.props.visibleNotAffectedByTriggerScale"
        [disabled]="isSystemLayer(layer.id)"
        [hideIcon]="true"
      >
      </mat-slide-toggle>
    </mat-expansion-panel-header>
    <atlas-layer-description [layer]="layer"></atlas-layer-description>
    <atlas-layer-trigger-scale-text
      [layer]="layer"
    ></atlas-layer-trigger-scale-text>
    <div class="tooltip-padding">
      <div class="layer-tooltip-text" *ngIf="!layer.props.disableTooltip">
        Tooltip Enabled
      </div>
      <div class="layer-tooltip-text" *ngIf="layer.props.disableTooltip">
        Tooltip disabled
      </div>
    </div>

    <div *ngIf="layer.props?.multipleThemes?.length !== 0">
      <atlas-layer-multiple-themes
        [layer]="layer"
      ></atlas-layer-multiple-themes>
    </div>

    <div class="legend-background">
      <atlas-layer-legend-detail
        [layer]="layer"
        (legendItemClick)="onLegendItemClick($event)"
      ></atlas-layer-legend-detail>
    </div>
  </mat-expansion-panel>
</mat-accordion>
