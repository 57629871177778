import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClearStateService } from 'src/app/core/services/clear-state.service';
import * as fromUIStore from 'src/app/core/store';

@Component({
  selector: 'atlas-settings-workspace',
  templateUrl: './settings-workspace.component.html',
  styleUrls: ['./settings-workspace.component.less']
})
export class SettingsWorkspaceComponent {
  constructor(
    private route: Router,
    private UIStore$: Store<fromUIStore.State>,
    private clearStateService: ClearStateService
  ) {}

  isSMFeatureAllowed$ = this.UIStore$.select(fromUIStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureAllowed
  );
  isProfilerFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureAllowed
  );

  onBack() {
    this.clearStateService.clearFeatureState();
    this.route.navigate(['/home']);
  }
}
