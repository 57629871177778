import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function formValueExistInArrayValidator(array: any[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return array
      .map((item) => item.toLowerCase())
      .includes(control.value.toLowerCase().trim())
      ? { valueAlreadyExist: true }
      : null;
  };
}
