import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserUsage } from 'src/app/core/models/user-usage';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserLoginUsageService {
  private atlasLoginUsageApiUrl = `${environment.baseUrl}api/users/user-login-usage`;

  constructor(private http: HttpClient) {}

  getLoginUsageList() {
    return this.http.get<UserUsage[]>(this.atlasLoginUsageApiUrl);
  }
  updateLoginUsage() {
    return this.http.put(this.atlasLoginUsageApiUrl, null);
  }
}
