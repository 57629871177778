import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'atlas-info-box',
  styleUrls: ['atlas-info-box.component.less'],
  templateUrl: 'atlas-info-box.component.html',
})
export class InfoBoxComponent implements OnInit, OnDestroy {
  @Output() elementChange = new EventEmitter<HTMLElement | null>();

  private _element: HTMLElement | null;
  @Input()
  set element(newValue: HTMLElement | null) {
    this._element = newValue;
    if (this._element != null) {
      this.setinfoBoxLocation(this._element);
    }
    this.elementChange.emit(this._element);
  }
  get element(): HTMLElement | null {
    return this._element;
  }

  private _htmlContent: string;
  @Input()
  set htmlContent(newValue: string) {
    this._htmlContent = newValue;
    if (this.infoBoxContent) {
      this.infoBoxContent.nativeElement.innerHTML = newValue;
    }
  }
  get htmlContent(): string {
    return this._htmlContent;
  }

  @ViewChild('infoBoxContent') infoBoxContent: ElementRef<HTMLElement>;
  @ViewChild('infoBoxContainer') infoBoxContainer: ElementRef<HTMLElement>;
  isInfoBoxOpen: boolean = false;

  ngOnInit(): void {
    this.addDocumentClickListener();
  }

  ngOnDestroy(): void {
    this.revomeDocumentClickListener();
  }

  documentClickEventHandler(event: { target: any }) {
    const isClickInside = this.infoBoxContainer.nativeElement.contains(
      event.target
    );

    if (event.target.id === this.element?.id) {
      this.toggle();
    } else if (!isClickInside && this.isInfoBoxOpen) {
      this.close();
    }
  }

  private addDocumentClickListener() {
    document.addEventListener(
      'click',
      this.documentClickEventHandler.bind(this)
    );
  }

  private revomeDocumentClickListener() {
    document.removeEventListener(
      'click',
      this.documentClickEventHandler.bind(this)
    );
  }

  toggle() {
    if (this._element) {
      this.isInfoBoxOpen ? this.close() : this.open();
    }
  }

  open() {
    if (this._element) {
      this.isInfoBoxOpen = true;
    }
  }

  close() {
    this.isInfoBoxOpen = false;
    this._element = null;
    this.elementChange.emit(this._element);
    this._htmlContent = '';
  }

  private setinfoBoxLocation(infoButtonElement: HTMLElement | null) {
    if (infoButtonElement) {
      const left = infoButtonElement.getBoundingClientRect().left;
      const top = infoButtonElement.getBoundingClientRect().top;
      this.infoBoxContainer.nativeElement.style.left = `${left - 10}px`;
      this.infoBoxContainer.nativeElement.style.top = `${top + 8}px`;
    }
  }
}
