import {
  getMapLayerDefaultProperties,
  getTileSetLayerData
} from '../helpers/map-layer-helper';
import { Layer } from '../models/layer';
import { MVTLayer } from '@deck.gl/geo-layers';
import { DynamicTilesetService } from '../services/dynamic-tileset.service';
import { MapService } from '../services/map.service';

import { TextLayer } from '@deck.gl/layers/typed';

// zoom level may be controlled with the min zoom level needs experimentation
// https://deck.gl/docs/api-reference/geo-layers/tile-layer

export class LabelTileSetLayer extends Layer {
  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicTilesetService: DynamicTilesetService
  ) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('LabelTileSet', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    layerProperties = await getTileSetLayerData(
      dynamicTilesetService,
      layerProperties,
      jwtToken,
      apiBaseUrl
    );

    layerProperties.getLabel = (d: any) => {
      return d[layerProperties.labelAttr];
    };

    layerProperties.binary = false;

    layerProperties.renderSubLayers = (props: any) => {
      const pointCollection = new Array();

      if (Array.isArray(props.data)) {
        props.data.forEach((element: any) => {
          if (element.geometry.type === 'Point') {
            let feature = {
              ...element,
              labelAttr: props.labelAttr,
              getColor: props.getColor,
              getSize: props.getSize,
              getAngle: props.getAngle,
              getTextAnchor: props.getTextAnchor,
              getAlignmentBaseline: props.getAlignmentBaseline,
              background: props.background,
              getBackgroundColor: props.getBackgroundColor
            };

            pointCollection.push(feature);
          }
        });
      }

      return [
        new TextLayer(props, {
          id: props.id + '-text',
          visible: props.visible,
          data: pointCollection,
          getText: (f) => {
            return f.properties[f.labelAttr];
          },
          getColor: props.getColor,
          getSize: props.getSize,
          getPosition: (f) => f.geometry.coordinates,
          getAngle: (f) => f.properties.AZIMUTH,
          getAlignmentBaseline: props.getAlignmentBaseline,
          background: props.background,
          getBackgroundColor: props.getBackgroundColor,
          getBorderColor: [0, 0, 0, 255]
        })
      ];
    };

    return new MVTLayer(layerProperties);
  }
}
