<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(editProfilerLibraryForm)"
  [saveDisabled]="editProfilerLibraryForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-edit-profiler-library-form
    #editProfilerLibraryForm
    formContent
    [library]="data.library"
    [userProfilerLibraries]="userProfilerLibraries"
  >
  </atlas-edit-profiler-library-form>
</atlas-add-edit-dialog>
