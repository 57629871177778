import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';

@Component({
  selector: 'atlas-list-selection',
  templateUrl: './atlas-list-selection.component.html',
  styleUrls: ['./atlas-list-selection.component.less']
})
export class AtlasListSelectionComponent {
  @Input() control: SupplyPointAttribute;
  
  formGroup: UntypedFormGroup;  

  constructor(private parentFormDirective: FormGroupDirective) { 
    this.formGroup = this.parentFormDirective.control;
  }

}
