import { Layer } from '../models/layer';
import { GeoJsonLayer } from '@deck.gl/layers';
import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';

export class PointLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('Point', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    return new GeoJsonLayer(layerProperties);
  }
}
