import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProfilerImportStatus } from 'src/app/profiler/model/profiler-import-status';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import {
  ListSortOptions,
  SearchAndSortValues,
  SortValuesArray,
  applyFilter,
  applySort
} from 'src/app/shared/utils/list-utils';

@Component({
  selector: 'atlas-swap-profiler-library-form',
  templateUrl: './swap-profiler-library-form.component.html',
  styleUrls: ['../../less/swap-library-form.component.less']
})
export class SwapProfilerLibraryFormComponent {
  private _userLibraries: ProfilerLibrary[] = [];
  private selectedSortOption: ListSortOptions = 'EditedDesc';
  @Output() switchClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Input() currentLibraryId: number;

  @Input()
  set userLibraries(newValue: ProfilerLibrary[]) {
    this._userLibraries = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
    this.librariesLoading = false;
  }
  get userLibraries(): ProfilerLibrary[] {
    return this._userLibraries;
  }
  swapLibraryForm: UntypedFormGroup;

  dataSource: ProfilerLibrary[];
  librariesLoading = true;

  sortOptions = SortValuesArray;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: '',
      sort: 'EditedDesc'
    } as SearchAndSortValues;
    this.swapLibraryForm = this.createForm(initialValue);
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter],
      sort: [initialLibraryValue.sort]
    });
  }

  onSwitchToLibrary(libraryId: number): void {
    this.switchClicked.emit(libraryId);
  }

  onSortChanged(evt: any) {
    this.selectedSortOption = evt.value;
    this.dataSource = applySort(evt.value, this.dataSource);
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._userLibraries);
  }
  onDeleteLibrary(libraryId: number) {
    this.deleteClicked.emit(
      this.dataSource.find((libary) => libary.id === libraryId)
    );
  }

  isDeleteButtonDisabled(profileImportStatus: ProfilerImportStatus | null) {
    return (
      profileImportStatus &&
      profileImportStatus.status !== 'ReportAggregateCompleted' &&
      profileImportStatus.errorMessage == null
    );
  }
}
