import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-simple-stats-page',
  templateUrl: './simple-stats-page.component.html',
  styleUrl: './simple-stats-page.component.less'
})
export class SimpleStatsPageComponent {
  @Input() iconName: string;
  @Input() value: string;
  @Input() name: string;
  @Input() isMainStats: boolean;
  @Input() isOddIndex: boolean;
}
