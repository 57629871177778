import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromSpatialModelerStore from 'src/app/spatial-modeller-store';

import { SmReportingFormComponent } from '../sm-reporting-form/sm-reporting-form.component';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import {
  ShareSmPowerBIReportAttempt,
  exportReportClicked
} from 'src/app/spatial-modeller-store/actions/sm-report.actions';
import { ReportTypes } from 'src/app/spatial-modeller/types/report-types';

@Component({
  selector: 'atlas-locator-reporting-dialog',
  templateUrl: './sm-reporting-dialog.component.html',
  styleUrls: ['./sm-reporting-dialog.component.less']
})
export class SmReportingDialogComponent {
  @ViewChild('reportingForm') reportingForm: SmReportingFormComponent;
  @Output() buttonClicked = new EventEmitter();
  dialogMode: DialogMode;

  constructor(
    public dialogRef: MatDialogRef<SmReportingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      smReportList: SmReportingInfo[];
      selectedSupplyPoint: SupplyPoint;
      testModelJobId: string;
    },
    private spatialModelerStore$: Store<fromSpatialModelerStore.State>
  ) {}

  onLaunchReport() {
    if (this.reportingForm.selectedReport?.reportType === ReportTypes.PowerBi) {
      window.open(this.reportingForm.selectedPbiReportUrl!, '_blank');
    } else {
      this.spatialModelerStore$.dispatch(
        exportReportClicked({
          reportId: this.reportingForm.selectedReport?.excelReportId!
        })
      );
    }
    this.dialogRef.close();
  }

  onShareClicked() {
    this.spatialModelerStore$.dispatch(
      ShareSmPowerBIReportAttempt({
        jobId: this.data.testModelJobId
      })
    );
    navigator.clipboard.writeText(this.reportingForm.selectedPbiReportUrl!);
  }

  isSpinnerVisible() {
    return !this.reportingForm
      ? null
      : this.reportingForm?.selectedReport &&
          this.reportingForm?.selectedReport.powerBiReportId &&
          !this.reportingForm?.selectedPbiReportUrl;
  }

  isTextCopyButtonVisible() {
    return !this.isSpinnerVisible();
  }
}
