import { Component, Input } from '@angular/core';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { Stats } from 'src/app/spatial-modeller/models/stats';

@Component({
  selector: 'atlas-standard-stats-page',
  templateUrl: './standard-stats-page.component.html',
  styleUrl: './standard-stats-page.component.less'
})
export class StandardStatsPageComponent {
  @Input() queryItem: any;
}
