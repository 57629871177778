import { createSelector } from '@ngrx/store';
import * as fromLocatorImportLibraryData from '../reducers/locator-import-library-data.reducer';
import * as fromFeature from '../reducers';
import {
  buildLocatorImportProgressSteps,
  LocatorImportStatusTypes
} from 'src/app/locator/helpers/locator-status-helper';
import { LocatorImportStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';
import { getImportUsingGeocoding } from '../reducers/locator-import-library-data.reducer';

export const getLocatorImportDataStatus = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  fromLocatorImportLibraryData.getImportStatus
);

export const getLocatorImportDataStatusMessage = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  fromLocatorImportLibraryData.getImportStatusMessage
);

export const getLocatorImportDataProgressSteps = createSelector(
  getLocatorImportDataStatusMessage,
  generateProgressSteps
);

export const getSelectedLocatorImportUsingGeocoding = createSelector(
  fromFeature.getLocatorImportLibraryDataState,
  getImportUsingGeocoding
);

export const getHasLocatorLibraryLocationsImportLimitBeenExceeded =
  createSelector(
    fromFeature.getLocatorImportLibraryDataState,
    fromLocatorImportLibraryData.getHasLocatorLibraryLocationsImportLimitBeenExceeded
  );

function generateProgressSteps(
  statusMessage: LocatorImportStatusTypes | undefined
): LocatorImportStatusProgressStep[] {
  if (statusMessage) {
    return buildLocatorImportProgressSteps(
      statusMessage as LocatorImportStatusTypes
    ) as LocatorImportStatusProgressStep[];
  }

  return [];
}
