import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as fromLocatorStore from 'src/app/locator-store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { Store } from '@ngrx/store';
import { LocatorStatusError } from 'src/app/locator/models/locator-import-status-error';
import { getSelectedPbiReportUrl } from 'src/app/core/helpers/power-bi-reports.helper';
import { PowerBiReportViewerService } from 'src/app/core/services/report-viewer.service';
import { Router } from '@angular/router';
import { logPowerBiReportAttempt } from 'src/app/locator-store/actions/locator-shape.actions';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorSummaryStatsStatusTypes } from 'src/app/locator/helpers/locator-status-helper';

@Component({
  selector: 'atlas-locator-library-summary-stats-dialog',
  templateUrl: './locator-library-summary-stats-dialog.component.html',
  styleUrls: ['./locator-library-summary-stats-dialog.component.less']
})
export class LocatorLibrarySummaryStatsDialogComponent implements OnInit {
  dialogMode: DialogMode;
  affirmativeButtonText: string;
  affirmativeButtonDisabled: boolean = false;

  locatorSummaryStatsStatus$ = this.locatorStore$.select(
    fromLocatorStore.getLocatorLibrarySummaryStatsStatus
  );
  locatorSummaryStatsStatus: LocatorSummaryStatsStatusTypes;

  locatorSummaryStatsStatusError: LocatorStatusError = {
    status: 'SummaryStatsStarted',
    errors: []
  };

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      libraryId: number;
      affirmativeButtonText: string;
      summaryStatsPowerBiReport: LocatorPowerBiReportingInfo;
    },
    private locatorStore$: Store<fromLocatorStore.State>,
    private powerBiReportViewerService: PowerBiReportViewerService,
    private router: Router
  ) {
    this.affirmativeButtonText = data.affirmativeButtonText;
  }

  ngOnInit() {
    this.subscription.add(
      this.locatorSummaryStatsStatus$.subscribe((locatorSummaryStatsStatus) => {
        if (locatorSummaryStatsStatus != null) {
          this.locatorSummaryStatsStatus = locatorSummaryStatsStatus.status;
          if (locatorSummaryStatsStatus.errorMessage) {
            this.locatorSummaryStatsStatusError = JSON.parse(
              locatorSummaryStatsStatus?.errorMessage
            );
          }
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onNextClicked() {
    this.launchReport();
  }

  isAffirmativeButtonDisabled() {
    return (
      this.locatorSummaryStatsStatusError.errors.length > 0 ||
      this.locatorSummaryStatsStatus !== 'SummaryStatsCompleted'
    );
  }

  isCancelButtonDisabled() {
    return this.locatorSummaryStatsStatus === 'SummaryStatsStarted';
  }

  launchReport() {
    getSelectedPbiReportUrl(
      this.powerBiReportViewerService,
      this.router,
      this.data.summaryStatsPowerBiReport.workspaceId,
      this.data.summaryStatsPowerBiReport.powerBiReportId,
      [this.data.libraryId]
    ).subscribe((pbiReportUrl) => {
      window.open(pbiReportUrl, '_blank');
      this.locatorStore$.dispatch(logPowerBiReportAttempt());

      pbiReportUrl;
    });
  }
}
