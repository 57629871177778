import { LocatorImportStatusProgressStep } from '../models/locator-status-progress-step';

export type LocatorImportStatusTypes =
  | 'LocatorImportDialogOpened'
  | 'FileUploadStarted'
  | 'FileUploadCompleted'
  | 'FileUploadFailed'
  | 'FileValidationStarted'
  | 'FileValidationFailed'
  | 'FileValidationCompleted'
  | 'GeocodingProcessStarted'
  | 'GeocodingProcessFailed'
  | 'GeocodingProcessCompleted'
  | 'ImportLocatorLibraryDataStarted'
  | 'ImportLocatorLibraryDataFailed'
  | 'ImportLocatorLibraryDataCompleted';

export type LocatorSummaryStatsStatusTypes =
  | 'SummaryStatsStarted'
  | 'SummaryStatsCompleted'
  | 'SummaryStatsFailed';

export type LocatorProgressStepsState =
  | 'NotStarted'
  | 'Processing'
  | 'Completed'
  | 'Error';

export type LocatorImportUiDialogSteps = 'select_csv_file' | 'processing_data';

export function buildLocatorImportProgressSteps(
  currentStatus: LocatorImportStatusTypes
): LocatorImportStatusProgressStep[] {
  const progressStates = {
    LocatorImportDialogOpened: () => [
      {
        name: 'select_csv_file',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Processing'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Error'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Completed'
      } as LocatorImportStatusProgressStep
    ]
  };

  return progressStates[currentStatus]();
}
