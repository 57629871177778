import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-info-button',
  styleUrls: ['info-button.component.less'],
  templateUrl: 'info-button.component.html',
})
export class InfoButtonComponent {
  @Input() scenarioId: number;
  @Output() removeInfoClicked = new EventEmitter<{
    scenarioId: number;
    infoButtonElement: HTMLElement;
  }>();

  infoButtonClicked(event: any) {
    this.removeInfoClicked.emit({
      scenarioId: this.scenarioId,
      infoButtonElement: event.srcElement,
    });
  }
}
