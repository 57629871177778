<div class="settings-content">
  <div>
    <div class="title">Default Catchment settings</div>
    <div class="description">
      Enables users to set their own default catchment size when new locations
      are defined.
    </div>
  </div>
  <form
    [formGroup]="locatorSettingsFormGroup"
    autocomplete="off"
    class="inputFields"
  >
    <div class="catchments">
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>Drive ({{ driveUnit }}s)</mat-label>
        <input
          matInput
          type="number"
          formControlName="carCatchmentSizeControl"
        />
      </mat-form-field>
      <div class="info">
        <div class="rangeLimit">
          Min/Max: {{ minRangeLimit }}:{{ maxCarRangeLimit }}
        </div>
        <div>
          <mat-error
            *ngIf="
              emptyCarCatchmentError() ||
              minCarCatchmentSizeError() ||
              maxCarCatchmentSizeError() ||
              positiveIntegersCarCatchmentSizeError()
            "
            class="error-message"
          >
            <span
              >Please enter an integer from {{ minRangeLimit }} to
              {{ maxCarRangeLimit }}</span
            >
          </mat-error>
        </div>
      </div>
      <mat-form-field
        appearance="outline"
        hideRequiredMarker="true"
        style="margin-top: -5px"
      >
        <mat-label>Walk ({{ walkUnit }}s)</mat-label>
        <input
          matInput
          type="number"
          formControlName="walkCatchmentSizeControl"
        />
      </mat-form-field>
      <div class="info">
        <div class="rangeLimit">
          Min/Max: {{ minRangeLimit }}:{{ maxWalkRangeLimit }}
        </div>
        <div>
          <mat-error
            *ngIf="
              emptyWalkCatchmentError() ||
              minWalkCatchmentSizeError() ||
              maxWalkCatchmentSizeError() ||
              positiveIntegersWalkCatchmentSizeError()
            "
            class="error-message"
          >
            <span
              >Please enter an integer from {{ minRangeLimit }} to
              {{ maxWalkRangeLimit }}</span
            >
          </mat-error>
        </div>
      </div>
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>PublicTransport ({{ publicTransportUnit }}s)</mat-label>
        <input
          matInput
          type="number"
          formControlName="publicTransportCatchmentSizeControl"
        />
      </mat-form-field>
      <div class="info">
        <div class="rangeLimit">
          Min/Max: {{ minRangeLimit }}:{{ maxPublicTransportRangeLimit }}
        </div>
        <div>
          <mat-error
            *ngIf="
              emptyPublicTransportCatchmentError() ||
              minPublicTransportCatchmentSizeError() ||
              maxPublicTransportCatchmentSizeError() ||
              positiveIntegersPublicTransportCatchmentSizeError()
            "
            class="error-message"
          >
            <span
              >Please enter an integer from {{ minRangeLimit }} to
              {{ maxPublicTransportRangeLimit }}</span
            >
          </mat-error>
        </div>
      </div>
    </div>

    <div class="catchments">
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>Circle (radius, {{ circleUnit }}s)</mat-label>
        <input
          matInput
          type="number"
          formControlName="circleCatchmentSizeControl"
        />
      </mat-form-field>
      <div class="info">
        <div class="rangeLimit">
          Min/Max {{ minCircleRangeLimit }}:{{ maxCircleRangeLimit }}
        </div>
        <div>
          <mat-error
            *ngIf="
              emptyCircleCatchmentError() ||
              minCircleCatchmentSizeError() ||
              maxCircleCatchmentSizeError()
            "
            class="error-message"
          >
            <span>Please enter a number up to {{ maxCircleRangeLimit }}</span>
          </mat-error>
        </div>
      </div>
      <mat-form-field
        appearance="outline"
        hideRequiredMarker="true"
        style="margin-top: -5px"
      >
        <mat-label>Polygon (radius, {{ polygonUnitToShow }}s)</mat-label>
        <input
          matInput
          type="number"
          formControlName="polyCatchmentSizeControl"
        />
      </mat-form-field>
      <div class="info">
        <div class="rangeLimit">Min: 0.1</div>
        <div>
          <mat-error
            *ngIf="emptyPolyCatchmentError() || minPolyCatchmentSizeError()"
            class="error-message"
          >
            <span>Please enter a number greater than 0.1</span>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
</div>
<button
  mat-flat-button
  color="primary"
  class="save-button"
  (click)="saveSettings()"
  [disabled]="invalidForm()"
>
  Save
</button>
