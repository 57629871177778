import { Injectable } from '@angular/core';
import { GeoJsonLayer } from '@deck.gl/layers';
import { Store } from '@ngrx/store';

import { MapLayerTypes } from '../types/map-layer.types';
import { draggableSelectionLayerIdentifier } from './layer.constants';
import { MapService } from '../services/map.service';
import { PinDropAndSelectionService } from '../services/pin-drop-and-selection-service';
import { existingPinDragSucceded } from '../../atlas-gazetteer/store/actions/gazetteer.actions';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromUIStore from 'src/app/core/store';
import { newLocationSelected } from 'src/app/core/store/actions/spatial-modeller-ui.actions';
import { getSelectionProperties } from '../helpers/selection-layer-helper';
import { getIconBasedOnAttribute } from '../helpers/thematic-style-helper';

@Injectable({
  providedIn: 'root'
})
export class DraggableSelectionLayer {
  private allowDragging: boolean;

  constructor(
    private mapService: MapService,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {
    this.UIStore$.select(fromUIStore.isLocationChanging).subscribe(
      (locationChanging) => (this.allowDragging = locationChanging)
    );
  }

  getSelectionLayer(
    layerType: MapLayerTypes,
    pinDropAndSelectionService: PinDropAndSelectionService,
    ...args: any[]
  ) {
    let baseProperties = {
      id: draggableSelectionLayerIdentifier,
      name: 'Selection Layer',
      description: 'Selection Layer',
      visible: true,
      pickable: true,
      onDragStart: (info: any, event: any) => this.dragStart(info, event),
      onDrag: (info: any, event: any) => this.drag(info, event),
      onDragEnd: (info: any, event: any) =>
        this.dragEnd(info, event, pinDropAndSelectionService)
    };

    // The order of these properties is very important.
    // 1. Properties the user has passed in to the function.
    // 2. Common base properties.
    // 3. Properties based on the layer type for changing selection colour.

    const isCustomIcon = this.isCustomIconMapLayerType(
      layerType,
      args[0].iconMask
    );
    layerType = isCustomIcon ? 'CustomIcon' : layerType;
    let layerProperties = {
      ...(args.length > 0 ? args[0] : {}),
      ...baseProperties,
      ...getSelectionProperties(layerType),
      zOrder: 100
    };

    layerProperties = this.ensureIconCanBeStyledAsSelected(layerProperties);

    return new GeoJsonLayer(layerProperties);
  }

  private isCustomIconMapLayerType(
    value: any,
    iconMask: boolean
  ): value is MapLayerTypes {
    return value === 'Icon' && !iconMask;
  }

  private ensureIconCanBeStyledAsSelected(layerProperties: any) {
    // if the icons have mask false need to turn it on to allow selection to be shown on the icon
    if (layerProperties.iconMask == false) {
      return this.updateGetIconPropertyForceMaskOn(layerProperties);
    }
    return layerProperties;
  }

  private updateGetIconPropertyForceMaskOn(layerProperties: any) {
    if ('iconAttribute' in layerProperties) {
      layerProperties.getIcon = (props: any) => {
        return getIconBasedOnAttribute(
          layerProperties.iconAttribute,
          layerProperties.iconDomain,
          layerProperties.icons,
          layerProperties.iconSourceUrl,
          layerProperties.iconOther,
          true,
          layerProperties.iconAnchorY,
          layerProperties.iconWidth,
          layerProperties.iconHeight,
          props
        );
      };
    }

    return layerProperties;
  }

  private dragStart(info: any, event: any) {
    if (this.allowDragging) {
      if (info && info.layer) {
        this.mapService.updateController(false);
      }
    }
  }

  private drag(info: any, event: any) {
    if (this.allowDragging) {
      if (info && info.layer && info.object) {
        let props = {
          data: {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: info.coordinate },
            properties: info.object.properties
          }
        };
        this.mapService.updateLayer(info.layer.id, props);
      }
    }
  }

  private dragEnd(
    info: any,
    event: any,
    pinDropAndSelectionService: PinDropAndSelectionService
  ) {
    if (this.allowDragging) {
      if (info && info.layer) {
        pinDropAndSelectionService.reselectManualPin();
        this.mapService.updateController(true);
        this.gazetteerStore$.dispatch(
          existingPinDragSucceded({
            location: {
              latitude: info.coordinate[1],
              longitude: info.coordinate[0]
            }
          })
        );
        this.UIStore$.dispatch(newLocationSelected());
      }
    }
  }
}
