import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { AtlasDxGridStateService } from 'src/app/shared/dev-express/services/atlas-dx-grid-state.service';
import { AtlasDxColumnFormatService } from 'src/app/shared/dev-express/services/atlas-dx-column-format.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';

@Component({
  selector: 'atlas-users-model-usage-list',
  templateUrl: './users-model-usage-list.component.html'
})
export class UsersModelUsageListComponent {
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  @Input()
  dataSource: any;

  @Input()
  template: TemplateRef<any>;

  constructor(
    private route: Router,
    public atlasFormat: AtlasDxColumnFormatService,
    public atlasGridState: AtlasDxGridStateService
  ) {}

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.push({
      location: 'before',
      template: 'parentTemplate'
    });
  }

  formatModelRunsPercentage(data: any) {
    return data.percentageOfExecutedModels.toFixed(2);
  }

  loadState = () => {
    var state = this.atlasGridState.loadState('users-list-grid-storage');
    return this.atlasGridState.loadState('users-list-grid-storage');
  };
  saveState = (state: any) => {
    this.atlasGridState.saveState('users-list-grid-storage', state);
  };

  onBack() {
    this.route.navigate(['/home']);
  }
}
