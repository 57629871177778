<div class="row-container" *ngIf="isLocatorFeatureSelected$ | async">
  <div class="library-name">
    <span
      #tooltipSpan
      class="body-large library-name"
      [matTooltip]="isTooltipVisible() ? getTooltipText() : ''"
      matTooltipClass="multiline-tooltip-wrap"
      >{{ currentLibrary.name }}</span
    >
  </div>
  <div class="buttons-container">
    @if(!(disableImportLocations$ | async) && (layoutService.isDesktopView$ |
    async)){
    <atlas-locator-import-library-data></atlas-locator-import-library-data>
    }
    <atlas-add-library></atlas-add-library>
    <atlas-swap-library></atlas-swap-library>
    <atlas-edit-library [currentLibrary]="currentLibrary"></atlas-edit-library>
  </div>
</div>
