import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { formValueExistInArrayValidator } from 'src/app/shared/utils/form-validators/form-value-exist-in-array.validator';

@Component({
  selector: 'atlas-edit-profiler-library-form',
  templateUrl: './edit-profiler-library-form.component.html',
  styleUrls: ['./edit-profiler-library-form.component.less']
})
export class EditProfilerLibraryFormComponent implements OnInit {
  private _library: ProfilerLibrary | null = null;
  @Input()
  set library(newValue: ProfilerLibrary | null) {
    this._library = newValue;
  }

  get library(): ProfilerLibrary | null {
    return this._library;
  }

  editProfilerLibraryForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) {}
  private _userProfilerLibraries: string[] = [];
  @Input()
  set userProfilerLibraries(newValue: string[]) {
    this._userProfilerLibraries = [...newValue];
    // Add validator to avoid libraries with the same name
    this.editProfilerLibraryForm?.controls.libraryName.setValidators([
      Validators.required,
      formValueExistInArrayValidator(
        this.library
          ? this._userProfilerLibraries.filter(
              (item) => item !== this.library?.name
            )
          : this._userProfilerLibraries
      )
    ]);
  }
  get userProfilerLibraries(): string[] {
    return this._userProfilerLibraries;
  }

  ngOnInit() {
    const initialValue: ProfilerLibrary =
      this.library || ({} as ProfilerLibrary);
    this.editProfilerLibraryForm = this.createLibraryForm(initialValue);
  }

  createLibraryForm(initialLibraryValue: ProfilerLibrary) {
    var form = this.fb.group({
      id: initialLibraryValue.id,
      libraryName: [initialLibraryValue.name, Validators.required]
    });
    return form;
  }

  emptyLibraryName() {
    if (this.editProfilerLibraryForm.controls.libraryName.value) {
      return (
        this.editProfilerLibraryForm.controls.libraryName.value?.trim()
          .length == 0
      );
    }

    return this.editProfilerLibraryForm.controls.libraryName.errors?.required;
  }

  uniqueLibraryName() {
    return !this.editProfilerLibraryForm.controls.libraryName.errors
      ?.valueAlreadyExist;
  }

  invalidForm(): boolean {
    return (
      !(
        this.editProfilerLibraryForm && this.editProfilerLibraryForm.controls
      ) ||
      !this.editProfilerLibraryForm.dirty ||
      !this.editProfilerLibraryForm.controls?.libraryName.valid ||
      this.emptyLibraryName() ||
      !this.uniqueLibraryName()
    );
  }
}
