import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';

@Component({
  selector: 'atlas-slider-toggle',
  templateUrl: './atlas-slider-toggle.component.html',
  styleUrls: ['./atlas-slider-toggle.component.less']
})
export class AtlasSliderToggleComponent {
  @Input() control: SupplyPointAttribute;
  
  formGroup: UntypedFormGroup;  

  constructor(private parentFormDirective: FormGroupDirective) { 
    this.formGroup = this.parentFormDirective.control;
  }

}
