import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  getUserTenantsInfoAttempt,
  getUserUsageListFailed,
  getUserUsageListSuceeded
} from '../actions';
import { UserLoginUsageService } from 'src/app/auth/services/user-login-usage.service';
import { UserUsage } from '../../models/user-usage';

@Injectable()
export class UserUsageEffects {
  constructor(
    private actions$: Actions,
    private userLoginUsageService: UserLoginUsageService
  ) {}

  getUserUsageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserTenantsInfoAttempt),
      switchMap(() =>
        this.userLoginUsageService.getLoginUsageList().pipe(
          map((userUsageList: UserUsage[]) => {
            return getUserUsageListSuceeded({ userUsageList: userUsageList });
          }),
          catchError((error) =>
            of(
              getUserUsageListFailed({
                errorOn: 'User usage ',
                error: error
              })
            )
          )
        )
      )
    )
  );
}
