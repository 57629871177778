<mat-toolbar>
  <atlas-menu></atlas-menu>
  <span class="middle-spacer"></span>
  <div class="logo-space">
    <span *ngIf="isUATMode$ | async" class="uat-text">
      Evaluation systems
    </span>
  </div>

  <span class="insite-image"></span>
</mat-toolbar>
