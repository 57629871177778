<div class="buttons-container">
  <button
    class="cancel-button"
    mat-flat-button
    aria-label="cancel shape"
    (click)="onCancel()"
  >
    Cancel
  </button>
  <button
    class="confirm-button"
    mat-flat-button
    color="primary"
    aria-label="confirm shape"
    (click)="onConfirm()"
  >
    Confirm Shape
  </button>
</div>
<div class="polygon-text">
  <p *ngIf="addingEditingPolygon">Manipulate the polygon by moving, adding or deleting vertices.</p>
</div>
