import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { map, switchMap, takeWhile, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appFeatureStore from 'src/app/core/store';
import * as locatorStore from 'src/app/locator-store';
import { LocatorSummaryStatsStatus } from '../models/locator-summary-stats-status';

@Injectable({ providedIn: 'root' })
export class LocatorSummaryStatsService {
  private atlasLocatorLibrariesApiUrl = `${environment.baseUrl}api/locator-libraries/`;

  statusPollDelay = 1000;
  isLocatorFeatureSelected: boolean = false;
  currentLocatorLibraryId: number;

  constructor(
    private http: HttpClient,
    private appFeatureStore$: Store<appFeatureStore.State>,
    private locatorStore$: Store<locatorStore.State>
  ) {
    this.appFeatureStore$
      .select(appFeatureStore.isLocatorFeatureSelected)
      .subscribe(
        (isLocatorFeatureSelected) =>
          (this.isLocatorFeatureSelected = isLocatorFeatureSelected)
      );

    this.locatorStore$
      .select(locatorStore.getLocatorLibraryId)
      .subscribe((libraryId) => (this.currentLocatorLibraryId = libraryId));
  }

  getLocatorLibrarySummaryStatsStatus(libraryId: number) {
    return this.http.get<LocatorSummaryStatsStatus>(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/summary-stats-status`
    );
  }

  createLocatorLibrarySummaryStats(libraryId: number) {
    return this.http.post(
      `${this.atlasLocatorLibrariesApiUrl}${libraryId}/summary-stats`,
      null
    );
  }

  pollForLocatorLibrarySummaryStatsStatus(libraryId: number) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.getLocatorLibrarySummaryStatsStatus(libraryId).pipe(
          map((summaryStatsStatusResponse) => summaryStatsStatusResponse)
        )
      ),
      takeWhile(
        (summaryStatsStatusResponse: LocatorSummaryStatsStatus) =>
          this.shouldPollingContinue(libraryId, summaryStatsStatusResponse),
        true
      )
    );
  }

  private shouldPollingContinue(
    libraryId: number,
    summaryStatsStatus: LocatorSummaryStatsStatus
  ) {
    return (
      summaryStatsStatus != null &&
      summaryStatsStatus.status !== 'SummaryStatsCompleted' &&
      this.isLocatorFeatureSelected &&
      libraryId === this.currentLocatorLibraryId &&
      summaryStatsStatus.errorMessage === null
    );
  }
}
