import { Component, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'atlas-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrls: ['./mfa-verification.component.less']
})
export class MFAVerificationComponent {
  verificationForm = this.fb.group({
    verificationCode: ['', Validators.required]
  });

  userInfo$ = this.authService.userInfo$;

  constructor(private fb: FormBuilder, public authService: AuthService) {}

  onConfirmVerificationCode() {
    this.authService.verifyLoginCode(
      this.verificationForm.controls.verificationCode.value as string
    );
  }

  onResendVerificationCode() {
    this.authService.removeErrorMessage();
    this.authService.resendMFALoginCode();
  }

  invalidForm() {
    return this.emptyCode();
  }

  emptyCode() {
    return this.verificationForm.controls.verificationCode.errors?.required;
  }
}
