import { CdkAccordionItem } from '@angular/cdk/accordion';
import { Component } from '@angular/core';
import { matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';

@Component({
  selector: 'atlas-expansion-panel',
  templateUrl: './atlas-expansion-panel.component.html',
  styleUrls: ['./atlas-expansion-panel.component.less'],
  animations: [matExpansionAnimations.bodyExpansion, matExpansionAnimations.indicatorRotate],
})
export class AtlasExpansionPanelComponent extends CdkAccordionItem {

  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  getClosedState(): MatExpansionPanelState {
    return !this.expanded ? 'expanded' : 'collapsed';
  }

  swipeUp() {
    this.open();
  }

  swipeDown() {
    this.close();
  }

}
