import { Component, Input } from '@angular/core';
import { CompanyProfiledRecordsUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-company-profiled-records-usage-summary',
  templateUrl: './company-profiled-records-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class CompanyProfiledRecordsUsageSummaryComponent {
  @Input()
  dataSource: CompanyProfiledRecordsUsage | null;
}
