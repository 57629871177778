import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'atlas-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.less'],
})
export class VerifyUserComponent {
  verifyCodeForm = this.fb.group({
    verificationCode: ['', Validators.required],
  });

  constructor(private fb: UntypedFormBuilder, private authService: AuthService) {}

  onVerify() {
    this.authService.verifySignupCode(
      this.verifyCodeForm.controls.verificationCode.value
    );
  }

  emptyVerificationCode() {
    return (
      this.verifyCodeForm.controls.verificationCode.errors &&
      this.verifyCodeForm.controls.verificationCode.errors.required
    );
  }
}
