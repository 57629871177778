import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from '../material/material-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { effects, reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromServices from './services';
import * as fromComponents from './components';
import { AtlasDialogModule } from '../atlas-dialog/atlas-dialog.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    AtlasDialogModule,
    StoreModule.forFeature('gazetteer', reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [...fromComponents.components],
  providers: [...fromServices.services]
})
export class AtlasGazetteerModule {}
