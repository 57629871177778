<div class="atlas-panel-body" *ngIf="isSelectedPointEmpty$ | async">
  Please select a location to view a report
</div>
<mat-divider *ngIf="areTestStatsAvailable$ | async"></mat-divider>

<div class="atlas-panel-body" *ngIf="areTestStatsAvailable$ | async">
  <atlas-spatial-modeller-reports-panel></atlas-spatial-modeller-reports-panel>
</div>
<mat-divider></mat-divider>
<atlas-sm-reports-panel-buttons
  *ngIf="areTestStatsAvailable$ | async"
></atlas-sm-reports-panel-buttons>
