import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';
import { AtlasDynamicFormComponent } from 'src/app/shared/atlas-dynamic-form/components/atlas-dynamic-form/atlas-dynamic-form.component';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { updateSupplyPointValues } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { ChangeLocationPendingConfirmationDialogComponent } from '../change-location-pending-confirmation-dialog/change-location-pending-confirmation-dialog.component';
import { SavePendingSupplyPointConfirmationDialogComponent } from '../save-pending-supply-point-confirmation-dialog/save-pending-supply-point-confirmation-dialog.component';

@Component({
  selector: 'atlas-spatial-modeller-properties',
  templateUrl: './spatial-modeller-properties.component.html',
  styleUrls: ['./spatial-modeller-properties.component.less']
})
export class SpatialModellerPropertiesComponent implements OnInit, OnDestroy {
  form$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getSpatialModellerSupplyPointAttributesByFascia
  );
  formValues$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getSpatialModellerSupplyPoint
  );
  isTestInProgress$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getTestInProgress
  );
  isSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsSupplyPoint
  );
  pendingUnsavedSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getPendingUnsavedSupplyPoint
  );
  pendingUnsavedLocationSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getPendingUnsavedLocationSupplyPoint
  );
  isDeltaSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsDeltaSupplyPoint
  );
  isBaseSupplyPoint$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsBaseSupplyPoint
  );
  isSupplyPointClosed$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getIsSupplyPointClosed
  );
  nextSupplyPointInfoToSelect$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getNextSupplyPointInfoToSelect
  );
  isTestInProgressFromSave$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getTestInProgressFromSave
  );
  isTestInProgressFromClose$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getTestInProgressFromClose
  );
  isTestInProgressFromReopen$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getTestInProgressFromReopen
  );
  isNetworkPlanningReadonlySystem$ = this.UIStore$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  @ViewChild(AtlasDynamicFormComponent)
  dynamicFormComponent: AtlasDynamicFormComponent;
  isLocationChanging: boolean;
  private subscription: Subscription = new Subscription();
  private pendingUnsavedSupplyPoint: boolean = false;
  private pendingUnsavedLocationSupplyPoint: boolean = false;

  constructor(
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private dialogService: DialogService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.pendingUnsavedSupplyPoint$.subscribe((pendingUnsavedSupplyPoint) => {
        this.pendingUnsavedSupplyPoint = pendingUnsavedSupplyPoint;
      })
    );
    this.subscription.add(
      this.pendingUnsavedLocationSupplyPoint$.subscribe(
        (pendingUnsavedLocationSupplyPoint) => {
          this.pendingUnsavedLocationSupplyPoint =
            pendingUnsavedLocationSupplyPoint;
        }
      )
    );
    this.subscription.add(
      this.UIStore$.select(fromUIStore.isLocationChanging).subscribe(
        (locationChanging) => (this.isLocationChanging = locationChanging)
      )
    );
    this.subscription.add(
      this.nextSupplyPointInfoToSelect$.subscribe(() => {
        if (this.pendingUnsavedLocationSupplyPoint && this.isLocationChanging) {
          this.dynamicFormComponent?.markFormAsPristine();
          setTimeout(() => this.openCancelChangeLocationDialog());
        } else if (this.pendingUnsavedSupplyPoint) {
          this.dynamicFormComponent?.markFormAsPristine();
          setTimeout(() => this.openUnsavedSupplyPointDialog());
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onFormValueChanged(supplyPointValues: any) {
    this.spatialModellerStore$.dispatch(
      updateSupplyPointValues({ supplyPointValues })
    );
  }

  openUnsavedSupplyPointDialog() {
    this.zone.run(() => {
      this.dialogService.show(
        SavePendingSupplyPointConfirmationDialogComponent,
        {
          width: DialogWidth.Small,
          panelClass: 'dialog-95vw-width',
          data: {}
        }
      );
    });
  }

  openCancelChangeLocationDialog() {
    this.zone.run(() => {
      this.dialogService.show(
        ChangeLocationPendingConfirmationDialogComponent,
        {
          width: DialogWidth.Small,
          panelClass: 'dialog-95vw-width',
          data: {}
        }
      );
    });
  }

  markFormAsPristine() {
    this.dynamicFormComponent?.markFormAsPristine();
  }
}
