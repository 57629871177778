import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Subscription, withLatestFrom } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { AtlasDynamicFormComponent } from 'src/app/shared/atlas-dynamic-form/components/atlas-dynamic-form/atlas-dynamic-form.component';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { openStreetView } from 'src/app/shared/utils/map-utils';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromUIStore from 'src/app/core/store';

import {
  generateNewSupplyPointClicked,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationStartedFromSPReOpen,
  spatialModellerModelLocationStartedFromSPSave,
  spatialModellerModelLocationStartedFromSPUntouchedTemporarySave,
  spatialModellerTestStarted,
  supplyPointValuesResetAttempt
} from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';

import { DeleteSupplyPointConfirmationDialogComponent } from '../../../scenario-management/delete-supply-point-confirmation-dialog/delete-supply-point-confirmation-dialog.component';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { getSpatialModellerLiveModeDisabled } from 'src/app/core/store';
import { saveSupplyPointClicked } from 'src/app/spatial-modeller-store/actions/supply-point.actions';

@Component({
  selector: 'atlas-sm-properties-panel-buttons',
  templateUrl: './sm-properties-panel-buttons.component.html',
  styleUrls: ['./sm-properties-panel-buttons.component.less']
})
export class SMPropertiesPanelButtonsComponent implements OnInit, OnDestroy {
  private selectedLocation: Point | null;
  private subscription: Subscription = new Subscription();

  @Input() isTestInProgress: boolean | null;
  @Input() pendingUnsavedChanges: boolean | null;
  @Input() isSupplyPoint: boolean | null;
  @Input() supplyPointForm: AtlasDynamicFormComponent;
  @Input() isDeltaSupplyPoint: boolean | null;
  @Input() isBaseSupplyPoint: boolean | null;
  @Input() isSupplyPointClosed: boolean | null | undefined;
  @Input() isLocationChanging: boolean | null;
  @Input() isNetworkPlanningReadonlySystem: boolean | null;
  @Output() saveClicked = new EventEmitter();

  @Input() isTestInProgressFromSave: boolean | null;
  @Input() isTestInProgressFromClose: boolean | null;
  @Input() isTestInProgressFromReopen: boolean | null;

  modelExecutedForAttributes: boolean = true;
  spatialModellerLiveModeDisabled: boolean;

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private UIStore$: Store<fromUIStore.State>,
    private dialogService: DialogService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.gazetteerStore$
        .select(fromGazetteerStore.getSelectedPoint)
        .subscribe((point) => {
          this.selectedLocation = point?.location;
        })
    );
    this.subscription.add(
      this.supplyPointForm.valueChanged.subscribe(() => {
        this.modelExecutedForAttributes = false;
      })
    );

    this.subscription.add(
      this.UIStore$.select(fromUIStore.getUserNetworkPlanningSettings)
        .pipe(
          withLatestFrom(
            this.UIStore$.select(getSpatialModellerLiveModeDisabled)
          ),
          map(([userNetworkPlanningSettings, smLiveModeDisabled]) => {
            if (
              userNetworkPlanningSettings &&
              userNetworkPlanningSettings.liveModeDisabled !== null
            ) {
              this.spatialModellerLiveModeDisabled =
                userNetworkPlanningSettings.liveModeDisabled;
            } else {
              this.spatialModellerLiveModeDisabled = smLiveModeDisabled;
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    if (this.spatialModellerLiveModeDisabled) {
      const isUntouchedSupplyPoint =
        this.isSupplyPoint && !this.pendingUnsavedChanges;
      if (isUntouchedSupplyPoint) {
        this.spatialModellerStore$.dispatch(spatialModellerTestStarted());
      } else {
        this.spatialModellerStore$.dispatch(
          spatialModellerModelLocationStarted()
        );
      }
    } else {
      this.spatialModellerStore$.dispatch(
        generateNewSupplyPointClicked({
          supplyPointValues: this.supplyPointForm.dynamicFormGroup.value
        })
      );
    }
    this.modelExecutedForAttributes = true;
  }

  onReset() {
    this.supplyPointForm.onReset();
    if (this.supplyPointForm.isTemporary) {
      this.spatialModellerStore$.dispatch(
        generateNewSupplyPointClicked({
          supplyPointValues: this.supplyPointForm.dynamicFormGroup.value
        })
      );
    } else {
      this.spatialModellerStore$.dispatch(
        supplyPointValuesResetAttempt({
          location: this.selectedLocation!
        })
      );
    }
    this.modelExecutedForAttributes = true;
  }

  onStreetViewClicked() {
    openStreetView(
      this.selectedLocation?.latitude,
      this.selectedLocation?.longitude
    );
  }

  onSaveClicked() {
    this.saveClicked.emit();

    // Model has been already executed for a supply point then
    // the model is not executed again, we just save the supply point in the DB
    if (this.modelExecutedForAttributes && this.isSupplyPoint) {
      this.spatialModellerStore$.dispatch(
        saveSupplyPointClicked({ executeModel: false })
      );
    } else {
      const isUntouchedTemporarySupplyPoint =
        !this.isSupplyPoint && !this.pendingUnsavedChanges;
      isUntouchedTemporarySupplyPoint
        ? this.spatialModellerStore$.dispatch(
            spatialModellerModelLocationStartedFromSPUntouchedTemporarySave()
          )
        : this.spatialModellerStore$.dispatch(
            spatialModellerModelLocationStartedFromSPSave()
          );
      this.modelExecutedForAttributes = true;
    }
  }

  onCloseClicked() {
    this.isSupplyPointClosed
      ? this.spatialModellerStore$.dispatch(
          spatialModellerModelLocationStartedFromSPReOpen()
        )
      : this.spatialModellerStore$.dispatch(
          spatialModellerModelLocationStartedFromSPClose()
        );
    this.modelExecutedForAttributes = true;
  }

  onDeleteClicked() {
    this.dialogService.show(DeleteSupplyPointConfirmationDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width',
      data: {}
    });
  }

  isSubmitButtonDisabled() {
    return (
      !this.supplyPointForm.dynamicFormGroup.valid ||
      this.modelExecutedForAttributes ||
      this.isTestInProgress ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isSubmitButtonLiveModeOffDisabled() {
    return (
      (!this.supplyPointForm.dynamicFormGroup.valid &&
        !this.isSupplyPointClosed) ||
      this.isTestInProgress ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isRestoreDefaultsButtonDisabled() {
    return (
      !this.pendingUnsavedChanges ||
      !this.supplyPointForm.dynamicFormGroup.valid ||
      this.isTestInProgress ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isSaveButtonDisabled() {
    return (
      this.isTestInProgress ||
      !this.supplyPointForm.dynamicFormGroup.valid ||
      (this.isSupplyPoint &&
        (!this.pendingUnsavedChanges || this.isSupplyPointClosed)) ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isCloseButtonDisabled() {
    return (
      this.isTestInProgress ||
      (!this.isSupplyPointClosed &&
        !this.supplyPointForm.dynamicFormGroup.valid) ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isDeleteButtonDisabled() {
    return (
      this.isTestInProgress ||
      !this.isDeltaSupplyPoint ||
      this.isLocationChanging ||
      this.isNetworkPlanningReadonlySystem
    );
  }

  isStreetViewDisabled() {
    return this.isLocationChanging;
  }

  isGenerateLocationInProgressFromSaveLiveModeOff() {
    return (
      this.isTestInProgressFromSave && this.spatialModellerLiveModeDisabled
    );
  }

  isGenerateLocationInProgressFromCloseOrReopenLiveModeOff() {
    return (
      this.spatialModellerLiveModeDisabled &&
      (this.isTestInProgressFromClose || this.isTestInProgressFromReopen)
    );
  }

  isTestInProgressNotFromSaveCloseReopenAndLiveModeOff() {
    return (
      this.isTestInProgress &&
      !(
        this.isTestInProgressFromSave ||
        this.isTestInProgressFromReopen ||
        this.isTestInProgressFromClose
      ) &&
      this.spatialModellerLiveModeDisabled
    );
  }
}
