import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'atlas-locator-import-library-data-form-2',
  templateUrl: './locator-import-library-data-form-2.component.html',
  styleUrls: ['./locator-import-library-data-form-2.component.less']
})
export class LocatorImportLibraryDataFormComponent2 {
  mode: ProgressSpinnerMode = 'determinate';

  private _totalRecords: number = 0;
  @Input()
  set totalRecords(newValue: number) {
    this._totalRecords = newValue;
    if (this._totalRecords > 0) {
      this.percentage = this.calculatePercentage(
        this._processedRecords,
        this._totalRecords
      );
    }
  }
  get totalRecords(): number {
    return this._totalRecords;
  }

  private _processedRecords: number = 0;
  @Input()
  set processedRecords(newValue: number) {
    this._processedRecords = newValue;
    if (this._processedRecords > 0) {
      this.percentage = this.calculatePercentage(
        this._processedRecords,
        this._totalRecords
      );
    }
  }
  get processedRecords(): number {
    return this._processedRecords;
  }
  @Input() errorsProcessingData = false;
  percentage: number = 0;

  constructor() {}

  calculatePercentage(processedRecords: number, totalRecords: number) {
    return Math.round((processedRecords * 100) / totalRecords);
  }
}
