<div class="atlas-dialog-container">
  <atlas-dialog-header
    [headerText]="headerText"
    [showProgressBar]="showProgressBar"
    [currentStage]="currentStage"
    [noOfStages]="noOfStages"
  ></atlas-dialog-header>

  <mat-dialog-content>
    <ng-content select="[formContent]"></ng-content>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <atlas-dialog-validation-messages>
      <ng-content select="[formValidation]"></ng-content>
    </atlas-dialog-validation-messages>

    <div class="dialog-buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="cancelClicked.emit()"
        [disabled]="cancelDisabled"
      >
      </atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        (clicked)="saveClicked.emit()"
        [disabled]="saveDisabled"
        [label]="saveButtonText"
        [closeOnSave]="closeOnSave"
      >
      </atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
