import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { DialogWidth } from '../../shared/atlas-dialog/enums/dialog-width.enum';
import { filter, map, take } from 'rxjs/operators';
import { DialogResult } from '../../shared/atlas-dialog/enums/dialog-result.enum';
import { isNotNullOrUndefined } from 'src/app/shared/utils/rxjs-filter-utils';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  show<T = void>(
    componentRef: ComponentType<any> | TemplateRef<any>,
    config: MatDialogConfig = {
      width: DialogWidth.Medium,
      panelClass: ''
    },
    affirmativeAction?: ((data: T) => void) | null,
    cancelAction?: ((data: T) => void) | null
  ) {
    this.dialog
      .open(componentRef, config)
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        take(1),
        //filter(({ action, _ }) => action === DialogResult.Affirmative),
        map(({ action, data }) => {
          if (action === DialogResult.Affirmative) {
            affirmativeAction?.(data);
          } else {
            cancelAction?.(data);
          }
        })
      )
      .subscribe();
  }
}
