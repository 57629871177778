import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorUIStore from 'src/app/core/store';
import {
  addingEditingPolygonCancelled,
  addingPolygonCancelled
} from 'src/app/core/store/actions/locator-ui.actions';
import { locatorEditPolygonIdentifier } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { PolygonSelectionService } from 'src/app/shared/atlas-mapping/services/polygon-selection-service';
import * as fromLocatorStore from 'src/app/locator-store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-add-edit-polygon-buttons',
  templateUrl: './add-edit-polygon-buttons.component.html',
  styleUrls: ['./add-edit-polygon-buttons.component.less']
})
export class AddEditPolygonButtonsComponent implements OnInit, OnDestroy {
  @Input() addingEditingPolygon: boolean | null;
  private selectedLocation: Point | null;
  private subscription: Subscription = new Subscription();
  constructor(
    private locatorUIStore$: Store<fromLocatorUIStore.State>,
    private mapService: MapService,
    private polygonSelectionService: PolygonSelectionService,
    private locatorStore$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.locatorStore$
        .select(fromLocatorStore.getSelectedLocation)
        .subscribe((point) => {
          this.selectedLocation = point;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onConfirm() {
    var layer = this.mapService.getLayer(locatorEditPolygonIdentifier);
    var geoJson = JSON.stringify(layer.props.data);
    this.polygonSelectionService.saveFreeformPolygon(geoJson);
  }

  onCancel() {
    this.selectedLocation === null
      ? this.locatorUIStore$.dispatch(addingPolygonCancelled())
      : this.locatorUIStore$.dispatch(addingEditingPolygonCancelled());
  }
}
