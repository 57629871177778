import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isDesktopView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isDesktopView$: Observable<boolean> =
    this.isDesktopView.asObservable();

  destroyed = new Subject<void>();

  readonly screenSize$ = this.breakpointObserver
    .observe([
      Breakpoints.XLarge,
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small
    ])
    .pipe(takeUntil(this.destroyed), distinctUntilChanged());

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        this.isDesktopView.next(!result.matches);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
