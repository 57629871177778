<atlas-dynamic-form
  #supplyPointForm
  [dynamicFormJson]="form$ | async"
  [formValues]="formValues$ | async"
  [isTemporary]="!(isSupplyPoint$ | async)"
  [isFormReadOnly]="isSupplyPointClosed$ | async"
  (valueChanged)="onFormValueChanged($event)"
></atlas-dynamic-form>
<mat-divider></mat-divider>
<atlas-sm-properties-panel-buttons
  [supplyPointForm]="supplyPointForm"
  [isTestInProgress]="isTestInProgress$ | async"
  [pendingUnsavedChanges]="pendingUnsavedSupplyPoint$ | async"
  [isSupplyPoint]="isSupplyPoint$ | async"
  [isDeltaSupplyPoint]="isDeltaSupplyPoint$ | async"
  [isBaseSupplyPoint]="isBaseSupplyPoint$ | async"
  [isSupplyPointClosed]="isSupplyPointClosed$ | async"
  [isLocationChanging]="isLocationChanging"
  [isNetworkPlanningReadonlySystem]="isNetworkPlanningReadonlySystem$ | async"
  [isTestInProgressFromSave]="isTestInProgressFromSave$ | async"
  [isTestInProgressFromClose]="isTestInProgressFromClose$ | async"
  [isTestInProgressFromReopen]="isTestInProgressFromReopen$ | async"
  (saveClicked)="markFormAsPristine()"
></atlas-sm-properties-panel-buttons>
