import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { systemLayerIdentifiers } from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import { UntypedFormBuilder } from '@angular/forms';
import { MapLayerStyleService } from 'src/app/shared/atlas-mapping/services/map-layer-style-service';

import {
  getLayerThematicColours,
  isIconLayer,
  isLayerSpotlightSupported
} from 'src/app/shared/atlas-mapping/helpers/map-Legend-helper';

@Component({
  selector: 'atlas-layer-legend',
  templateUrl: './layer-legend.component.html',
  styleUrls: ['../less/legend-expander.less']
})
export class LayerLegendComponent implements OnInit {
  @Input() layer: any;
  @Input() layerExpandedState: boolean | undefined;
  @Output() layerExpandedStateChanged: EventEmitter<boolean> =
    new EventEmitter();

  private spotlightedDomains: Set<string> = new Set();
  private originalColors: any[] | null = null;

  constructor(
    private mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private fb: UntypedFormBuilder,
    private layerStyleService: MapLayerStyleService
  ) {}

  ngOnInit() {
    if (isLayerSpotlightSupported(this.layer)) {
      var colours = getLayerThematicColours(this.layer);

      this.layerStyleService.initializeSpotLightLayerColors(
        this.layer.id,
        colours
      );

      if (isIconLayer(this.layer)) {
        var icons = this.layer.props.icons;
        this.layerStyleService.initializeSpotLightLayerIcons(
          this.layer.id,
          icons
        );
      }
    }
  }

  onVisibilityChange(evt: any, layerId: string) {
    var layerPropertiesChanging = { visible: evt.checked };
    this.mapService.updateLayer(layerId, layerPropertiesChanging);
    !evt.checked
      ? this.pinDropAndSelectionService.removeSelectionIfMatchingLayer(layerId)
      : this.mapService.syncTriggerScale(layerId);

    this.mapService.saveLayerVisiblityInStorage(layerId, evt.checked);
  }

  onOpened() {
    this.layerExpandedStateChanged.emit(true);
  }

  onClosed() {
    this.layerExpandedStateChanged.emit(false);
  }

  isSystemLayer(layerId: string) {
    return systemLayerIdentifiers.includes(layerId);
  }

  onLegendItemClick(domain: string) {
    this.layerStyleService.toggleSpotlight(
      this.layer.id,
      domain,
      [200, 200, 200, 50] // De-emphasized color
    );
  }
}
