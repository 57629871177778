import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { SnackbarService } from 'src/app/core/services/snackbar.service';
import {
  cancelAndRetrieveLastLibraryAttempt,
  createProfilerLibrarySucceeded,
  deleteProfilerLibrarySucceeded,
  displaySnackbarAfterProfilerLocationsPointFound,
  profilerLibraryErrorOccurred,
  profilerLocationsLayerMedianPointNotFound,
  swapProfilerLibrarySucceeded,
  updateProfilerLibrarySucceeded
} from '../actions/profiler-library.actions';
import { ActionCreatorWithProps } from 'src/app/shared/actions/action-creator-with-props';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { ProfilerImportStatus } from 'src/app/profiler/model/profiler-import-status';
import { PollProfilerImportStatusCompleted } from '../actions/profiler-import-status.actions';

@Injectable()
export class SnackbarEffects {
  constructor(private actions$: Actions, private snackbar: SnackbarService) {}

  snackbarOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          profilerLibraryErrorOccurred,
          profilerLocationsLayerMedianPointNotFound
        ),
        tap(({ errorOn, error: message }) => {
          this.snackbar.error(errorOn, message);
        })
      ),
    { dispatch: false }
  );

  createProfilerLibrarySucceededSnackbar$ =
    this.createEditProfilerLibrarySnackbarEffect(
      this.actions$,
      [createProfilerLibrarySucceeded],
      'Profile successfully created.',
      '',
      'prefix'
    );

  updateProfilerLibrarySucceededSnackbar$ =
    this.createEditProfilerLibrarySnackbarEffect(
      this.actions$,
      [updateProfilerLibrarySucceeded],
      'Profile successfully updated.',
      '',
      'prefix'
    );

  swapProfilerLibrarySucceededSnackbar$ =
    this.createEditProfilerLibrarySnackbarEffect(
      this.actions$,
      [swapProfilerLibrarySucceeded],
      'Profile switched to ',
      '',
      'suffix'
    );

  deleteProfilerLibrarySucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [deleteProfilerLibrarySucceeded],
    'Profile successfully deleted.'
  );
  cancelAndRetrieveLastLibraryAttemptSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [cancelAndRetrieveLastLibraryAttempt],
    'Cancel successful.'
  );

  profilerImportFileSucceeded$ = this.createSnackbarEffect(
    this.actions$,
    [PollProfilerImportStatusCompleted],
    'File imported successfully.'
  );

  profilerLocationsLayerMedianPointFound$ = this.createSnackbarEffect(
    this.actions$,
    [displaySnackbarAfterProfilerLocationsPointFound],
    'Repositioning map.'
  );

  private createSnackbarEffect(
    actions$: Actions,
    initiatedActions:
      | ActionCreatorWithProps[]
      | ActionCreatorWithProps<{
          library: ProfilerLibrary;
        }>[]
      | ActionCreatorWithProps<{
          importStatus: ProfilerImportStatus;
        }>[]
      | ActionCreatorWithProps<{
          id: number;
        }>[],
    actionString: string,
    headerActionString?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(() =>
            this.snackbar.show(
              this.getSnackbarText(actionString),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private createEditProfilerLibrarySnackbarEffect(
    actions$: Actions,
    initiatedActions:
      | ActionCreatorWithProps<{
          library: ProfilerLibrary;
        }>[]
      | ActionCreatorWithProps<{
          library: ProfilerLibrary;
          file: File;
        }>[],
    actionString: string,
    headerActionString?: string,
    libraryPosition?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(({ library }) =>
            this.snackbar.show(
              this.getSnackbarText(actionString, library.name, libraryPosition),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private getSnackbarText(
    action: string,
    objectName?: string,
    objectPosition?: string
  ) {
    if (objectName) {
      if (objectPosition) {
        switch (objectPosition) {
          case 'prefix':
            return objectName ? `${objectName} ${action}` : `${action}`;

          case 'suffix':
            return objectName ? `${action} ${objectName}` : `${action}`;

          default:
            return objectName ? `${objectName} ${action}` : `${action}`;
        }
      }
    }

    return `${action}`;
  }
}
