import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { Subscription } from 'rxjs/internal/Subscription';

import { DOCUMENT } from '@angular/common';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'atlas-locator-reports-panel',
  templateUrl: './locator-reports-panel.component.html',
  styleUrls: ['./locator-reports-panel.component.less']
})
export class LocatorReportsPanelComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() pieGridView: [number, number] = [250, 620];
  @Input() numberCardView: [number, number] = [300, 620];
  @Input() graphHeight: number;
  titleType: string = '';

  identifier = this.getGUID();

  selectedQueryItem: LocatorLofiQueryResult | null =
    new LocatorLofiQueryResult();

  selectedShapes$ = this.locatorStore$
    .select(fromLocatorStore.getSelectedShapes)
    .pipe(tap(() => this.cdr.detectChanges()));

  selectedLocation$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  );

  isLofiReportAvailable$ = this.locatorStore$
    .select(fromLocatorStore.getIsLofiReportAvailable)
    .pipe(tap(() => this.cdr.detectChanges()));

  isReportDataAvailable$ = this.locatorStore$.select(
    fromLocatorStore.getIsReportDataAvailable
  );

  queryTotal: number = -1;
  queryItems: LocatorLofiQueryResult[] = [];

  graphConfig: any = {};
  selectedShapeName: string;
  selectedLocationName: string;
  showStats: boolean;
  showGraph: boolean;
  shapesLength: number;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private locatorStore$: Store<fromLocatorStore.State>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedLocation$.subscribe((selectedLocation) => {
        if (selectedLocation) {
          this.selectedLocationName = selectedLocation.name;
        }
      })
    );
    this.subscription.add(
      this.selectedShapes$.subscribe((selectedShapes) => {
        if (
          selectedShapes &&
          selectedShapes.length > 0 &&
          selectedShapes[0].name
        ) {
          this.selectedShapeName = selectedShapes[0].name;
          this.shapesLength = selectedShapes.length;
        }
      })
    );

    this.subscription.add(
      this.locatorStore$
        .select(fromLocatorStore.getLofiReport)
        .subscribe((lofFiReport) => {
          if (lofFiReport) {
            this.queryItems = lofFiReport as LocatorLofiQueryResult[];
            this.queryTotal = this.queryItems.length;

            this.selectedQueryItem = this.queryItems[0];
            this.setGraphConfig(this.selectedQueryItem);
            this.updateTitleType();
          } else {
            this.queryTotal = 0;
            this.queryItems = [];
            this.selectedQueryItem = null;
          }
        })
    );
  }

  private updateTitleType(): void {
    if (this.selectedQueryItem) {
      const titles: string[] = [];
      this.buildShapeTitles(
        titles,
        this.selectedQueryItem,
        this.selectedShapeName
      );
      this.buildLocationTitles(
        titles,
        this.selectedQueryItem,
        this.selectedLocationName
      );
      this.titleType = titles.join(' & ');
    }
  }

  private buildShapeTitles(
    titles: string[],
    queryItem: { shapeName: string; isStats: boolean; isGraph: boolean },
    selectedShapeName: string
  ): void {
    const isShapeMatch = queryItem.shapeName === selectedShapeName;

    if (isShapeMatch) {
      if (queryItem.isStats) titles.push('Catchment Stats');
      if (queryItem.isGraph) titles.push('Catchment Graph');
    }
  }

  private buildLocationTitles(
    titles: string[],
    queryItem: { shapeName: string; isStats: boolean; isGraph: boolean },
    selectedLocationName: string
  ): void {
    const isLocationMatch = queryItem.shapeName === selectedLocationName;

    if (isLocationMatch) {
      if (queryItem.isStats) titles.push('Location Stats');
      if (queryItem.isGraph) titles.push('Location Graph');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectQuery(position: number) {
    this.activateDot(position);
    this.selectedQueryItem = this.queryItems[position];
    this.updateTitleType();
    this.setGraphConfig(this.selectedQueryItem);
  }

  private activateDot(postion: number) {
    const container = this._document.getElementById(this.identifier);

    var dots = container?.querySelectorAll('.dot');

    if (dots) {
      dots.forEach((dot) => {
        dot.className = dot.className.replace(' active', '');
      });

      dots[postion].className += ' active';
    }
  }

  private setGraphConfig(selectedQuery: LocatorLofiQueryResult) {
    this.graphConfig =
      selectedQuery === undefined ||
      selectedQuery.graphConfiguration == null ||
      selectedQuery.graphConfiguration == undefined
        ? {}
        : JSON.parse(selectedQuery.graphConfiguration);
  }

  private getGUID(): string {
    let d = new Date().getTime();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return guid;
  }
}
