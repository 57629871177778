import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { MapService } from '../../../atlas-mapping/services/map.service';

import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import {
  allowManualDropPinClicked,
  deactivateManualDropPinClicked
} from '../../store/actions/gazetteer.actions';
import { Subscription, combineLatest, delay, map } from 'rxjs';
import { PinDropAndSelectionService } from 'src/app/shared/atlas-mapping/services/pin-drop-and-selection-service';
import * as fromUIStore from 'src/app/core/store';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import * as fromProfilerStore from 'src/app/profiler-store';
import { getProfilerLibraryId } from 'src/app/profiler-store';

@Component({
  selector: 'atlas-drop-pin',
  templateUrl: './atlas-drop-pin.component.html',
  styleUrls: ['../less/atlas-gazetteer-button.less']
})
export class DropPinComponent implements OnInit, OnDestroy {
  pinDropActive: boolean;
  subscription = new Subscription();
  userLibraries: ProfilerLibrary[];

  // It prevents the user to click in this button before the layer has been created
  pinDropLayerAvailable$ =
    this.pinDropAndSelectionService.pinDropLayerAvailability$;

  isLocationChanging$ = this.UIStore$.select(fromUIStore.isLocationChanging);

  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  libraryId$ = this.store$.select(getProfilerLibraryId);

  isNetworkPlanningReadonlySystem$ = this.store$.select(
    fromUIStore.getNetworkPlanningReadonlySystem
  );

  isCatchmentReportingReadonlySystem$ = this.store$.select(
    fromUIStore.getCatchmentReportingReadonlySystem
  );

  isCatchmentReportingSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );

  isDropPinButtonDisabled$ = combineLatest([
    this.pinDropLayerAvailable$,
    this.isLocationChanging$,
    this.isProfilerFeatureSelected$,
    this.isNetworkPlanningReadonlySystem$,
    this.isCatchmentReportingReadonlySystem$,
    this.isCatchmentReportingSelected$
  ]).pipe(
    delay(100),
    map(
      ([
        pinDropLayerAvailable,
        isLocationChanging,
        isProfilerFeatureSelected,
        isNetworkPlanningReadonlySystem,
        isCatchmentReportingReadonlySystem,
        isCatchmentReportingSelected
      ]) => {
        return (
          !pinDropLayerAvailable ||
          isLocationChanging ||
          isProfilerFeatureSelected ||
          isNetworkPlanningReadonlySystem ||
          (isCatchmentReportingReadonlySystem && isCatchmentReportingSelected)
        );
      }
    )
  );

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    public mapService: MapService,
    private pinDropAndSelectionService: PinDropAndSelectionService,
    private changeDetectorRef: ChangeDetectorRef,
    private UIStore$: Store<fromUIStore.State>,
    private store$: Store<fromProfilerStore.State>
  ) {}

  ngOnInit(): void {
    // Async Pipe cannot be used in the html view since the map component runs code outside the NgZone (ngZone.runOutsideAngular)
    // so that it does not trigger Angular change detection cycle.
    // The above explanation means that clicking on the map doesn't trigger any check detection.
    // This has to be done manually using detectChanges() method
    // https://mokkapps.de/blog/the-last-guide-for-angular-change-detection-you-will-ever-need/
    this.subscription.add(
      this.pinDropAndSelectionService.allowManualPinDrop$.subscribe((value) => {
        this.pinDropActive = value;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onManuallyAddPinToMapClick() {
    this.pinDropActive
      ? this.gazetteerStore$.dispatch(deactivateManualDropPinClicked())
      : this.gazetteerStore$.dispatch(allowManualDropPinClicked());
  }
}
