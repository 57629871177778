import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { UsersProfiledRecordsUsage } from '../../models/usage-summary';
import { Subscription } from 'rxjs';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-profiled-records-usage-tab-content',
  templateUrl: './profiled-records-usage-tab-content.component.html',
  styleUrls: ['./profiled-records-usage-tab-content.component.less']
})
export class ProfiledRecordsUsageTabContentComponent
  implements OnInit, OnDestroy
{
  constructor(
    private Store$: Store<fromStore.State>,
    public layoutService: LayoutService,
    private UIStore$: Store<fromUIStore.State>
  ) {}
  showGrid = true;
  allUsersUsage: UsersProfiledRecordsUsage[];
  private subscription = new Subscription();
  allowInternalUsersUsage: boolean;
  userUsageSummary$ = this.Store$.select(fromStore.getRecordsUserUsageSummary);
  companyUsageSummary$ = this.Store$.select(
    fromStore.getRecordsCompanyUsageSummary
  );
  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );

    this.subscription.add(
      this.Store$.select(fromStore.getRecordsAllUsersUsageSummary).subscribe(
        (usersUsage) => {
          this.allUsersUsage = hideCACIUsers(
            usersUsage,
            this.allowInternalUsersUsage
          );
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onToggleGridChart() {
    this.showGrid = !this.showGrid;
  }
}
