import { createAction, props } from '@ngrx/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';

export const locationFromLocationGridClicked = createAction(
  '[Locator Location Grid] Location Clicked From Location Grid',
  props<{ locationData: LocatorLibraryData }>()
);

export const locationFromGazetteerClicked = createAction(
  '[Locator Location Grid] Location Clicked From Gazetteer Clicked',
  props<{ libraryDataId: number }>()
);

export const selectedDataPointInfoRetrieved = createAction(
  '[Locator Location Effects] Selected Location Info Retrieved From State',
  props<{ locationData: LocatorLibraryData }>()
);

export const locatorLocationSelected = createAction(
  '[Locator Effects] Gets the Locator Location Address',
  props<{ location: Point }>()
);

export const deleteLibraryLocationsAttempt = createAction(
  '[Locator properties delete Dialog] Delete Library Locations Attempt',
  props<{ libraryDataIds: number[] }>()
);

export const deleteLibraryLocationsSucceeded = createAction(
  '[Atlas API] Delete Library Locations Succeeded',
  props<{ libraryDataIds: number[] }>()
);

export const libraryLocationErrorOccurred = createAction(
  '[Atlas Backend API] Library Location Error Occured',
  props<{ errorOn: string; error: string }>()
);

export const selectLocationsForBatchOperation = createAction(
  '[Locator Location Grid] Select Locations For Batch Operation',
  props<{ locationIds: number[] }>()
);

export const clearMapAndGazetterForMultiSelect = createAction(
  '[Locator Location Grid] Clear Map And Gazetter For Multi Select'
);

export const deselectAllLocations = createAction(
  '[Locator Location Grid] Deselect All Locations'
);
