import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'atlas-locator-library-summary-stats-step-2',
  templateUrl: './locator-library-summary-stats-step-2.component.html',
  styleUrls: ['./locator-library-summary-stats-step-2.component.less']
})
export class LocatorLibrarySummaryStatsStep2 {
  mode: ProgressSpinnerMode = 'determinate';
  percentage: number = 100;

  constructor() {}
}
