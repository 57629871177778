import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { effects } from './effects';
import * as fromStore from './reducers';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromStore.locatorFeatureKey, fromStore.reducers),
    EffectsModule.forFeature(effects)
  ]
})
export class AtlasLocatorStoreModule {}
