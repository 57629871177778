<div class="atlas-dialog-container">
  <atlas-dialog-header headerText="Reporting"></atlas-dialog-header>
  <mat-dialog-content class="mat-dialog-content">
    <atlas-locator-reporting-form
      #reportingForm
      [pbiReports]="availablePbiReports"
      [selectedShapes]="data.selectedShapes"
      [selectedLocationData]="data.selectedLocationData"
    ></atlas-locator-reporting-form>
  </mat-dialog-content>
  <div class="reports-label">Share</div>
  <p></p>
  <div class="url-container">
    <mat-form-field appearance="outline">
      <mat-label>Report Link</mat-label>
      <input
        matInput
        #reportUrlInput
        type="text"
        [value]="reportingForm.selectedPbiReportUrl"
        readonly
        [disabled]="!reportingForm.selectedPbiReportUrl"
      />
    </mat-form-field>
    <div class="button-padding">
      <button
        class="copy-button secondary-button"
        mat-flat-button
        (click)="onShareClicked()"
        [disabled]="!reportingForm.selectedPbiReportUrl"
      >
        <span *ngIf="isTextCopyButtonVisible()">Copy</span>
        <mat-spinner
          class="update-button-spinner"
          *ngIf="isSpinnerVisible()"
          diameter="24"
          strokeWidth="2"
        >
        </mat-spinner>
      </button>
    </div>
  </div>
  <atlas-dialog-footer>
    <div class="dialog-buttons-container">
      <atlas-dialog-cancel-button
        (clicked)="buttonClicked.emit()"
        label="Close"
      ></atlas-dialog-cancel-button>
      <button
        mat-flat-button
        color="primary"
        class="launch-report-button"
        aria-label="Launch Report"
        (click)="onLaunchReport()"
        [disabled]="!reportingForm.selectedPbiReportUrl"
      >
        Launch Report
      </button>
    </div>
  </atlas-dialog-footer>
</div>
