import { Part } from '@aws-sdk/client-s3';
import { createAction, props } from '@ngrx/store';

export const locatorFileImportAttempt = createAction(
  '[Locator File Import Dialog] Locator File Import Attempt',
  props<{
    file: File;
    importUsingGeocoding: boolean;
  }>()
);

export const locatorFileImportSuccess = createAction(
  '[Locator File Import] Locator File Import Succeess',
  props<{
    file: File;
  }>()
);

export const locatorFileUploadError = createAction(
  '[Locator File Upload] Locator File Upload Error',
  props<{
    fileKey: string;
    errors: any;
  }>()
);

export const locatorFileUploadUpdateFileKey = createAction(
  '[Locator Upload Service] Locator File Upload Update FileKey',
  props<{
    fileKey: string;
  }>()
);

export const locatorMetaDataUploadAttempt = createAction(
  '[Locator Import Service] Locator File Upload Meta Data Attempt',
  props<{
    fileKey: string;
  }>()
);

export const locatorInitiateMultipartUploadAttempt = createAction(
  '[Locator Import Service] Locator Initiate Multipart Upload Attempt',
  props<{
    file: File;
    fileKey: string;
  }>()
);

export const locatorInitiateMultipartUploadSuccess = createAction(
  '[Locator Import Service] Locator Initiate Multipart Upload Success',
  props<{
    uploadId: string;
    file: File;
  }>()
);

export const locatorMultipartUploadComplete = createAction(
  '[Locator Import Service] Locator Multipart Upload Complete',
  props<{
    uploadId: string;
    filename: string;
    parts: Part[];
  }>()
);

export const locatorAbortMultipartUpload = createAction(
  '[Locator Import Service] Locator Abort Multipart Upload',
  props<{
    uploadId: string;
    filename: string;
    errorMessage: any;
  }>()
);

export const locatorMetaDataUploadSuccess = createAction(
  '[Locator Import Service] Locator File Upload Meta Data Success',
  props<{
    fileKey: string;
  }>()
);

export const locatorFileUploadCompleteStatusSuccess = createAction(
  '[Locator File Import] Locator File Upload Complete Status Success'
);

export const hasLocatorLibraryLocationsLimitBeenExceededAttempt = createAction(
  '[Locator File Import Dialog] Check Has Locator Library Limit Been Exceeded Attempt',
  props<{
    libraryId: number;
  }>()
);

export const hasLocatorLibraryLocationsLimitBeenExceededResult = createAction(
  '[Locator Import Effect] Result Of Locator Library Locations Limit Check',
  props<{
    result: boolean;
  }>()
);
