import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserSettings } from '../models/user-settings';
import { LocatorShapeDefaults } from '../models/locator-shape-defaults';
import { GeneralSettings } from '../models/general-settings';
import { NetworkPlanningSettings } from '../models/network-planning-settings';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  userSettingsApiUrl = `${environment.baseUrl}api/users/user-settings`;

  constructor(private http: HttpClient) {}

  public getUserSettings() {
    return this.http.get<UserSettings>(`${this.userSettingsApiUrl}`);
  }

  public updateUserSettings(updateUserSettingsRequest: {
    general: GeneralSettings | null;
    locator: LocatorShapeDefaults | null;
    networkPlanning: NetworkPlanningSettings | null;
  }) {
    return this.http.put<UserSettings>(
      `${this.userSettingsApiUrl}`,
      updateUserSettingsRequest
    );
  }
}
