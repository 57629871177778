import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromCoreStore from 'src/app/core/store/';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import * as fromTenantManagementUI from 'src/app/core/store';

@Component({
  selector: 'atlas-switch-tenant-confirmation-dialog-component',
  templateUrl: './switch-tenant-confirmation-dialog-component.component.html',
  styleUrls: ['./switch-tenant-confirmation-dialog-component.component.less']
})
export class SwitchTenantConfirmationDialogComponent {
  get headerText() {
    return 'Switch tenant';
  }
  selectedSystemName$ = this.tenantStore$.select(
    fromTenantManagementUI.getSystemName
  );
  constructor(
    private dialogRef: MatDialogRef<SwitchTenantConfirmationDialogComponent>,
    private coreStore$: Store<fromCoreStore.State>,
    private tenantStore$: Store<fromTenantManagementUI.State>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tenantId: number;
      tenantName: string;
      selectedSystemName: string;
    }
  ) {}

  onSwitchClicked() {
    this.coreStore$.dispatch(
      fromCoreStore.updateUserTenantAttempt({
        tenantId: this.data.tenantId,
        tenantName: this.data.tenantName
      })
    );

    this.dialogRef.close({});
  }

  onCancelClicked() {
    this.dialogRef.close({
      action: DialogResult.Cancel,
      data: { switchTenantCancelled: true }
    });
  }
}
