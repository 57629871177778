import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as fromSpatialModellerUI from './spatial-modeller-ui.reducer';
import * as fromLocatorUI from './locator-ui.reducer';
import * as fromProfilerUI from './profiler-ui.reducer';
import * as fromFeatureUI from './app-feature-ui.reducer';
import * as fromTenantManagementUI from './tenant-management.reducer';
import * as fromUserSettings from './user-settings.reducer';
import * as fromUserUsage from './user-usage.reducer';

export const UIFeatureKey = 'ui';

export interface State {
  spatialModellerUI: fromSpatialModellerUI.State;
  locatorUI: fromLocatorUI.State;
  profilerUI: fromProfilerUI.State;
  appFeatureUI: fromFeatureUI.State;
  tenantManagement: fromTenantManagementUI.State;
  userSettings: fromUserSettings.State;
  userUsage: fromUserUsage.State;
}

export const reducers: ActionReducerMap<State> = {
  spatialModellerUI: fromSpatialModellerUI.spatialModellerUIReducer,
  locatorUI: fromLocatorUI.locatorUIReducer,
  profilerUI: fromProfilerUI.profilerUIReducer,
  appFeatureUI: fromFeatureUI.appFeatureUIReducer,
  tenantManagement: fromTenantManagementUI.tenantManagementReducer,
  userSettings: fromUserSettings.userSettingsReducer,
  userUsage: fromUserUsage.userUsageReducer
};

export const getUIModuleState = createFeatureSelector<State>(UIFeatureKey);

export const getSpatialModellerUIState = createSelector(
  getUIModuleState,
  (state) => state && state.spatialModellerUI
);

export const getLocatorUIState = createSelector(
  getUIModuleState,
  (state) => state && state.locatorUI
);

export const getProfilerUIState = createSelector(
  getUIModuleState,
  (state) => state && state.profilerUI
);

export const getAppFeatureUIState = createSelector(
  getUIModuleState,
  (state) => state && state.appFeatureUI
);

export const getTenantManagementState = createSelector(
  getUIModuleState,
  (state) => state && state.tenantManagement
);

export const getUserSettingsState = createSelector(
  getUIModuleState,
  (state) => state && state.userSettings
);

export const getUserUsageState = createSelector(
  getUIModuleState,
  (state) => state && state.userUsage
);
