import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';


@Component({
  selector: 'atlas-checkbox',
  templateUrl: './atlas-checkbox.component.html',
  styleUrls: ['./atlas-checkbox.component.less']
})
export class AtlasCheckboxComponent implements OnInit {
  @Input() control: SupplyPointAttribute;
  
  formGroup: UntypedFormGroup;  

  constructor(private parentFormDirective: FormGroupDirective) { }

  ngOnInit() {
    this.formGroup = this.parentFormDirective.control;
  }
}
