<div class="settings-container">
  <form
    [formGroup]="generalUserSettingsForm"
    (ngSubmit)="saveGeneralUserSettings()"
    autocomplete="off"
    style="height: 100%"
  >
    <div class="settings-content">
      @if(isIsolineProviderTravelTime() && ((isSMFeatureAllowed$ | async) ||
      (isLocatorFeatureAllowed$ | async)) ){
      <div class="title">Speed used for drive catchments</div>
      <div>
        <div class="combo-box">
          <mat-form-field appearance="outline" hideRequiredMarker="true">
            <mat-label>Drive catchment speed</mat-label>
            <mat-select
              [hideSingleSelectionIndicator]="true"
              formControlName="driveCatchmentSpeed"
            >
              @for (driveCatchmentSpeed of driveCatchmentSpeedList; track
              driveCatchmentSpeed) {
              <mat-option [value]="driveCatchmentSpeed.key">{{
                driveCatchmentSpeed.value
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      }
      <div class="title">Tenant</div>
      <div>
        <div class="combo-box">
          <mat-form-field
            appearance="outline"
            hideRequiredMarker="true"
            *ngIf="isSingleOrNoAvailableTenants()"
          >
            <mat-label>Tenant</mat-label>
            <mat-select
              [hideSingleSelectionIndicator]="true"
              [value]="selectedTenantId"
              [disabled]="true"
              #switchTenantComboBox
            >
              <mat-option [value]="selectedTenantId">{{
                selectedSystemName
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            hideRequiredMarker="true"
            *ngIf="!isSingleOrNoAvailableTenants()"
          >
            <mat-label>Tenant</mat-label>
            <mat-select
              [hideSingleSelectionIndicator]="true"
              [value]="selectedTenantId"
              (selectionChange)="onDropDownSelectionChanged($event.value)"
              [disabled]="isSingleOrNoAvailableTenants()"
              #switchTenantComboBox
            >
              <mat-option
                *ngFor="let tenant of availableTenants$ | async"
                [value]="tenant.key"
                >{{ tenant.value.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="description">
          Allows users who are assigned to multiple tenants to switch between
          tenants. When switching tenant the user will be logged out of the
          current tenant, and be taken to the InSite Everywhere login screen
          where they must login again to access the new tenant.
        </div>
      </div>
      <div class="title">Tool-tip text alignment</div>
      <div>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="toolTipLayout"
        >
          <mat-radio-button
            [value]="toolTipLayouts.TwoColumns"
            class="radio-button-padding"
            ><mat-label>Two columns</mat-label>
          </mat-radio-button>

          <mat-radio-button
            [value]="toolTipLayouts.LeftAligned"
            class="radio-button-margin radio-button-padding"
            ><mat-label>Aligned Left</mat-label></mat-radio-button
          >

          <mat-radio-button
            [value]="toolTipLayouts.WrappedText"
            class="radio-button-margin radio-button-padding"
            ><mat-label>Wrapped Text</mat-label></mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="title">Enable map to pan and zoom to what is selected</div>
      <mat-slide-toggle
        class="zoom-to-extent-layout"
        [hideIcon]="true"
        formControlName="disableZoomToExtent"
      >
      </mat-slide-toggle>
      <div class="title">Include legend on map download/copy</div>
      <mat-slide-toggle
        class="zoom-to-extent-layout"
        [hideIcon]="true"
        formControlName="hideLegendOnMapDownload"
      >
      </mat-slide-toggle>
    </div>
    <div class="save-button-container">
      <button
        mat-flat-button
        color="primary"
        color="primary"
        class="save-button"
        type="submit"
        [disabled]="isSaveDisabled()"
      >
        Save
      </button>
    </div>
  </form>
</div>
