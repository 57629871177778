export function encloseInArray<T>(value: T) {
  if (Array.isArray(value)) {
    return value;
  }

  return value ? [value] : [];
}

export function createArrayOfNumbers(numElements: number) {
  return [...Array(numElements).keys()];
}

export function getArrayCount<T>(arr: T[]) {
  return arr.length;
}

export function allHaveSameValue<T>(arr: T[]) {
  return new Set(arr).size === 1;
}

export function removeItem<T>(arr: T[], value: T): T[] {
  const index = arr.indexOf(value);
  const newArray = [...arr];
  if (index > -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}
