import { createReducer, on } from '@ngrx/store';

import {
  clearLocatorLibraryStateOnLogoff,
  createLibrarySucceeded,
  getLocatorLibrarySucceeded,
  swapLibrarySucceeded,
  updateLibrarySucceeded
} from '../actions/locator-library.actions';

import { LocatorSummaryStatsStatus } from 'src/app/locator/models/locator-summary-stats-status';
import {
  getLocatorSummaryStatsStatusSucceeded,
  pollLocatorSummaryStatsStatusCompleted,
  pollLocatorSummaryStatsStatusFailed,
  pollLocatorSummaryStatsStatusInProgress
} from '../actions/locator-summary-stats-actions';

export interface State {
  id: number;
  summaryStatsStatus: LocatorSummaryStatsStatus | null;
}

export const initialState: State = {
  id: 0,
  summaryStatsStatus: null
};

export const locatorSummaryStatsReducer = createReducer(
  initialState,
  on(
    getLocatorLibrarySucceeded,
    updateLibrarySucceeded,
    createLibrarySucceeded,
    swapLibrarySucceeded,
    (state, { library }) => {
      return {
        ...state,
        id: library.id,
        summaryStatsStatus: null
      };
    }
  ),

  on(clearLocatorLibraryStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  }),
  on(
    getLocatorSummaryStatsStatusSucceeded,
    pollLocatorSummaryStatsStatusInProgress,
    pollLocatorSummaryStatsStatusCompleted,
    pollLocatorSummaryStatsStatusFailed,
    (state, { locatorSummaryStatsStatus }) => {
      return {
        ...state,
        summaryStatsStatus: locatorSummaryStatsStatus
      };
    }
  )
);

export const getLocatorSummaryStatsStatus = (state: State) =>
  state && state.summaryStatsStatus;
export const getLocatorSummaryStatsStatusMessage = (state: State) =>
  state && state.summaryStatsStatus?.status;
export const getJsonLocatorSummaryStatsStatusError = (state: State) =>
  state && state.summaryStatsStatus?.errorMessage;
