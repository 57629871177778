<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(catchmentForm)"
  [saveDisabled]="catchmentForm.isInvalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-catchment-form
    #catchmentForm
    formContent
    [shape]="data.shape"
    [mode]="data.mode"
    [defaultSize]="defaultSize"
    [defaultShapeUnit]="defaultShapeUnit"
    [isShared]="isShared"
    [currentShapes]="shapes"
    [shapeType]="data.shapeType"
    [locationsCount]="locationsCount"
  >
  </atlas-catchment-form>
  >
</atlas-add-edit-dialog>
