@if(!errorsProcessingData){
<div class="title">Creating report</div>
<div class="spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="0" class="background-spinner">
  </mat-progress-spinner>
  <mat-progress-spinner
    [mode]="mode"
    [value]="percentage"
    class="percentage-spinner"
  >
  </mat-progress-spinner>
</div>

<div class="percentage-text">
  <span class="percentage-text">{{ percentage }}%</span>
</div>
<div class="message-container">
  <span class="summary-text">
    Batch reports for large quantities of catchments may take a moment. Thank
    you for your patience.
  </span>
</div>
} @else{
<div class="title title-error">Error</div>
<div class="error-spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="100" class="error-spinner">
  </mat-progress-spinner>
  <div class="error-text">
    <span>N/A</span>
  </div>
</div>
}
