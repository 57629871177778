<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="swap scenario"
    (click)="onSwapScenario()"
    matTooltip="Switch to another scenario"
    [matTooltipShowDelay]="1000"
    [disabled]="disabled"
  >
    <mat-icon class="swap-button">swap_horiz</mat-icon>
  </button>
</div>
