import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import {
  spatialModellerGetStatsAttempt,
  spatialModellerGetStatsSucceded,
} from '../actions/spatial-modeller-stats.actions';
import { SpatialModellerDataService } from 'src/app/spatial-modeller/services/spatial-modeller-data.service';
import { Stats } from 'src/app/spatial-modeller/models/stats';

@Injectable()
export class SpatialModellerStatsEffects {
  constructor(
    private actions$: Actions,
    private spatialModellerDataService: SpatialModellerDataService
  ) {}

  spatialModellerGetStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(spatialModellerGetStatsAttempt),
      switchMap(({ testId }) =>
        this.spatialModellerDataService.getTestStats(testId).pipe(
          map((stats: Stats[]) => {
            return spatialModellerGetStatsSucceded({ stats });
          })
        )
      )
    )
  );
}
