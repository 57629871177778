import { Store } from '@ngrx/store';
import { Directive, ElementRef } from '@angular/core';
import * as fromLocatorUIStore from 'src/app/core/store';
import { DisableUI } from './disable-ui';

@Directive({
  selector: '[atlasModifyPolyDisableUI]'
})
export class AtlasModifyPolyDisableUIDirective extends DisableUI {
  constructor(
    private el: ElementRef,
    private locatorUIStore$: Store<fromLocatorUIStore.State>
  ) {
    super(
      el,
      locatorUIStore$.select(fromLocatorUIStore.getAddingEditingPolygon)
    );

  }
}
