import { Component, Input } from '@angular/core';
import { UsersModelUsage } from 'src/app/reporting/models/usage-summary';

@Component({
  selector: 'atlas-user-model-usage-summary',
  templateUrl: './user-model-usage-summary.component.html',
  styleUrls: ['../less/summary-usage.less']
})
export class UserModelUsageSummaryComponent {
  @Input()
  dataSource: UsersModelUsage | null;
}
