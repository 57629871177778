import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { LocatorImportLibraryDataDialogComponent } from '../locator-import-library-data-dialog/locator-import-library-data-dialog.component';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromAppFeatureUIStore from 'src/app/core/store';
import { Store } from '@ngrx/store';
import { getLocatorLibraryId } from 'src/app/locator-store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'atlas-locator-import-library-data',
  templateUrl: './locator-import-library-data.component.html'
})
export class LocatorImportLibraryDataComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private libraryId$ = this.store$.select(getLocatorLibraryId);
  private libraryId: number;
  private tenantImportUsingGeocoding: boolean;

  private importUsingGeocoding$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getCatchmentReportingImportUsingGeocoding
  );

  isCatchmentReportingReadonlySystem$ = this.featureUIStore$.select(
    fromAppFeatureUIStore.getCatchmentReportingReadonlySystem
  );

  constructor(
    private featureUIStore$: Store<fromAppFeatureUIStore.State>,
    private store$: Store<fromLocatorStore.State>,
    public dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.libraryId$.subscribe((id) => {
        this.libraryId = id;
      })
    );

    this.subscription.add(
      this.importUsingGeocoding$.subscribe((useGeocoding) => {
        this.tenantImportUsingGeocoding = useGeocoding;
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onOpenLocatorImportDataDialog() {
    this.dialogService.show(LocatorImportLibraryDataDialogComponent, {
      width: DialogWidth.Medium,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        mode: DialogMode.Add,
        headerPrefix: 'Import Locations',
        affirmativeButtonText: 'Continue',
        libraryId: this.libraryId,
        tenantImportUsingGeocoding: this.tenantImportUsingGeocoding
      },
      disableClose: true
    });
  }
}
