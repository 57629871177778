import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Scenario } from 'src/app/core/models/scenario';

import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { usageErrorOccured } from 'src/app/reporting/store/actions/usage.actions';

import { ActionCreatorWithProps } from 'src/app/shared/actions/action-creator-with-props';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import {
  spatialModellerModelLocationPollingCancelled,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationStartedFromSPReOpen,
  spatialModellerModelLocationStartedFromSPSave,
  spatialModellerModelLocationStartedFromSPUntouchedTemporarySave,
  spatialModellerModelLocationSucceed,
  spatialModellerModelLocationSucceedFromSPClose,
  spatialModellerModelLocationSucceedFromSPReOpen,
  spatialModellerModelLocationSucceedFromSPSave,
  spatialModellerTestAfterLocationGeneratedStarted,
  spatialModellerTestCompleted,
  spatialModellerTestErrorOccurred,
  spatialModellerTestStarted
} from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import {
  ShareSmPowerBIReportErrorOccurred,
  ShareSmPowerBIReportSucceeded,
  exportReportClicked,
  exportReportComplete,
  exportReportErrorOccurred} from '../actions/sm-report.actions';
import {
  createScenarioSucceeded,
  updateScenarioSucceeded,
  scenarioErrorOccurred,
  swapScenarioSucceeded,
  deleteScenarioSucceeded,
  moveDeltaSupplyPointsSucceeded
} from '../actions/scenario.actions';
import {
  saveSupplyPointSucceeded,
  deleteSupplyPointSucceeded,
  closeSupplyPointSucceeded,
  reopenSupplyPointSucceeded
} from '../actions/supply-point.actions';
import { SmTestUserActionSources } from 'src/app/spatial-modeller/models/test-action-sources';
import { State } from '../reducers';

@Injectable()
export class SnackbarEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private store$: Store<State>
  ) {}

  snackbarOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          spatialModellerTestErrorOccurred,
          exportReportErrorOccurred,
          scenarioErrorOccurred,
          usageErrorOccured,
          ShareSmPowerBIReportErrorOccurred
        ),
        tap(({ errorOn, error: message }) => {
          this.snackbar.error(errorOn, message);
        })
      ),
    { dispatch: false }
  );

  spatialModellerTestStartedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [spatialModellerTestStarted],
    'Creating catchment, please wait'
  );

  spatialModellerTestAfterLocationGeneratedStarted$ = this.createSnackbarEffect(
    this.actions$,
    [spatialModellerTestAfterLocationGeneratedStarted],
    'Creating catchment, please wait'
  );

  createSnackbarEffectForSpatialModellerModelLocationStarted$ =
    this.createSnackbarEffect(
      this.actions$,
      [spatialModellerModelLocationStarted],
      'Generating Location Id, please wait'
    );

  createSnackbarEffectForSpatialModellerModelLocationStartedFromSPSave$ =
    this.createSnackbarEffect(
      this.actions$,
      [
        spatialModellerModelLocationStartedFromSPSave,
        spatialModellerModelLocationStartedFromSPUntouchedTemporarySave
      ],
      'Saving location, please wait'
    );

  createSnackbarEffectForSpatialModellerModelLocationStartedFromSPClose$ =
    this.createSnackbarEffect(
      this.actions$,
      [spatialModellerModelLocationStartedFromSPClose],
      'Closing location, please wait'
    );

  createSnackbarEffectForSpatialModellerModelLocationStartedFromSPReOpen$ =
    this.createSnackbarEffect(
      this.actions$,
      [spatialModellerModelLocationStartedFromSPReOpen],
      'Reopening Location, please wait'
    );

  spatialModellerTestCompletedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [spatialModellerTestCompleted],
    'Catchment complete'
  );

  exportReportStartedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [exportReportClicked],
    'Downloading report, please wait'
  );

  exportReportCompletedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [exportReportComplete],
    'Report download complete'
  );

  createScenarioSucceededSnackbar$ = this.createEditScenarioSnackbarEffect(
    this.actions$,
    [createScenarioSucceeded],
    'scenario successfully created.',
    '',
    'prefix'
  );

  updateScenarioSucceededSnackbar$ = this.createEditScenarioSnackbarEffect(
    this.actions$,
    [updateScenarioSucceeded],
    'scenario successfully updated.',
    '',
    'prefix'
  );

  swapScenarioSucceededSnackbar$ = this.createEditScenarioSnackbarEffect(
    this.actions$,
    [swapScenarioSucceeded],
    'Scenario switched to ',
    '',
    'suffix'
  );

  deleteScenarioSucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [deleteScenarioSucceeded],
    'Scenario successfully deleted.'
  );

  deleteSupplypointSucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [deleteSupplyPointSucceeded],
    'Location successfully deleted.'
  );

  spatialModellerSupplyPointSavedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [saveSupplyPointSucceeded],
    'Supply point succesfully saved'
  );

  moveSupplyPointsSucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [moveDeltaSupplyPointsSucceeded],
    'Location(s) successfully moved.'
  );

  spatialModellerModelLocationSucceededSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [
      spatialModellerModelLocationSucceed,
      spatialModellerModelLocationSucceedFromSPSave,
      spatialModellerModelLocationSucceedFromSPClose,
      spatialModellerModelLocationSucceedFromSPReOpen
    ],
    'Location Id succesfully generated'
  );

  spatialModellerSupplyPointClosedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [closeSupplyPointSucceeded],
    'Supply point succesfully closed'
  );

  spatialModellerSupplyPointReopenedSnackbar$ = this.createSnackbarEffect(
    this.actions$,
    [reopenSupplyPointSucceeded],
    'Supply point succesfully reopened'
  );

  spatialModellerModelLocationCancelledSnackbar$ =
    this.createSpatialModellerModelLocationPollingCancelledSnackbarEffect(
      this.actions$,
      [spatialModellerModelLocationPollingCancelled]
    );

  sharePowerBiReportAttempt$ = this.createSnackbarEffect(
    this.actions$,
    [ShareSmPowerBIReportSucceeded],
    'Report Link copied to clipboard'
  );

  private createSnackbarEffect(
    actions$: Actions,
    initiatedActions:
      //| Action
      | ActionCreatorWithProps[]
      | ActionCreatorWithProps<{
          testId: number;
        }>[]
      | ActionCreatorWithProps<{
          scenario: Scenario;
        }>[]
      | ActionCreatorWithProps<{
          id: number;
        }>[]
      | ActionCreatorWithProps<{
          supplyPoint: SupplyPoint;
          location: Point;
          executeModel: boolean;
        }>[]
      | ActionCreatorWithProps<{
          uId: string;
        }>[]
      | ActionCreatorWithProps<{ supplyPoint: SupplyPoint; location: Point }>[]
      | ActionCreatorWithProps<{
          reportId: string;
        }>[],
    actionString: string,
    headerActionString?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(() =>
            this.snackbar.show(
              this.getSnackbarText(actionString),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private createEditScenarioSnackbarEffect(
    actions$: Actions,
    initiatedActions: ActionCreatorWithProps<{
      scenario: Scenario;
    }>[],
    actionString: string,
    headerActionString?: string,
    scenarioPosition?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(({ scenario }) =>
            this.snackbar.show(
              this.getSnackbarText(
                actionString,
                scenario.name,
                scenarioPosition
              ),
              headerActionString
            )
          )
        ),

      { dispatch: false }
    );
  }

  private createSpatialModellerModelLocationPollingCancelledSnackbarEffect(
    actions$: Actions,
    initiatedActions: ActionCreatorWithProps<{
      supplyPoint: SupplyPoint;
      source: SmTestUserActionSources;
    }>[]
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(({ supplyPoint, source }) => {
            const cancellationText = this.getLocationPollingCancelledText(
              supplyPoint,
              source
            );
            this.snackbar.error('', this.getSnackbarText(cancellationText));
          })
        ),

      { dispatch: false }
    );
  }

  private getSnackbarText(
    action: string,
    objectName?: string,
    objectPosition?: string
  ) {
    if (objectName) {
      if (objectPosition) {
        switch (objectPosition) {
          case 'prefix':
            return objectName ? `${objectName} ${action}` : `${action}`;

          case 'suffix':
            return objectName ? `${action} ${objectName}` : `${action}`;

          default:
            return objectName ? `${objectName} ${action}` : `${action}`;
        }
      }
    }

    return `${action}`;
  }

  private getLocationPollingCancelledText(
    supplyPoint: SupplyPoint,
    source: SmTestUserActionSources
  ) {
    switch (source) {
      case SmTestUserActionSources.SAVE_SP:
        return `Save location ${supplyPoint.name}  (${supplyPoint.supplyId}) has been cancelled.`;
      case SmTestUserActionSources.CLOSE_SP:
        return `Close location ${supplyPoint.name} (${supplyPoint.supplyId}) has been cancelled.`;
      case SmTestUserActionSources.REOPEN_SP:
        return `Reopen location ${supplyPoint.name}  (${supplyPoint.supplyId}) has been cancelled.`;
      default:
        return `Adding a new location ${supplyPoint.name}  (${supplyPoint.supplyId}) has been cancelled.`;
    }
  }

  private createMultipleSnackbarEffect(
    actions$: Actions,
    initiatedActions:
      | ActionCreatorWithProps[]
      | ActionCreatorWithProps<{
          testId: number;
        }>[],
    actionStrings: string[],
    headerActionString?: string
  ) {
    return createEffect(
      () =>
        actions$.pipe(
          ofType(...initiatedActions),
          tap(() =>
            this.snackbar.showMultiple(actionStrings, headerActionString)
          )
        ),

      { dispatch: false }
    );
  }
}
