<div *ngIf="deltaSummary$ | async as summary">
  <div>
    {{ summary.closed + summary.added + summary.edited }} change(s) in scenario.

    <span
      [class]="disabled ? 'link-disabled' : 'link'"
      *ngIf="layoutService.isDesktopView$ | async"
      (click)="!disabled ? onViewDeltaList() : $event.preventDefault()"
      ><u>View list</u></span
    >
  </div>
  <div
    [class]="
      disabled ? 'summary-item-container-disabled' : 'summary-item-container'
    "
    class="summary-item-container"
  >
    <div>{{ summary.added }} New</div>
    <div>{{ summary.edited }} Edited</div>
    <div>{{ summary.closed }} Closed</div>
  </div>
</div>
