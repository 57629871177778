import { createReducer, on } from '@ngrx/store';
import {
  exportReportClicked,
  exportReportErrorOccurred,
  exportReportComplete,
  getReportsSucceded
} from '../actions/sm-report.actions';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';
import { searchTextCleared } from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';
import {
  swapScenarioSucceeded,
  createScenarioSucceeded,
  updateScenarioSucceeded
} from '../actions/scenario.actions';
import {
  supplyPointDeselected,
  spatialModellerTestStarted,
  spatialModellerModelLocationStarted,
  spatialModellerModelLocationStartedFromSPSave,
  spatialModellerModelLocationStartedFromSPUntouchedTemporarySave,
  spatialModellerModelLocationStartedFromSPClose,
  spatialModellerModelLocationStartedFromSPReOpen
} from '../actions/spatial-modeller-test.actions';
import { deleteSupplyPointSucceeded } from '../actions/supply-point.actions';

export interface State {
  reports: SmReportingInfo[] | null;
  reportInProgress: boolean;
}

export const initialState: State = {
  reportInProgress: false,
  reports: []
};

export const smReportingReducer = createReducer(
  initialState,
  on(exportReportClicked, (state) => {
    return {
      ...state,
      reportInProgress: true
    };
  }),
  on(
    exportReportComplete,
    exportReportErrorOccurred,
    swapScenarioSucceeded,
    createScenarioSucceeded,
    updateScenarioSucceeded,
    searchTextCleared,
    supplyPointDeselected,
    deleteSupplyPointSucceeded,
    spatialModellerTestStarted,
    spatialModellerModelLocationStarted,
    spatialModellerModelLocationStartedFromSPSave,
    spatialModellerModelLocationStartedFromSPUntouchedTemporarySave,
    spatialModellerModelLocationStartedFromSPClose,
    spatialModellerModelLocationStartedFromSPReOpen,
    (state) => {
      return {
        ...state,
        reportInProgress: false
      };
    }
  ),
  on(getReportsSucceded, (state, { reports }) => {
    return {
      ...state,
      reports: reports
    };
  })
);
