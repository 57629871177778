import { ImportValidationService } from "./import-validation.service";
import { ImportService } from "./import.service";
import { ProfilerService } from "./profiler.service";
import { UploadService } from "./upload.service";

export const services = [
    ProfilerService,
    ImportService,
    UploadService,
    ImportValidationService
];
