<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(editLibraryForm)"
  [saveDisabled]="editLibraryForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-add-edit-library-form
    #editLibraryForm
    formContent
    [library]="data.library"
    [userLibraries]="userLibraries"
  ></atlas-add-edit-library-form>
</atlas-add-edit-dialog>
