import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'lastValueInArray' })
export class LastValueInArrayPipe<T> implements PipeTransform {
  transform(value: T[]): T | null {
    if (value) {
      return value.length > 0 ? value[value.length - 1] : ({} as T);
    }
    return null;
  }
}
