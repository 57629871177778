<atlas-add-edit-dialog
  [saveButtonText]="affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  [closeOnSave]="true"
  (saveClicked)="onAddNewLocationClicked(defineNewLocationForm)"
  [saveDisabled]="isAffirmativeButtonDisabled(defineNewLocationForm)"
  class="add-edit-dialog-content"
>
  <atlas-define-new-location-form #defineNewLocationForm formContent>
  </atlas-define-new-location-form>
</atlas-add-edit-dialog>
