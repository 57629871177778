import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-locator-import-library-data-form-3',
  templateUrl: './locator-import-library-data-form-3.component.html',
  styleUrls: ['./locator-import-library-data-form-3.component.less']
})
export class LocatorImportLibraryDataFormComponent3 {
  @Input() processedRecords: number;
  @Input() importedRecords: number;

  constructor() {}
}
