import { createReducer, on } from '@ngrx/store';
import { loadAppFeaturesSucceeded } from '../actions/app-feature-ui.actions';
import { locatorFeatureClicked } from '../actions/locator-ui.actions';
import {
  profilerFeatureClicked,
  profilerLayersSucceeded
} from '../actions/profiler-ui.actions';
import { smFeatureClicked } from '../actions/spatial-modeller-ui.actions';

export interface State {
  profilerFeatureAllowed: boolean;
  featureSelected: boolean;
  hasProfilerFeatureLoaded: boolean;
}

const initialState: State = {
  profilerFeatureAllowed: false,
  featureSelected: false,
  hasProfilerFeatureLoaded: false
};

export const profilerUIReducer = createReducer(
  initialState,
  on(loadAppFeaturesSucceeded, (state, { appFeatures }) => {
    return {
      ...state,
      profilerFeatureAllowed: appFeatures.profilerFeature
    };
  }),
  on(smFeatureClicked, locatorFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: false,
      hasProfilerFeatureLoaded: false
    };
  }),
  on(profilerFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: true
    };
  }),
  on(profilerLayersSucceeded, (state) => {
    return {
      ...state,
      hasProfilerFeatureLoaded: true
    };
  })
);
