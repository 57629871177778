import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import {
  formGroupControlValuesMatchingValidator,
  formControlValuesMatchingValidator
} from 'src/app/shared/utils/form-validators/form-values-matching-validator';
import { formValueMinLengthValidator } from 'src/app/shared/utils/form-validators/form-value-min-length.validator';
import { formValueContainsNumberValidator } from 'src/app/shared/utils/form-validators/form-value-contains-number.validator';
import { formValueContainsLowerCaseValidator } from 'src/app/shared/utils/form-validators/form-value-contains-lower-case.validator';
import { formValueContainsUpperCaseValidator } from 'src/app/shared/utils/form-validators/form-value-contains-upper-case.validator';
import { formValueContainsSpecialCharValidator } from 'src/app/shared/utils/form-validators/form-value-contains-special-char.validator';
import {
  hasLowerCase,
  hasMinLength,
  hasNumber,
  hasSpecialChar,
  hasUpperCase
} from '../utils/password-form-control-errors';

@Component({
  selector: 'atlas-verify-new-password',
  templateUrl: './verify-new-password.component.html',
  styleUrls: ['./verify-new-password.component.less']
})
export class VerifyNewPasswordComponent {
  hide = true;
  hideConfirm = true;

  verifyNewPasswordForm = this.fb.group(
    {
      verificationCode: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          formValueMinLengthValidator(8),
          formValueContainsNumberValidator(),
          formValueContainsUpperCaseValidator(),
          formValueContainsLowerCaseValidator(),
          formValueContainsSpecialCharValidator()
        ])
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          formControlValuesMatchingValidator('password', 'confirmPassword')
        ])
      ]
    },
    {
      validators: formGroupControlValuesMatchingValidator(
        'password',
        'confirmPassword'
      )
    }
  );

  constructor(
    private fb: UntypedFormBuilder,
    public authService: AuthService
  ) {}

  onVerifyNewPassword() {
    this.authService.confirmPasswordAfterForgotPassword(
      this.verifyNewPasswordForm.controls.verificationCode.value,
      this.verifyNewPasswordForm.controls.password.value
    );
  }

  invalidForm() {
    return (
      this.emptyVerificationCode() ||
      this.emptyPassword() ||
      !this.hasMinLenght() ||
      !this.hasNumber() ||
      !this.hasLowerCase() ||
      !this.hasUpperCase() ||
      !this.hasSpecialChar() ||
      this.verifyNewPasswordForm.errors?.missMatch
    );
  }

  emptyVerificationCode() {
    return this.verifyNewPasswordForm.controls.verificationCode.errors
      ?.required;
  }

  emptyPassword() {
    return this.verifyNewPasswordForm.controls.password.errors?.required;
  }

  notTouchedPassword() {
    return (
      this.verifyNewPasswordForm.controls.password.value === '' &&
      !this.verifyNewPasswordForm.controls.password.touched
    );
  }

  hasMinLenght() {
    return hasMinLength(this.verifyNewPasswordForm.controls.password);
  }

  hasNumber() {
    return hasNumber(this.verifyNewPasswordForm.controls.password);
  }

  hasLowerCase() {
    return hasLowerCase(this.verifyNewPasswordForm.controls.password);
  }

  hasUpperCase() {
    return hasUpperCase(this.verifyNewPasswordForm.controls.password);
  }

  hasSpecialChar() {
    return hasSpecialChar(this.verifyNewPasswordForm.controls.password);
  }

  showEmptyConfirmPasswordError() {
    return (
      this.emptyPassword() &&
      this.verifyNewPasswordForm.controls.confirmPassword?.errors?.required
    );
  }

  invalidConfirmPassword() {
    return (
      this.verifyNewPasswordForm.invalid &&
      (this.verifyNewPasswordForm.controls.confirmPassword.dirty ||
        this.verifyNewPasswordForm.controls.confirmPassword.touched) &&
      this.verifyNewPasswordForm.errors?.missMatch
    );
  }
}
