import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Part } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';
import * as fromLocatorStore from 'src/app/locator-store';
import {
  locatorAbortMultipartUpload,
  locatorFileUploadError,
  locatorFileUploadUpdateFileKey,
  locatorInitiateMultipartUploadAttempt,
  locatorMetaDataUploadAttempt,
  locatorMultipartUploadComplete
} from 'src/app/locator-store/actions/locator-import.actions';
import { firstValueFrom, lastValueFrom } from 'rxjs';

// The size at which point to upload using multipart upload
const singleFileUploadSize = 10 * 1024 * 1024; // 10MB in bytes

// Each mulitpart part size must be at least 5MB
const multipartFileUploadMinimumSize = 5 * 1024 * 1024; // 5MB in bytes

@Injectable({
  providedIn: 'root'
})
export class LocatorUploadService {
  private atlasLocatorImportUrl = `${environment.baseUrl}api/locator/import-library-data`;
  private guidFilename: string;

  constructor(
    private http: HttpClient,
    private store$: Store<fromLocatorStore.State>
  ) {}

  public uploadFile(file: File) {
    this.guidFilename = `${uuidv4()}.csv`;
    this.store$.dispatch(
      locatorFileUploadUpdateFileKey({ fileKey: this.guidFilename })
    );

    if (file.size <= singleFileUploadSize) {
      this.uploadFileInOneGo(file);
    } else {
      this.store$.dispatch(
        locatorInitiateMultipartUploadAttempt({
          file: file,
          fileKey: this.guidFilename
        })
      );
    }
  }

  public async uploadFileInOneGo(file: File) {
    try {
      const fileData = await this.readFileAsText(file);
      const response: any = await this.getPresignedUrl(this.guidFilename);
      await this.uploadFileAsText(response.presignedUrl, fileData);
      this.store$.dispatch(
        locatorMetaDataUploadAttempt({ fileKey: this.guidFilename })
      );
    } catch (error) {
      this.handleUploadError(file.name, error);
    }
  }

  public async uploadFileMultiPart(file: File, uploadId: string) {
    try {
      let eTagParts: Part[] = [];
      const partCount = Math.floor(file.size / multipartFileUploadMinimumSize);
      const uploadPromises: Promise<void>[] = [];
      const partSize = Math.ceil(file.size / partCount);

      // Divide file by partCount and upload each part in parallel.
      for (let i = 0; i < partCount; i++) {
        const start = i * partSize;
        const end = start + partSize;
        const partNumber = i + 1;

        // Set up all the uploads as async functions that return a promise.
        uploadPromises.push(
          (async () => {
            const response: any = await this.getMultipartPresignedUrl(
              this.guidFilename,
              uploadId,
              partNumber
            );
            const fileDataChunk = await this.readFileChunk(file, start, end);
            const uploadResponse: any = await this.uploadFileAsBuffer(
              response.presignedUrl,
              fileDataChunk
            );
            const etag = uploadResponse.headers.get('ETag')!.replace(/"/g, '');
            eTagParts.push({ ETag: etag, PartNumber: partNumber });
          })()
        );
      }

      // Invoke all the upload functions and wait for completion.
      await Promise.all(uploadPromises);

      this.store$.dispatch(
        locatorMultipartUploadComplete({
          uploadId,
          filename: this.guidFilename,
          parts: eTagParts
        })
      );
    } catch (err) {
      this.store$.dispatch(
        locatorAbortMultipartUpload({
          uploadId,
          filename: this.guidFilename,
          errorMessage: err
        })
      );
    }
  }

  private async getPresignedUrl(filename: string) {
    return this.http
      .get<{ presignedUrl: string }>(
        `${this.atlasLocatorImportUrl}/presigned-url/${filename}`
      )
      .toPromise();
  }

  private async getMultipartPresignedUrl(
    filename: string,
    uploadId: string,
    partNumber: number
  ) {
    const httpResponse$ = this.http.get<{ presignedUrl: string }>(
      `${this.atlasLocatorImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`
    );
    return await firstValueFrom(httpResponse$);
  }

  private async uploadFileAsBuffer(url: string, data: ArrayBuffer) {
    const headers = { 'Content-Type': 'application/octet-stream' };
    const httpResponse$ = this.http.put(url, data, {
      headers,
      observe: 'response'
    });
    return await firstValueFrom(httpResponse$);
  }

  private async uploadFileAsText(url: string, fileData: any) {
    const headers = { 'Content-Type': 'text/csv' };
    const httpResponse$ = this.http.put(url, fileData, { headers });
    return await firstValueFrom(httpResponse$);
  }

  private async readFileAsText(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.onerror = (e: any) => reject(e.target.error);
      reader.readAsText(file);
    });
  }

  private async readFileChunk(
    file: File,
    start: number,
    end: number
  ): Promise<ArrayBuffer> {
    const fileSlice = file.slice(start, end);
    return await fileSlice.arrayBuffer();
  }

  private handleUploadError(filename: string, error: any) {
    this.store$.dispatch(
      locatorFileUploadError({ fileKey: filename, errors: error })
    );
  }
}
