import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { openStreetView } from 'src/app/shared/utils/map-utils';

@Component({
  selector: 'atlas-locator-properties-panel-buttons',
  templateUrl: './locator-properties-panel-buttons.component.html',
  styleUrls: ['./locator-properties-panel-buttons.component.less']
})
export class LocatorPropertiesPanelButtonsComponent
  implements OnInit, OnDestroy
{
  private selectedLocation: Point | null;
  private subscription: Subscription = new Subscription();

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.gazetteerStore$
        .select(fromGazetteerStore.getSelectedPoint)
        .subscribe((point) => {
          this.selectedLocation = point?.location;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onStreetViewClicked() {
    openStreetView(
      this.selectedLocation?.latitude,
      this.selectedLocation?.longitude
    );
  }
}
