import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { ModifyMode } from '@nebula.gl/edit-modes/dist';
import { Point } from '../../atlas-gazetteer/models/point.model';

import * as fromLocatorStore from 'src/app/locator-store';
import * as fromAppFeatureStore from 'src/app/core/store';
import * as fromLocatorUIStore from 'src/app/core/store';

import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';
import {
  addingPolygonConfirmAttempt,
  editLocatorPolygonShapeConfirm
} from 'src/app/locator-store/actions/locator-shape.actions';

declare var require: any;
const turfCircle = require('@turf/circle');
const turfHelper = require('@turf/helpers');

@Injectable({
  providedIn: 'root'
})
export class PolygonSelectionService implements OnDestroy {
  private subscription = new Subscription();
  private polygonDataSubject$ = new BehaviorSubject<any>(null);
  private polygonBuiltOnPoint: Point;
  private confirmAddEditPolygon: boolean;
  private editingExistingPolygonShape: boolean;

  polygonDataDrop$ = this.polygonDataSubject$.asObservable();

  polygonStepsDefaults: number = 8;
  polygonRadiusInKilometersDefaults: number = 1;
  userPolygonSettings:
    | { steps: number; radiusInKilometers: number }
    | undefined | null;

  polygonSteps: number;
  polygonRadiusInKilometers: number;

  getPolygonDefaults$ = this.featureStore$.select(
    fromAppFeatureStore.getPolygonDefaults
  );

  generalUserLocatorSettings$ = this.featureStore$.select(
    fromAppFeatureStore.getUserLocatorSettings
  );

  addingEditingPolygon$ = this.locatorUIStore$.select(
    fromLocatorUIStore.getAddingEditingPolygon
  );

  getEditingExistingPolygonShape$ = this.locatorStore$.select(
    fromLocatorStore.getEditingExistingPolygonShape
  );

  constructor(
    private locatorStore$: Store<fromLocatorStore.State>,
    private featureStore$: Store<fromAppFeatureStore.State>,
    private locatorUIStore$: Store<fromLocatorUIStore.State>
  ) {
    this.subscription.add(
      this.getPolygonDefaults$.pipe(filter((p) => p!=null)).subscribe((p) => {       
        this.polygonStepsDefaults = p.steps!;
        this.polygonRadiusInKilometersDefaults = p.radiusInKilometers!;        
      })
    );
    this.subscription.add(
      this.generalUserLocatorSettings$.subscribe((locatorSettings) => {
        this.userPolygonSettings = locatorSettings?.polygon;
      })
    );

    this.subscription.add(
      this.addingEditingPolygon$.subscribe((addingEditingPolygon) => {
        this.confirmAddEditPolygon = addingEditingPolygon;
      })
    );

    this.subscription.add(
      this.getEditingExistingPolygonShape$.subscribe(
        (editingExistingPolygonShape) => {
          this.editingExistingPolygonShape = editingExistingPolygonShape;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  polgonShapeEdited(
    updatedData: any,
    editType: any,
    featureIndexes: any,
    editContext: any
  ) {
    this.polygonDataSubject$.next({
      updatedData,
      editType,
      featureIndexes,
      editContext
    });
  }

  buildDefaultFreeFormPolygonFromPoint(point: Point) {
    this.polygonSteps = this.userPolygonSettings?.steps
      ? this.userPolygonSettings.steps
      : this.polygonStepsDefaults;
    this.polygonRadiusInKilometers = this.userPolygonSettings
      ?.radiusInKilometers
      ? this.userPolygonSettings.radiusInKilometers
      : this.polygonRadiusInKilometersDefaults;

    var center = [point.longitude, point.latitude];
    var radius = this.polygonRadiusInKilometers;
    var options = {
      steps: this.polygonSteps,
      units: 'kilometers'
    };
    var tfeature = turfHelper.featureCollection([
      turfCircle.default(turfHelper.point(center), radius, options)
    ]);

    this.polygonBuiltOnPoint = point;

    return {
      visible: true,
      data: tfeature,
      mode: ModifyMode,
      selectedFeatureIndexes: [0]
    };
  }

  saveFreeformPolygon(geoJson: string) {
    this.confirmAddEditPolygon
      ? this.addPolygonToExistingLocation(geoJson)
      : this.addPolygonWithNewLocation(geoJson);
  }

  private addPolygonWithNewLocation(geoJson: string) {
    this.locatorStore$.dispatch(
      addingPolygonConfirmAttempt({
        location: this.polygonBuiltOnPoint,
        geoJson: geoJson
      })
    );
  }

  private addPolygonToExistingLocation(geoJson: string) {
    this.editingExistingPolygonShape
      ? this.locatorStore$.dispatch(
          editLocatorPolygonShapeConfirm({
            geoJson: geoJson
          })
        )
      : this.locatorStore$.dispatch(
          addingPolygonConfirmAttempt({
            location: this.polygonBuiltOnPoint,
            geoJson: geoJson
          })
        );
  }
}
