
// Take from Amplify generted aws-exports.js and parameterised to work with
// different deployment environments.

import { environment } from './environments/environment';

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: environment.userPoolId,
  aws_user_pools_web_client_id: environment.webClientId,
  oauth: {
    domain: environment.cognitoAuthDomain,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: environment.redirectSignIn,
    redirectSignOut: environment.redirectSignOut,
    responseType: 'token',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'FAMILY_NAME', 'GIVEN_NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;
