import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';

@Component({
  selector: 'atlas-add-edit-scenario-validation-dialog',
  templateUrl: './add-edit-scenario-validation-dialog.component.html',
  styleUrls: ['./add-edit-scenario-validation-dialog.component.less']
})
export class AddEditScenarioValidationDialogComponent {
  dialogMode: DialogMode;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      headerText: string;
      validationMessage: string;
      targetScenario: string;
    }
  ) {}
}
