import { Component } from '@angular/core';
import { LayoutService } from '../atlas-layout/services/layout.service';

@Component({
  selector: 'atlas-panel-header',
  templateUrl: './atlas-panel-header.component.html',
  styleUrls: ['./atlas-panel-header.component.less']
})
export class AtlasPanelHeaderComponent {

  constructor(public layoutService: LayoutService) { }

}
