<atlas-single-button-dialog buttonText="Cancel" [headerText]="data.headerText">
  <form formContent>
    <div style="margin-top: 10px" formContent>
      <div class="list-container">
        <mat-list *ngIf="data.validationMessages.length > 0">
          <mat-list-item *ngFor="let msg of data.validationMessages">
            <div mat-line>
              {{ msg }}
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </form>
</atlas-single-button-dialog>
