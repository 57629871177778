import { Store } from '@ngrx/store';
import { Directive, ElementRef } from '@angular/core';
import * as fromProfilerUIStore from 'src/app/profiler-store';
import { DisableUI } from './disable-ui';

@Directive({
  selector: '[atlasStatusScreenDisableUI]'
})
export class AtlasStatusScreenDisableUIDirective extends DisableUI {
  constructor(
    private el: ElementRef,
    private profilerStore$: Store<fromProfilerUIStore.State>
  ) {
    super(
      el,
      profilerStore$.select(
        (state) =>
          fromProfilerUIStore.getImportStatusMessage(state) !==
            'ReportAggregateCompleted' &&
          fromProfilerUIStore.getProfilerLibraryId(state) !== 0
      )
    );
  }
}
