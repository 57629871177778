import { BoundaryLayer } from './boundary-layer';

import { AtlasIconLayer } from './icon-layer';
import { PointLayer } from './point-layer';

import { BoundaryTileSetLayer } from './boundary-tileset-layer';
import { PinDropLayer } from './pin-drop-layer';
import { ThematicIconLayer } from './thematic-icon-layer';
import { ThematicPointLayer } from './thematic-point-layer';
import { ThematicBoundaryLayer } from './thematic-boundary-layer';
import { ThematicBoundaryTileSetLayer } from './thematic-boundary-tileset-layer';
import { PointTileSetLayer } from './point-tileset-layer';
import { IconTileSetLayer } from './icon-tileset-layer';
import { ThematicPointTileSetLayer } from './thematic-point-tileset-layer';
import { ThematicIconTileSetLayer } from './thematic-icon-tileset-layer';

export const layers = [
  BoundaryLayer,
  PointLayer,
  BoundaryTileSetLayer,
  PointTileSetLayer,
  IconTileSetLayer,
  ThematicBoundaryLayer,
  ThematicIconLayer,
  ThematicPointLayer,
  ThematicBoundaryTileSetLayer,
  ThematicPointTileSetLayer,
  ThematicIconTileSetLayer,
  AtlasIconLayer,
  PinDropLayer
];
