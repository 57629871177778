import { SpatialModellerUIEffects } from './spatial-modeller-ui-.effects';
import { UsageEffects } from '../../../reporting/store/effects/usage.effects';
import { AppFeatureUIEffects } from './app-feature-ui.effects';
import { LayerManagementEffects } from './layer-management.effects';
import { LoadingOverlayEffects } from './loading-overlay.effect';
import { TenantManangementEffects } from './tenant-management.effects';
import { SnackbarEffects } from './snack-bar.effects';
import { UserSettingsEffects } from './user-settings.effects';
import { UserUsageEffects } from './user-usage.effects';
import { DataUpdateEffects } from './data-update.effects';

export const effects = [
  UsageEffects,
  SpatialModellerUIEffects,
  AppFeatureUIEffects,
  LayerManagementEffects,
  LoadingOverlayEffects,
  TenantManangementEffects,
  UserSettingsEffects,
  UserUsageEffects,
  SnackbarEffects,
  DataUpdateEffects
];
