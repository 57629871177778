<div class="tab-padding">
  <div class="title"></div>
  <div class="seperate">
    <atlas-user-geocoded-records-usage-summary
      [dataSource]="(userUsageSummary$ | async)!"
    ></atlas-user-geocoded-records-usage-summary>
    <atlas-company-geocoded-records-usage-summary
      class="company-usage-padding"
      [dataSource]="(companyUsageSummary$ | async)!"
    ></atlas-company-geocoded-records-usage-summary>
  </div>

  <div *ngIf="layoutService.isDesktopView$ | async">
    <span class="link" (click)="onToggleGridChart()">
      <u>{{ showGrid ? 'View Chart' : 'View Grid' }}</u>
    </span>

    <div class="chart-grid-height">
      @if (showGrid) {
      <atlas-users-geocoded-records-usage-list
        class="title-padding"
        [dataSource]="allUsersUsage"
      ></atlas-users-geocoded-records-usage-list>
      } @else {
      <div class="title-padding chart-container">
        <atlas-geocoded-records-usage-chart></atlas-geocoded-records-usage-chart>
      </div>
      }
    </div>
  </div>
</div>
