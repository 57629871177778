import { createAction, props } from '@ngrx/store';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { MapLayerTemplate } from '../../models/map-layer-template';

export const locatorFeatureClicked = createAction(
  '[Main Toolbar] Locator Clicked',
  props<{ locatorShapeType: LocatorShapeTypes }>()
);

export const locatorLayersSucceeded = createAction(
  '[Locator Layers loading] Locator Layers succeeded',
  props<{
    locatorShapeType: LocatorShapeTypes | null;
    locatorMapLayerTemplates: MapLayerTemplate[];
  }>()
);

export const resetHasLocatorFeatureLoaded = createAction(
  '[OnLogOut] Reset Locator ShapeType OnLogOut'
);

export const addingEditingPolygonAttempt = createAction(
  '[Locator Add Catchment buttons] Adding Editing Polygon Attempt'
);

export const addingEditingPolygonComplete = createAction(
  '[Locator Add Catchment buttons] Adding Editing Polygon Complete'
);

export const addingEditingPolygonCancelled = createAction(
  '[Locator Add Catchment buttons] Adding Editing Polygon Cancelled'
);

export const addingPolygonCancelled = createAction(
  '[Locator Add Catchment buttons] Adding Polygon Cancelled'
);

export const addingNewPolygonAttempt = createAction(
  '[Locator Library Effect] Adding New Polygon Attempt'
);

export const addingNewPolygonComplete = createAction(
  '[Locator Library Effect] Adding New Polygon Complete'
);

export const generatingCatchmentsAttempt = createAction(
  '[Locator Location Shape List] Generating Catchments Attempt'
);

export const generatingCatchmentsComplete = createAction(
  '[Locator Location Shape List] Generating Catchments Complete'
);
