import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import {
  applyFilter,
  applySort,
  ListSortOptions,
  SearchAndSortValues,
  SortValuesArray
} from 'src/app/shared/utils/list-utils';

@Component({
  selector: 'atlas-swap-library-form',
  styleUrls: ['../../less/swap-library-form.component.less'],
  templateUrl: 'swap-library-form.component.html'
})
export class SwapLibraryFormComponent implements OnInit {
  private _userLibraries: LocatorLibrary[] = [];
  private selectedSortOption: ListSortOptions = 'EditedDesc';

  @Output() switchClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Input() currentLibraryId: number;

  @Input()
  set userLibraries(newValue: LocatorLibrary[]) {
    this._userLibraries = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
    this.librariesLoading = false;
  }
  get userLibraries(): LocatorLibrary[] {
    return this._userLibraries;
  }
  swapLibraryForm: UntypedFormGroup;

  dataSource: LocatorLibrary[];
  librariesLoading = true;

  sortOptions = SortValuesArray;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: '',
      sort: 'EditedDesc'
    } as SearchAndSortValues;
    this.swapLibraryForm = this.createForm(initialValue);

    this.librariesLoading = true;
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter],
      sort: [initialLibraryValue.sort]
    });
  }

  onSwitchToLibrary(libraryId: number): void {
    this.switchClicked.emit(libraryId);
  }

  onSortChanged(evt: any) {
    this.selectedSortOption = evt.value;
    this.dataSource = applySort(evt.value, this.dataSource);
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this._userLibraries);
  }

  onDeleteLibrary(libraryId: number) {
    this.deleteClicked.emit(
      this.dataSource.find((libary) => libary.id === libraryId)
    );
  }
}
