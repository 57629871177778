import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUIStore from 'src/app/core/store';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { delay, map } from 'rxjs/operators';
import { LocatorReportTypes } from 'src/app/locator/types/locator-report.types';

@Component({
  selector: 'atlas-locator-reports',
  templateUrl: './locator-reports.component.html',
  styleUrls: ['./locator-reports.component.less']
})
export class LocatorReportsComponent {
  selectedQueryItem = new LocatorLofiQueryResult();

  selectedShapes$ = this.locatorStore$
    .select(fromLocatorStore.getSelectedShapes)
    .pipe(delay(100));

  isLofiReportAvailable$ = this.locatorStore$.select(
    fromLocatorStore.getIsLofiReportAvailable
  );

  summaryStatsReportAvailable$ = this.locatorStore$
    .select(fromLocatorStore.getLocatorPowerBiReports)
    .pipe(
      map((pbiReportList) =>
        pbiReportList.some(
          (pbiReport) =>
            pbiReport.reportType === LocatorReportTypes.Summary_Stats
        )
      )
    );

  showSummaryStatsReport$ = this.UIStore$.select(
    fromUIStore.showSummaryStatsReport
  );

  constructor(
    private locatorStore$: Store<fromLocatorStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}
}
