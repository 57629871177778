<div
  *ngFor="
    let legendInfo of layer.props?.legendInfo;
    index as i;
    trackBy: trackByFn
  "
  class="legend-detail"
>
  <div
    class="layer-legend-container"
    *ngIf="legendInfo?.mapLayerType == 'Boundary'"
  >
    <div>
      <span
        class="boundary-legend-icon"
        [style.background-color]="legendInfo.fillColor"
        [style.border-color]="legendInfo.lineColor"
        [style.border-width]="legendInfo.lineWidth + 'px'"
      >
      </span>
    </div>
    <div>
      <span class="boundary-legend-text legend-text"
        >{{ legendInfo.text }}
      </span>
    </div>
  </div>

  <div
    class="layer-legend-container"
    *ngIf="legendInfo?.mapLayerType == 'Line'"
  >
    <div>
      <span
        class="line-legend-icon"
        [style.background-color]="legendInfo.fillColor"
        [style.border-color]="legendInfo.lineColor"
        [style.border-width]="legendInfo.lineWidth + 'px'"
      >
      </span>
    </div>
    <div>
      <span class="boundary-legend-text legend-text"
        >{{ legendInfo.text }}
      </span>
    </div>
  </div>

  <div
    class="layer-legend-container"
    *ngIf="legendInfo?.mapLayerType == 'Point'"
  >
    <div>
      <span
        class="point-legend-icon"
        [style.background-color]="legendInfo.fillColor"
        [style.border-color]="legendInfo.lineColor"
      >
      </span>
    </div>
    <div>
      <span class="point-legend-text legend-text">{{ legendInfo.text }}</span>
    </div>
  </div>

  <div
    class="layer-legend-container"
    *ngIf="
      legendInfo?.mapLayerType == 'Icon' ||
      legendInfo?.mapLayerType == 'InSiteEverywhereMapPin'
    "
  >
    <div>
      <span class="icon-legend-icon">
        <img
          class="icon-size"
          [style.filter]="legendInfo.iconFilter"
          [attr.src]="legendInfo.iconUrl"
          [style.width]="legendInfo.iconWidth"
          [style.height]="legendInfo.iconHeight"
        />
      </span>
    </div>

    <div>
      <span class="icon-legend-text legend-text">{{ legendInfo.text }}</span>
    </div>
  </div>

  <div
    class="layer-legend-container"
    *ngIf="legendInfo?.mapLayerType == 'Label'"
  >
    <div>
      <span
        class="boundary-legend-icon"
        [style.background-color]="legendInfo.labelBackground"
        [style.color]="legendInfo.fillColor"
        [style.border-color]="legendInfo.labelBackground"
        [style.border-width]="legendInfo.lineWidth + 'px'"
        style="padding-left: 5px"
      >
        Abc
      </span>
    </div>
    <div>
      <span class="boundary-legend-text legend-text"
        >{{ legendInfo.text }}
      </span>
    </div>
  </div>
</div>
