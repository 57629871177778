import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromProfilerStore from 'src/app/profiler-store';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { AddProfilerLibraryFormComponent } from '../add-profiler-library-form/add-profiler-library-form.component';
import { createProfilerLibraryDialogSaveClicked } from 'src/app/profiler-store/actions/profiler-library.actions';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'atlas-add-profiler-library-dialog',
  templateUrl: './add-profiler-library-dialog.component.html',
  styleUrls: ['./add-profiler-library-dialog.component.less']
})
export class AddProfilerLibraryDialogComponent {
  dialogMode: DialogMode;
  userLibraries: string[] = [];
  private subscription = new Subscription();
  constructor(
    private profilerService: ProfilerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      library: ProfilerLibrary;
      affirmativeButtonText: string;
    },
    private dialogRef: MatDialogRef<AddProfilerLibraryDialogComponent>,
    private store$: Store<fromProfilerStore.State>
  ) {}

  hasProfilerLimitBeenExceededResult$: Observable<boolean> = this.store$.select(
    fromProfilerStore.hasProfilerLimitBeenExceededResult
  );

  ngOnInit() {
    this.hasProfilerLimitBeenExceeded();
    this.getAllLibraries();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  hasProfilerLimitBeenExceeded() {
    this.store$.dispatch(
      fromProfilerStore.ProfilerImportActions.hasProfilerLimitBeenExceededAttempt()
    );
  }
  private getAllLibraries() {
    this.subscription.add(
      this.profilerService
        .getAllProfilerLibraries()
        .subscribe((libraries: ProfilerLibrary[]) => {
          this.userLibraries = libraries.map((library) => library.name);
        })
    );
  }

  onSaveClicked(addLibraryFormComponent: AddProfilerLibraryFormComponent) {
    const newLibrary = addLibraryFormComponent.addLibraryForm.value;
    if (addLibraryFormComponent.addLibraryForm.valid) {
      this.store$.dispatch(
        createProfilerLibraryDialogSaveClicked({
          libraryRequest: {
            name: newLibrary.libraryName,
            file: addLibraryFormComponent.selectedFile
          }
        })
      );

      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: addLibraryFormComponent.addLibraryForm.controls.libraryName.value
      });
    }
  }
}
