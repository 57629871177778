<div *ngIf="queryItem.isGraph">
  <div
    style="text-align: center; padding-top: 20px"
    *ngIf="!allowedCharts.includes(graphConfig?.chartType)"
  >
    <mat-icon>bar_chart</mat-icon>
    <h4>The configured chart type is not valid</h4>
    <h4>{{ graphConfig?.chartType }}</h4>
  </div>

  <div
    *ngIf="allowedCharts.includes(graphConfig?.chartType)"
    style="width: 100%"
    [ngStyle]="{ 'height.px': height }"
  >
    <ngx-charts-bar-horizontal
      *ngIf="graphConfig?.chartType === 'bar-horizontal'"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [schemeType]="schemeType"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [gradient]="graphConfig.gradient"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [legendPosition]="graphConfig.legendPosition"
      [showXAxisLabel]="graphConfig.showXAxisLabel"
      [showYAxisLabel]="graphConfig.showYAxisLabel"
      [xAxisLabel]="graphConfig.xAxisLabel"
      [yAxisLabel]="graphConfig.yAxisLabel"
      [tooltipDisabled]="graphConfig.tooltipDisabled"
      [showGridLines]="graphConfig.showGridLines"
      [barPadding]="graphConfig.barPadding"
      [roundDomains]="graphConfig.roundDomains"
      [roundEdges]="graphConfig.roundEdges"
      [noBarWhenZero]="graphConfig.noBarWhenZero"
      [showDataLabel]="graphConfig.showDataLabel"
      [trimXAxisTicks]="graphConfig.trimXAxisTicks"
      [trimYAxisTicks]="graphConfig.trimYAxisTicks"
      [rotateXAxisTicks]="graphConfig.rotateXAxisTicks"
      [maxXAxisTickLength]="graphConfig.maxXAxisTickLength"
      [maxYAxisTickLength]="graphConfig.maxYAxisTickLength"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-bar-horizontal>

    <ngx-charts-bar-vertical
      *ngIf="graphConfig?.chartType === 'bar-vertical'"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [schemeType]="schemeType"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [gradient]="graphConfig.gradient"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.showXAxisLabel"
      [showYAxisLabel]="graphConfig.showYAxisLabel"
      [xAxisLabel]="graphConfig.xAxisLabel"
      [yAxisLabel]="graphConfig.yAxisLabel"
      [tooltipDisabled]="graphConfig.tooltipDisabled"
      [showGridLines]="graphConfig.showGridLines"
      [barPadding]="graphConfig.barPadding"
      [roundDomains]="graphConfig.roundDomains"
      [roundEdges]="graphConfig.roundEdges"
      [noBarWhenZero]="graphConfig.noBarWhenZero"
      [showDataLabel]="graphConfig.showDataLabel"
      [trimXAxisTicks]="graphConfig.trimXAxisTicks"
      [trimYAxisTicks]="graphConfig.trimYAxisTicks"
      [rotateXAxisTicks]="graphConfig.rotateXAxisTicks"
      [maxXAxisTickLength]="graphConfig.maxXAxisTickLength"
      [maxYAxisTickLength]="graphConfig.maxYAxisTickLength"
      [yScaleMax]="graphConfig.yScaleMax"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-bar-vertical>

    <ngx-charts-pie-chart
      *ngIf="graphConfig?.chartType === 'pie-chart'"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [schemeType]="schemeType"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [legend]="graphConfig.showLegend"
      [legendTitle]="graphConfig.legendTitle"
      [legendPosition]="graphConfig.legendPosition"
      [labels]="graphConfig.showLabels"
      [doughnut]="graphConfig.doughnut"
      [arcWidth]="graphConfig.arcWidth"
      [gradient]="graphConfig.gradient"
      [tooltipDisabled]="graphConfig.tooltipDisabled"
      [tooltipText]="pieTooltipText"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-pie-chart>
    <ngx-charts-advanced-pie-chart
      *ngIf="graphConfig?.chartType === 'advanced-pie-chart'"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [schemeType]="schemeType"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [valueFormatting]="graphConfig.valueFormatting"
      [gradient]="graphConfig.gradient"
      [tooltipDisabled]="graphConfig.tooltipDisabled"
      [tooltipText]="pieTooltipText"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-advanced-pie-chart>
    <ngx-charts-pie-grid
      *ngIf="graphConfig?.chartType === 'pie-grid'"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [view]="pieGridView"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-pie-grid>

    <ngx-charts-tree-map
      *ngIf="graphConfig?.chartType === 'tree-map'"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      [tooltipDisabled]="graphConfig.tooltipDisabled"
      [gradient]="graphConfig.gradient"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-tree-map>
    <ngx-charts-number-card
      *ngIf="graphConfig?.chartType === 'number-card'"
      [results]="queryItem.graphData"
      [animations]="graphConfig.animations"
      [scheme]="setColorScheme(graphConfig.colorScheme)"
      cardColor="#232837"
      emptyColor="#1e222e"
      [view]="numberCardView"
      [customColors]="graphConfig.customColors"
    >
    </ngx-charts-number-card>
  </div>
</div>
