import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { getLocatorLibrarySucceeded } from '../actions/locator-library.actions';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { LocatorSummaryStatsService } from 'src/app/locator/services/locator-summary-stats.service';
import {
  getLocatorSummaryStatsStatusSucceeded,
  pollLocatorSummaryStatsStatusCompleted,
  pollLocatorSummaryStatsStatusFailed,
  pollLocatorSummaryStatsStatusInProgress,
  pollLocatorSummaryStatsStatusStarted,
  triggerCreateSummaryStatsAttempt,
  triggerCreateSummaryStatsErrorOccurred,
  triggerCreateSummaryStatsSucceeded
} from '../actions/locator-summary-stats-actions';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LocatorLibrarySummaryStatsDialogComponent } from 'src/app/core/components/library-management/locator-library-summary-stats-dialog/locator-library-summary-stats-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { LocatorReportTypes } from 'src/app/locator/types/locator-report.types';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
@Injectable()
export class LocatorSummaryStatsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<fromLocatorStore.State>,
    private locatorSummaryStatsService: LocatorSummaryStatsService,
    private appFeatureStateService: AppFeatureStateService,
    private dialogService: DialogService
  ) {}

  getLocatorSummaryStatsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocatorLibrarySucceeded),
      this.appFeatureStateService.allowWhenLocatorFeatureAllowed(),
      switchMap(({ library }) =>
        this.locatorSummaryStatsService
          .getLocatorLibrarySummaryStatsStatus(library.id)
          .pipe(
            map((locatorSummaryStatsStatus) => {
              return getLocatorSummaryStatsStatusSucceeded({
                locatorSummaryStatsStatus
              });
            })
          )
      )
    )
  );

  triggerCreateSummaryStatsAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerCreateSummaryStatsAttempt),
      withLatestFrom(this.store$.select(fromLocatorStore.getLocatorLibraryId)),
      switchMap(([_, libraryId]) =>
        this.locatorSummaryStatsService
          .createLocatorLibrarySummaryStats(libraryId)
          .pipe(
            map(() => {
              return triggerCreateSummaryStatsSucceeded();
            }),
            catchError((error) =>
              of(
                triggerCreateSummaryStatsErrorOccurred({
                  errorOn: 'Error Summary Stats',
                  error: `Summary stats creation process did not started.`
                })
              )
            )
          )
      )
    )
  );

  triggerCreateSummaryStatsSucceded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerCreateSummaryStatsSucceeded),
      withLatestFrom(
        this.store$.select(fromLocatorStore.getLocatorLibraryId),
        this.store$.select(fromLocatorStore.getLocatorPowerBiReports)
      ),
      filter(
        ([_, libraryId, powerBiReportList]) =>
          powerBiReportList != null &&
          powerBiReportList.some(
            (pbiReport) =>
              pbiReport.reportType === LocatorReportTypes.Summary_Stats
          )
      ),
      switchMap(([_, libraryId, powerBiReportList]) => {
        const summaryStatsPowerBiReport = powerBiReportList.find(
          (pbiReport) =>
            pbiReport.reportType === LocatorReportTypes.Summary_Stats
        );
        this.openSummaryStatsDialog(libraryId, summaryStatsPowerBiReport!);
        return this.locatorSummaryStatsService
          .getLocatorLibrarySummaryStatsStatus(libraryId)
          .pipe(
            map((locatorSummaryStatsStatus) => {
              return getLocatorSummaryStatsStatusSucceeded({
                locatorSummaryStatsStatus
              });
            })
          );
      })
    )
  );

  getLocatorSummaryStatsStatusSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLocatorSummaryStatsStatusSucceeded),
      filter(
        ({ locatorSummaryStatsStatus }) =>
          locatorSummaryStatsStatus != null &&
          locatorSummaryStatsStatus.status !== 'SummaryStatsCompleted'
      ),
      map(({ locatorSummaryStatsStatus }) =>
        pollLocatorSummaryStatsStatusStarted({
          libraryId: locatorSummaryStatsStatus!.libraryId
        })
      )
    )
  );

  pollLocatorSummaryStatsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollLocatorSummaryStatsStatusStarted),
      switchMap(({ libraryId }) =>
        this.locatorSummaryStatsService
          .pollForLocatorLibrarySummaryStatsStatus(libraryId)
          .pipe(
            withLatestFrom(
              this.store$.select(fromLocatorStore.getLocatorLibraryId)
            ),
            filter(
              ([locatorSummaryStatsStatus, currentLibraryId]) =>
                locatorSummaryStatsStatus?.libraryId === currentLibraryId
            ),
            map(([locatorSummaryStatsStatus, _]) => {
              if (
                locatorSummaryStatsStatus.status === 'SummaryStatsCompleted'
              ) {
                return pollLocatorSummaryStatsStatusCompleted({
                  locatorSummaryStatsStatus
                });
              } else {
                return locatorSummaryStatsStatus.errorMessage != null
                  ? pollLocatorSummaryStatsStatusFailed({
                      locatorSummaryStatsStatus
                    })
                  : pollLocatorSummaryStatsStatusInProgress({
                      locatorSummaryStatsStatus
                    });
              }
            })
          )
      )
    )
  );

  openSummaryStatsDialog(
    libraryId: number,
    summaryStatsPowerBiReport: LocatorPowerBiReportingInfo
  ) {
    this.dialogService.show(LocatorLibrarySummaryStatsDialogComponent, {
      width: DialogWidth.Medium,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: '',
        headerPrefix: 'Comparison Report',
        affirmativeButtonText: 'Launch Report',
        libraryId,
        summaryStatsPowerBiReport
      },
      disableClose: true
    });
  }
}
