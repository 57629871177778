import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DynamicLayerService } from './dynamic-layer-service';

@Injectable({ providedIn: 'root' })
export class DynamicTilesetService implements DynamicLayerService {
  constructor(private http: HttpClient) {}

  // We are not returning an observable here because we must wait for the response from the server.
  // Don't copy this anywhere else since we always use observables.
  public getDynamicLayerObject(dynamicTilesetEndpoint: string) {
    return this.http.get<any>(dynamicTilesetEndpoint);
  }
}
