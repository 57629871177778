<mat-divider></mat-divider>

<div class="atlas-panel-body">
  <atlas-profiler-reports-panel
    [graphHeight]="250"
  ></atlas-profiler-reports-panel>
</div>

<mat-divider></mat-divider>
<atlas-profiler-reports-panel-buttons></atlas-profiler-reports-panel-buttons>
