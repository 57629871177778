import { Component, Input } from '@angular/core';

import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';

@Component({
  selector: 'atlas-profiler-reports-stats',
  templateUrl: './profiler-reports-stats.component.html',
  styleUrls: ['./profiler-reports-stats.component.less']
})
export class ProfilerReportsStatsComponent {
  @Input() queryItem: ProfilerLofiQueryResult;
  constructor() {}
}
