<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked()"
  (cancelClicked)="onCancelClicked()"
  [saveDisabled]="isInvalidForm()"
  class="add-edit-dialog-content"
>
  <form [formGroup]="addDescriptionForm" autocomplete="off" formContent>
    <p class="text-padding" *ngIf="data.mode === dialogMode.Add">
      Enter a description to confirm the shape of your polygon, or click cancel
      to make further edits to the shape.
    </p>
    <div *ngIf="data.mode === dialogMode.Edit">
      <p class="text-padding" *ngIf="!data.editingExistingPolygonShape">
        Rename your polygon.
      </p>
      <p class="text-padding" *ngIf="data.editingExistingPolygonShape">
        If you are happy with the shape of your polygon, press confirm, or press
        cancel to continue editing
      </p>
    </div>
    <div *ngIf="!data.editingExistingPolygonShape">
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>Description</mat-label>
        <input
          matInput
          type="text"
          formControlName="description"
          cdkFocusInitial
        />
        <mat-error *ngIf="emptyDescription()">
          Please enter a description of at least 3 characters
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</atlas-add-edit-dialog>
