import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import * as fromScenarioStore from 'src/app/spatial-modeller-store';

@Component({
  selector: 'atlas-delta-summary',
  templateUrl: './delta-summary.component.html',
  styleUrls: ['./delta-summary.component.less']
})
export class DeltaSummaryComponent {
  @Input() disabled: boolean | null = false;
  constructor(
    private store$: Store<fromScenarioStore.State>,
    public layoutService: LayoutService,
    private route: Router
  ) {}

  deltaSummary$ = this.store$.select(fromScenarioStore.getScenarioDeltaSummary);

  onViewDeltaList() {
    this.route.navigate(['/spatial-modeller/delta-management']);
  }
}
