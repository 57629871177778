import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AppFeatureStateService } from 'src/app/shared/services/app-feature-state.service';
import { LoadingOverlayService } from '../../services/loading-overlay.service';
import { initialiseAppAttempt } from '../actions';
import { loadAppFeaturesSucceeded } from '../actions/app-feature-ui.actions';
import {
  locatorFeatureClicked,
  locatorLayersSucceeded
} from '../actions/locator-ui.actions';
import {
  smFeatureClicked,
  smLayersSucceeced
} from '../actions/spatial-modeller-ui.actions';
import {
  profilerFeatureClicked,
  profilerLayersSucceeded
} from '../actions/profiler-ui.actions';

@Injectable()
export class LoadingOverlayEffects {
  constructor(
    public actions$: Actions,
    public loadingOverlayService: LoadingOverlayService,
    private appFeatureStateService: AppFeatureStateService
  ) {}

  showLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initialiseAppAttempt, smFeatureClicked, profilerFeatureClicked),
        tap(() => this.loadingOverlayService.show())
      ),
    { dispatch: false }
  );

  showLoadingForLocatorLayers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locatorFeatureClicked),
        this.appFeatureStateService.allowWhenLocatorFeatureNotLoaded(),
        tap(() => this.loadingOverlayService.show())
      ),
    { dispatch: false }
  );

  hideLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          loadAppFeaturesSucceeded,
          smLayersSucceeced,
          locatorLayersSucceeded,
          profilerLayersSucceeded
        ),
        tap(() => this.loadingOverlayService.hide())
      ),
    { dispatch: false }
  );
}
