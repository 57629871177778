import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, filter, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  createProfilerLibrarySucceeded,
  swapProfilerLibrarySucceeded
} from '../actions/profiler-library.actions';
import {
  PollProfilerImportStatusCompleted,
  PollProfilerImportStatusFailed,
  PollProfilerImportStatusInProgress,
  PollProfilerImportStatusStarted
} from '../actions/profiler-import-status.actions';
import { ProfilerService } from 'src/app/profiler/services/profiler.service';
import { profilerFeatureClicked } from 'src/app/core/store/actions/profiler-ui.actions';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as fromProfilerStore from 'src/app/profiler-store';
import { fileImportHeaderValidationSuccess } from '../actions/profiler-import-validation.actions';
import { of } from 'rxjs';

@Injectable()
export class ProfilerImportStatusEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private profilerService: ProfilerService
  ) {}

  startProfilerStatusImportPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfilerLibrarySucceeded, swapProfilerLibrarySucceeded),
      filter(
        ({ library }) =>
          library.importStatus?.status !== 'ReportAggregateCompleted'
      ),
      switchMap(({ library }) =>
        of(PollProfilerImportStatusStarted({ libraryId: library.id }))
      )
    )
  );

  restartPollingAfterReuploadEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileImportHeaderValidationSuccess),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getProfilerLibraryId)
      ),
      map(([_, libraryId]) => {
        return PollProfilerImportStatusStarted({ libraryId });
      })
    )
  );

  createProfilerLibrarySucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profilerFeatureClicked),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getProfilerImportStatus)
      ),
      filter(
        ([_, importStatus]) =>
          importStatus != null &&
          importStatus.status !== 'ReportAggregateCompleted'
      ),
      map(([_, importStatus]) =>
        PollProfilerImportStatusStarted({ libraryId: importStatus!.libraryId })
      )
    )
  );

  pollProfilerImportStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PollProfilerImportStatusStarted),

      switchMap(({ libraryId }) =>
        this.profilerService.pollForProfilerImportStatus(libraryId).pipe(
          withLatestFrom(
            this.store$.select(fromProfilerStore.getProfilerLibraryId)
          ),
          filter(
            ([importStatus, currentLibraryId]) =>
              importStatus.libraryId === currentLibraryId
          ),
          map(([importStatus, _]) => {
            // console.log('Polled for libraryId: ', importStatus.libraryId);
            // console.log('Current status:', importStatus.status);
            // console.log('Error Message:', importStatus.errorMessage);
            if (importStatus.status === 'ReportAggregateCompleted') {
              return PollProfilerImportStatusCompleted({ importStatus });
            } else {
              return importStatus.errorMessage != null
                ? PollProfilerImportStatusFailed({ importStatus })
                : PollProfilerImportStatusInProgress({ importStatus });
            }
          })
        )
      )
    )
  );
}
