<div
  *ngIf="
    (enableExpandLofiReportPanel$ | async) &&
    (layoutService.isDesktopView$ | async)
  "
>
  <div *ngIf="!hidden" class="lofi-expand-button-container">
    <button
      class="mat-focus-indicator atlas-icon-button mat-flat-button mat-primary icon"
      mat-flat-button
      color="primary"
      aria-label="Street view"
      (click)="showLofiPanel()"
    >
      <mat-icon>bar_chart</mat-icon>
    </button>
  </div>
  <div class="lofi-panel-container" *ngIf="hidden">
    <mat-icon class="lofi-image-close" (click)="hideLofiPanel()"
      >close</mat-icon
    >

    <div class="lofi-panel">
      <!-- remove the extra div when sm gets graphs-->
      <div *ngIf="isSMFeatureSelected$ | async" style="margin-top: 20px">
        <atlas-spatial-modeller-reports-panel>
          *ngIf="isSMFeatureSelected$ |
          async"</atlas-spatial-modeller-reports-panel
        >
      </div>
      <atlas-locator-reports-panel
        [pieGridView]="pieGridView"
        [numberCardView]="numberCardView"
        [graphHeight]="470"
        *ngIf="isLocatorFeatureSelected$ | async"
      ></atlas-locator-reports-panel>
    </div>
  </div>
</div>
