import { Layer } from '../models/layer';

import {
  getH3TileSetLayerData,
  getMapLayerDefaultProperties
} from '../helpers/map-layer-helper';
import { DynamicTilesetService } from '../services/dynamic-tileset.service';
import { MapService } from '../services/map.service';

import { _H3TileLayer } from '@deck.gl/carto/typed';

export class ThematicH3HexagonTileSetLayer extends Layer {
  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicTilesetService: DynamicTilesetService
  ) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties(
      'ThematicH3HexagonTileSet',
      jwtToken
    );

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    // Highlighting the line when moving over the hexagon does not work like the other layers.
    // identifiers and properties are laid out differnetly

    //layerProperties.onHover = (info: any) => {
    //  styleH3TilesetGeometryLines(info, mapService);
    //};
    // properties for highlight the geometry that is hovered border gets thicker and turns black
    //layerProperties.orginalLineColor = layerProperties.getLineColor;
    //layerProperties.highlightColor = [129, 179, 255, 0]; // transparent*/

    await getH3TileSetLayerData(
      dynamicTilesetService,
      layerProperties,
      jwtToken
    );

    return new _H3TileLayer(layerProperties);
  }
}
