import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromProfilerStore from 'src/app/profiler-store';
import { ProfilerReportingFormComponent } from '../profiler-reporting-form/profiler-reporting-form.component';

@Component({
  selector: 'atlas-profiler-reporting-dialog',
  templateUrl: './profiler-reporting-dialog.component.html',
  styleUrls: ['./profiler-reporting-dialog.component.less']
})
export class ProfilerReportingDialogComponent {
  @ViewChild('reportingForm') reportingForm: ProfilerReportingFormComponent;
  @Output() buttonClicked = new EventEmitter();
  dialogMode: DialogMode;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      pbiReportList: ProfilerPowerBiReportingInfo[];
      libraryId: number;
    },
    private profilerStore$: Store<fromProfilerStore.State>
  ) {}

  onLaunchReport() {
    window.open(this.reportingForm.selectedPbiReportUrl, '_blank');
  }

  onShareClicked() {
    navigator.clipboard.writeText(this.reportingForm.selectedPbiReportUrl);
  }

  isSpinnerVisible() {
    return !this.reportingForm
      ? null
      : this.reportingForm?.selectedReport &&
          !this.reportingForm?.selectedPbiReportUrl;
  }

  isTextCopyButtonVisible() {
    return !this.isSpinnerVisible();
  }
}
