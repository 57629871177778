<form [formGroup]="dynamicFormGroup" autocomplete="off">
  <div class="control-container">
    <div *ngFor="let control of dynamicForm">
      <atlas-input
        *ngIf="
          [
            formControlTypes.Textbox,
            formControlTypes.NumericTextbox,
            formControlTypes.DatePicker
          ].includes(control.displayType)
        "
        [control]="control"
      >
      </atlas-input>

      <atlas-select
        *ngIf="control.displayType === formControlTypes.ComboBox"
        [control]="control"
      >
      </atlas-select>

      <atlas-checkbox
        *ngIf="control.displayType === formControlTypes.Checkbox"
        [control]="control"
      >
      </atlas-checkbox>

      <atlas-radio-button
        *ngIf="control.displayType === formControlTypes.Radio"
        [control]="control"
      >
      </atlas-radio-button>

      <atlas-slider-toggle
        *ngIf="control.displayType === formControlTypes.Slider"
        [control]="control"
      >
      </atlas-slider-toggle>

      <atlas-list-selection
        *ngIf="control.displayType === formControlTypes.List"
        [control]="control"
      >
      </atlas-list-selection>
    </div>
  </div>
</form>
