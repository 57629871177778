<div class="chart-title">All Users</div>
<div class="chart-container">
  <ngx-charts-bar-vertical-2d
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [showGridLines]="showGridLines"
    [groupPadding]="1"
  >
  </ngx-charts-bar-vertical-2d>
</div>
