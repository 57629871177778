<div class="control-container" atlasAddNewPolyDisableUI>
  <atlas-catchment-list
    atlasModifyPolyDisableUI
    [location]="selectedLocation$ | async"
    [selectedShapes]="selectedShapes$ | async"
    [areMultipleLocationsSelected]="areMultipleLocationsSelected"
  >
  </atlas-catchment-list>
</div>

<atlas-add-edit-catchment-buttons
  *ngIf="!(addingEditingPolygon$ | async)"
  atlasAddNewPolyDisableUI
  [location]="selectedLocation$ | async"
  [selectedShapes]="selectedShapes$ | async"
  [areMultipleLocationsSelected]="areMultipleLocationsSelected"
>
</atlas-add-edit-catchment-buttons>
<atlas-add-edit-polygon-buttons
  *ngIf="addingEditingPolygon$ | async as addingEditingPolygon"
  [addingEditingPolygon]="addingEditingPolygon"
>
</atlas-add-edit-polygon-buttons>

<mat-divider></mat-divider>
<div
  class="delete-button-padding"
  atlasModifyPolyDisableUI
  atlasAddNewPolyDisableUI
>
  <button
    class="secondary-button delete-button"
    (click)="onDeleteClicked()"
    [disabled]="
      (generatingCatchmentInProgress$ | async) ||
      (isCatchmentReportingReadonlySystem$ | async)
    "
  >
    <mat-icon>delete</mat-icon>
    <div>
      Delete @if (areMultipleLocationsSelected) {Locations} @else {Location}
    </div>
  </button>
</div>
<atlas-locator-properties-panel-buttons></atlas-locator-properties-panel-buttons>
