<div
  class="status-screen mat-app-background"
  matRaised
  *ngIf="
    (isProfilerFeatureSelected$ | async) &&
    !isProfileStatusSucceded() &&
    (getProfilerLibraryId$ | async) !== 0
  "
>
  <span class="header">Creating Profile</span>
  <mat-divider></mat-divider>
  <atlas-profiler-progress
    [progressSteps]="getProgressSteps$ | async"
    [importStatusErrors]="getImportStatusErrors$ | async"
  ></atlas-profiler-progress>

  <div *ngIf="isErrorOccured() | async">
    <button class="cancel-button" (click)="onCancelClicked()">Cancel</button>
  </div>
</div>
