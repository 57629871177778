import { ElementRef } from "@angular/core";
import { Observable } from "rxjs";
import { disableUIClassName } from "./disable-ui-constants";

// Provides common functionality for disabling UI elements based on the
// selector past in to the constructor. Use with directives to attach to
// html elements. The directive must extend this class and provide the
// html element that needs disabling and the selector that returns a
// boolean.
// For example see AtlasAddNewPolyDisableUIDirective.
export class DisableUI {
  private elementTypes = ['input', 'select', 'button'];

  constructor(
    private element: ElementRef,
    private selector: Observable<boolean>
  ) {
    this.selector.subscribe((b) => this.disableUI(b));
  }

  private disableUI(shouldDisable: boolean) {
    shouldDisable
      ? this.disableInput()
      : this.enableInput();
  }

  private disableInput() {
    this.isInput()
      ? this.element.nativeElement.disabled = true
      : this.element.nativeElement.classList.add(disableUIClassName);
  }

  private enableInput() {
    this.isInput()
      ? this.element.nativeElement.disabled = false
      : this.element.nativeElement.classList.remove(disableUIClassName);
  }

  private isInput() {
    return this.elementTypes.includes(this.element.nativeElement.localName);
  }
}