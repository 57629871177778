import {
  getMapLayerDefaultProperties,
  getTileSetLayerData
} from '../helpers/map-layer-helper';
import { Layer } from '../models/layer';
import { MVTLayer } from '@deck.gl/geo-layers';
import { DynamicTilesetService } from '../services/dynamic-tileset.service';
import { MapService } from '../services/map.service';
import { styleBoundaryTilesetGeometryLines } from '../helpers/boundary-geometry-style-helper';

// zoom level may be controlled with the min zoom level needs experimentation
// https://deck.gl/docs/api-reference/geo-layers/tile-layer

export class BoundaryTileSetLayer extends Layer {
  async getLayer(
    jwtToken: string,
    apiBaseUrl: string,
    mapService: MapService,
    dynamicTilesetService: DynamicTilesetService
  ) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties(
      'BoundaryTileSet',
      jwtToken
    );

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    layerProperties.onHover = (info: any) => {
      styleBoundaryTilesetGeometryLines(info, mapService);
    };
    // properties for highlight the geometry that is hovered border gets thicker and turns black
    layerProperties.orginalLineColor = layerProperties.getLineColor;
    layerProperties.highlightColor = [129, 179, 255, 0]; // transparent

    layerProperties = await getTileSetLayerData(
      dynamicTilesetService,
      layerProperties,
      jwtToken,
      apiBaseUrl
    );

    return new MVTLayer(layerProperties);
  }
}
