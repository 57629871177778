import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

@Component({
  selector: 'atlas-delete-profiler-library-confirmation-dialog',
  templateUrl: './delete-profiler-library-confirmation-dialog.component.html',
  styleUrls: ['./delete-profiler-library-confirmation-dialog.component.less']
})
export class DeleteProfilerLibraryConfirmationDialogComponent {
  get headerText() {
    return 'Delete profile';
  }
  constructor(
    private store$: Store<fromProfilerStore.State>,
    private dialogRef: MatDialogRef<DeleteProfilerLibraryConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      library: ProfilerLibrary;
    }
  ) {}
  onDeleteClicked() {
    this.store$.dispatch(
      fromProfilerStore.ProfilerLibraryActions.deleteProfilerLibraryAttempt({
        id: this.data.library.id
      })
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative,
      data: this.data.library.id
    });
  }
}
