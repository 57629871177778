import { Injectable, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AuthModule } from './auth/auth.module';

import { LayoutModule } from '@angular/cdk/layout';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';

import { HomeWorkspaceComponent } from './home-workspace/home-workspace.component';
import { CoreModule } from './core/core.module';
import { AtlasMappingModule } from './shared/atlas-mapping/atlas-mapping.module';
import { MaterialComponentsModule } from './shared/material/material-components.module';
import { AtlasExpansionPanelComponent } from './shared/atlas-layout/components/atlas-expansion-panel/atlas-expansion-panel.component';
import { AtlasPropertiesLayoutComponent } from './shared/atlas-layout/components/atlas-properties-layout/atlas-properties-layout.component';
import { LayoutService } from './shared/atlas-layout/services/layout.service';
import { AtlasDynamicFormModule } from './shared/atlas-dynamic-form/atlas-dynamic-form.module';
import { AtlasGazetteerModule } from './shared/atlas-gazetteer/atlas-gazetteer.module';
import { AtlasStyleGuideModule } from './dev/dev.module';
import { AtlasSpatialModellerStoreModule } from './spatial-modeller-store/spatial-modeller-store.module';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers';
import { environment } from 'src/environments/environment';
import { AtlasSpatialModellerModule } from './spatial-modeller/spatial-modeller.module';
import { AtlasReportingModule } from './reporting/reporting.module';
import { AtlasLocatorModule } from './locator/locator.module';
import { AtlasLocatorStoreModule } from './locator-store/locator-store.module';
import { AtlasProfilerModule } from './profiler/profiler.module';
import { AtlasProfilerStoreModule } from './profiler-store/profiler-store.module';

@Injectable()
export class AtlasHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

// Modification of the Mat-Form-Field prototype to recalculate
// the outline gap to get adapted to the font size of the inner label
// TODO: Commented out for Angular 16 upgrade. Needs a revisit
// patchMatFormFieldOutlineGap();

@NgModule({
  declarations: [
    AppComponent,
    HomeWorkspaceComponent,
    AtlasPropertiesLayoutComponent,
    AtlasExpansionPanelComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CdkAccordionModule,
    ReactiveFormsModule,
    AtlasMappingModule,
    LayoutModule,
    HammerModule,
    MaterialComponentsModule,
    AtlasDynamicFormModule,
    AtlasGazetteerModule,
    CoreModule,
    AtlasStyleGuideModule,
    AtlasSpatialModellerModule,
    AtlasSpatialModellerStoreModule,
    AtlasReportingModule,
    AtlasLocatorModule,
    AtlasLocatorStoreModule,
    AtlasProfilerModule,
    AtlasProfilerStoreModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [] // NB this module must always come after the StoreModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AtlasHammerConfig
    },
    LayoutService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
