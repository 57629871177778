<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="swap profile"
    (click)="onSwapLibrary()"
    matTooltip="Switch to another profile"
    [matTooltipShowDelay]="1000"
    [disabled]="currentLibraryId === 0 || (librariesCount$ | async)"
  >
    <mat-icon class="swap-button">swap_horiz</mat-icon>
  </button>
</div>
