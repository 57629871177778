import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';

@Component({
  selector: 'atlas-spatial-modeller-reports-panel',
  templateUrl: './sm-reports-panel.component.html',
  styleUrls: ['./sm-reports-panel.component.less']
})
export class SpatialModellerReportsPanelComponent {
  areTestStatsAvailable$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getAreTestStatsAvailable
  );

  stats$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getSpatialModellerStats
  );

  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );

  constructor(
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private gazetteerStore$: Store<fromGazetteerStore.State>
  ) {}
}
