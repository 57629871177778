import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ProfilerLibrary } from '../model/profiler-library';
import { ProfilerPowerBiReportingInfo } from '../model/profiler-power-bi-reporting-info';
import { ProfilerLofiQueryResult } from '../model/profiler-lofi-report';
import { ProfilerBaseClassification } from '../model/profiler-base-classification';
import { ProfilerImportStatus } from '../model/profiler-import-status';
import { timer } from 'rxjs';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import * as appFeatureStore from 'src/app/core/store';
import * as profilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class ProfilerService {
  private atlasProfilerLibrariesApiUrl = `${environment.baseUrl}api/profiler-libraries/`;
  private atlasProfilerReportingInfoApiUrl = `${environment.baseUrl}api/profiler/`;
  private atlasProfilerLibrariesCountApiUrl = `${environment.baseUrl}api/profiler-libraries-count/`;
  statusPollDelay = 5 * 1000;

  profilerFeatureSelected: boolean = false;
  currentProfilerLibraryId: number;

  constructor(
    private http: HttpClient,
    private appFeatureStore$: Store<appFeatureStore.State>,
    private profilerStore$: Store<profilerStore.State>
  ) {
    this.appFeatureStore$
      .select(appFeatureStore.isProfilerFeatureSelected)
      .subscribe(
        (isProfilerFeatureSelected) =>
          (this.profilerFeatureSelected = isProfilerFeatureSelected)
      );

    this.profilerStore$
      .select(profilerStore.getProfilerLibraryId)
      .subscribe((libraryId) => (this.currentProfilerLibraryId = libraryId));
  }

  getAllProfilerLibraries() {
    return this.http.get<ProfilerLibrary[]>(
      `${this.atlasProfilerLibrariesApiUrl}`
    );
  }

  getLibrariesCount() {
    return this.http.get<boolean>(`${this.atlasProfilerLibrariesCountApiUrl}`);
  }

  createLibrary(libraryRequest: { name: string }) {
    return this.http.post<ProfilerLibrary>(
      `${this.atlasProfilerLibrariesApiUrl}`,
      libraryRequest
    );
  }

  updateProfilerLibrary(
    id: number,
    libraryRequest: {
      name: string;
    }
  ) {
    return this.http.put<ProfilerLibrary>(
      `${this.atlasProfilerLibrariesApiUrl}${id}`,
      libraryRequest
    );
  }

  getProfilerLibraryById(id: number) {
    return this.http.get<ProfilerLibrary>(
      `${this.atlasProfilerLibrariesApiUrl}${id}`
    );
  }

  getLastedEditedProfilerLibrary() {
    return this.http.get<ProfilerLibrary>(
      `${this.atlasProfilerLibrariesApiUrl}last-edited`
    );
  }

  deleteProfilerLibrary(libraryId: number) {
    return this.http.delete(`${this.atlasProfilerLibrariesApiUrl}${libraryId}`);
  }

  getProfilerPowerBiReportingInfo() {
    return this.http.get<ProfilerPowerBiReportingInfo>(
      `${this.atlasProfilerReportingInfoApiUrl}reporting-info`
    );
  }

  getProfilerPowerBiReportCollection() {
    return this.http.get<ProfilerPowerBiReportingInfo[]>(
      `${this.atlasProfilerReportingInfoApiUrl}pbi-reports`
    );
  }

  getLofiReportForLibrary(libraryId: number) {
    return this.http.get<ProfilerLofiQueryResult[]>(
      `${this.atlasProfilerLibrariesApiUrl}${libraryId}/lofi-report`
    );
  }

  generateLofiReportForLibrary(
    libraryId: number,
    baseId: number,
    classificationId: number
  ) {
    return this.http.post<ProfilerLofiQueryResult[]>(
      `${this.atlasProfilerLibrariesApiUrl}${libraryId}/lofi-report`,
      { ClassificationId: classificationId, BaseId: baseId }
    );
  }

  getBaseAndClassification() {
    return this.http.get<ProfilerBaseClassification>(
      `${this.atlasProfilerLibrariesApiUrl}base-classification`
    );
  }

  getImportProfileStatus(libraryId: number) {
    return this.http.get<ProfilerImportStatus>(
      `${this.atlasProfilerLibrariesApiUrl}${libraryId}/import-summary`
    );
  }

  pollForProfilerImportStatus(libraryId: number) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.getImportProfileStatus(libraryId).pipe(
          map((importStatusResponse) => importStatusResponse)
        )
      ),
      takeWhile(
        (importStatusResponse: ProfilerImportStatus) =>
          this.shouldPollingContinue(libraryId, importStatusResponse),
        true
      )
    );
  }

  private shouldPollingContinue(
    libraryId: number,
    importStatus: ProfilerImportStatus
  ) {
    return (
      importStatus.status !== 'ReportAggregateCompleted' &&
      this.profilerFeatureSelected &&
      libraryId === this.currentProfilerLibraryId
    );
  }
}
