import { Injectable } from '@angular/core';

// Dev extreeme allows the grid state to be stored to local storage
// https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/StatePersistence/Angular/Light/
// The state in formation store covers a huge number of things including selection and focus row, these are things that are data driven in the the Titan app
// https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/stateStoring/
// The purpose of this custom service is to stor only key pieces of state for he grid
@Injectable({ providedIn: 'root' })
export class AtlasDxGridStateService {
  loadState = (storageKey: string) => {
    let hasState = localStorage.getItem(storageKey);
    // only return the column information
    if (hasState) {
      let state = JSON.parse(hasState);
      return state ? { columns: state.columns } : {};
    }
    return {};
  };

  saveState = (storageKey: string, state: any) => {
    // only save the column information
    let onlyColumnState = { columns: state.columns };
    localStorage.setItem(storageKey, JSON.stringify(onlyColumnState));
  };
}
