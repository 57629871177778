<mat-error *ngIf="requiredError()">
  {{control.displayText}} is required
</mat-error>
<mat-error *ngIf="minLengthError()">
  {{control.displayText}} must be at least {{control.strMinLength}} characters
</mat-error>
<mat-error *ngIf="maxLengthError()">
  {{control.displayText}} must be at most {{control.strMaxLength}} characters
</mat-error>
<mat-error *ngIf="minError()">
  {{control.displayText}} must be at least {{control.minValue}}
</mat-error>
<mat-error *ngIf="maxError()">
  {{control.displayText}} must be at most {{control.maxValue}}
</mat-error>