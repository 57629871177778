import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

//https:medium.com/ngconf/filtering-types-with-correct-type-inference-in-rxjs-f4edf064880d
function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
export function isNotNullOrUndefined<T>() {
  return (source$: Observable<null | undefined | T>) =>
    source$.pipe(filter(inputIsNotNullOrUndefined));
}
