<div class="header-div">
  <span class="header-text"
    >{{ headerText }}
    @if(showProgressBar){
    {{ currentStage }}/{{ noOfStages }}
    }
  </span>
  <ng-content></ng-content>
</div>

@if(showProgressBar){
<atlas-progress-bar [noOfStages]="noOfStages" [currentStage]="currentStage">
</atlas-progress-bar>
}
<mat-divider></mat-divider>
