export const pinDropLayerIdentifier = 'Pin_Drop_LAYER';

export const smModelTestResultsLayerIdentifier = 'SM_MODEL_TEST_RESULTS';
export const smScenarioBaseLayerIdentifier = 'SM_SCENARIO_BASE';
export const smScenarioBaseTilesetLayerIdentifier = 'SM_SCENARIO_BASE_TILESET';
export const smScenarioDeltaLayerIdentifier = 'SM_SCENARIO_DELTA';
export const smScenarioDeltaTilesetLayerIdentifier =
  'SM_SCENARIO_DELTA_TILESET';
export const smModelResultsDriveTimeLayerIdentifier =
  'SM_MODEL_RESULTS_DRIVETIME';
export const smModelResultsCircleLayerIdentifier = 'SM_MODEL_RESULTS_Circle';
export const locatorDataShapesLayerIdentifier = 'LOCATOR_LIBRARY_DATA_SHAPES';
export const locatorDataShapeSpatialFunctionLayerIdentifier =
  'LOCATOR_LIBRARY_DATA_SHAPES_SPATIAL_FUNCTION';
export const locatorEditPolygonIdentifier = 'LOCATOR_EDIT_POLYGON_LAYER';
export const locatorDataShapeIntersectLayerIdentifier =
  'LOCATOR_LIBRARY_DATA_SHAPES_INTERSECT';
export const selectionLayerIdentifier = 'SELECTION_LAYER';
export const draggableSelectionLayerIdentifier = 'DRAGGABLE_SELECTION_LAYER';

export const locatorContourLayerIndentifier = 'LOCATOR_CONTOUR_LAYER';

export const profilerLocationsLayerIdentifier = 'PROFILER_LOCATIONS_LAYER';

export const locatorLibraryLocationsTilesetLayerIdentifier =
  'LOCATOR_LIBRARY_LOCATIONS_TILESET';

export const measureDistanceIdentifier = 'MEASURE_DISTANCE_LAYER';

export const systemLayerIdentifiers = [
  pinDropLayerIdentifier,
  selectionLayerIdentifier,
  draggableSelectionLayerIdentifier,
  locatorEditPolygonIdentifier,
  measureDistanceIdentifier
];

export const systemTilesetLayerIdentifiers = [
  smScenarioBaseTilesetLayerIdentifier,
  smScenarioDeltaTilesetLayerIdentifier,
  locatorLibraryLocationsTilesetLayerIdentifier
];

export const INITIAL_VIEW_STATE = {
  longitude: 1.5491,
  latitude: 53.8008,
  zoom: 5
};
