import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import { Stats } from 'src/app/spatial-modeller/models/stats';

export const getSpatialModellerStats = createSelector(
  fromFeature.getSpatialModellerStatsState,
  (state) => state.stats
);

export const getAreTestStatsAvailable = createSelector(
  getSpatialModellerStats,
  areTestStatsAvailable
);

function areTestStatsAvailable(stats: Stats[] | null): boolean {
  return !!stats && stats.length > 0;
}
