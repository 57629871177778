<h3>Move selected locations to scenario</h3>

<form [formGroup]="moveForm" autocomplete="off">
  <div class="outer-container">
    <div class="container">
      <div class="scenario-list">
        <mat-form-field appearance="outline" hideRequiredMarker="true">
          <mat-label>Search existing scenarios</mat-label>
          <input
            matInput
            type="text"
            formControlName="scenario"
            placeholder="Search existing scenarios"
            (keyup)="onFilterChanged($event)"
            [matAutocomplete]="auto"
          />

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let scenario of dataSource" [value]="scenario">
              {{ scenario.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <button
        class="no-shadow"
        mat-raised-button
        color="primary"
        (click)="onMove($event)"
        [disabled]="
          moveForm.invalid ||
          !hasItemsToMove ||
          !validateScenarioName(moveForm.controls.scenario.value)
        "
      >
        Move location(s)
      </button>
    </div>

    <div>
      <button
        mat-flat-button
        type="button"
        class="secondary-button no-right-margin"
        (click)="onBack($event)"
      >
        <mat-icon> chevron_left</mat-icon> Back
      </button>
    </div>
  </div>
</form>
