<div class="tab-padding">
  <div class="title"></div>
  <div class="chart-grid-height">
    <atlas-users-login-usage-list
      class="title-padding"
      *ngIf="layoutService.isDesktopView$ | async"
      [dataSource]="userUsageList"
    ></atlas-users-login-usage-list>
  </div>
</div>
