@if(closeOnSave){<button
  mat-flat-button
  class="dialog-button"
  color="primary"
  type="submit"
  mat-dialog-close
  (click)="clicked.emit()"
  [disabled]="disabled"
>
  {{ label }}
</button>
} @else{
<button
  mat-flat-button
  class="dialog-button"
  color="primary"
  type="submit"
  (click)="clicked.emit()"
  [disabled]="disabled"
>
  {{ label }}
</button>
}
