import { createSelector } from '@ngrx/store';
import * as fromLocatorLibrarySummaryStats from '../reducers/locator-library-summary-stats.reducer';
import * as fromFeature from '../reducers';
import {
  buildSummaryStatsProgressSteps,
  LocatorSummaryStatsStatusTypes
} from 'src/app/locator/helpers/locator-status-helper';
import { LocatorSummaryStatsStatusProgressStep } from 'src/app/locator/models/locator-status-progress-step';

export const getLocatorLibrarySummaryStatsStatus = createSelector(
  fromFeature.getLocatorLibrarySummaryStatsState,
  fromLocatorLibrarySummaryStats.getLocatorSummaryStatsStatus
);

export const getLocatorLibrarySummaryStatsStatusMessage = createSelector(
  fromFeature.getLocatorLibrarySummaryStatsState,
  fromLocatorLibrarySummaryStats.getLocatorSummaryStatsStatusMessage
);

export const getLocatorLibrarySummaryStatsProgressSteps = createSelector(
  getLocatorLibrarySummaryStatsStatusMessage,
  generateProgressSteps
);

function generateProgressSteps(
  statusMessage: LocatorSummaryStatsStatusTypes | undefined
): LocatorSummaryStatsStatusProgressStep[] {
  if (statusMessage) {
    return buildSummaryStatsProgressSteps(
      statusMessage as LocatorSummaryStatsStatusTypes
    ) as LocatorSummaryStatsStatusProgressStep[];
  }
  return [];
}
