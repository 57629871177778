import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { MaterialComponentsModule } from 'src/app/shared/material/material-components.module';
import { AtlasDynamicFormModule } from '../shared/atlas-dynamic-form/atlas-dynamic-form.module';
import { AtlasGazetteerModule } from '../shared/atlas-gazetteer/atlas-gazetteer.module';
import { httpInterceptorProviders } from './interceptors/app.interceptors';
import { AtlasPipesModule } from '../shared/pipes/atlas-pipes.module';
import * as fromComponents from './components';
import * as fromStore from './store';
import { effects } from './store/effects';
import { DevExpressComponentsModule } from '../shared/dev-express/dev-express-components.module';
import { AtlasDialogModule } from '../shared/atlas-dialog/atlas-dialog.module';
import { AtlasAddNewPolyDisableUIDirective } from '../shared/directives/disable-ui/add-new-poly-disable-ui.directive';
import { AtlasModifyPolyDisableUIDirective } from '../shared/directives/disable-ui/modify-poly-disable-ui.directive';
import { AtlasProfilerModule } from '../profiler/profiler.module';
import { AtlasStatusScreenDisableUIDirective } from '../shared/directives/disable-ui/status-screen-disable-ui.directive';

@NgModule({
  declarations: [
    ...fromComponents.components,
    AtlasModifyPolyDisableUIDirective,
    AtlasAddNewPolyDisableUIDirective,
    AtlasStatusScreenDisableUIDirective
  ],
  exports: [
    ...fromComponents.components,
    AtlasModifyPolyDisableUIDirective,
    AtlasAddNewPolyDisableUIDirective,
    AtlasStatusScreenDisableUIDirective
  ],
  providers: [httpInterceptorProviders],
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    AtlasDynamicFormModule,
    AtlasGazetteerModule,
    AtlasPipesModule,
    ReactiveFormsModule,
    DevExpressComponentsModule,
    AtlasDialogModule,
    NgxChartsModule,
    AtlasProfilerModule,
    StoreModule.forFeature(fromStore.UIFeatureKey, fromStore.reducers),
    EffectsModule.forFeature(effects)
  ]
})
export class CoreModule {}
