import { Directive, Host, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { RowClickEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
@Directive({
  selector: '[atlasDxiRowSelectionTemplate]'
})
export class AtlasDxiRowSelectionTemplateDirective implements OnDestroy {
  subscription = new Subscription();

  constructor(@Host() dataGrid: DxDataGridComponent) {
    this.subscription.add(
      dataGrid.onSelectionChanged.subscribe((event: SelectionChangedEvent) => {
        if (
          event.currentDeselectedRowKeys &&
          event.currentDeselectedRowKeys.length === 1
        ) {
          event.component.option('focusedRowIndex', -1);
        }
      })
    );

    this.subscription.add(
      dataGrid.onRowClick.subscribe((event: RowClickEvent) => {
        if (!event.isSelected) {
          event.component.option('focusedRowIndex', -1);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
