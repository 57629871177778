import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';

@Directive({
  selector: '[atlasDxiColumnMinutesSuffixTemplate]',
})
export class AtlasDxiColumnMinutesSuffixTemplateDirective {
  constructor(@Host() dataGridColumn: DxiColumnComponent) {
    dataGridColumn.cellTemplate = function (cellElement: any, cellInfo: any) {
      cellElement.append(cellInfo.text + ' min');
    };
  }
}
