import { createReducer, on } from '@ngrx/store';
import { getUserUsageListSuceeded } from '../actions';
import { UserUsage } from '../../models/user-usage';
export interface State {
  userUsageList: UserUsage[];
}

export const initialState: State = {
  userUsageList: []
};

export const userUsageReducer = createReducer(
  initialState,
  on(getUserUsageListSuceeded, (state, { userUsageList }) => {
    return {
      ...state,
      userUsageList
    };
  })
);
