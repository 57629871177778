import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';

/*
  this is to be used as a routing guard to allow the user to navigate to the page to change
  the password after first login
*/

@Injectable({ providedIn: 'root' })
export class FirstLoginGuard  {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;
    this.authService.userInfo$.pipe(take(1)).subscribe((userInfo) => {
      if (userInfo && userInfo.challengeName === 'NEW_PASSWORD_REQUIRED') {
        canActivate = true;
      }
    });
    return canActivate;
  }
}
