import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
@Component({
  selector: 'atlas-map-control-panel',
  templateUrl: './map-control-panel.component.html',
  styleUrl: './map-control-panel.component.less'
})
export class MapControlPanelComponent {
  private subscription = new Subscription();
  selectedLocation: Point;
  showButtons: boolean = false;
  selectedSupplypoint$ = this.smStore$.select(
    fromSmStore.getSpatialModellerSupplyPoint
  );

  selectedLocatorLocation$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  );
  centrePoint: Point;
  centerPointView$ = this.mapService.centreViewCoordinates$;

  constructor(
    public mapService: MapService,
    private smStore$: Store<fromSmStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    public layoutService: LayoutService
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.selectedSupplypoint$.subscribe((supplyPoint) => {
        if (supplyPoint) {
          this.selectedLocation = supplyPoint.location;
        }
      })
    );

    this.subscription.add(
      this.selectedLocatorLocation$.subscribe((locatorLocation) => {
        if (locatorLocation) {
          const location = {
            latitude: locatorLocation.latitude!,
            longitude: locatorLocation.longitude!
          };
          this.selectedLocation = location;
        }
      })
    );

    this.subscription.add(
      this.centerPointView$.subscribe((coordinates) => {
        this.centrePoint = {
          latitude: coordinates[1],
          longitude: coordinates[0]
        };
      })
    );
  }

  onZoomInClicked() {
    const point = this.getInitialViewPoint();
    this.mapService.zoomMap(point, this.mapService.zoom + 1);
  }

  onZoomOutClicked() {
    const point = this.getInitialViewPoint();
    this.mapService.zoomMap(point, this.mapService.zoom - 1);
  }

  getInitialViewPoint(): Point {
    return this.selectedLocation ? this.selectedLocation : this.centrePoint;
  }
  onScreenshotClicked(): void {
    this.showButtons = true;
  }
  onCopydMapClicked() {
    this.mapService.takeMapScreenshot('Clipboard');
  }
  onDownloadMapClicked() {
    this.mapService.takeMapScreenshot('Download');
  }
  onCloseClicked(): void {
    this.showButtons = false;
  }
}
