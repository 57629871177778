import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
@Component({
  selector: 'atlas-release-notes-content-list',
  templateUrl: './release-notes-content-list.component.html',
  styleUrls: ['./release-notes-content-list.component.less']
})
export class ReleaseNotesContentListComponent {
  @ViewChild('contentListDiv') contentListDiv: ElementRef<HTMLElement>;
  @Output()
  private _goToRelease: string;
  @Input()
  get goToRelease() {
    return this._goToRelease;
  }
  set goToRelease(value: string) {
    this._goToRelease = value;
    if (this.contentListDiv) {
      const targetdiv =
        this.contentListDiv.nativeElement.getElementsByClassName(
          `${this.goToRelease}`
        );
      targetdiv[0]?.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
