<div class="row-container" *ngIf="isSmFeatureSelected$ | async">
  <div class="scenario-name">
    <span
      #tooltipSpan
      class="body-large scenario-name"
      [matTooltip]="isTooltipVisible() ? getTooltipText() : ''"
      matTooltipClass="multiline-tooltip-wrap"
      >{{ currentScenario.name }}</span
    >
  </div>
  <div class="buttons-container">
    <atlas-add-scenario
      [disabled]="isScenarioManagementDisabled$ | async"
    ></atlas-add-scenario>
    <atlas-swap-scenario
      [disabled]="isScenarioManagementDisabled$ | async"
    ></atlas-swap-scenario>
    <atlas-edit-scenario
      [currentScenario]="currentScenario"
      [disabled]="isScenarioManagementDisabled$ | async"
    ></atlas-edit-scenario>
  </div>
</div>
<atlas-delta-summary
  *ngIf="isSmFeatureSelected$ | async"
  [disabled]="isScenarioManagementDisabled$ | async"
>
</atlas-delta-summary>
