import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Part } from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  private atlasProfilerImportUrl = `${environment.baseUrl}api/profiler/import`;

  constructor(private http: HttpClient) {}

  public uploadMetaDataConfig(fileKey: string, libraryId: any) {
    return this.http.post(`${this.atlasProfilerImportUrl}/upload-meta-data/`, {
      fileKey: fileKey,
      libraryId: libraryId
    });
  }

  public getExportedPostcodeWithAcornUrl(filekey: string, libraryId: any) {
    return this.http.get<{ presignedUrl: any }>(
      `${this.atlasProfilerImportUrl}/exported-postcodes/presigned-url/${libraryId}/${filekey}`
    );
  }

  public fileUploadUpdateFileKey(libraryId: number, fileKey: string) {
    return this.http.put(
      `${this.atlasProfilerImportUrl}/update-filekey/${libraryId}/${fileKey}`,
      null
    );
  }

  public deleteFilesFromS3(fileKey: string) {
    return this.http.delete(
      `${this.atlasProfilerImportUrl}/delete-filekey/${fileKey}`
    );
  }

  public fileUploadCompleteStatus(libraryId: number) {
    return this.http.put(
      `${this.atlasProfilerImportUrl}/file-upload-complete/${libraryId}`,
      null
    );
  }

  public fileUploadFailedStatus(libraryId: number, errorMsg: string) {
    return this.http.put(`${this.atlasProfilerImportUrl}/file-upload-failed`, {
      libraryId: libraryId,
      errorMessage: errorMsg
    });
  }

  public initiateMultipartUpload(filename: string) {
    return this.http.get<{ uploadId: string }>(
      `${this.atlasProfilerImportUrl}/initiate-multipart-upload/${filename}`
    );
  }

  async getMultipartPresignedUrl(
    filename: string,
    uploadId: string,
    partNumber: number
  ) {
    return await this.http
      .get<{ presignedUrl: string }>(
        `${this.atlasProfilerImportUrl}/presigned-url/${filename}/${uploadId}/${partNumber}`
      )
      .toPromise();
  }

  public completeMultipartUpload(
    libraryId: number,
    filename: string,
    uploadId: string,
    partsList: Part[]
  ) {
    return this.http.put(
      `${this.atlasProfilerImportUrl}/complete-multipart-upload/${libraryId}/${filename}/${uploadId}`,
      partsList.map(({ ETag, PartNumber }) => ({
        ETag: ETag,
        PartNumber: PartNumber
      }))
    );
  }

  public cancelMultipartUpload(filename: string, uploadId: string) {
    return this.http.delete(
      `${this.atlasProfilerImportUrl}/cancel-multipart-upload/${filename}/${uploadId}`
    );
  }

  public hasProfilerLimitBeenExceeded() {
    return this.http.get<any>(
      `${this.atlasProfilerImportUrl}/exceeded-company-limit`
    );
  }
}
