// Code from InSite Desktop to avoid unwanted endless decimals between conversions

const MILES_PER_KM = 0.621371; // 1 kilometer is equal to approximately 0.621371 miles
const KM_PER_MILE = 1.609344; // 1 mile is equal to approximately 1.609344

export function kilometersToMiles(kilometersToConvert: number): number {
  var temp =
    Math.floor(kilometersToConvert * MILES_PER_KM * 100.0 + 0.5) / 100.0;
  if (
    kilometersToConvert ==
    Math.floor(Math.ceil(temp) * MILES_PER_KM * 100 + 0.5) / 100.0
  ) {
    temp = Math.ceil(temp);
  } else if (
    kilometersToConvert ==
    Math.floor(Math.floor(temp) * MILES_PER_KM * 100 + 0.5) / 100.0
  ) {
    temp = Math.floor(temp);
  }
  return temp;
}

export function milesToKilometers(milesToConvert: number): number {
  var temp = Math.floor((milesToConvert / MILES_PER_KM) * 100.0 + 0.5) / 100.0;
  if (
    milesToConvert ==
    Math.floor((Math.ceil(temp) / KM_PER_MILE) * 100 + 0.5) / 100.0
  ) {
    temp = Math.ceil(temp);
  } else if (
    milesToConvert ==
    Math.floor((Math.floor(temp) / KM_PER_MILE) * 100 + 0.5) / 100.0
  ) {
    temp = Math.floor(temp);
  }
  return temp;
}
