<div class="atlas-dialog-container">
  <atlas-dialog-header headerText="Cancel change location"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div class="message-content">Confirm you want to cancel the edit?</div>
    <p></p>
  </mat-dialog-content>

  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button
        label="No"
        (clicked)="onNo()"
      ></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Yes"
        (clicked)="onYes()"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>