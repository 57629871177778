<div *ngIf="queryItem?.isStats">
  @if(isSimpleStatsView$ | async){
  <div>
    <ng-template
      ngFor
      let-i="index"
      let-statsItem
      [ngForOf]="queryItem.statsData"
    >
      <div>
        <atlas-simple-stats-page
          [value]="statsItem.value"
          [name]="statsItem.name"
        ></atlas-simple-stats-page>
      </div>
    </ng-template>
  </div>
  } @else{
  <div>
    <atlas-standard-stats-page
      [queryItem]="queryItem.statsData"
    ></atlas-standard-stats-page>
  </div>
  }
</div>
