import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { DialogService } from 'src/app/core/services/dialog.service';
import { DialogMode } from '../../../../shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from '../../../../shared/atlas-dialog/enums/dialog-width.enum';
import { AddScenarioDialogComponent } from '../add-scenario-dialog/add-scenario-dialog.component';
import * as fromUIStore from 'src/app/core/store';

@Component({
  selector: 'atlas-add-scenario',
  templateUrl: './add-scenario.component.html',
  styleUrls: ['../less/scenario-management-button.component.less']
})
export class AddScenarioComponent {
  @Input() disabled: boolean | null = false;
  @Output() addScenarioClicked = new EventEmitter();

  constructor(public dialogService: DialogService) {}

  onOpenNewScenarioDialog() {
    this.dialogService.show(AddScenarioDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        entityName: 'Scenario',
        mode: DialogMode.Add,
        headerPrefix: 'Create a new',
        affirmativeButtonText: 'Create'
      },
      disableClose: true
    });
  }
}
