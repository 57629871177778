import {
  MapScreenShotConfig,
  MapScreenShotContext,
  MapScreenShotLegendInfo
} from './map-screenshot-types';

const DEFAULT_CONFIG: MapScreenShotConfig = {
  dimensions: {
    width: 150,
    padding: 10,
    position: { x: 10, y: 10 }
  },
  fonts: {
    header: '7px Arial',
    layerName: '7px Arial',
    items: '7px Arial'
  },
  maxHeight: 830
};

export class MapScreenshotRenderer {
  private readonly config: MapScreenShotConfig;

  constructor(customConfig?: Partial<MapScreenShotConfig>) {
    this.config = { ...DEFAULT_CONFIG, ...customConfig };
  }

  public areAllLayersLoaded(deckgl: any) {
    return deckgl.props.layers.every((layer: any) => layer.isLoaded);
  }

  public async generateScreenshot(mapScreenShotContext: MapScreenShotContext) {
    const { map, deckgl, layers, hideLegend } = mapScreenShotContext;

    let combinedCanvas = await this.generateCompositeCanvas(
      mapScreenShotContext
    );

    if (deckgl.screenShotOption == 'Download') {
      downloadCanvasToPng(combinedCanvas, 'map_screen_shot.png');
    }

    if (deckgl.screenShotOption == 'Clipboard') {
      await copyCanvasToClipBoard(combinedCanvas);
    }
  }

  // Combine Deck And Mapbox Canvases
  private async generateCompositeCanvas(mapContext: MapScreenShotContext) {
    const { map, deckgl, layers, hideLegend } = mapContext;

    const combinedCanvas = this.createCombinedCanvas(deckgl.getCanvas());
    let ctx = combinedCanvas.getContext('2d');

    if (!ctx) throw new Error('Failed to get canvas context');

    this.drawCanvasLayers(ctx, map.getCanvas(), deckgl.getCanvas());

    // Add the legend in the top-left corner
    if (!hideLegend) {
      await this.drawLegend(layers, ctx);
    }

    this.addCopyright(ctx!, combinedCanvas);

    return combinedCanvas;
  }

  private createCombinedCanvas(
    referenceCanvas: HTMLCanvasElement
  ): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = referenceCanvas.width;
    canvas.height = referenceCanvas.height;
    return canvas;
  }

  private drawCanvasLayers(
    ctx: CanvasRenderingContext2D,
    mapboxCanvas: HTMLCanvasElement,
    deckCanvas: HTMLCanvasElement
  ): void {
    ctx.drawImage(mapboxCanvas, 0, 0);
    ctx.drawImage(deckCanvas, 0, 0);
  }

  private async drawLegend(
    layers: Map<string, any>,
    ctx: CanvasRenderingContext2D
  ) {
    const visibleLayers = this.getVisibleLayers(layers);
    const legendHeight = this.calculateLegendHeight(visibleLayers);

    this.drawLegendBackground(ctx, legendHeight);
    this.drawLegendHeader(ctx);

    await this.drawLayerLegends(visibleLayers, ctx);
  }

  private async drawLayerLegends(
    layers: any[],
    ctx: CanvasRenderingContext2D
  ): Promise<void> {
    let currentY =
      this.config.dimensions.position.y + this.config.dimensions.padding + 5;

    for (const layer of layers) {
      if (currentY >= this.config.maxHeight - this.config.dimensions.padding)
        break;

      currentY = await this.drawLayerLegend(layer, ctx, currentY);
    }
  }

  private async drawLayerLegend(
    layer: any,
    ctx: CanvasRenderingContext2D,
    startY: number
  ): Promise<number> {
    const { x } = this.config.dimensions.position;
    const { padding } = this.config.dimensions;

    let currentY = startY + padding;

    ctx.font = this.config.fonts.layerName;
    ctx.fillText(layer.props.name, x + padding, currentY + padding);

    currentY += padding + 10;

    for (const legendInfo of layer.props.legendInfo) {
      if (currentY >= this.config.maxHeight - this.config.dimensions.padding)
        break;

      await this.drawLegendItem(legendInfo, ctx, x + padding, currentY);
      currentY += 12;
    }

    return currentY;
  }

  private async drawLegendItem(
    legendInfo: MapScreenShotLegendInfo,
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number
  ): Promise<void> {
    const lengedRenders = {
      Boundary: this.drawBoundaryLegend,
      Line: this.drawLineLegend,
      Point: this.drawPointLegend,
      Icon: this.drawIconLegend,
      InSiteEverywhereMapPin: this.drawIconLegend,
      Label: this.drawLabelLegend
    };

    const drawer = lengedRenders[legendInfo.mapLayerType];
    if (drawer) {
      await drawer.call(this, ctx, x, y, legendInfo);
    } else {
      ctx.fillText('Unknown Legend', x, y);
    }
  }

  private drawBoundaryLegend(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    legendInfo: any
  ) {
    ctx.fillStyle = legendInfo.fillColor; // Fill color
    ctx.strokeStyle = legendInfo.lineColor; // Border color
    ctx.lineWidth = legendInfo.lineWidth; // Border width
    ctx.fillRect(x, y, 9, 9); // Draw boundary square
    ctx.strokeRect(x, y, 9, 9); // Draw boundary border
    ctx.fillStyle = 'black';
    ctx.fillText(legendInfo.text, x + 15, y + 7); // Add text
  }

  private drawLegendBackground(
    ctx: CanvasRenderingContext2D,
    height: number
  ): void {
    const { x, y } = this.config.dimensions.position;
    const { width } = this.config.dimensions;

    ctx.fillStyle = 'rgba(255, 255, 255, 1)';
    ctx.fillRect(x, y, width, height);

    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.strokeRect(x, y, width, height);
  }

  private drawLineLegend(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    legendInfo: any
  ) {
    ctx.fillStyle = legendInfo.fillColor; // Fill color
    ctx.strokeStyle = legendInfo.fillColor; // Border color
    ctx.lineWidth = legendInfo.lineWidth; // Border width
    ctx.fillRect(x, y, 40, 3); // Draw boundary square
    ctx.strokeRect(x, y, 40, 3); // Draw boundary border
    ctx.fillStyle = 'black';
    ctx.fillText(legendInfo.text, x + 50, y + 3); // Add text
  }

  private drawPointLegend(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    legendInfo: any
  ) {
    ctx.fillStyle = legendInfo.fillColor; // Fill color for point
    ctx.strokeStyle = legendInfo.lineColor; // Border color
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(x + 5, y + 5, 5, 0, Math.PI * 2); // Draw circle for point
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = 'black';
    ctx.fillText(legendInfo.text, x + 15, y + 7); // Add text
  }

  private async drawIconLegend(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    legendInfo: any
  ) {
    const icon = new Image(); // Create a new Image object
    icon.crossOrigin = 'anonymous'; // Enable CORS

    // Optionally apply a filter if necessary
    if (legendInfo.iconFilter) {
      ctx.filter = legendInfo.iconFilter; // Apply the filter
    }

    // Promisify the image loading process
    const loadImage = (url: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        icon.onload = () => {
          resolve();
        };
        icon.onerror = (err) => {
          reject(new Error('Failed to load image at ' + url));
        };
        icon.src = url;
      });
    };

    // Wait for the image to load
    try {
      await loadImage(legendInfo.iconUrl);

      // Draw the icon on the canvas
      ctx.drawImage(icon, x, y, 12, 12);

      // Reset filter and draw the text after the icon has loaded
      ctx.filter = 'none';
      ctx.fillStyle = 'black';
      ctx.fillText(legendInfo.text, x + 15, y + 7); // Add text to the right of the icon
    } catch (error) {
      ctx.fillStyle = 'red';
      ctx.fillText('Icon failed to load', x + 15, y + 7); // Display error message
    }
  }

  private drawLabelLegend(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    legendInfo: any
  ) {
    ctx.fillStyle = legendInfo.labelBackground; // Background color
    ctx.strokeStyle = legendInfo.labelBackground; // Border color
    ctx.lineWidth = 1; // Border width

    ctx.fillRect(x, y - 4, 10, 5); // Draw label background
    ctx.strokeRect(x, y - 4, 10, 5); // Draw label border

    ctx.fillStyle = legendInfo.fillColor; // Text color
    ctx.font = '5px Arial';
    ctx.fillText('Abc', x, y); // Add label placeholder text

    ctx.fillStyle = 'black';
    //ctx.fillText(legendInfo.text, x + 40, y + 15); // Add legend description text
  }

  private drawLegendHeader(ctx: CanvasRenderingContext2D): void {
    const { x, y } = this.config.dimensions.position;
    const { padding } = this.config.dimensions;

    ctx.fillStyle = 'black';
    ctx.font = this.config.fonts.header;
    ctx.fillText('Legend:', x + padding, y + padding + 15);
  }
  private getVisibleLayers(layers: Map<string, any>): any[] {
    return Array.from(layers.values())
      .filter((layer) => layer.props.visible && layer.id !== 'Pin_Drop_LAYER')
      .sort((a, b) => b.props.zOrder - a.props.zOrder);
  }

  private calculateLegendHeight(layers: any[]): number {
    const itemCount = layers.reduce(
      (count, layer) => count + layer.props.legendInfo.length,
      0
    );

    const height =
      this.config.dimensions.padding * 2 + layers.length * 35 + itemCount * 12;

    return Math.min(height, this.config.maxHeight);
  }

  private addCopyright(
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement
  ): void {
    const copyrightHeight = 30;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.fillRect(
      0,
      canvas.height - copyrightHeight,
      canvas.width,
      copyrightHeight
    );

    ctx.font = '12px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText(
      'The applicable copyright notices can be found at http://www.caci.co.uk/copyrightnotices.pdf',
      canvas.width / 2,
      canvas.height - 10
    );
  }
}

export function downloadCanvasToPng(canvas: any, imageName: string) {
  // Convert the combined canvas to a data URL
  const base64Image = canvas.toDataURL('image/png');

  // Create a temporary link and click it to download
  const a = document.createElement('a');
  a.href = base64Image;
  a.download = imageName;
  a.click();
}

export async function copyCanvasToClipBoard(canvas: any) {
  try {
    // Convert the combined canvas to a blob
    const blob = await new Promise<Blob>((resolve) =>
      canvas.toBlob(resolve, 'image/png')
    );

    // Create a ClipboardItem with the blob
    const item = new ClipboardItem({ 'image/png': blob });

    // Write the ClipboardItem to the clipboard
    await navigator.clipboard.write([item]);
  } catch (err) {
    console.error('Failed to copy image to clipboard:', err);
  }
}
