import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-icon-text-button',
  templateUrl: './atlas-icon-text-button.component.html',
  styleUrls: ['./atlas-icon-text-button.component.less']
})
export class AtlasIconTextButtonComponent {
  @Input() icon: string;
  @Input() labelText: string;
  @Input() disabled: boolean;
  @Output() clicked = new EventEmitter();
}
