<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="add scenario"
    (click)="onOpenNewScenarioDialog()"
    matTooltip="Create a new scenario"
    [matTooltipShowDelay]="1000"
    [disabled]="disabled"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
