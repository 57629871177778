import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';

import { UserAction } from 'src/app/spatial-modeller/models/supply-point';

@Component({
  selector: 'atlas-delete-supply-point-confirmation-dialog',
  templateUrl: './delete-supply-point-confirmation-dialog.component.html',
  styleUrls: ['./delete-supply-point-confirmation-dialog.component.less'],
})
export class DeleteSupplyPointConfirmationDialogComponent {
  get headerText() {
    return 'Delete location from scenario';
  }

  public get userAction(): typeof UserAction {
    return UserAction;
  }

  constructor(
    private store$: Store<fromSmStore.State>,
    private dialogRef: MatDialogRef<DeleteSupplyPointConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {}
  ) {}

  selectedSupplypoint$ = this.store$.select(
    fromSmStore.getSpatialModellerSupplyPoint
  );

  onDeleteClicked() {
    this.store$.dispatch(
      fromSmStore.SupplyPointActions.deleteSupplyPointClicked()
    );
    this.dialogRef.close({
      action: DialogResult.Affirmative,
    });
  }
}
