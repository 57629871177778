import { createAction, props } from '@ngrx/store';
import { UsageSummary } from '../../models/usage-summary';

export const usageErrorOccured = createAction(
  '[Usage Effect] Error occured in usage effect',
  props<{ errorOn: string; error: string }>()
);
export const clearUsage = createAction('[Usage Component] Clear Usage');

export const getUsageSummaryAttempt = createAction(
  '[Usage Component] Get  Usage Summary Attempt'
);
export const getUsageSummarySucceeded = createAction(
  '[Atlas API] Get  Usage  Summary Succeeded',
  props<{ usageSummary: UsageSummary }>()
);
export const hasGeocodingCompanyLimitBeenExceededAttempt = createAction(
  '[Locator File Import Dialog] Check Has Geocoding Company Limit Been Exceeded Attempt'
);
export const hasGeocodingCompanyLimitBeenExceededResult = createAction(
  '[Locator Import Effect] Result Of Geocoded Company Limit Check',
  props<{
    result: boolean;
  }>()
);
export const hasGeocodingCompanyLimitBeenExceededErrorOccurred = createAction(
  '[Locator Import Effect] Result Of Geocoded Company Limit Error',
  props<{ errorOn: string; error: string }>()
);
export const hasGeocodingLicenseExpiredAttempt = createAction(
  '[Locator File Import Dialog] Check Geocoding License Expired Attempt'
);
export const hasGeocodingLicenseExpiredResult = createAction(
  '[Locator Import Effect] Geocoding License Expired Result Check',
  props<{
    result: boolean;
  }>()
);
export const hasGeocodingLicenseExpiredErrorOccurred = createAction(
  '[Locator Import Effect] Result of Geocoding License Expired Error',
  props<{ errorOn: string; error: string }>()
);
