import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const getReportInProgress = createSelector(
  fromFeature.getExportReportState,
  (state) => state?.reportInProgress
);

export const getReports = createSelector(
  fromFeature.getExportReportState,
  (state) => state?.reports
);

export const getReportCount = createSelector(
  fromFeature.getExportReportState,
  (state) => {
    return state == undefined || state == null ? 0 : state?.reports?.length;
  }
);
