import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { AtlasDxColumnFormatService } from 'src/app/shared/dev-express/services/atlas-dx-column-format.service';
import { AtlasDxGridStateService } from 'src/app/shared/dev-express/services/atlas-dx-grid-state.service';

@Component({
  selector: 'atlas-users-login-usage-list',
  templateUrl: './users-login-usage-list.component.html'
})
export class UsersLoginUsageListComponent {
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  @Input()
  dataSource: any;

  @Input()
  template: TemplateRef<any>;

  fullName(data: any): string {
    return `${data.firstName} ${data.surname}`;
  }

  constructor(
    private route: Router,
    public atlasFormat: AtlasDxColumnFormatService,
    public atlasGridState: AtlasDxGridStateService
  ) {}

  onToolbarPreparing(event: any) {
    event.toolbarOptions.items.push({
      location: 'before',
      template: 'parentTemplate'
    });
  }

  loadState = () => {
    var state = this.atlasGridState.loadState('users-list-grid-storage');
    return this.atlasGridState.loadState('users-list-grid-storage');
  };

  saveState = (state: any) => {
    this.atlasGridState.saveState('users-list-grid-storage', state);
  };

  onBack() {
    this.route.navigate(['/home']);
  }
}
