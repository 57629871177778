import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromUIStore from 'src/app/core/store';
import { FeatureManagementOptions } from '../feature-management-panel/feature-management-options';

@Component({
  selector: 'atlas-feature-management-buttons',
  templateUrl: './feature-management-buttons.component.html',
  styleUrls: ['./feature-management-buttons.component.less']
})
export class FeatureManagementButtonsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Output()
  featureManagementOptionSelected: EventEmitter<FeatureManagementOptions> =
    new EventEmitter();

  isSMFeatureAllowed$ = this.UIStore$.select(fromUIStore.isSMFeatureAllowed);
  isLocatorFeatureAllowed$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureAllowed
  );

  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );

  constructor(private UIStore$: Store<fromUIStore.State>) {}

  selectedOption: FeatureManagementOptions = 'Layers';

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.isSMFeatureSelected$.subscribe((selected) => {
        if (selected) {
          this.featureManagementOptionSelected.emit('Layers');
          this.selectedOption = 'Layers';
        }
      })
    );

    this.subscription.add(
      this.isLocatorFeatureSelected$.subscribe((selected) => {
        if (selected) {
          this.featureManagementOptionSelected.emit('Layers');
          this.selectedOption = 'Layers';
        }
      })
    );
    this.subscription.add(
      this.isProfilerFeatureSelected$.subscribe((selected) => {
        if (selected) {
          this.featureManagementOptionSelected.emit('Layers');
          this.selectedOption = 'Layers';
        }
      })
    );
  }

  onLayersClicked() {
    this.featureManagementOptionSelected.emit('Layers');
    this.selectedOption = 'Layers';
  }

  onLocatorLocations() {
    this.featureManagementOptionSelected.emit('LocatorLocations');
    this.selectedOption = 'LocatorLocations';
  }
}
