import {
  pinDropLayerIdentifier,
  smScenarioBaseTilesetLayerIdentifier
} from '../../atlas-mapping/layers/layer.constants';
import { MapService } from '../../atlas-mapping/services/map.service';
import { PinDropAndSelectionService } from '../../atlas-mapping/services/pin-drop-and-selection-service';
import { Point } from '../models/point.model';
import { SearchResult } from '../models/search-result';
import { SearchResultActions } from '../models/search-result-actions';

export function executeSearchResultAction(
  searchResultAction: SearchResultActions,
  searchResult: SearchResult,
  point: Point,
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService
) {
  const searchResultActions = {
    DropPin: (
      searchResult: SearchResult,
      point: Point,
      mapService: MapService,
      pinDropAndSelectionService: PinDropAndSelectionService
    ) => DropPinAction(mapService, pinDropAndSelectionService, point),
    PanAndZoom: (
      searchResult: SearchResult,
      point: Point,
      mapService: MapService,
      pinDropAndSelectionService: PinDropAndSelectionService
    ) => PanAndZoomAction(mapService, pinDropAndSelectionService, point),
    SpatialModelerCustom: (
      searchResult: SearchResult,
      point: Point,
      mapService: MapService,
      pinDropAndSelectionService: PinDropAndSelectionService
    ) =>
      SupplyPointSelectionAction(
        mapService,
        pinDropAndSelectionService,
        point,
        searchResult.identifier,
        searchResult.description
      ),

    LocatorCustom: (
      searchResult: SearchResult,
      point: Point,
      mapService: MapService,
      pinDropAndSelectionService: PinDropAndSelectionService
    ) =>
      LocatorLocationSelectionAction(
        mapService,
        pinDropAndSelectionService,
        point,
        searchResult.identifier
      ),
    ChangeSelectedPinLocation: (
      searchResult: SearchResult,
      point: Point,
      mapService: MapService,
      pinDropAndSelectionService: PinDropAndSelectionService
    ) =>
      ChangeSelectedPinLocationAction(
        mapService,
        pinDropAndSelectionService,
        point,
        searchResult.identifier
      )
  };

  return searchResultActions[searchResultAction](
    searchResult,
    point,
    mapService,
    pinDropAndSelectionService
  );
}

export function DropPinAction(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  point: Point
) {
  pinDropAndSelectionService.setAllowManualPinDrop(false);
  mapService.updateLayer(pinDropLayerIdentifier, {
    data: [[point.longitude, point.latitude]]
  });
  pinDropAndSelectionService.reselectManualPin();

  mapService.selectionParentLayer.next(
    mapService.getLayer(pinDropLayerIdentifier)
  );

  mapService.centreMap({
    latitude: point.latitude,
    longitude: point.longitude
  });
}

export function PanAndZoomAction(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  point: Point
) {
  mapService.selectionParentLayer.next(
    mapService.getLayer(pinDropLayerIdentifier)
  );

  mapService.centreMap({
    latitude: point.latitude,
    longitude: point.longitude
  });
}

export function LocatorLocationSelectionAction(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  point: Point,
  identifier: string
) {
  pinDropAndSelectionService.dispatchLocatorLocationFromSearch(
    identifier as unknown as number
  );
}

export function SupplyPointSelectionAction(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  point: Point,
  identifier: string,
  description: string
) {
  // TODO update custom search seed data to use custom action

  // get all the layers for the map
  const allLayers = mapService.getAllLayers();

  let matchedFeature = null;
  let macthedLayerId = '';

  for (let layer of allLayers) {
    if (layer[1]?.props?.data?.features) {
      let features = layer[1]?.props?.data?.features;

      matchedFeature = features.find((feature: any) => {
        if (feature.properties) {
          return (
            feature.properties.is_supply_point &&
            feature.properties.supply_key == identifier
          );
        }
      });

      if (matchedFeature) {
        macthedLayerId = layer[0];
        break;
      }
    }
  }

  if (matchedFeature == null) {
    macthedLayerId = smScenarioBaseTilesetLayerIdentifier;
    matchedFeature = hasBaseTileset(allLayers, point, identifier, description);
  }

  if (matchedFeature) {
    mapService.updateLayer(macthedLayerId, { visible: true });

    matchedFeature.geometry.coordinates = [point.longitude, point.latitude];

    pinDropAndSelectionService.dispatchSupplyPointSelectedFromSearch(
      allLayers.get(macthedLayerId),
      matchedFeature
    );
  }

  mapService.centreMap({
    latitude: point.latitude,
    longitude: point.longitude
  });
}

// Tilesets dont have all the features encoded in the layer
// if no match but the base tileset feature exists then assume it is part ofthat layer
function hasBaseTileset(
  layers: Readonly<Map<string, any>>,
  point: Point,
  identifier: string,
  description: string
) {
  for (let layer of layers) {
    if (layer[0] === smScenarioBaseTilesetLayerIdentifier) {
      const props: string[] = description.split(',');

      return {
        geometry: {
          type: 'Point',
          coordinates: [point.longitude, point.latitude]
        },
        properties: {
          supply_key: identifier,
          supply_id: props[0],
          name: props[1],
          fascia: props[2],
          is_supply_point: true
        },
        type: 'Feature'
      };
    }
  }

  return null;
}

export function centreMap(
  mapService: MapService,

  point: Point
) {
  mapService.centreMap({
    latitude: point.latitude,
    longitude: point.longitude
  });
}

export function ChangeSelectedPinLocationAction(
  mapService: MapService,
  pinDropAndSelectionService: PinDropAndSelectionService,
  point: Point,
  identifier: string
) {
  pinDropAndSelectionService.updateDraggableSelectionLayerLocation(point);
  mapService.centreMap({
    latitude: point.latitude,
    longitude: point.longitude
  });
}
