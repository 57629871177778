<div
  class="password-validation-container"
  [class.empty-password-validation-container]="passwordNotTouched"
  [class.wrong-password-validation-container]="isPasswordValidationWrong()"
  [class.passed-password-validation-container]="isPasswordValidationPassed()"
>
  <ul>
    <li [class.validation-passed]="minLengthRequirementAccomplished">
      Minimum password length: 8 characters.
    </li>
    <li [class.validation-passed]="numberRequirementAccomplished">
      Contains at least 1 number.
    </li>
    <li [class.validation-passed]="specialCharRequirementAccomplished">
      Contains at least 1 special character (! &#64; # $ % ^ & * + = ^ . [ ] &#123;
      &#125; ( ) ? - " ! / \ , &gt; &lt; ' : ; | _ ~ `).
    </li>
    <li [class.validation-passed]="upperCaseLetterRequirementAccomplished">
      Contains at least 1 uppercase letter.
    </li>
    <li [class.validation-passed]="lowerCaseLetterRequirementAccomplished">
      Contains at least 1 lowercase letter.
    </li>
  </ul>
</div>
