import { Layer } from '../models/layer';
import { getMapLayerDefaultProperties } from '../helpers/map-layer-helper';
import { MapService } from '../services/map.service';
import { TextLayer } from '@deck.gl/layers/typed';

// potential improvements for label layer can be found here
// import {CollisionFilterExtension} from '@deck.gl/extensions';
// https://deck.gl/examples/text-layer
// https://github.com/visgl/deck.gl/blob/8.9-release/examples/website/text/app.jsx

// background label styling properties
// https://github.com/visgl/deck.gl/issues/4579
export class LabelLayer extends Layer {
  async getLayer(jwtToken: string, apiBaseUrl: string, mapService: MapService) {
    this.setJwtTokenAndApiBaseUrl(jwtToken, apiBaseUrl);

    let defaultProps = getMapLayerDefaultProperties('Label', jwtToken);

    var layerProperties =
      this.mergeDefaultPropertiesWitUserDefinedProperties(defaultProps);

    layerProperties.getText = (d: any) => {
      return d[layerProperties.labelAttr];
    };
    layerProperties.getPosition = (d: any) => {
      return [d.longitude, d.latitude];
    };

    return new TextLayer(layerProperties);
  }
}
