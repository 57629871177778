import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import * as fromLocatorStore from 'src/app/locator-store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { openStreetView } from 'src/app/shared/utils/map-utils';
import * as fromSmStore from 'src/app/spatial-modeller-store';

@Component({
  selector: 'atlas-street-view-jpeg',
  templateUrl: './street-view-jpeg.component.html',
  styleUrls: ['./street-view-jpeg.component.less']
})
export class StreetViewJpegComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  selectedLocation: Point | undefined;
  public heading: number = 0;
  public imageNumber: number = 1;
  selectedSupplypoint$ = this.store$.select(
    fromSmStore.getSpatialModellerSupplyPoint
  );
  selectedLocation$ = this.locatorStore$.select(
    fromLocatorStore.getSelectedLocation
  );
  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );
  hidden: boolean = false;

  constructor(
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    private store$: Store<fromSmStore.State>,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.selectedSupplypoint$.subscribe((supplyPoint) => {
        this.selectedLocation = supplyPoint?.location;
      })
    );

    this.subscription.add(
      this.selectedLocation$.subscribe((location) => {
        if ((location?.latitude, location?.longitude))
          this.selectedLocation = {
            latitude: location.latitude,
            longitude: location.longitude
          };
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showImage() {
    this.hidden = true;
    return { visible: true };
  }

  hideImage() {
    this.hidden = false;
    return { visible: false };
  }

  onStreetViewClicked() {
    openStreetView(
      this.selectedLocation?.latitude,
      this.selectedLocation?.longitude
    );
  }

  swipeRight() {
    this.heading = (this.heading + 90) % 360;
    this.imageNumber = (this.imageNumber % 4) + 1;
  }

  swipeLeft() {
    this.heading = (this.heading - 90) % 360;
    this.imageNumber = this.imageNumber === 1 ? 4 : (this.imageNumber - 1) % 4;
  }
}
