<dx-data-grid
  style="padding-top: 10px"
  class="standard-grid"
  [dataSource]="dataSource"
  keyExpr="email"
  atlasDxDataGridDefaultOptions
  (onToolbarPreparing)="onToolbarPreparing($event)"
>
  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customLoad]="loadState"
    [customSave]="saveState"
  >
  </dxo-state-storing>

  <dxo-load-panel [enabled]="false"> </dxo-load-panel>

  <dxi-column dataField="fullName" caption="Name"></dxi-column>
  <dxi-column dataField="email"></dxi-column>
  <dxi-column dataField="modelRuns" [alignment]="'left'"></dxi-column>
  <dxi-column
    dataField="percentageOfExecutedModels"
    caption="Model Runs %"
    [allowFiltering]="true"
    [alignment]="'left'"
    [calculateCellValue]="formatModelRunsPercentage"
  ></dxi-column>
  <dxi-column
    dataField="incompleteModelRuns"
    [visible]="false"
    [alignment]="'left'"
  ></dxi-column>

  <div>
    <atlas-dx-grid-header gridTitle="All Users"></atlas-dx-grid-header>
  </div>
  <div *dxTemplate="let data of 'parentTemplate'">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</dx-data-grid>
