import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MaterialComponentsModule } from 'src/app/shared/material/material-components.module';
import { AtlasPipesModule } from 'src/app/shared/pipes/atlas-pipes.module';
import { AtlasReportingRoutingModule } from './reporting-routing.module';

import * as fromComponents from './components';
import * as fromServices from './services';
import * as fromStore from './store';
import { effects } from './store/effects';
import { DevExpressComponentsModule } from 'src/app/shared/dev-express/dev-express-components.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialComponentsModule,
    DevExpressComponentsModule,
    AtlasPipesModule,
    AtlasReportingRoutingModule,
    NgxChartsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromStore.UsageFeatureKey, fromStore.reducers),
    EffectsModule.forFeature(effects)
  ],
  declarations: [...fromComponents.components],
  exports: [...fromComponents.components],
  providers: [...fromServices.services]
})
export class AtlasReportingModule {}
