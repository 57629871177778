import { MapLayerTemplate } from 'src/app/core/models/map-layer-template';
import { MapService } from '../services/map.service';
import { buildMapLayer } from './map-layer-helper';
import { systemTilesetLayerIdentifiers } from '../layers/layer.constants';
import { environment } from 'src/environments/environment';

export function showFeatureActionMapLayers(
  mapTemplates: MapLayerTemplate[],
  updatables: Record<string, number>,
  mapService: MapService,
  visible: boolean | null = null
) {
  mapTemplates.forEach((template: MapLayerTemplate) => {
    if (template) {
      var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
        template.identifier
      );
      addMapLayerWithRefreshedData(
        [{ ...template }],
        mapService,
        updatables,
        visible ?? usersVisiblePreference
      );
    }
  });
}

export function getUpdatablesFromRecord(
  record: Record<string, number>,
  updatables: string[]
) {
  var updatablesRecord: Record<string, number> = {};
  updatables.forEach((updatable) => {
    if (record[updatable] !== undefined) {
      updatablesRecord[updatable] = record[updatable];
    }
  });
  return updatablesRecord;
}

async function addMapLayerWithRefreshedData(
  mapTemplates: any[],
  mapService: MapService,
  updatables: Record<string, number>,
  visible: boolean
) {
  mapTemplates.forEach(async (t) => {
    if (!systemTilesetLayerIdentifiers.includes(t.identifier)) {
      var layer = buildMapLayer(t.type, t);
      layer.zOrder = t.zOrder;
      layer.type = t.type;
      layer.userDefinedProps.visible = visible;
      replaceDataUrl(layer.userDefinedProps, updatables);

      await mapService
        .addLayer(layer)
        .catch((error) => mapService.handleErrorOnRetrievingMapLayers(error));
    }
  });
}

function replaceDataUrl(layer: any, updatables: Record<string, number>) {
  let dataUrl = layer?.featureActionUrl;
  if (dataUrl) {
    for (var key in updatables) {
      dataUrl = dataUrl.replace(`{${key}}`, updatables[key]);
    }
    layer.data = `${environment.baseUrl}${dataUrl}`;
  }
}
