import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const isLocatorFeatureAllowed = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.locatorFeatureAllowed
);

export const isLocatorFeatureSelected = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.featureSelected
);

export const getHasLocatorFeatureLoaded = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.hasLocatorFeatureLoaded
);

export const getAddingEditingPolygon = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.addingEditingPolygon
);

export const getAddingNewPolygon = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.addingNewPolygon
);

export const getGeneratingCatchments = createSelector(
  fromFeature.getLocatorUIState,
  (state) => state?.generatingCatchments
);
