import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedReportViewerToken } from '../models/shared-report-viewer-token';

@Injectable({ providedIn: 'root' })
export class PowerBiReportViewerService {
  constructor(private httpClient: HttpClient) {}

  sharedReportTokenUrl = `${environment.reportingApiBaseUrl}api/shared-report`;

  getSharedReportToken(
    workspaceId: string,
    reportingItemId: string,
    reportParams: any[]
  ): Observable<SharedReportViewerToken> {
    let params = new HttpParams();

    params = params.append('parameters', reportParams.join(','));

    return this.httpClient
      .get<SharedReportViewerToken>(
        `${this.sharedReportTokenUrl}/${workspaceId}/${reportingItemId}/token`,
        { params }
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Server response code= ' + error.status));
  }
}
