import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function validateCoordinate(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const stringCoordinate = control.value?.split('.');
    return (stringCoordinate?.length === 2 &&
      areCoordinatesNumeric(stringCoordinate[0], stringCoordinate[1]) &&
      stringCoordinate[0] !== '' &&
      stringCoordinate[1]?.length <= 6) ||
      (stringCoordinate?.length === 1 &&
        areCoordinatesNumeric(stringCoordinate[0]))
      ? null
      : { invalidCoordinate: true };
  };
}

function areCoordinatesNumeric(coordinateA: any, coordinateB: any = null) {
  return coordinateB === null
    ? !isNaN(coordinateA)
    : !(isNaN(coordinateA) || isNaN(coordinateB));
}
