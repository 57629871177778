import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { shapeDuplicateValidator } from '../../../chip-list/duplicate-locator-shape-validator';
import * as fromLocatorStore from 'src/app/locator-store';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/core/store';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults
} from 'src/app/core/models/locator-shape-defaults';
import { Subscription } from 'rxjs';
import { shapeSizeExceededValidator } from '../../../chip-list/shape-size-exceeded-validator';
import {
  getShapeRangeLimit,
  getShapeTypeName
} from 'src/app/locator/helpers/locator-shape.helper';
import {
  DriveCatchmentSpeedNames,
  DriveCatchmentSpeeds
} from 'src/app/core/enums/drive-catchment-speeds.enum';
import { GeneralSettings } from 'src/app/core/models/general-settings';

interface UnitType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'atlas-catchment-form',
  templateUrl: './catchment-form.component.html',
  styleUrls: ['./catchment-form.component.less']
})
export class CatchmentFormComponent implements OnInit {
  @Input() isShared: boolean;
  @Input() mode: DialogMode;
  @Input() shape: LocatorShape;
  @Input() currentShapes: LocatorShape[] | null;
  @Input() shapeType: LocatorShapeTypes;

  @Input() defaultSize: number | null;
  @Input() defaultShapeUnit: string | null;
  @Input() driveCatchmentSpeed: string;
  @Input() locationsCount: number;

  unitTypes: UnitType[] = [
    { value: 'mile', viewValue: 'Miles' },
    { value: 'minute', viewValue: 'Minutes' },
    { value: 'km', viewValue: 'Kilometres' }
  ];
  dialogMode = DialogMode;
  defaultUnit: UnitType | null | undefined = null;

  catchmentSizeControl: FormControl;
  catchmentSingleSizeControl: FormControl;
  catchmentUnitControl: FormControl;
  driveCatchmentSpeedControl: FormControl;

  catchmentFormGroup: UntypedFormGroup;
  shapeCircleConfig: CircleShapeDefaults;
  generalUserSettings: GeneralSettings | null;
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults;
  shapeWalkDriveTimeConfig: DriveTimeShapeDefaults;
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults;
  driveCatchmentSpeedList = DriveCatchmentSpeedNames;
  locatorShapesDefaults$ = this.store$.select(
    fromStore.getLocatorShapesDefaults
  );
  generalUserSettings$ = this.store$.select(fromStore.getUserGeneralSettings);

  driveCatchmentSpeedDefault$ = this.store$.select(
    fromStore.getDefaultDriveCatchmentSpeed
  );

  driveCatchmentSpeedDefault: DriveCatchmentSpeeds;
  subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store$: Store<fromLocatorStore.State>
  ) {}

  ngOnInit(): void {
    this.subscribeToShapeDefaults();
    this.subscribeToSpeedDefaults();
    this.subscribeToGeneralUserSettings();
    this.createFormControls();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isInvalidForm(): boolean {
    return (
      !(this.catchmentFormGroup && this.catchmentFormGroup.controls) ||
      !this.catchmentFormGroup.dirty ||
      this.catchmentFormGroup.invalid ||
      this.isShapeDuplicate() ||
      this.isShapeSizeExceeded() ||
      this.isShapeSizeEmpty() ||
      this.isShapeLessThanMinLength() ||
      this.isLocationSizeExceeded()
    );
  }

  isShapeDuplicate(): boolean {
    return (
      this.catchmentFormGroup.controls.size.dirty &&
      this.catchmentFormGroup.controls.size.errors?.shapeDuplicate
    );
  }
  isShapeSizeExceeded(): boolean {
    return (
      this.catchmentFormGroup.controls.size.dirty &&
      this.catchmentFormGroup.controls.size.errors?.isShapeSizeExceeded
    );
  }

  isShapeSizeEmpty() {
    return (
      this.catchmentFormGroup.controls.size.dirty &&
      this.catchmentFormGroup.controls.size.errors?.required
    );
  }

  isShapeSizeInteger() {
    return (
      this.catchmentFormGroup.controls.size.dirty &&
      this.catchmentFormGroup.controls.size.errors?.isInteger
    );
  }

  isShapeLessThanMinLength() {
    return (
      this.catchmentFormGroup.controls.size.dirty &&
      this.catchmentFormGroup.controls.size.errors?.min
    );
  }

  getShapeTypeName(): string {
    return getShapeTypeName(this.shapeType);
  }

  getShapeRangeLimit() {
    return getShapeRangeLimit(
      this.shapeCircleConfig,
      this.shapeCarDriveTimeConfig,
      this.shapeWalkDriveTimeConfig,
      this.shapePublicTransportDriveTimeConfig,
      this.shapeType
    );
  }

  isDrivetimeShape(): boolean {
    return (
      this.shapeType === LocatorShapeTypes.Car ||
      this.shapeType === LocatorShapeTypes.Walk ||
      this.shapeType === LocatorShapeTypes.PublicTransport
    );
  }

  isDriveCatchmentSpeedShape(): boolean {
    return (
      this.shapeType === LocatorShapeTypes.Car ||
      this.shapeType === LocatorShapeTypes.PublicTransport
    );
  }

  isLocationSizeExceeded(): boolean {
    return this.locationsCount > 100;
  }

  getDriveCatchmentSpeedDescription(value: DriveCatchmentSpeeds): string {
    const speedName = DriveCatchmentSpeedNames.find(
      (speed) => speed.key === value
    );
    return speedName ? DriveCatchmentSpeeds[value] : '';
  }

  getMaxChipsAllowed() {
    return this.locationsCount > 1 ? 1 : 6;
  }

  getCurrentShapes(
    currentShapes: LocatorShape[] | null
  ): LocatorShape[] | null {
    return this.hasMultipleSelections() ? [] : currentShapes;
  }

  private hasMultipleSelections() {
    return this.locationsCount > 1;
  }

  private subscribeToGeneralUserSettings() {
    this.subscription.add(
      this.generalUserSettings$.subscribe((settings) => {
        this.generalUserSettings = settings;
      })
    );
  }

  private subscribeToSpeedDefaults() {
    this.subscription.add(
      this.driveCatchmentSpeedDefault$.subscribe((driveCatchmentSpeed) => {
        this.driveCatchmentSpeedDefault = driveCatchmentSpeed;
      })
    );
  }

  private subscribeToShapeDefaults() {
    this.subscription.add(
      this.locatorShapesDefaults$.subscribe((shapeDefaults) => {
        this.shapeCarDriveTimeConfig = shapeDefaults!.car!;
        this.shapeWalkDriveTimeConfig = shapeDefaults!.walk!;
        this.shapeCircleConfig = shapeDefaults!.circle!;
        this.shapePublicTransportDriveTimeConfig =
          shapeDefaults!.publicTransport!;
      })
    );
  }

  private createFormControls() {
    this.catchmentSizeControl = new FormControl(
      this.shape ? [this.defaultSize] : [],
      [Validators.required]
    );
    this.catchmentSingleSizeControl = new FormControl(
      this.defaultSize ? this.defaultSize : 0
    );

    const driveCatchmentSpeedInitalValue =
      this.generalUserSettings && this.generalUserSettings?.driveCatchmentSpeed
        ? this.driveCatchmentSpeedList[
            this.generalUserSettings.driveCatchmentSpeed
          ]
        : this.driveCatchmentSpeedList[this.driveCatchmentSpeedDefault];

    let initialDriveCatchmentSpeed = driveCatchmentSpeedInitalValue.key;

    if (this.mode === DialogMode.Edit && this.shape.drivetimeConfiguration) {
      const driveTimeConfig = JSON.parse(this.shape.drivetimeConfiguration);
      const driveCatchmentSpeed = driveTimeConfig.DriveCatchmentSpeed;

      const selectedSpeed = this.driveCatchmentSpeedList.find(
        (speed) => DriveCatchmentSpeeds[speed.key] === driveCatchmentSpeed
      );

      initialDriveCatchmentSpeed = selectedSpeed
        ? selectedSpeed.key
        : this.driveCatchmentSpeedList[
            this.generalUserSettings!.driveCatchmentSpeed!
          ].key;
    }

    this.driveCatchmentSpeedControl = new FormControl({
      value: initialDriveCatchmentSpeed,
      disabled: false
    });

    if (this.mode == DialogMode.Edit) {
      this.subscription.add(
        this.driveCatchmentSpeedControl.valueChanges.subscribe((value) => {
          this.updateCatchmentSingleSizeControlValidators();
        })
      );
      this.updateCatchmentSingleSizeControlValidators();
    }

    this.defaultUnit = this.unitTypes.find(
      (unit) => unit.value === this.defaultShapeUnit
    );

    this.catchmentUnitControl = new FormControl({
      value: this.defaultUnit?.value,
      disabled: true
    });

    this.catchmentFormGroup = this.fb.group({
      size:
        this.mode === DialogMode.Add
          ? this.catchmentSizeControl
          : this.catchmentSingleSizeControl,
      unit: this.catchmentUnitControl,
      driveCatchmentSpeed: this.driveCatchmentSpeedControl
    });
  }

  private updateCatchmentSingleSizeControlValidators(): void {
    let validators = [Validators.required, Validators.min(0.01)];
    validators.push(
      shapeDuplicateValidator(
        this.currentShapes,
        this.shapeType,
        this.defaultSize,
        this.getDriveCatchmentSpeedDescription(
          this.driveCatchmentSpeedControl.value
        )
      ),
      shapeSizeExceededValidator(
        this.shapeCircleConfig,
        this.shapeCarDriveTimeConfig,
        this.shapeWalkDriveTimeConfig,
        this.shapePublicTransportDriveTimeConfig,
        this.shapeType
      )
    );

    if (this.isDrivetimeShape()) {
      validators.push(Validators.pattern('^[0-9]*$'));
    }

    this.catchmentSingleSizeControl.setValidators(validators);
    this.catchmentSingleSizeControl.updateValueAndValidity();
  }
}
