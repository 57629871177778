import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';

import * as fromSmStore from 'src/app/spatial-modeller-store';
import * as fromLocatorStore from 'src/app/locator-store';
import * as fromUIStore from 'src/app/core/store';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';

@Component({
  selector: 'atlas-expand-lofi-report',
  templateUrl: './expanded-lofi-reports.component.html',
  styleUrls: ['./expanded-lofi-reports.component.less']
})
export class ExpandedLoFiReportComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  selectedLocation: Point | undefined;
  heading: number = 0;
  imageNumber: number = 1;

  hidden: boolean = false;

  pieGridView: [number, number] = [250, 620];
  numberCardView: [number, number] = [300, 620];

  constructor(
    private spatialModellerStore$: Store<fromSmStore.State>,
    private locatorStore$: Store<fromLocatorStore.State>,
    public layoutService: LayoutService,
    private UIStore$: Store<fromUIStore.State>
  ) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.layoutService.screenSize$.subscribe((b) => this.breakpointChanged(b))
    );
  }

  isSMFeatureSelected$ = this.UIStore$.select(fromUIStore.isSMFeatureSelected);
  isLocatorFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isLocatorFeatureSelected
  );

  areTestStatsAvailable$ = this.spatialModellerStore$.select(
    fromSmStore.getAreTestStatsAvailable
  );

  isLofiReportAvailable$ = this.locatorStore$.select(
    fromLocatorStore.getIsLofiReportAvailable
  );

  enableExpandLofiReportPanel$ = combineLatest([
    this.areTestStatsAvailable$,
    this.isLofiReportAvailable$
  ]).pipe(
    map(([statsAvailable, lofiReportAvailable]) => {
      return statsAvailable || lofiReportAvailable;
    })
  );

  showLofiPanel() {
    this.hidden = true;
    return { visible: true };
  }

  hideLofiPanel() {
    this.hidden = false;
    return { visible: false };
  }

  private breakpointChanged(breakpoint: BreakpointState) {
    if (breakpoint.breakpoints[Breakpoints.XLarge]) {
      this.numberCardView = [600, 500];
      this.pieGridView = [600, 500];
    } else if (breakpoint.breakpoints[Breakpoints.Large]) {
      this.numberCardView = [450, 450];
      this.pieGridView = [450, 450];
    } else if (breakpoint.breakpoints[Breakpoints.Medium]) {
      this.numberCardView = [250, 300];
      this.pieGridView = [250, 300];
    } else if (breakpoint.breakpoints[Breakpoints.Small]) {
      this.numberCardView = [250, 300];
      this.pieGridView = [250, 300];
    }
  }
}
