import { LocatorService } from './locator.service';
import { LocatorImportService } from './locator-import.service';
import { LocatorImportValidationService } from './locator-import-validation.service';
import { LocatorUploadService } from './locator-upload.service';

export const services = [
  LocatorService,
  LocatorImportService,
  LocatorImportValidationService,
  LocatorUploadService
];
