import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import * as fromUIStore from 'src/app/core/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'atlas-main-tools-panel',
  templateUrl: './main-tools-panel.component.html',
  styleUrls: ['./main-tools-panel.component.less']
})
export class MainToolsPanelComponent implements OnInit {
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  constructor(
    public layoutService: LayoutService,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {}
}
