import { Directive, Host } from '@angular/core';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';

@Directive({
  selector: '[atlasDxiBandOptions]',
})
export class AtlasDxiBandOptionsDirective {
  constructor(@Host() dataGridBand: DxiColumnComponent) {
    dataGridBand.allowReordering = false;
    dataGridBand.allowHiding = false;
    dataGridBand.allowResizing = true;
    dataGridBand.cssClass = 'atlas-dxi-band-headers';
  }
}
