import { Component, Input } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog.service';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { EditLocationDescriptionDialogComponent } from '../edit-location-description-dialog/edit-location-description-dialog.component';

@Component({
  selector: 'atlas-edit-location-description',
  templateUrl: './edit-location-description.component.html',
  styleUrls: ['./edit-location-description.component.less']
})
export class EditLocationDescriptionComponent {
  @Input() currentLocation: LocatorLibraryData | null;
  @Input() areMultipleLocationsSelected: boolean | null;

  constructor(public dialogService: DialogService) {}
  onOpenEditLocationNameDialog() {
    this.dialogService.show(EditLocationDescriptionDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-95vw-width',
      data: {
        entityName: 'Name',
        mode: DialogMode.Add,
        headerPrefix: 'Location',
        location: this.currentLocation,
        affirmativeButtonText: 'Update'
      },
      disableClose: true
    });
  }
}
