@if(mode === dialogMode.Add){
<div>
  @if(locationsCount > 1){
  <div class="text-container">
    Enter the value of the catchment you would like to add (Max 1)
  </div>
  } @else{
  <div>
    <div class="text-container">
      Enter the value/s of the catchments you would like to add (Max 6)
    </div>
  </div>
  }
</div>
}

<div class="text-container" *ngIf="mode === dialogMode.Edit">
  Enter a new value to modify the size of the selected catchment
</div>
<p *ngIf="isShared" class="shared-text">
  This {{ shape.name }} has been shared in a report, by editing this catchment
  the report will reflect these changes.
</p>
<p *ngIf="isLocationSizeExceeded()" class="error-message">
  A maximum of 100 locations can be used to create catchments.
</p>

<form [formGroup]="catchmentFormGroup" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field
      appearance="outline"
      hideRequiredMarker="true"
      *ngIf="isDriveCatchmentSpeedShape()"
    >
      <mat-label>Drive catchment speed</mat-label>
      <mat-select
        [hideSingleSelectionIndicator]="true"
        [formControl]="driveCatchmentSpeedControl"
      >
        <mat-option
          *ngFor="let option of driveCatchmentSpeedList"
          [value]="option.key"
        >
          {{ option.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <atlas-locator-catchment-chip-list
      *ngIf="mode === dialogMode.Add"
      placeholder=""
      [formControl]="catchmentSizeControl"
      chipListTitle="Catchment sizes"
      [allowFreeTyping]="true"
      [maxChipsAllowed]="getMaxChipsAllowed()"
      [shapeType]="shapeType"
      [currentShapes]="getCurrentShapes(currentShapes)"
      [shapeCircleConfig]="shapeCircleConfig"
      [shapeCarDriveTimeConfig]="shapeCarDriveTimeConfig"
      [shapeWalkDriveTimeConfig]="shapeWalkDriveTimeConfig"
      [shapePublicTransportDriveTimeConfig]="
        shapePublicTransportDriveTimeConfig
      "
      [driveCatchmentSpeed]="
        getDriveCatchmentSpeedDescription(driveCatchmentSpeedControl.value)
      "
    >
    </atlas-locator-catchment-chip-list>

    <mat-form-field
      appearance="outline"
      *ngIf="mode === dialogMode.Edit"
      hideRequiredMarker="true"
    >
      <mat-label>Size</mat-label>
      <input
        matInput
        type="number"
        [formControl]="catchmentSingleSizeControl"
        placeholder="catchment size"
        cdkFocusIntial
      />
      <mat-error *ngIf="isShapeSizeEmpty()"
        >Please enter at least one positive integer value.</mat-error
      >
      <mat-error *ngIf="isShapeLessThanMinLength()"
        >Only a positive value is accepted.</mat-error
      >
      <mat-error *ngIf="isShapeDuplicate()">
        Shape size already exists. Please choose a different size.
      </mat-error>

      <mat-error
        *ngIf="
          !isShapeSizeInteger() && !isShapeSizeEmpty() && !isShapeDuplicate()
        "
      >
        Please enter only positive integer values.
      </mat-error>

      <mat-error *ngIf="isShapeSizeExceeded()"
        >You have exceeded the limit of {{ getShapeRangeLimit() }} for a
        {{ getShapeTypeName() }}</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Units</mat-label>
      <mat-select
        [formControl]="catchmentUnitControl"
        [hideSingleSelectionIndicator]="true"
      >
        <mat-option *ngFor="let option of unitTypes" [value]="option.value">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
