import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { SupplyPointAttribute } from '../models/supply-point-attribute';
import { Stats } from '../models/stats';
import { TestStatusResponse } from '../models/test-status-response';
import { of, timer } from 'rxjs';
import { filter, map, switchMap, take, takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ModelTestStatus } from '../models/model-test-status';
import { environment } from 'src/environments/environment';
import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import { Store } from '@ngrx/store';
import { ModelLocationResponse } from '../models/model-location-response';
import { SmReportingInfo } from '../models/sm-reporting-info';

@Injectable({ providedIn: 'root' })
export class SpatialModellerDataService {
  private atlasSpatialModellerApiUrl = `${environment.baseUrl}api/spatial-modeller/`;
  statusPollDelay = 2 * 1000;

  isTestInProgress: boolean = false;

  pollingModelLocationStatus: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>
  ) {
    this.spatialModellerStore$
      .select(fromSpatialModellerStore.getTestInProgress)
      .subscribe((isInProgress) => (this.isTestInProgress = isInProgress));
  }

  getSupplyPointAttributes() {
    return this.http.get<SupplyPointAttribute[]>(
      `${this.atlasSpatialModellerApiUrl}sm-supply-point-attributes`
    );
  }

  getSupplyPointValues(id: number) {
    return this.http.get<SupplyPoint>(
      `${this.atlasSpatialModellerApiUrl}sm-supply-point-values/${id}`
    );
  }

  // this is for the in-memory mvp model
  executeTest(supplyPoint: SupplyPoint) {
    return this.http.post<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}tests`,
      supplyPoint
    );
  }

  // this is for the in-memory mvp model
  updateModelTestResult(testId: number, jobId: string) {
    return this.http.put<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}tests/${testId}/jobs/${jobId}`,
      null
    );
  }

  executeScenarioTest(scenarioId: number, uid: number, supplyId: string) {
    return this.http.post<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}scenarios/${scenarioId}/tests`,
      { uid, supplyId }
    );
  }

  updateModelScenarioTestResult(
    scenarioId: number,
    testId: number,
    jobId: string
  ) {
    return this.http.put<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}scenarios/${scenarioId}/tests/${testId}/jobs/${jobId}`,
      null
    );
  }

  getTestStats(testId: number) {
    return this.http.get<Stats[]>(
      `${this.atlasSpatialModellerApiUrl}tests/${testId}/stats`
    );
  }

  pollForModelScenarioTestStatus(
    scenarioId: number,
    testId: number,
    jobId: string
  ) {
    this.pollingModelLocationStatus = false;
    return this.pollForModelScenarioProgressUntilComplete(
      scenarioId,
      testId,
      jobId
    ).pipe(
      map((statusResponse: TestStatusResponse) => {
        if (
          statusResponse.status === 'BAD_REQUEST' ||
          statusResponse.status === 'API_ERROR'
        ) {
          throw new Error(
            `Test execution failed. ${statusResponse.errorMessage}`
          );
        }
        if (statusResponse.status === 'FAILED') {
          throw new Error(
            `Test execution failed. Unknown error, please try again.`
          );
        }
        return statusResponse;
      }),
      filter(
        (statusResponse: TestStatusResponse) =>
          statusResponse.status === 'SUCCEEDED',
        true
      )
    );
  }

  pollForModelTestStatus(testId: number, jobId: string) {
    return this.pollForProgressUntilComplete(testId, jobId).pipe(
      map((statusResponse: TestStatusResponse) => {
        if (
          statusResponse.status === 'BAD_REQUEST' ||
          statusResponse.status === 'API_ERROR'
        ) {
          throw new Error(
            `Test execution failed. ${statusResponse.errorMessage}`
          );
        }
        if (statusResponse.status === 'FAILED') {
          throw new Error(
            `Test execution failed. Unknown error, please try again.`
          );
        }
        return statusResponse;
      }),
      filter(
        (statusResponse: TestStatusResponse) =>
          statusResponse.status === 'SUCCEEDED',
        true
      )
    );
  }

  executeModelLocationGeneration(supplyPoint: SupplyPoint) {
    return this.http.post<ModelLocationResponse>(
      `${this.atlasSpatialModellerApiUrl}model/locations`,
      supplyPoint
    );
  }

  pollForModelLocationStatus(name: string) {
    this.pollingModelLocationStatus = true;
    return this.pollForModelLocationProgressUntilComplete(name).pipe(
      map((statusResponse: ModelLocationResponse) => {
        if (statusResponse.status !== 'RUNNING') {
          this.pollingModelLocationStatus = false;
        }

        if (
          statusResponse.status === 'BAD_REQUEST' ||
          statusResponse.status === 'API_ERROR'
        ) {
          throw new Error(
            `Test execution failed. ${statusResponse.errorMessage}`
          );
        }
        if (statusResponse.status === 'INACCESSIBLE_SUPPLYPOINT') {
          throw new Error(
            `Inaccessible road network. Please try with a different location.`
          );
        }
        return statusResponse;
      }),
      filter(
        (statusResponse: ModelLocationResponse) =>
          statusResponse.status === 'SUCCEEDED' ||
          statusResponse.status === 'POLLING_MODEL_LOCATION_CANCELLED' ||
          statusResponse.status === 'FAILED',
        true
      )
    );
  }

  private pollForModelLocationProgressUntilComplete(name: string) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.authService.isUserAuthenticated().pipe(
          switchMap((isUserAuthenticated) => {
            return isUserAuthenticated
              ? this.pollingModelLocationStatus
                ? this.getModelLocationStatus(name)
                : of({
                    name: name,
                    status:
                      'POLLING_MODEL_LOCATION_CANCELLED' as ModelTestStatus,
                    errorMessage: '',
                    uId: ''
                  })
              : of({
                  name: name,
                  status: 'NOT_AUTHENTICATED' as ModelTestStatus,
                  errorMessage: '',
                  uId: ''
                });
          })
        )
      ),
      takeWhile(
        (statusResponse: ModelLocationResponse) =>
          statusResponse.status === 'RUNNING',
        true
      )
    );
  }

  private pollForProgressUntilComplete(testId: number, jobId: string) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.authService.isUserAuthenticated().pipe(
          switchMap((isUserAuthenticated) => {
            return isUserAuthenticated
              ? this.getModelTestStatus(testId, jobId)
              : of({
                  testId: testId,
                  jobId: jobId,
                  status: 'NOT_AUTHENTICATED' as ModelTestStatus,
                  errorMessage: ''
                });
          })
        )
      ),
      takeWhile(
        (statusResponse: TestStatusResponse) =>
          statusResponse.status === 'RUNNING' && this.isTestInProgress,
        true
      )
    );
  }

  private pollForModelScenarioProgressUntilComplete(
    scenarioId: number,
    testId: number,
    jobId: string
  ) {
    return timer(0, this.statusPollDelay).pipe(
      switchMap(() =>
        this.authService.isUserAuthenticated().pipe(
          switchMap((isUserAuthenticated) => {
            return isUserAuthenticated
              ? this.getModelScenarioTestStatus(scenarioId, testId, jobId)
              : of({
                  testId: testId,
                  jobId: jobId,
                  status: 'NOT_AUTHENTICATED' as ModelTestStatus,
                  errorMessage: ''
                });
          })
        )
      ),
      takeWhile(
        (statusResponse: TestStatusResponse) =>
          statusResponse.status === 'RUNNING' && this.isTestInProgress,
        true
      )
    );
  }

  private getModelTestStatus(testId: number, jobId: string) {
    return this.http.get<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}tests/${testId}/jobs/${jobId}`
    );
  }

  private getModelLocationStatus(name: string) {
    return this.http.get<ModelLocationResponse>(
      `${this.atlasSpatialModellerApiUrl}model/locations/${name}`
    );
  }

  private getModelScenarioTestStatus(
    scenarioId: number,
    testId: number,
    jobId: string
  ) {
    return this.http.get<TestStatusResponse>(
      `${this.atlasSpatialModellerApiUrl}scenarios/${scenarioId}/tests/${testId}/jobs/${jobId}`
    );
  }
}
