import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atlas-layer-trigger-scale-text',
  templateUrl: './layer-trigger-scale-text.component.html',
  styleUrls: ['./layer-trigger-scale-text.component.less']
})
export class LayerTriggerScaleTextComponent implements OnInit {
  @Input() layer: any;

  constructor() {}

  ngOnInit(): void {}
}
