import { createAction, props } from '@ngrx/store';
import { UserUsage } from '../../models/user-usage';

export const getUserUsageListAttempt = createAction(
  '[Usage WorkSpace Component] Get User usage Info Attempt'
);

export const getUserUsageListSuceeded = createAction(
  '[Usage WorkSpace Component] Get User usage List Suceeded',
  props<{ userUsageList: UserUsage[] }>()
);

export const getUserUsageListFailed = createAction(
  '[AtlasAPI] Get User Usage List Failed',
  props<{ errorOn: string; error: string }>()
);
