import { createAction, props } from '@ngrx/store';
import { ProfilerBaseClassification } from 'src/app/profiler/model/profiler-base-classification';
import { ProfilerLibrary } from 'src/app/profiler/model/profiler-library';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';

export const getProfilerLibrarySucceded = createAction(
  '[Profiler Library Effect] Get Profiler Library Succeded',
  props<{ library: ProfilerLibrary }>()
);

export const getProfilerLibraryCountSucceded = createAction(
  '[Profiler Library Effect] Get Profiler Library Count Succeded',
  props<{
    result: boolean;
  }>()
);

export const noLastEditedProfileFound = createAction(
  '[Profiler Library Effect] No Last Edited Profile Found'
);

export const createProfilerLibraryDialogSaveClicked = createAction(
  '[Add Library Dialog] Create Profiler Library Save Clicked',
  props<{
    libraryRequest: {
      name: string;
      file: File;
    };
  }>()
);

export const createProfilerLibrarySucceeded = createAction(
  '[Atlas API] Create Profiler Library Succeeded',
  props<{
    library: ProfilerLibrary;
    file: File;
  }>()
);

export const updateProfilerLibraryDialogSaveClicked = createAction(
  '[Edit Profiler Library Dialog] Update Profiler Library Save Clicked',
  props<{
    id: number;
    libraryRequest: {
      name: string;
    };
  }>()
);

export const swapProfilerLibraryAttempt = createAction(
  '[Swap Library Dialog] Swap Profiler Library Attempt',
  props<{ id: number }>()
);

export const swapProfilerLibrarySucceeded = createAction(
  '[Atlas API] Swap profiler Library Succeeded',
  props<{ library: ProfilerLibrary }>()
);

export const updateProfilerLibrarySucceeded = createAction(
  '[Atlas API] Update Profiler Library Succeeded',
  props<{ library: ProfilerLibrary }>() // WIP: it will be ProfilerLibrary type, not any
);

export const deleteProfilerLibraryAttempt = createAction(
  '[Delete Library Dialog] Delete Profiler Library Attempt',
  props<{ id: number }>()
);
export const cancelAndRetrieveLastLibraryAttempt = createAction(
  '[Profiler Library Effect] Cancel Importing Attempt',
  props<{ id: number }>()
);

export const deleteProfilerLibrarySucceeded = createAction(
  '[Atlas API] Delete Profiler Library Succeeded',
  props<{ id: number }>()
);

export const profilerLibraryErrorOccurred = createAction(
  '[Atlas Backend API] Profiler Library Error Ocurred',
  props<{ errorOn: string; error: string }>()
);

export const getProfilerPowerBiReportingInfoSucceded = createAction(
  '[Profiler Library Effect] Get Profiler Power Bi Reporting Info Succeded',
  props<{ pbiReports: ProfilerPowerBiReportingInfo[] }>()
);

export const getProfilerPowerBiReportingInfoErrorOccurred = createAction(
  '[Atlas Backend API] Get Profiler Power Bi Reporting Info Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const getProfilerLoFiReportAttempt = createAction(
  '[Profiler Reporting Effect] Get Profiler Lofi report attempt',
  props<{ libraryId: number }>()
);

export const getProfilerLoFiReportSucceded = createAction(
  '[Profiler Library Effect] Get Profiler Lofi report Succeded',
  props<{ report: ProfilerLofiQueryResult[] }>()
);

export const getProfilerLoFiReportErrorOccurred = createAction(
  '[Profiler Backend API] Get Profiler Lofi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const generateProfilerLoFiReportAttempt = createAction(
  '[Profiler Properties] Generate Profiler Lofi report attempt',
  props<{ libraryId: number; classificationId: number; baseId: number }>()
);

export const generateProfilerLoFiReportSucceded = createAction(
  '[Profiler Reporting Effect] Generate Profiler Lofi report Succeded',
  props<{ report: ProfilerLofiQueryResult[] }>()
);

export const generateProfilerLoFiReportErrorOccurred = createAction(
  '[Atlas Backend API] Generate Profiler Lofi Report Error Occurred',
  props<{ errorOn: string; error: string }>()
);

export const getBaseAndClassificationSucceded = createAction(
  '[Atlas Backend API] Get Base And Classification Succeeded',
  props<{ baseAndClassification: ProfilerBaseClassification }>()
);

export const baseSelectionChanged = createAction(
  '[Profiler Library Effect] Base Selection Changed',
  props<{ selectedBaseId: number }>()
);

export const classificationSelectionChanged = createAction(
  '[Profiler Library Effect] Classification Selection Changed',
  props<{ selectedClassificationId: number }>()
);

export const configureProfilerLocationsLayer = createAction(
  '[Profiler reporting Effect] Configure Profiler locations layer'
);

export const profilerLocationsLayerMedianPointFound = createAction(
  '[Profiler Layer management  Effect] Profiler locations layer median point found and map repositioned'
);

export const profilerLocationsLayerMedianPointNotFound = createAction(
  '[Profiler Layer management  Effect] Profiler locations layer median point not found',
  props<{ errorOn: string; error: string }>()
);

export const displaySnackbarAfterProfilerLocationsPointFound = createAction(
  '[Profiler Layer management  Effect] Display snackbar after Profiler locations point found'
);

export const clearProfilerLibraryStateOnLogoff = createAction(
  '[Menu component] Reset the Profiler library state on log off'
);

export function hasProfilerLimitBeenExceededAttempt(arg0: { id: number }): any {
  throw new Error('Function not implemented.');
}
