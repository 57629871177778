<atlas-add-edit-dialog
  [saveButtonText]="data.affirmativeButtonText"
  [entityName]="data.entityName"
  [headerPrefix]="data.headerPrefix"
  [mode]="data.mode"
  (saveClicked)="onSaveClicked(editLocationNameForm)"
  [saveDisabled]="editLocationNameForm.invalidForm()"
  class="add-edit-dialog-content"
>
  <atlas-edit-location-description-form
    #editLocationNameForm
    formContent
    [location]="data.location"
  ></atlas-edit-location-description-form>
</atlas-add-edit-dialog>
