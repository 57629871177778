import { Component, OnDestroy, OnInit } from '@angular/core';

import * as fromProfilerStore from 'src/app/profiler-store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProfilerImportStatus } from 'src/app/profiler/model/profiler-import-status';
import { ProfilerStatusTypes } from 'src/app/profiler/helpers/profiler-status-helper';

@Component({
  selector: 'atlas-profiler-import-summary',
  templateUrl: './profiler-import-summary.component.html',
  styleUrls: ['./profiler-import-summary.component.less']
})
export class ProfilerImportSummaryComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  libraryId$ = this.store$.select(fromProfilerStore.getProfilerLibraryId);
  currentLibraryStatus$ = this.store$.select(
    fromProfilerStore.getProfilerState
  );
  libraryId: number;

  createDate: Date;
  importedRecords: number;
  profiledRecords: number;
  profiledRecordsPercentage: string;
  status: ProfilerStatusTypes | null;
  errorMessage: string;

  constructor(private store$: Store<fromProfilerStore.State>) {}

  ngOnInit(): void {
    const getImportProfileSummary = this.libraryId$.pipe(
      filter((libraryId) => libraryId != null && libraryId > 0),
      withLatestFrom(
        this.store$.select(fromProfilerStore.getProfilerImportStatus)
      ),
      map(([libraryId, importStatus]) => {
        this.libraryId = libraryId;
        return this.getImportProfileSummary(importStatus);
      })
    );
    this.subscription.add(getImportProfileSummary.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isProfileStatusSucceded() {
    return this.status === 'ReportAggregateCompleted';
  }

  private getImportProfileSummary(importSummary: ProfilerImportStatus | null) {
    if (importSummary) {
      this.createDate = importSummary.createDt;
      this.importedRecords = importSummary.importedRecords;
      this.profiledRecords = importSummary.profiledRecords;
      this.profiledRecordsPercentage = (
        (this.profiledRecords * 100) /
        this.importedRecords
      ).toFixed(2);
      this.status = importSummary.status;
    } else {
      this.status = null;
    }
  }
}
