import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AtlasDxColumnFormatService {
  readonly cost = { type: 'fixedPoint', precision: '2' };

  readonly costWithoutDecimal = { type: 'fixedPoint', precision: '0' };

  readonly quantity = { type: 'fixedPoint', precision: '2' };

  readonly distance = { type: 'fixedPoint', precision: '2' };

  readonly distanceWithoutDecimal = { type: 'fixedPoint', precision: '0' };

  readonly time = { type: 'HH:mm' };

  readonly dateTime = { type: 'dd MMM yyyy HH:mm' };

  readonly date = { type: 'dd MMM yyyy' };
}
