import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';

/*
  this to be used as a routing guard to prevent the user from navigating to pages without logining on

*/

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private _router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuthenticated();
  }

  canLoad(
    route: import('@angular/router').Route,
    segments: import('@angular/router').UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAuthenticated();
  }

  private checkAuthenticated() {
    return Auth.currentAuthenticatedUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        this._router.navigate(['/login']);
        return false;
      });
  }
}
