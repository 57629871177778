<div>
  <div class="atlas-panel-header"
    (swipeup)="swipeUp()"
    (swipedown)="swipeDown()"
  >
    <div class="grab-handle"></div>
    <span *ngIf="!disabled"
      class="expansion-indicator mat-expansion-indicator"
      [@indicatorRotate]="getClosedState()" 
      (click)="toggle()"
      >
    </span>
  </div>

  <div
    [class.expanded]="expandedChange | async"
    [class.disabled]="disabled"
    [@bodyExpansion]="getExpandedState()"
  >
    <ng-content></ng-content>
  </div>
</div>
