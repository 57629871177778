import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as fromSpatialModellerStore from 'src/app/spatial-modeller-store';
import * as fromGazetteerStore from 'src/app/shared/atlas-gazetteer/store';
import { exportReportClicked } from 'src/app/spatial-modeller-store/actions/sm-report.actions';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SmReportingDialogComponent } from '../sm-reporting-dialog/sm-reporting-dialog.component';
import { DialogWidth } from 'src/app/shared/atlas-dialog/enums/dialog-width.enum';
import { SmReportingInfo } from 'src/app/spatial-modeller/models/sm-reporting-info';
import { Subscription } from 'rxjs';
import { SupplyPoint } from 'src/app/spatial-modeller/models/supply-point';
import { ReportTypes } from 'src/app/spatial-modeller/types/report-types';

@Component({
  selector: 'atlas-sm-reports-panel-buttons',
  templateUrl: './sm-reports-panel-buttons.component.html',
  styleUrls: ['./sm-reports-panel-buttons.component.less']
})
export class SmReportsPanelButtonsComponent implements OnInit, OnDestroy {
  isSelectedPointEmpty$ = this.gazetteerStore$.select(
    fromGazetteerStore.getIsSelectedPointEmpty
  );

  isReportInProgress$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getReportInProgress
  );

  reportCount$ = this.spatialModellerStore$.select(
    fromSpatialModellerStore.getReportCount
  );

  reportList: SmReportingInfo[] = [];
  selectedSupplyPoint: SupplyPoint | null;
  testModelJobId: string | null;

  private subscription: Subscription = new Subscription();

  constructor(
    private spatialModellerStore$: Store<fromSpatialModellerStore.State>,
    private gazetteerStore$: Store<fromGazetteerStore.State>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.spatialModellerStore$
        .select(fromSpatialModellerStore.getReports)
        .subscribe((reports) => {
          this.reportList = reports ? reports : [];
        })
    );

    this.subscription.add(
      this.spatialModellerStore$
        .select(fromSpatialModellerStore.getSpatialModellerSupplyPoint)
        .subscribe((sp) => {
          this.selectedSupplyPoint = sp;
        })
    );

    this.subscription.add(
      this.spatialModellerStore$
        .select(fromSpatialModellerStore.getTestModelJobId)
        .subscribe((testModelJobId) => {
          this.testModelJobId = testModelJobId;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onFileDownloadClicked() {
    if (
      this.reportList.length === 1 &&
      this.reportList[0].reportType === ReportTypes.Excel
    ) {
      this.spatialModellerStore$.dispatch(
        exportReportClicked({ reportId: this.reportList[0].excelReportId })
      );
    }
  }

  onLaunchReporting() {
    this.dialogService.show(SmReportingDialogComponent, {
      width: DialogWidth.Small,
      panelClass: 'dialog-full-width-height',
      data: {
        smReportList: this.reportList,
        selectedSupplyPoint: this.selectedSupplyPoint,
        testModelJobId: this.testModelJobId
      },
      disableClose: true
    });
  }
}
