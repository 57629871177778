<div [formGroup]="formGroup" class="radio-group-container">
    <mat-label>{{control.displayText}}:</mat-label>
    <mat-radio-group [formControlName]="control.name" class="radio-group-layout">
        <mat-radio-button *ngFor="let option of control.keyPairValueItems" [value]="option.key.toString()" class="radio-button-spacing">
            {{option.value}}
        </mat-radio-button>
    </mat-radio-group>
    <atlas-dynamic-form-validation 
        [formGroup]="formGroup" 
        [control]="control"
    >
    </atlas-dynamic-form-validation>
</div>