<div
  class="stats-item"
  [class.secondary-stats-item-background]="!isMainStats"
  [class.secondary-stats-item-right-margin]="isOddIndex && !isMainStats"
  [class.secondary-stats-item-left-margin]="!isOddIndex && !isMainStats"
>
  <mat-icon class="stats-icon">{{ iconName }} </mat-icon>
  <div style="width: 10px"></div>
  <div class="stats-text">
    <span class="stats-value">{{ value }}</span>
    <span class="stats-name body-medium">{{ name }}</span>
  </div>
</div>
