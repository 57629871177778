import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { isShapeSizeExceeded } from 'src/app/locator/helpers/locator-shape.helper';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults
} from '../../models/locator-shape-defaults';

export function shapeSizeExceededValidator(
  shapeCircleConfig: CircleShapeDefaults,
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults,
  shapeWalkDriveTimeConfig: DriveTimeShapeDefaults,
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults,
  shapeType: LocatorShapeTypes
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const sizeValue = control.value;
    if (
      isShapeSizeExceeded(
        shapeCircleConfig,
        shapeCarDriveTimeConfig,
        shapeWalkDriveTimeConfig,
        shapePublicTransportDriveTimeConfig,
        shapeType,
        [sizeValue]
      )
    ) {
      return { isShapeSizeExceeded: true };
    }
    return null;
  };
}
