import { AbstractControl } from '@angular/forms';

export function hasMinLength(control: AbstractControl) {
  return control.errors?.lessThanMinLength ? false : true;
}

export function hasNumber(control: AbstractControl) {
  return control.errors?.NoContainNumber ? false : true;
}

export function hasLowerCase(control: AbstractControl) {
  return control.errors?.NoContainLowerCase ? false : true;
}

export function hasUpperCase(control: AbstractControl) {
  return control.errors?.NoContainUpperCase ? false : true;
}

export function hasSpecialChar(control: AbstractControl) {
  return control.errors?.NoContainSpecialChar ? false : true;
}
