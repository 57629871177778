import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectionListChange } from '@angular/material/list';

import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';

import {
  applyFilter,
  applySort,
  ListSortOptions,
  SearchAndSortValues
} from 'src/app/shared/utils/list-utils';

@Component({
  selector: 'atlas-locator-reference-library-form',
  styleUrls: ['reference-library-form.component.less'],
  templateUrl: 'reference-library-form.component.html'
})
export class LocatorReferenceLibraryFormComponent implements OnInit {
  private selectedSortOption: ListSortOptions = 'NameAsc';
  private __libaries: LocatorReferenceLibrary[] = [];

  selectedLibrary: LocatorReferenceLibrary | null = null;

  @Input()
  set libaries(newValue: LocatorReferenceLibrary[]) {
    this.__libaries = [...newValue];
    this.dataSource = applySort(this.selectedSortOption, [...newValue]);
  }
  get libaries(): LocatorReferenceLibrary[] {
    return this.__libaries;
  }

  locatorReferenceLibraryForm: UntypedFormGroup;
  dataSource: LocatorReferenceLibrary[];

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    const initialValue: SearchAndSortValues = {
      filter: ''
    } as SearchAndSortValues;
    this.locatorReferenceLibraryForm = this.createForm(initialValue);
  }

  createForm(initialLibraryValue: SearchAndSortValues) {
    return this.fb.group({
      filter: [initialLibraryValue.filter]
    });
  }

  onFilterChanged(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource = applyFilter(filterValue, this.__libaries);
  }

  onSelectionChange(change: MatSelectionListChange) {
    this.selectedLibrary = change.options.find(
      (option) => option.selected
    )?.value;
  }
}
