<div class="selected-location-info">
  <div class="address-info-container">
    <atlas-properties-legend></atlas-properties-legend>
    @if (areMultipleLocationsSelected) {
      <div class="address-info">
        <h3>N/a</h3>
        <span class="secondary-address-info">N/a</span>
      </div>
    }
    @else {
      @if(!(updateLocation$ | async)){
        <div class="address-info">
          @if(areSupplyPointAddressPropertiesEmpty()){
          <h3>{{ address | mainAddressInfo }}</h3>
          <span class="secondary-address-info">
            {{ address | secondaryAddressInfo }}
          </span>
          } @else {
          <span>{{
            [
              selectedSupplyPoint!.address1,
              selectedSupplyPoint!.address2,
              selectedSupplyPoint!.address3,
              selectedSupplyPoint!.address4
            ] | concatSupplyPointAddressProperties
          }}</span>
          }
        </div>
      } 
      @else{
        <div class="change-location-container">
          @if(!(newLocationSelected$ | async)){
          <span> Select a new location </span>
          } @else{
          <div>
            <span class="secondary-address-info">Location selected: </span>
            <span class="secondary-address-info">
              {{ address | mainAddressInfo }}, {{ address | secondaryAddressInfo }}
            </span>
          </div>
          }
        </div>
      }
    }

    @if(isSmFeatureSelected$ | async){
      <div>
        @if(!(updateLocation$ | async)){
        <span
          [class]="isChangeLocationAllowed() ? 'link' : 'link-disabled'"
          (click)="
            isChangeLocationAllowed()
              ? onChangeLocation()
              : $event.preventDefault()
          "
        >
          <u>Change location</u>
        </span>
        } @else{
        <div class="change-location-options">
          <span class="link" (click)="onConfirm()">
            <u>Confirm</u>
          </span>
          <span class="link" (click)="onCancel()">
            <u>Cancel</u>
          </span>
        </div>
        }
      </div>
    }
  </div>

  <div class="coordinates-container">
    <div class="coordinates-info-title">Coordinates</div>
    <div class="coordinates-info">
      @if (areMultipleLocationsSelected) {
        <span>N/a</span>
      }
      @else {
        <span> {{ selectedLocation?.latitude }}</span>
        <span> {{ selectedLocation?.longitude }}</span>
      }
    </div>
  </div>
</div>
