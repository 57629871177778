import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'atlas-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
})
export class ResetPasswordComponent {
  forgotPasswordForm = this.fb.group({
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
        ),
      ]),
    ],
  });

  constructor(private fb: UntypedFormBuilder, private authService: AuthService) {}

  onForgotPassword() {
    this.authService.sendEmailForgotPassword(
      this.forgotPasswordForm.controls.email.value
    );
  }

  invalidEmail() {
    return (
      this.forgotPasswordForm.controls.email.invalid &&
      (this.forgotPasswordForm.dirty || this.forgotPasswordForm.touched) &&
      this.forgotPasswordForm.controls.email.errors &&
      (this.forgotPasswordForm.controls.email.errors.required ||
        this.forgotPasswordForm.controls.email.errors.pattern)
    );
  }

  disabledButton() {
    return (
      this.forgotPasswordForm.controls.email.invalid ||
      (this.forgotPasswordForm.controls.email.errors &&
        (this.forgotPasswordForm.controls.email.errors.required ||
          this.forgotPasswordForm.controls.email.errors.pattern))
    );
  }

  onGoBackToLogin() {
    this.authService.removeErrorMessage();
    this.authService.setAuthAction('login');
  }
}
