import { Component, Input } from '@angular/core';

@Component({
  selector: 'atlas-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.less']
})
export class DialogHeaderComponent {
  @Input() headerText: string;
  @Input() showProgressBar: boolean = false;
  @Input() noOfStages: number;
  @Input() currentStage: number;
}
