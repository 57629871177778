import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromLocatorStore from 'src/app/locator-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import {
  addingPolygonConfirmComplete,
  addingPolygonConfirmCancelled,
  updateLocatorPolygonDescriptionAttempt,
  updateLocatorShapeAttempt,
  editLocatorPolygonShapeConfirmCancelled
} from 'src/app/locator-store/actions/locator-shape.actions';

import { saveTempFreeFormLocatorLibraryDataPointAttempt } from 'src/app/locator-store/actions/locator-library.actions';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';

@Component({
  selector: 'atlas-add-edit-polygon-confirmation-dialog',
  templateUrl: './add-edit-polygon-confirmation-dialog.component.html',
  styleUrls: ['./add-edit-polygon-confirmation-dialog.component.less']
})
export class AddEditPolygonConfirmationDialogComponent {
  descriptionControl = new FormControl(this.data.currentDescription, [
    Validators.required
  ]);

  addDescriptionForm = this.fb.group({
    description: this.descriptionControl
  });

  dialogMode = DialogMode;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      library: LocatorLibrary;
      affirmativeButtonText: string;
      location: Point;
      geoJson: string;
      selectedLocation: LocatorLibraryData;
      editingExistingPolygonShape: boolean;
      currentDescription: string;
    },
    private dialogRef: MatDialogRef<AddEditPolygonConfirmationDialogComponent>,
    private locatorStore$: Store<fromLocatorStore.State>,
    private fb: UntypedFormBuilder
  ) {}
  onSaveClicked() {
    this.data.mode === DialogMode.Add
      ? this.dispatchAddShapes()
      : this.dispatchUpdateShape();

    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  onCancelClicked() {
    this.data.mode === DialogMode.Add
      ? this.dispatchAddCancelled()
      : this.dispatchUpdateCancelled();
  }

  private dispatchAddCancelled() {
    this.locatorStore$.dispatch(addingPolygonConfirmCancelled());
  }

  private dispatchUpdateCancelled() {
    if (this.data.editingExistingPolygonShape) {
      this.locatorStore$.dispatch(
        editLocatorPolygonShapeConfirmCancelled({
          geoJson: this.data.geoJson
        })
      );
    }
  }

  isInvalidForm(): boolean {
    return (
      (!(this.addDescriptionForm && this.addDescriptionForm.controls) ||
        !this.addDescriptionForm.dirty ||
        !this.addDescriptionForm.valid) &&
      !this.data.editingExistingPolygonShape
    );
  }

  emptyDescription() {
    if (this.descriptionControl.value) {
      return this.descriptionControl.value?.trim().length == 0;
    }
    return this.descriptionControl.errors?.required;
  }

  private dispatchAddShapes() {
    this.data.selectedLocation === null
      ? this.locatorStore$.dispatch(
          saveTempFreeFormLocatorLibraryDataPointAttempt({
            location: this.data.location,
            geoJson: this.data.geoJson,
            description: this.descriptionControl.value as string
          })
        )
      : this.locatorStore$.dispatch(
          addingPolygonConfirmComplete({
            location: this.data.location,
            geoJson: this.data.geoJson,
            description: this.descriptionControl.value as string
          })
        );
  }

  private dispatchUpdateShape() {
    this.data.editingExistingPolygonShape
      ? this.locatorStore$.dispatch(
          updateLocatorShapeAttempt({
            shapeRange: 0,
            freeFormGeoJson: this.data.geoJson,
            freeFormName: null,
            driveCathmentSpeed: null
          })
        )
      : this.locatorStore$.dispatch(
          updateLocatorPolygonDescriptionAttempt({
            name: this.descriptionControl.value as string
          })
        );
  }
}
