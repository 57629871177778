import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { locationNameValidator } from 'src/app/locator/helpers/locator-validators';

import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorService } from 'src/app/locator/services/locator.service';

@Component({
  selector: 'atlas-edit-location-description-form',
  templateUrl: './edit-location-description-form.component.html',
  styleUrls: ['./edit-location-description-form.component.less']
})
export class EditLocationDescriptionFormComponent {
  @Input() location: LocatorLibraryData | null;

  editLocationNameForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private locationService: LocatorService
  ) {}

  ngOnInit() {
    const initialValue: LocatorLibraryData =
      this.location || ({} as LocatorLibraryData);
    this.editLocationNameForm = this.updateLocationForm(initialValue);
  }

  updateLocationForm(initialLibraryLocationValue: LocatorLibraryData) {
    var form = this.fb.group({
      libraryId: initialLibraryLocationValue.libraryId,
      libraryDataId: initialLibraryLocationValue.id,
      locationName: [
        initialLibraryLocationValue.name,
        {
          Validators: Validators.required,
          asyncValidators: locationNameValidator(
            (name, libId) => this.locationService.checkLocationNameExists(name, libId),
            this.location?.libraryId!
          )
        }
      ]
    });
    return form;
  }

  emptyLocationName() {
    if (this.editLocationNameForm.controls.locationName.value) {
      return (
        this.editLocationNameForm.controls.locationName.value?.trim().length ==
        0
      );
    }

    return this.editLocationNameForm.controls.locationName.errors?.required;
  }

  locationNameAlreadyExists() {
    return this.editLocationNameForm.controls.locationName.hasError('valueAlreadyExist');
  }

  invalidForm(): boolean {
    return (
      !(this.editLocationNameForm && this.editLocationNameForm.controls) ||
      !this.editLocationNameForm.dirty ||
      !this.editLocationNameForm.controls?.locationName.valid ||
      this.emptyLocationName() ||
      this.locationNameAlreadyExists()
    );
  }
}
