import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';


@Component({
  selector: 'atlas-select',
  templateUrl: './atlas-select.component.html',
  styleUrls: ['./atlas-select.component.less']
})
export class AtlasSelectComponent {
  @Input() control: SupplyPointAttribute;
  
  formGroup: UntypedFormGroup;  

  constructor(private parentFormDirective: FormGroupDirective) { 
    this.formGroup = this.parentFormDirective.control;
  }

}
