import { createReducer, on } from '@ngrx/store';
import {
  baseSelectionChanged,
  classificationSelectionChanged,
  clearProfilerLibraryStateOnLogoff,
  createProfilerLibrarySucceeded,
  generateProfilerLoFiReportSucceded,
  getBaseAndClassificationSucceded,
  getProfilerLibraryCountSucceded,
  getProfilerLibrarySucceded,
  getProfilerLoFiReportSucceded,
  getProfilerPowerBiReportingInfoSucceded,
  noLastEditedProfileFound,
  swapProfilerLibrarySucceeded,
  updateProfilerLibrarySucceeded
} from '../actions/profiler-library.actions';
import { ProfilerPowerBiReportingInfo } from 'src/app/profiler/model/profiler-power-bi-reporting-info';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
import {
  ProfilerBase,
  ProfilerClassification
} from 'src/app/profiler/model/profiler-base-classification';
import { ProfilerImportStatus } from 'src/app/profiler/model/profiler-import-status';
import {
  PollProfilerImportStatusInProgress,
  PollProfilerImportStatusCompleted,
  PollProfilerImportStatusFailed
} from '../actions/profiler-import-status.actions';
import { exportImportedPostcodesWithAcornTypeSucceeded } from '../actions/profiler-import.actions';
import { hasProfilerLimitBeenExceededResult } from '../actions/profiler-import.actions';
import { deleteLibrarySucceeded } from 'src/app/locator-store/actions/locator-library.actions';

export interface State {
  id: number;
  name: string;
  createDt: Date | null;
  editedDt: Date | null;
  importStatus: ProfilerImportStatus | null;
  powerBiReports: ProfilerPowerBiReportingInfo[];
  loFiReport: ProfilerLofiQueryResult[] | null;
  bases: ProfilerBase[];
  classifications: ProfilerClassification[];
  selectedBaseOptionId: number;
  selectedClassificationOptionId: number;
  hasProfilerLimitBeenExceeded: boolean;
  librariesCount: boolean;
}

export const initialState: State = {
  id: 0,
  name: '',
  createDt: null,
  editedDt: null,
  importStatus: null,
  powerBiReports: [],
  loFiReport: null,
  bases: [],
  classifications: [],
  selectedBaseOptionId: 1,
  selectedClassificationOptionId: 1,
  hasProfilerLimitBeenExceeded: false,
  librariesCount: false
};

export const profilerReducer = createReducer(
  initialState,
  on(updateProfilerLibrarySucceeded, (state, { library }) => {
    return {
      ...state,
      name: library.name
    };
  }),
  on(
    getProfilerLibrarySucceded,
    createProfilerLibrarySucceeded,
    swapProfilerLibrarySucceeded,
    (state, { library }) => {
      return {
        ...state,
        id: library.id,
        name: library.name,
        createDt: library.createDt,
        editedDt: library.editedDt,
        importStatus: library.importStatus,
        selectedBaseOptionId:
          state.bases && state.bases.length > 0 ? state.bases[0].id : 1,
        selectedClassificationOptionId:
          state.classifications && state.classifications.length > 0
            ? state.classifications[0].id
            : 1
      };
    }
  ),
  on(getProfilerPowerBiReportingInfoSucceded, (state, { pbiReports }) => {
    return {
      ...state,
      powerBiReports: pbiReports
    };
  }),

  on(baseSelectionChanged, (state, { selectedBaseId: selectedId }) => {
    return {
      ...state,
      selectedBaseOptionId: selectedId
    };
  }),

  on(
    classificationSelectionChanged,
    (state, { selectedClassificationId: selectedId }) => {
      return {
        ...state,
        selectedClassificationOptionId: selectedId
      };
    }
  ),

  on(getBaseAndClassificationSucceded, (state, { baseAndClassification }) => {
    return {
      ...state,
      bases: baseAndClassification.base,
      classifications: baseAndClassification.classification,
      selectedBaseOptionId: baseAndClassification.base[0].id,
      selectedClassificationOptionId: baseAndClassification.classification[0].id
    };
  }),
  on(
    getProfilerLoFiReportSucceded,
    generateProfilerLoFiReportSucceded,
    (state, { report }) => {
      return {
        ...state,
        loFiReport: report
      };
    }
  ),

  on(
    PollProfilerImportStatusInProgress,
    PollProfilerImportStatusCompleted,
    PollProfilerImportStatusFailed,
    (state, { importStatus }) => {
      return {
        ...state,
        importStatus: importStatus
      };
    }
  ),

  on(noLastEditedProfileFound, (state) => {
    return {
      ...state,
      id: 0,
      name: '',
      createDt: null,
      editedDt: null,
      importStatus: null,
      selectedBaseOptionId: 1,
      selectedClassificationOptionId: 1
    };
  }),

  on(clearProfilerLibraryStateOnLogoff, () => {
    return {
      ...initialState
    };
  }),
  on(
    exportImportedPostcodesWithAcornTypeSucceeded,
    (state, { exportedFileUrl }) => {
      return {
        ...state,
        importStatus: state.importStatus
          ? { ...state.importStatus, exportedFileUrl }
          : null
      };
    }
  ),

  on(hasProfilerLimitBeenExceededResult, (state, { result }) => {
    return {
      ...state,
      hasProfilerLimitBeenExceeded: result
    };
  }),

  on(getProfilerLibraryCountSucceded, (state, { result }) => {
    return {
      ...state,
      librariesCount: result
    };
  })
);

export const getId = (state: State) => state && state.id;

export const getPowerBiReports = (state: State) =>
  state && state.powerBiReports;

export const getLofiReport = (state: State) => state && state.loFiReport;

export const getBases = (state: State) => state && state.bases;

export const getSelectedBaseId = (state: State) =>
  state && state.selectedBaseOptionId;

export const getSelectedClassificationId = (state: State) =>
  state && state.selectedClassificationOptionId;

export const getClassifications = (state: State) =>
  state && state.classifications;

export const getImportStatus = (state: State) => state && state.importStatus;

export const getImportStatusMessage = (state: State) =>
  state && state.importStatus?.status;

export const getJsonImportStatusError = (state: State) =>
  state && state.importStatus?.errorMessage;

export const gethasProfilerLimitBeenExceededResult = (state: State) =>
  state && state.hasProfilerLimitBeenExceeded;

export const getLibrariesCount = (state: State) =>
  state && state.librariesCount;
