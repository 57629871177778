<h1>Palette</h1>
<hr />
<div class="palette-row" style="padding: 20px">
  <div class="light-theme-palette">
    <h2>Light Theme</h2>
    <div class="theme-content">
      <div class="theme-row">
        <div class="theme-colour">Default</div>
        <div class="theme-colour">Hover</div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box default-light-theme-bg-colour"></div>
          <div class="colour-description">
            <div>Primary BG colour</div>
            <div>#FFFFFF</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-bg-colour diagonalCross"
          ></div>
          <div class="colour-description">
            <div>Primary BG colour</div>
            <div>#FFFFFF</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-primary-text-colour"
          ></div>
          <div class="colour-description">
            <div>Primary Text</div>
            <div>#000000</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-primary-text-colour diagonalCross"
          ></div>
          <div class="colour-description">
            <div>Primary Text</div>
            <div>None</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box default-light-theme-active-colour"></div>
          <div class="colour-description">
            <div>Active/Selected</div>
            <div>#197DFF</div>
            <div style="font-size: 10px">
              Text links,Buttons and Selected tabs
            </div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-active-hover-colour"
          ></div>
          <div class="colour-description">
            <div>Link Hover</div>
            <div>#81B3FF</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-deactivated-colour"
          ></div>
          <div class="colour-description">
            <div>Deactivated State</div>
            <div>#676A6B</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-deactivated-hover-colour"
          ></div>
          <div class="colour-description">
            <div>Deactivated State</div>
            <div>#8A8C8F</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dark-theme-palette">
    <h2>Dark Theme</h2>
    <div class="theme-content">
      <div class="theme-row">
        <div class="theme-colour">Default</div>
        <div class="theme-colour">Hover</div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            style="border: 1px solid gray"
            class="theme-colour-box default-dark-theme-bg-colour"
          ></div>
          <div class="colour-description">
            <div>Primary BG colour</div>
            <div>#FFFFFF</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-bg-colour diagonalCross"
          ></div>
          <div class="colour-description">
            <div>Primary BG colour</div>
            <div>#FFFFFF</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            class="theme-colour-box default-dark-theme-primary-text-colour"
          ></div>
          <div class="colour-description">
            <div>Primary Text</div>
            <div>#f8f8f8</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-primary-text-colour diagonalCross"
          ></div>
          <div class="colour-description">
            <div>Primary Text</div>
            <div>None</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box default-light-theme-active-colour"></div>
          <div class="colour-description">
            <div>Active/Selected</div>
            <div>#197DFF</div>
            <div style="font-size: 10px">
              Text links,Buttons and Selected tabs
            </div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-active-hover-colour"
          ></div>
          <div class="colour-description">
            <div>Link Hover</div>
            <div>#81B3FF</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-deactivated-colour"
          ></div>
          <div class="colour-description">
            <div>Deactivated State</div>
            <div>#676A6B</div>
          </div>
        </div>
        <div class="theme-colour">
          <div
            class="theme-colour-box default-light-theme-deactivated-hover-colour"
          ></div>
          <div class="colour-description">
            <div>Deactivated State</div>
            <div>#8A8C8F</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="palette-row" style="padding: 20px">
  <div class="accent-palette">
    <h2>Accent-colours</h2>
    <div class="theme-content">
      <div class="theme-row">
        <div class="theme-colour">Use: Accorn reports, additional map pins</div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div
            style="border: 1px solid gray"
            class="theme-colour-box lead-accent-primary-list-report-item-colour"
          ></div>
          <div class="colour-description">
            <div>Lead Accent/ Primary List or Report item</div>
            <div>#197DFF</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box complementary-colour"></div>
          <div class="colour-description">
            <div>Complementary</div>
            <div>#9B51E0</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box grass-green-success-colour"></div>
          <div class="colour-description">
            <div>Grass Green/Success</div>
            <div>#27AE60</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box focus-colour"></div>
          <div class="colour-description">
            <div>Focus Orange</div>
            <div>#F29A4B</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box error-colour"></div>
          <div class="colour-description">
            <div>Rose Red/Errors</div>
            <div>#EB5757</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box warning-colour"></div>
          <div class="colour-description">
            <div>Golden Yellow/Warning</div>
            <div>#F2C94C</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box disabled-colour"></div>
          <div class="colour-description">
            <div>Disabled/ Non-Interactive elements</div>
            <div>#4D4D4D</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <div class="theme-colour-box captions-colour"></div>
          <div class="colour-description">
            <div>Captions</div>
            <div>#797979</div>
            <div style="font-size: 10px">Ternary text</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-states">
    <h2>Icon states</h2>
    <div class="theme-content">
      <div class="theme-row">
        <div class="theme-colour">
          Use: When icons, tabs with icons are interacted with
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            mat-stroked-button
            color="primary"
            aria-label="Drivetime"
            class="icon-item inactive-icon"
          >
            <mat-icon>auto_graph</mat-icon>
          </button>

          <div class="colour-description">
            <div>Interactive Tab Icon</div>
            <div>Inactive state</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            mat-stroked-button
            color="primary"
            aria-label="Drivetime"
            class="icon-item hover-icon"
          >
            <mat-icon>auto_graph</mat-icon>
          </button>

          <div class="colour-description">
            <div>Interactive Tab Icon</div>
            <div>Hover state</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            mat-stroked-button
            color="primary"
            aria-label="Drivetime"
            class="icon-item active-icon"
          >
            <mat-icon>auto_graph</mat-icon>
          </button>

          <div class="colour-description">
            <div>Interactive Tab Icon</div>
            <div>Default state</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="icon-states">
    <h2>Font Icon buttons</h2>
    <div class="theme-content">
      <div class="theme-row">
        <div class="theme-colour">
          Use: When buttons require custom icons in SVG format using fonts
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            class="atlas-icon-button atlas-icon icon-reset"
            mat-flat-button
            color="primary"
            aria-label="Reset"
          ></button>

          <div class="description">
            <div>Stroked button with icon</div>
            <div>CSS class: atlas-icon icon-reset</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            class="atlas-icon-button atlas-icon icon-street-view"
            mat-flat-button
            color="primary"
            aria-label="Street view"
          ></button>

          <div class="description">
            <div>Stroked button with icon</div>
            <div>CSS class: atlas-icon icon-street-view</div>
          </div>
        </div>
      </div>
      <div class="theme-row">
        <div class="theme-colour">
          <button
            class="atlas-icon-button atlas-icon icon-reset"
            mat-flat-button
            color="primary"
            aria-label="Reset"
            disabled="true"
          ></button>

          <div class="description">
            <div>Disabled stroked button with icon</div>
            <div>CSS class: atlas-icon icon-reset</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
