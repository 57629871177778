import { Component, Input } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { SupplyPointAttribute } from 'src/app/spatial-modeller/models/supply-point-attribute';

import { InputControlType } from '../../../models/dynamic-form';

const getControlType = {
  Textbox: 'text',
  NumericTextbox: 'number',
  DatePicker: 'date'
};

@Component({
  selector: 'atlas-input',
  templateUrl: './atlas-input.component.html',
  styleUrls: ['./atlas-input.component.less']
})
export class AtlasInputComponent {
  formControl: SupplyPointAttribute;

  @Input()
  set control(theControl: SupplyPointAttribute) {
    this.formControl = theControl;
    this.controlType = getControlType[this.formControl.displayType as InputControlType];
  }

  formGroup: UntypedFormGroup;
  controlType: string;

  constructor(private parentFormDirective: FormGroupDirective) {
    this.formGroup = this.parentFormDirective.control;
  }
}
