import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserUsage } from 'src/app/core/models/user-usage';
import * as fromUserUsage from 'src/app/core/store';
import * as fromUIStore from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-users-login-usage-tab-content',
  templateUrl: './users-login-usage-tab-content.component.html'
})
export class UsersLoginUsageTabContentComponent implements OnInit, OnDestroy {
  userUsageList: UserUsage[];
  private subscription = new Subscription();
  allowInternalUsersUsage: boolean;
  constructor(
    public layoutService: LayoutService,
    private userUsageStore$: Store<fromUserUsage.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );
    this.subscription.add(
      this.userUsageStore$
        .select(fromUserUsage.getUserUsageList)
        .subscribe((usersUsage) => {
          this.userUsageList = hideCACIUsers(
            usersUsage,
            this.allowInternalUsersUsage
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
