import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'atlas-release-notes-content',
  templateUrl: './release-notes-content.component.html',
  styleUrls: ['./release-notes-content.component.less']
})
export class ReleaseNotesContentComponent implements OnInit {
  goToRelease: string;
  goToRelease$: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedRelease: string;
  scrollingUsingBar: boolean = true;
  private _gitVersion: string;

  public get appVersion(): string {
    return environment.appVersion + '.' + this._gitVersion;
  }

  listOfReleases = [
    'prod_v1.4.0',
    'prod_v1.3.6',
    'prod_v1.3.5',
    'prod_v1.3.4',
    'prod_v1.3.3',
    'prod_v1.3.2',
    'prod_v1.3.1',
    'prod_v1.3.0',
    'prod_v1.2.7',
    'prod_v1.2.6',
    'prod_v1.2.5',
    'prod_v1.2.4',
    'prod_v1.2.3',
    'prod_v1.2.2',
    'prod_v1.2.1',
    'prod_v1.2.0',
    'prod_v1.1.5',
    'prod_v1.1.4',
    'prod_v1.1.3',
    'prod_v1.1.2',
    'prod_v1.1.1',
    'prod_v1.1.0',
    'prod_v1.0.0'
  ];
  listOfReleaseNames = [
    'v1.4.0 - October 31, 2024',
    'v1.3.6 - September 26, 2024',
    'v1.3.5 - August 29, 2024',
    'v1.3.4 - July 25, 2024',
    'v1.3.3 - July 4, 2024',
    'v1.3.2 - May 30, 2024',
    'v1.3.1 - May 23, 2024',
    'v1.3.0 - May 9, 2024',
    'v1.2.7 - April 18, 2024',
    'v1.2.6 - March 26, 2024',
    'v1.2.5 - March 13, 2024',
    'v1.2.4 - January 23, 2024',
    'v1.2.3 - January 9, 2024',
    'v1.2.2 - November 30, 2023',
    'v1.2.1 - November 28, 2023',
    'v1.2.0 - November 27, 2023',
    'v1.1.5 - November 8, 2023',
    'v1.1.4 - October 2, 2023',
    'v1.1.3 - August 14, 2023',
    'v1.1.2 - June 30, 2023',
    'v1.1.1 - June 28, 2023',
    'v1.1.0 - May 26, 2023',
    'v1.0.0 - Dec 15, 2022'
  ];

  constructor(private http: HttpClient, public layoutService: LayoutService) {}

  public ngOnInit(): void {
    this.readVersionFromFile();
  }

  goTo(release: string) {
    this.scrollingUsingBar = false;
    this.selectedRelease = release;
    this.goToRelease$.next(release);
  }

  private readVersionFromFile() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/text;');
    this.http
      .get('assets/git-version.txt', { responseType: 'text' })
      .subscribe((version: string) => {
        this._gitVersion = version;
      });
  }

  onMouseWheel(event: Event) {
    this.scrollingUsingBar = false;
    const versionsParentDiv = event.currentTarget as Element;
    const versionDivChildren =
      versionsParentDiv.getElementsByClassName('version-header');
    let lastVersionInView: Element | null = this.getLastVersionInView(
      versionsParentDiv,
      versionDivChildren,
      (event as WheelEvent).deltaY
    );

    this.setVersionInView(lastVersionInView);
  }

  onScrollEnd(event: Event) {
    if (!this.scrollingUsingBar) {
      this.scrollingUsingBar = true;
    }
  }

  onScroll(event: Event) {
    if (!this.scrollingUsingBar) {
      return;
    }

    const versionsParentDiv = event.target as Element;
    const versionDivChildren =
      versionsParentDiv.getElementsByClassName('version-header');
    let lastVersionInView: Element | null = this.getLastVersionInView(
      versionsParentDiv,
      versionDivChildren,
      0
    );
    this.setVersionInView(lastVersionInView);
  }

  isScrolledIntoView(divElem: Element, elemItem: Element, deltaY: number) {
    const parentDivElem = divElem as HTMLElement;
    const elemHtmlItem = elemItem as HTMLElement;
    var divOffsetTop =
      parentDivElem.offsetTop + parentDivElem.scrollTop + deltaY;

    var divOffsetBottom = divOffsetTop + parentDivElem.clientHeight;
    var elemTop = elemHtmlItem.offsetTop;
    var elemBottom = elemTop + elemHtmlItem.clientHeight;
    var isInView = elemBottom <= divOffsetBottom && elemTop >= divOffsetTop;
    return isInView;
  }

  private getLastVersionInView(
    versionsParentDiv: Element,
    versionDivChildren: HTMLCollectionOf<Element>,
    deltaY: number
  ) {
    let lastVersionInView: Element | null = null;
    for (let i = 0; i < versionDivChildren.length; i++) {
      if (
        this.isScrolledIntoView(
          versionsParentDiv,
          versionDivChildren[i],
          deltaY
        )
      ) {
        lastVersionInView = versionDivChildren[i];
        break;
      }
    }
    return lastVersionInView;
  }

  setVersionInView(lastVersionInView: Element | null) {
    if (lastVersionInView) {
      const release = (lastVersionInView as HTMLElement).classList[1];
      this.selectedRelease = release;

      const indexElementSelected = document.getElementById(release);
      indexElementSelected?.scrollIntoView();
    }
  }
}
