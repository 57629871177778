import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, switchMap, first } from 'rxjs/operators';

export function locationNameValidator(
  validateFn: (libraryId: number, name: string) => Observable<boolean>,
  libraryId: number
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return control.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(name => validateFn(libraryId, name)),
      map(exists => exists ? { valueAlreadyExist: true } : null),
      first(),
    );
  };
}