<div class="workspace-container">
  <atlas-delta-supply-points-list
    [dataSource]="deltaSupplyPoints$ | async"
    (deltaLocationClickSelected)="onDeltaLocationClickSelected($event)"
  ></atlas-delta-supply-points-list>
</div>
<div class="footer-container">
  <atlas-move-supply-points-to-scenario
    (moveButtonClicked)="onMove($event)"
    (backButtonClicked)="onBack($event)"
    [userScenarios]="userScenarios"
    [hasItemsToMove]="selectedSupplyIds.length > 0"
  ></atlas-move-supply-points-to-scenario>
</div>
