import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import { UsersModelUsage } from '../../models/usage-summary';
import { Subscription } from 'rxjs';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-model-usage-tab-content',
  templateUrl: './model-usage-tab-content.component.html',
  styleUrls: ['./model-usage-tab-content.component.less']
})
export class ModelUsageTabContentComponent implements OnInit, OnDestroy {
  constructor(
    public layoutService: LayoutService,
    private store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  showGrid = true;
  private subscription = new Subscription();
  allUsersUsage: UsersModelUsage[];
  allowInternalUsersUsage: boolean;
  userUsageSummary$ = this.store$.select(fromStore.getModelsUserUsageSummary);
  companyUsageSummary$ = this.store$.select(
    fromStore.getModelsCompanyUsageSummary
  );

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );

    this.subscription.add(
      this.store$
        .select(fromStore.getModelsAllUsersUsageSummary)
        .subscribe((usersUsage) => {
          this.allUsersUsage = hideCACIUsers(
            usersUsage,
            this.allowInternalUsersUsage
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onToggleGridChart() {
    this.showGrid = !this.showGrid;
  }
}
