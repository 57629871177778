<form [formGroup]="locatorReferenceLibraryForm" autocomplete="off">
  <div class="form-controls-padding" style="margin-top: 10px">
    <div>
      <mat-form-field appearance="outline" hideRequiredMarker="true">
        <mat-label>Data</mat-label>
        <input
          matInput
          type="text"
          formControlName="filter"
          placeholder="Search data"
          (keyup)="onFilterChanged($event)"
        />

        <mat-icon class="search-icon-wrapper" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div>
      <div class="library-label">Data</div>
      <div class="scrollable-list">
        <mat-selection-list
          #pbiReports
          [multiple]="false"
          [hideSingleSelectionIndicator]="true"
          (selectionChange)="onSelectionChange($event)"
        >
          <mat-list-option *ngFor="let library of dataSource" [value]="library">
            <span class="library-name">{{ library.name }}</span>
            <div>
              <span class="library-description">{{ library.description }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>
</form>
