import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as appFeatureUIStore from 'src/app/core/store/';

@Component({
  selector: 'atlas-locator-import-library-data-form',
  templateUrl: './locator-import-library-data-form.html',
  styleUrls: ['./locator-import-library-data-form.less']
})
export class LocatorImportLibraryDataFormComponent {
  fileName: string;
  selectedFile: File;
  locatorImportLibraryDataForm: UntypedFormGroup;

  @Input() hasLocatorLibraryLocationsLimitBeenExceeded: boolean;
  @Input() libraryId: number;
  @Input() tenantImportUsingGeocoding: boolean;

  @Output() private selectedFileChanged = new EventEmitter<string>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    const initialValue =
      {
        libraryId: this.libraryId,
        geocodingSelection: this.tenantImportUsingGeocoding
      } || ({} as number);
    this.locatorImportLibraryDataForm =
      this.createImportLocationsForm(initialValue);
  }

  createImportLocationsForm(initialValue: {
    libraryId: number;
    geocodingSelection: boolean;
  }) {
    var form = this.fb.group({
      id: initialValue.libraryId,
      geocodingSelection: [initialValue.geocodingSelection, Validators.required]
    });
    if (!this.tenantImportUsingGeocoding) {
      form.controls.geocodingSelection.disable();
    }
    return form;
  }

  emptyImportFileName() {
    if (this.locatorImportLibraryDataForm.controls.importFileName.value) {
      return (
        this.locatorImportLibraryDataForm.controls.importFileName.value?.trim()
          .length == 0
      );
    }

    return this.locatorImportLibraryDataForm.controls.importFileName.errors
      ?.required;
  }

  invalidForm(): boolean {
    return (
      !(
        this.locatorImportLibraryDataForm &&
        this.locatorImportLibraryDataForm.controls
      ) ||
      !this.selectedFile ||
      this.hasLocatorLibraryLocationsLimitBeenExceeded
    );
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.fileName = file.name;
    this.selectedFile = file;
    this.selectedFileChanged.emit(this.fileName);
  }

  downloadFile(url: string, fileName: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  onDownloadClicked() {
    const csvTemplateUrl =
      'assets/templates/catchment_reporting_import_locations_template.csv';
    this.downloadFile(
      csvTemplateUrl,
      'catchment_reporting_import_locations_template.csv'
    );
  }
}
