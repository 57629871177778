import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Subscription } from 'rxjs';

import * as fromLocatorStore from 'src/app/locator-store';
import { deleteLibraryLocationsAttempt } from 'src/app/locator-store/actions/locator-location.actions';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorService } from 'src/app/locator/services/locator.service';

@Component({
  selector: 'atlas-delete-location-confirmation-dialog',
  templateUrl: './delete-location-confirmation-dialog.component.html',
  styleUrls: ['./delete-location-confirmation-dialog.component.less']
})
export class DeleteLocationConfirmationDialogComponent implements OnDestroy{
  isCheckboxChecked: boolean = false;
  private subscription = new Subscription();
  private locationIds: number[];
  private selectedLocation$ = this.store$.select(fromLocatorStore.getSelectedLocation);
  private batchOperationLocationIds$ = this.store$.select(
    fromLocatorStore.getBatchOperationLocationIds
  );

  selectedLocation: LocatorLibraryData | null;
  isShared: boolean;
  locationsCount: number;

  get headerText() {
    if (this.locationsCount === 0) {
      return 'Delete Location';
    } else {
      return 'Delete Multiple Locations';
    }
  }
  
  constructor(
    private store$: Store<fromLocatorStore.State>,
    private locatorService: LocatorService
  ) {
    this.subscription.add( this.selectedLocation$.subscribe((selectedLocation) => {
      this.selectedLocation = selectedLocation;
      if ((selectedLocation?.id, selectedLocation?.libraryId))
        this.locatorService
          .checkLocationReportDataShared(
            selectedLocation.libraryId,
            selectedLocation.id
          )
          .subscribe((isShared) => {
            this.isShared = isShared;
          });
    }));

    this.subscription.add(this.batchOperationLocationIds$.subscribe((locations) => {
      this.locationsCount = locations.length;
      this.locationIds = locations;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDeleteClicked(location: LocatorLibraryData | null) {
    let locationIds = this.locationsCount === 0
      ? [location!.id]
      : this.locationIds;
    
    this.store$.dispatch(
      deleteLibraryLocationsAttempt({
        libraryDataIds: locationIds
      })
    );
  }
}
