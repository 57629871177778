import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxTemplateModule } from 'devextreme-angular';

import * as fromComponents from './components';
import * as fromDirectives from './directives';

@NgModule({
  declarations: [...fromComponents.components, ...fromDirectives.directives],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxLoadPanelModule,
    DxSelectBoxModule,
    DxTemplateModule,
  ],
  exports: [
    ...fromComponents.components,
    ...fromDirectives.directives,

    DxDataGridModule,
    DxCheckBoxModule,
    DxLoadPanelModule,
    DxSelectBoxModule,
    DxTemplateModule,
  ],

  providers: [],
})
export class DevExpressComponentsModule {}
