<div
  class="atlas-panel-body"
  *ngIf="
    !(selectedShapes$ | async) &&
    !(isLofiReportAvailable$ | async) &&
    (!(summaryStatsReportAvailable$ | async) ||
      !(showSummaryStatsReport$ | async))
  "
>
  Please select a shape to view a report
</div>
<mat-divider></mat-divider>

<div class="atlas-panel-body">
  <atlas-locator-reports-panel
    [graphHeight]="250"
  ></atlas-locator-reports-panel>
</div>

<mat-divider></mat-divider>
<atlas-locator-reports-panel-buttons></atlas-locator-reports-panel-buttons>
