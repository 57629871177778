import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromLocatorStore from 'src/app/locator-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import {
  createLocatorShapesAttempt,
  updateLocatorShapeAttempt
} from 'src/app/locator-store/actions/locator-shape.actions';
import { CatchmentFormComponent } from '../catchment-form/catchment-form.component';
import { LocatorShapeTypes } from 'src/app/locator/types/locator-shape.types';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import {
  CircleShapeDefaults,
  DriveTimeShapeDefaults,
  LocatorShapeDefaults
} from 'src/app/core/models/locator-shape-defaults';
import { Subscription } from 'rxjs';
import {
  getDefaultDistanceUnit,
  getLocatorShapesDefaults
} from 'src/app/core/store/selectors/app-feature-ui.selectors';
import { FormControl } from '@angular/forms';
import { LocatorService } from 'src/app/locator/services/locator.service';
import { getSelectedLocation } from 'src/app/locator-store';
import { getShapeUnit } from 'src/app/shared/utils/shape-utils';
import { DriveCatchmentSpeedNames } from 'src/app/core/enums/drive-catchment-speeds.enum';

@Component({
  selector: 'atlas-add-edit-catchment-dialog',
  templateUrl: './add-edit-catchment-dialog.component.html',
  styleUrls: ['./add-edit-catchment-dialog.component.less']
})
export class AddEditCatchmentDialogComponent implements OnInit, OnDestroy {
  dialogMode: DialogMode;
  defaultSize: number | null = null;
  defaultDistanceUnit: string | null = null;
  defaultShapeUnit: string | null = null;
  shapeCircleConfig: CircleShapeDefaults;
  shapeCarDriveTimeConfig: DriveTimeShapeDefaults;
  shapeWalkDriveTimeConfig: DriveTimeShapeDefaults;
  shapePublicTransportDriveTimeConfig: DriveTimeShapeDefaults;
  isShared: boolean;
  shapes: LocatorShape[] | null;
  locatorShapeDefaults: LocatorShapeDefaults | null;
  driveCatchmentSpeedList = DriveCatchmentSpeedNames;
  locationsCount: number;
  locatorShapesDefaults$ = this.store$.select(getLocatorShapesDefaults);
  defaultDistanceUnit$ = this.store$.select(getDefaultDistanceUnit);
  libraryLocation$ = this.store$.select(getSelectedLocation);
  private batchOperationLocationIds$ = this.store$.select(
    fromLocatorStore.getBatchOperationLocationIds
  );

  subscription: Subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityName: string;
      headerPrefix: string;
      mode: DialogMode;
      affirmativeButtonText: string;
      shapeType: LocatorShapeTypes;
      shape: LocatorShape;
      libraryId: number;
      isSettings: boolean;
    },
    private dialogRef: MatDialogRef<AddEditCatchmentDialogComponent>,
    private store$: Store<fromLocatorStore.State>,
    private locatorService: LocatorService
  ) {}

  ngOnInit(): void {
    if (this.data.mode === 'Add') {
      this.subscribeToShapeDefaults();
    } else {
      this.setEditShapeDefaultSize();
      this.checkIsReportShared();
    }

    this.subscribeToDistanceUnit();
    this.subscribeToLibraryLocations();
    this.subscribeToLocationsCount();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSaveClicked(catchmentForm: CatchmentFormComponent) {
    if (this.data.isSettings) {
      const shapeConfig = this.getShapeConfigFromUserInput(catchmentForm);

      const shapeDefaults = {
        car: this.data.shapeType === LocatorShapeTypes.Car ? shapeConfig : null,
        walk:
          this.data.shapeType === LocatorShapeTypes.Walk ? shapeConfig : null,
        circle:
          this.data.shapeType === LocatorShapeTypes.Circle ? shapeConfig : null,
        polygon: null,
        publicTransport:
          this.data.shapeType === LocatorShapeTypes.PublicTransport
            ? shapeConfig
            : null
      };
      this.dialogRef.close({
        action: DialogResult.Affirmative,
        data: {
          shapeType: this.data.shapeType,
          shapeDefaults
        }
      });
    } else {
      this.data.mode === 'Add'
        ? this.dispatchAddShapes(catchmentForm)
        : this.dispatchUpdateShape(catchmentForm);
    }
  }

  private dispatchAddShapes(catchmentForm: CatchmentFormComponent) {
    const sizeValues: number[] = this.getAddedShapeValues(
      catchmentForm.catchmentSizeControl
    );
    const speedValue = catchmentForm.driveCatchmentSpeedControl.value;
    {
      this.store$.dispatch(
        createLocatorShapesAttempt({
          shapeType: this.data.shapeType,
          shapeRanges: sizeValues,
          freeFormGeoJson: null,
          freeFormName: null,
          driveCatchmentSpeed: speedValue,
          multipleCatchments: this.locationsCount > 1
        })
      );
    }
  }

  private dispatchUpdateShape(catchmentForm: CatchmentFormComponent) {
    const sizeValue: number = this.getUpdatedShapeValue(
      catchmentForm.catchmentSingleSizeControl
    );
    const speedValue = catchmentForm.driveCatchmentSpeedControl.value;
    this.store$.dispatch(
      updateLocatorShapeAttempt({
        shapeRange: sizeValue,
        freeFormGeoJson: null,
        freeFormName: null,
        driveCathmentSpeed: speedValue
      })
    );
  }

  private getAddedShapeValues(catchmentSizeFormControl: FormControl<any>) {
    const addedShapeValues = {
      1: () =>
        this.getDriveTimeAddedValues(
          catchmentSizeFormControl,
          this.shapeCarDriveTimeConfig
        ),
      2: () =>
        this.getDriveTimeAddedValues(
          catchmentSizeFormControl,
          this.shapeWalkDriveTimeConfig
        ),
      3: () => catchmentSizeFormControl.value as number[],
      4: () => [-1],
      5: () =>
        this.getDriveTimeAddedValues(
          catchmentSizeFormControl,
          this.shapePublicTransportDriveTimeConfig
        ),
      6: () => [-1]
    };
    return addedShapeValues[this.data.shapeType]();
  }

  private getDriveTimeAddedValues(
    catchmentSizeFormControl: FormControl<any>,
    driveTimeShapeDefaults: DriveTimeShapeDefaults
  ): number[] {
    const catchmentSizeControlValues =
      catchmentSizeFormControl.value as number[];
    const driveTimeSizeValues =
      driveTimeShapeDefaults.distance === 0
        ? catchmentSizeControlValues.map((value) => value * 60) // Converts minutes into seconds
        : catchmentSizeControlValues;
    return driveTimeSizeValues;
  }

  private getUpdatedShapeValue(
    catchmentSingleSizeFormControl: FormControl<any>
  ) {
    const updatedShapeValue = {
      1: () =>
        this.getDriveTimeUpdatedValue(
          catchmentSingleSizeFormControl,
          this.shapeCarDriveTimeConfig
        ),
      2: () =>
        this.getDriveTimeUpdatedValue(
          catchmentSingleSizeFormControl,
          this.shapeWalkDriveTimeConfig
        ),
      3: () => catchmentSingleSizeFormControl.value as number,
      4: () => -1,
      5: () =>
        this.getDriveTimeUpdatedValue(
          catchmentSingleSizeFormControl,
          this.shapePublicTransportDriveTimeConfig
        ),
      6: () => -1
    };

    return updatedShapeValue[this.data.shapeType]();
  }

  private getDriveTimeUpdatedValue(
    catchmentSingleSizeFormControl: FormControl<any>,
    driveTimeShapeDefaults: DriveTimeShapeDefaults
  ): number {
    const catchmentSizeControlValue =
      catchmentSingleSizeFormControl.value as number;
    const driveTimeSizeValue =
      driveTimeShapeDefaults.distance === 0
        ? catchmentSizeControlValue * 60 // Converts minutes into seconds
        : catchmentSizeControlValue;
    return driveTimeSizeValue;
  }

  private setEditShapeDefaultSize() {
    if (this.data.mode === DialogMode.Edit) {
      this.setShapeConfig();
      this.defaultSize = this.getShapeDefaultSize();
    }
  }

  private getShapeConfigFromUserInput(catchmentForm: CatchmentFormComponent) {
    const shapeConfigBuilders = {
      1: () => this.buildDriveTimeConfig(catchmentForm),
      2: () => this.buildDriveTimeConfig(catchmentForm),
      3: () => this.buildCircleConfig(catchmentForm),
      4: () => null,
      5: () => this.buildDriveTimeConfig(catchmentForm),
      6: () => null
    };

    return shapeConfigBuilders[this.data.shapeType]();
  }

  private setShapeConfig() {
    const shapeConfig = {
      1: () => this.setCarShapeConfig(),
      2: () => this.setWalkShapeConfig(),
      3: () => this.setCircleShapeConfig(),
      4: () => -1,
      5: () => this.setPublicTransportShapeConfig(),
      6: () => -1
    };

    return shapeConfig[this.data.shapeType]();
  }

  private setCarShapeConfig() {
    const jsonObject = this.firstLetterKeysToLowerCase(
      JSON.parse(this.data.shape.drivetimeConfiguration)
    );
    this.shapeCarDriveTimeConfig = Object.assign(
      new DriveTimeShapeDefaults(),
      jsonObject
    );
  }

  private setWalkShapeConfig() {
    const jsonObject = this.firstLetterKeysToLowerCase(
      JSON.parse(this.data.shape.drivetimeConfiguration)
    );
    this.shapeWalkDriveTimeConfig = Object.assign(
      new DriveTimeShapeDefaults(),
      jsonObject
    );
  }

  private setCircleShapeConfig() {
    const jsonObject = this.firstLetterKeysToLowerCase(
      JSON.parse(this.data.shape.circleConfiguration)
    );
    this.shapeCircleConfig = Object.assign(
      new CircleShapeDefaults(),
      jsonObject
    );
  }

  private setPublicTransportShapeConfig() {
    const jsonObject = this.firstLetterKeysToLowerCase(
      JSON.parse(this.data.shape.drivetimeConfiguration)
    );
    this.shapePublicTransportDriveTimeConfig = Object.assign(
      new DriveTimeShapeDefaults(),
      jsonObject
    );
  }
  private buildDriveTimeConfig(catchmentForm: CatchmentFormComponent) {
    const sizeValues: number[] = this.getAddedShapeValues(
      catchmentForm.catchmentSizeControl
    );
    return {
      distance: 0,
      timeInSeconds: sizeValues,
      driveCatchmentSpeed: catchmentForm.driveCatchmentSpeedControl.value,
      unit: getShapeUnit(
        this.data.shapeType,
        this.locatorShapeDefaults,
        this.defaultDistanceUnit!
      )
    };
  }

  private buildCircleConfig(catchmentForm: CatchmentFormComponent) {
    return {
      radius: catchmentForm.catchmentSizeControl.value,
      unit: this.defaultDistanceUnit
    };
  }

  private getShapeDefaultSize() {
    const shapeDefaultValue = {
      1: () => this.getCarDriveTimeDefaultSize(),
      2: () => this.getWalkDriveTimeDefaultSize(),
      3: () => this.getCircleDefaultSize(),
      4: () => -1,
      5: () => this.getPublicTransportDriveTimeDefaultSize(),
      6: () => -1
    };

    return shapeDefaultValue[this.data.shapeType]();
  }

  private getCarDriveTimeDefaultSize() {
    const defaultCarDriveTimeSize = this.getDriveTimeDefaultSize(
      this.shapeCarDriveTimeConfig
    );
    return defaultCarDriveTimeSize;
  }

  private getWalkDriveTimeDefaultSize() {
    const defaultWalkDriveTimeSize = this.getDriveTimeDefaultSize(
      this.shapeWalkDriveTimeConfig
    );
    return defaultWalkDriveTimeSize;
  }

  private getPublicTransportDriveTimeDefaultSize() {
    const defaultPublicTransportDriveTimeSize = this.getDriveTimeDefaultSize(
      this.shapePublicTransportDriveTimeConfig
    );
    return defaultPublicTransportDriveTimeSize;
  }

  private getDriveTimeDefaultSize(
    defaultDriveTimeConfig: DriveTimeShapeDefaults
  ) {
    const defaultDriveTimeSize =
      defaultDriveTimeConfig.distance === 0
        ? defaultDriveTimeConfig.timeInSeconds / 60
        : defaultDriveTimeConfig.distance;
    return defaultDriveTimeSize;
  }

  private getCircleDefaultSize() {
    return this.shapeCircleConfig.radius;
  }

  private firstLetterKeysToLowerCase(obj: { [x: string]: any }) {
    if (obj instanceof Array) {
      for (var i in obj) {
        obj[i] = this.firstLetterKeysToLowerCase(obj[i]);
      }
    }
    if (
      typeof obj === 'string' ||
      typeof obj === 'number' ||
      typeof obj === 'boolean'
    ) {
      return obj;
    }
    var keys = Object.keys(obj);
    var n = keys.length;
    var lowKey;
    while (n--) {
      var key = keys[n];
      if (key === (lowKey = key.charAt(0).toLocaleLowerCase() + key.slice(1)))
        continue;
      obj[lowKey] = this.firstLetterKeysToLowerCase(obj[key]);
      delete obj[key];
    }
    return obj;
  }

  private checkIsReportShared() {
    this.locatorService
      .checkShapeReportDataShared(
        this.data.libraryId,
        this.data.shape?.libraryDataId,
        this.data.shape?.id
      )
      .subscribe((isShared) => {
        this.isShared = isShared;
      });
  }

  private subscribeToLibraryLocations() {
    this.subscription.add(
      this.libraryLocation$.subscribe((shapes) => {
        if (shapes) {
          this.shapes = shapes!.shapes;
        }
      })
    );
  }

  private subscribeToDistanceUnit() {
    this.subscription.add(
      this.defaultDistanceUnit$.subscribe((distanceUnit) => {
        this.defaultDistanceUnit = distanceUnit;
        this.defaultShapeUnit = getShapeUnit(
          this.data.shapeType,
          this.locatorShapeDefaults,
          this.defaultDistanceUnit
        );
      })
    );
  }

  private subscribeToShapeDefaults() {
    this.subscription.add(
      this.locatorShapesDefaults$.subscribe((shapeDefaults) => {
        this.locatorShapeDefaults = shapeDefaults;
        this.shapeCarDriveTimeConfig = shapeDefaults!.car!;
        this.shapeWalkDriveTimeConfig = shapeDefaults!.walk!;
        this.shapeCircleConfig = shapeDefaults!.circle!;
        this.shapePublicTransportDriveTimeConfig =
          shapeDefaults!.publicTransport!;
      })
    );
  }

  private subscribeToLocationsCount(): void {
    this.subscription.add(
      this.batchOperationLocationIds$.subscribe((locations) => {
        this.locationsCount = locations.length;
      })
    );
  }
}
