<div class="main-container mat-app-background" matRaised>
  <div atlasModifyPolyDisableUI>
    <atlas-feature-buttons></atlas-feature-buttons>
    <div class="header-container">
      <div *ngIf="isProfilerFeatureSelected$ | async; else otherFeatures">
        <div atlasStatusScreenDisableUI>
          <atlas-gazetteer style="width: 100%"></atlas-gazetteer>
        </div>
      </div>
      <ng-template #otherFeatures>
        <atlas-gazetteer style="width: 100%"></atlas-gazetteer>
      </ng-template>
    </div>

    <div class="header-container" *ngIf="layoutService.isDesktopView$ | async">
      <atlas-scenario-management
        class="scenario-management-container"
      ></atlas-scenario-management>
    </div>
    <div class="header-container" *ngIf="layoutService.isDesktopView$ | async">
      <atlas-library-management></atlas-library-management>
    </div>
    <div class="header-container" *ngIf="layoutService.isDesktopView$ | async">
      <atlas-profiler-library-management></atlas-profiler-library-management>
    </div>
    <div class="no-header-padding">
      <div *ngIf="layoutService.isDesktopView$ | async">
        <div *ngIf="isProfilerFeatureSelected$ | async; else otherFeatures">
          <div atlasStatusScreenDisableUI>
            <atlas-feature-management-panel></atlas-feature-management-panel>
          </div>
        </div>
        <ng-template #otherFeatures>
          <atlas-feature-management-panel></atlas-feature-management-panel>
        </ng-template>
      </div>
    </div>
  </div>
</div>
