import { createSelector } from '@ngrx/store';
import { getProfilerState } from '../reducers';
import * as fromProfiler from '../reducers/profiler.reducer';
import { ProfilerLofiQueryResult } from 'src/app/profiler/model/profiler-lofi-report';
import {
  ProfilerStatusTypes,
  buildProfilerProgressSteps
} from 'src/app/profiler/helpers/profiler-status-helper';
import { ProfilerStatusProgressStep } from 'src/app/profiler/model/profiler-status-progress-step';
import { ProfilerImportStatusError } from 'src/app/profiler/model/profiler-import-status-error';

export const getProfilerLibraryId = createSelector(
  getProfilerState,
  fromProfiler.getId
);

export const getProfilerPowerBiReports = createSelector(
  getProfilerState,
  fromProfiler.getPowerBiReports
);

export const getLofiReport = createSelector(
  getProfilerState,
  fromProfiler.getLofiReport
);

export const getIsLofiReportAvailable = createSelector(
  getLofiReport,
  isLofiReportAvailable
);

export const getBases = createSelector(getProfilerState, fromProfiler.getBases);

export const getClassifications = createSelector(
  getProfilerState,
  fromProfiler.getClassifications
);

export const getSelectedBaseId = createSelector(
  getProfilerState,
  fromProfiler.getSelectedBaseId
);

export const getSelectedClassificationId = createSelector(
  getProfilerState,
  fromProfiler.getSelectedClassificationId
);

export const getProfilerImportStatus = createSelector(
  getProfilerState,
  fromProfiler.getImportStatus
);

export const getImportStatusMessage = createSelector(
  getProfilerState,
  fromProfiler.getImportStatusMessage
);

export const getProgressSteps = createSelector(
  getImportStatusMessage,
  generateProgressSteps
);

export const getJsonImportStatusError = createSelector(
  getProfilerState,
  fromProfiler.getJsonImportStatusError
);

export const getImportStatusErrorObject = createSelector(
  getJsonImportStatusError,
  getProcessedImportStatusErrors
);

export const hasProfilerLimitBeenExceededResult = createSelector(
  getProfilerState,
  fromProfiler.gethasProfilerLimitBeenExceededResult
);

export const getLibrariesCount = createSelector(
  getProfilerState,
  fromProfiler.getLibrariesCount
);

function generateProgressSteps(
  statusMessage: ProfilerStatusTypes | undefined
): ProfilerStatusProgressStep[] {
  if (statusMessage) {
    return buildProfilerProgressSteps(
      statusMessage as ProfilerStatusTypes
    ) as ProfilerStatusProgressStep[];
  }

  return [];
}

function isLofiReportAvailable(
  report: ProfilerLofiQueryResult[] | null
): boolean {
  return !!report && report.length > 0;
}

function getProcessedImportStatusErrors(
  jsonImportStatusErrors: string | undefined
) {
  if (jsonImportStatusErrors) {
    try {
      return JSON.parse(jsonImportStatusErrors) as ProfilerImportStatusError;
    } catch (error) {
      return null;
    }
  }
  return null;
}
