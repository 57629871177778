import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../services/user-settings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getUserSettingsSuceeded,
  getUserTenantsInfoSucceeded,
  updateCatchmentReportingUserSettingsError,
  updateCatchmentReportingUserSettingsOnSaveClicked,
  updateCatchmentReportingUserSettingsSucceeded,
  updateGeneralUserSettingsError,
  updateGeneralUserSettingsOnSaveClicked,
  updateGeneralUserSettingsSucceeded,
  updateLocatorUserSettingsError,
  updateLocatorUserSettingsOnSaveClicked,
  updateLocatorUserSettingsSucceeded,
  updateNetworkPlanningUserSettingError,
  updateNetworkPlanningUserSettingsOnSaveClicked,
  updateNetworkPlanningUserSettingSucceeded
} from '../actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UserSettings } from '../../models/user-settings';
import { State, getUserSettingsState } from '../reducers';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { getUserSettings } from '../selectors';
@Injectable()
export class UserSettingsEffects {
  constructor(
    private actions$: Actions,
    private userSettingsService: UserSettingsService,
    private store$: Store<State>
  ) {}

  getUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserTenantsInfoSucceeded),
      switchMap(() =>
        this.userSettingsService.getUserSettings().pipe(
          map((userSettings: UserSettings) => {
            return getUserSettingsSuceeded({ userSettings });
          })
        )
      )
    )
  );

  updateLocatorUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocatorUserSettingsOnSaveClicked),
      withLatestFrom(this.store$.select(getUserSettings)),
      switchMap(([{ locator }, userSettings]) => {
        userSettings.locator = locator;
        return this.userSettingsService.updateUserSettings(userSettings).pipe(
          map((userSettings: UserSettings) => {
            return updateLocatorUserSettingsSucceeded({
              userSettings
            });
          }),
          catchError((error) =>
            of(
              updateLocatorUserSettingsError({
                errorOn: 'User settings',
                error: 'Error saving your Catchment Reporting user settings'
              })
            )
          )
        );
      })
    )
  );

  updateGeneralUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGeneralUserSettingsOnSaveClicked),
      withLatestFrom(this.store$.select(getUserSettings)),
      switchMap(([{ generalSettings }, userSettings]) => {
        userSettings.general = generalSettings;

        return this.userSettingsService.updateUserSettings(userSettings).pipe(
          map((userSettings: UserSettings) => {
            return updateGeneralUserSettingsSucceeded({
              userSettings
            });
          }),
          catchError((error) =>
            of(
              updateGeneralUserSettingsError({
                errorOn: 'User settings',
                error: 'Error saving your General user settings'
              })
            )
          )
        );
      })
    )
  );
  updateNetworkPlanningUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNetworkPlanningUserSettingsOnSaveClicked),
      withLatestFrom(this.store$.select(getUserSettings)),
      switchMap(([{ networkPlanning }, userSettings]) => {
        userSettings.networkPlanning = networkPlanning;

        return this.userSettingsService.updateUserSettings(userSettings).pipe(
          map((userSettings: UserSettings) => {
            return updateNetworkPlanningUserSettingSucceeded({
              userSettings
            });
          }),
          catchError((error) =>
            of(
              updateNetworkPlanningUserSettingError({
                errorOn: 'User settings',
                error: 'Error saving your Network planning user settings'
              })
            )
          )
        );
      })
    )
  );
  updateCatchmentReportingUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCatchmentReportingUserSettingsOnSaveClicked),
      withLatestFrom(this.store$.select(getUserSettings)),
      switchMap(([{ catchmentReporting }, userSettings]) => {
        userSettings.catchmentReporting = catchmentReporting;
        return this.userSettingsService.updateUserSettings(userSettings).pipe(
          map((userSettings: UserSettings) => {
            return updateCatchmentReportingUserSettingsSucceeded({
              userSettings
            });
          }),
          catchError((error) =>
            of(
              updateCatchmentReportingUserSettingsError({
                errorOn: 'User settings',
                error: 'Error saving your Catchment Reporting user settings'
              })
            )
          )
        );
      })
    )
  );
}
