import { createReducer, on } from '@ngrx/store';

import { loadAppFeaturesSucceeded } from '../actions/app-feature-ui.actions';
import {
  addingEditingPolygonAttempt,
  addingEditingPolygonCancelled,
  addingEditingPolygonComplete,
  addingNewPolygonAttempt,
  addingNewPolygonComplete,
  addingPolygonCancelled,
  generatingCatchmentsAttempt,
  generatingCatchmentsComplete,
  locatorFeatureClicked,
  locatorLayersSucceeded,
  resetHasLocatorFeatureLoaded
} from '../actions/locator-ui.actions';
import { profilerFeatureClicked } from '../actions/profiler-ui.actions';
import { smFeatureClicked } from '../actions/spatial-modeller-ui.actions';

export interface State {
  locatorFeatureAllowed: boolean;
  featureSelected: boolean;
  hasLocatorFeatureLoaded: boolean;
  addingEditingPolygon: boolean;
  addingNewPolygon: boolean;
  generatingCatchments: boolean;
}

export const initialState: State = {
  locatorFeatureAllowed: false,
  featureSelected: false,
  hasLocatorFeatureLoaded: false,
  addingEditingPolygon: false,
  addingNewPolygon: false,
  generatingCatchments: false
};

export const locatorUIReducer = createReducer(
  initialState,
  on(loadAppFeaturesSucceeded, (state, { appFeatures }) => {
    return {
      ...state,
      locatorFeatureAllowed: appFeatures.locatorFeature
    };
  }),
  on(smFeatureClicked, profilerFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: false,
      hasLocatorFeatureLoaded: false
    };
  }),
  on(locatorFeatureClicked, (state) => {
    return {
      ...state,
      featureSelected: true
    };
  }),
  on(locatorLayersSucceeded, (state) => {
    return {
      ...state,
      hasLocatorFeatureLoaded: true
    };
  }),
  on(resetHasLocatorFeatureLoaded, (state) => {
    return {
      ...state,
      hasLocatorFeatureLoaded: false
    };
  }),
  on(addingEditingPolygonAttempt, (state) => {
    return {
      ...state,
      addingEditingPolygon: true
    };
  }),
  on(
    addingEditingPolygonComplete,
    addingEditingPolygonCancelled,
    addingPolygonCancelled,
    (state) => {
      return {
        ...state,
        addingEditingPolygon: false
      };
    }
  ),
  on(addingNewPolygonAttempt, (state) => {
    return {
      ...state,
      addingNewPolygon: true
    };
  }),
  on(addingNewPolygonComplete, (state) => {
    return {
      ...state,
      addingNewPolygon: false
    };
  }),
  on(generatingCatchmentsAttempt, (state) => {
    return {
      ...state,
      generatingCatchments: true
    };
  }),
  on(generatingCatchmentsComplete, (state) => {
    return {
      ...state,
      generatingCatchments: false
    };
  })
);
