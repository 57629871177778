import { createSelector } from '@ngrx/store';
import * as fromCore from '../reducers';
import {
  getDefaultHideLegendOnMapDownload,
  getDefaultDisableZoomToExtent
} from './app-feature-ui.selectors';

export const getUserLocatorSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.locator
);

export const getUserGeneralSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.general
);

export const getDisableZoomToExtent = createSelector(
  fromCore.getUserSettingsState,
  getDefaultDisableZoomToExtent,
  (state, defaultDisableZoomToExtent) =>
    state?.general && state?.general?.disableZoomToExtent !== null
      ? state.general.disableZoomToExtent
      : defaultDisableZoomToExtent
);

export const getHideLegendOnMapDownload = createSelector(
  fromCore.getUserSettingsState,
  getDefaultHideLegendOnMapDownload,
  (state, defaultHideLegendOnMapDownload) =>
    state?.general && state?.general?.hideLegendOnMapDownload !== null
      ? state.general.hideLegendOnMapDownload
      : defaultHideLegendOnMapDownload
);

export const getUserNetworkPlanningSettings = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning
);

export const getUserNetworkPlanningSettingsLiveModeDisabled = createSelector(
  fromCore.getUserSettingsState,
  (state) => state?.networkPlanning?.liveModeDisabled
);

export const getUserSettings = createSelector(
  getUserGeneralSettings,
  getUserLocatorSettings,
  getUserNetworkPlanningSettings,
  (general, locator, networkPlanning) => {
    return { general, locator, networkPlanning };
  }
);
