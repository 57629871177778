<div class="atlas-dialog-container">
  <atlas-dialog-header [headerText]="headerText"></atlas-dialog-header>

  <mat-dialog-content class="mat-dialog-content">
    <p></p>
    <div class="message-content">
      The model did not complete due to a travel model failure. What would you
      like to do with this model?
    </div>
    <p></p>
  </mat-dialog-content>
  
  <atlas-dialog-footer>
    <div class="buttons-container">
      <atlas-dialog-cancel-button
        label="Cancel"
        (clicked)="onCancel()"
      ></atlas-dialog-cancel-button>
      <atlas-dialog-affirmative-close-button
        label="Retry"
        (clicked)="onRetry()"
      ></atlas-dialog-affirmative-close-button>
    </div>
  </atlas-dialog-footer>
</div>
