import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DialogMode } from '../../enums/dialog-mode.enum';

@Component({
  selector: 'atlas-add-edit-dialog',
  templateUrl: './atlas-add-edit-dialog.component.html',
  styleUrls: ['./atlas-add-edit-dialog.component.less']
})
export class AtlasAddEditDialogComponent implements OnInit {
  @Input() entityName: string;
  @Input() mode: DialogMode;
  private _headerPrefix: string;
  @Input()
  set headerPrefix(newValue: string) {
    this._headerPrefix = newValue;

    this.headerText = this.useHeaderPrefix
      ? this._headerPrefix
        ? `${this._headerPrefix} ${this.entityName}`
        : `${this.mode} ${this.entityName}`
      : `${this.entityName}`;
  }
  get headerPrefix(): string {
    return this._headerPrefix;
  }

  @Input() closeOnSave = true;
  @Input() useHeaderPrefix = true;
  @Input() saveButtonText = 'Save and close';
  @Input() saveDisabled = false;
  @Input() cancelDisabled = false;
  @Input() showProgressBar = false;
  @Input() noOfStages: number;
  @Input() currentStage: number;

  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  headerText: string;

  ngOnInit(): void {}
}
