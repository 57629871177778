import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { VerifyNewPasswordComponent } from './auth/components/verify-new-password/verify-new-password.component';
import { VerifyUserComponent } from './auth/components/verify-user/verify-user.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { EmailGuard } from './auth/services/email-guard.service';
import { FirstLoginGuard } from './auth/services/first-login-guard.service';
import { StyleGuideWorkspaceComponent } from './dev/components/style-guide/style-guide-workspace/style-guide-workspace.component';
import { HomeWorkspaceComponent } from './home-workspace/home-workspace.component';
import { LegalComponent } from './auth/components/legal/legal.component';
import { FirstTimeLoginComponent } from './auth/components/first-time-login/first-time-login.component';
import { WithNavbarComponent } from './core/components/with-navbar/with-navbar.component';
import { MFAVerificationComponent } from './auth/components/mfa-verification/mfa-verification.component';
import { MFAVerificationGuard } from './auth/services/mfa-verification-guard.service';

const routes: Routes = [
  {
    path: '',
    component: WithNavbarComponent,
    children: [
      {
        path: 'home',
        component: HomeWorkspaceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'style-guide',
        component: StyleGuideWorkspaceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'spatial-modeller',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./spatial-modeller/spatial-modeller.module').then(
            (m) => m.AtlasSpatialModellerModule
          )
      },
      {
        path: 'reporting',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./reporting/reporting.module').then(
            (m) => m.AtlasReportingModule
          )
      }
    ]
  },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'verify-new-user',
    component: VerifyUserComponent,
    canActivate: [EmailGuard]
  },
  {
    path: 'verify-new-password',
    component: VerifyNewPasswordComponent,
    canActivate: [EmailGuard]
  },
  {
    path: 'first-time-login',
    component: FirstTimeLoginComponent,
    canActivate: [FirstLoginGuard]
  },
  { path: 'legal', component: LegalComponent },
  {
    path: 'mfa-verification',
    component: MFAVerificationComponent,
    canActivate: [MFAVerificationGuard]
  },
  { path: 'legal', component: LegalComponent },
  { path: '**', redirectTo: '' }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload' //Must have if you want to be able to use the anchor more than once
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
