import { createReducer, on } from '@ngrx/store';
import { LocatorLibraryData } from 'src/app/locator/models/locator-library-data';
import { LocatorLofiQueryResult } from 'src/app/locator/models/locator-lofi-report';
import { LocatorShape } from 'src/app/locator/models/locator-shape';
import {
  mapPinCleared,
  searchTextCleared
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';

import {
  clearLocatorLibraryStateOnLogoff,
  createLibrarySucceeded,
  generateTempLocatorLibraryDataPointAttempt,
  generateTempLocatorLibraryDataPointSucceded,
  getLocatorLibraryDataSucceeded,
  getLocatorLibrarySucceeded,
  getLocatorLoFiReportSucceded,
  getLocatorPowerBiReportingInfoSucceded,
  getReferenceLibrariesSucceeded,
  swapLibrarySucceeded,
  updateLibrarySucceeded,
  updateLocationNameSucceeded
} from '../actions/locator-library.actions';

import {
  deleteLibraryLocationsSucceeded,
  deselectAllLocations,
  locationFromLocationGridClicked,
  selectLocationsForBatchOperation
} from '../actions/locator-location.actions';

import { without as _without } from 'lodash';
import {
  allShapesFromLocationShapeListClicked,
  createLocatorShapesAttempt,
  createLocatorShapesSucceeded,
  deleteLibraryLocationShapeSucceeded,
  generateLocatorLoFiReportAttempt,
  generateLocatorLoFiReportSucceded,
  shapeFromLocationShapeListClicked,
  editLocatorPolygonShapeAttempt,
  updateLocatorShapeAttempt,
  updateLocatorShapeSucceeded,
  updateLocatorShapesErrorOccurred,
  getPowerBiReportDataCountSucceded
} from '../actions/locator-shape.actions';
import { LocatorPowerBiReportingInfo } from 'src/app/locator/models/locator-power-bi-reporting-info';
import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';
import {
  generatingCatchmentsComplete,
  locatorLayersSucceeded
} from 'src/app/reducers';
import { MapLayerTemplate } from 'src/app/core/models/map-layer-template';

export interface State {
  id: number;
  name: string;
  createDt: Date | null;
  editedDt: Date | null;
  libraryLocations: LocatorLibraryData[];
  powerBiReports: LocatorPowerBiReportingInfo[];
  // Temp state to determine report panel visibility.
  // Will get replaced by stats for locator.
  shapeGenerated: boolean;
  selectedShapes: LocatorShape[] | null;
  selectedLocation: LocatorLibraryData | null;
  loFiReport: LocatorLofiQueryResult[] | null;
  editingExistingPolygonShape: boolean;
  referenceLibraries: LocatorReferenceLibrary[];
  batchOperationLocationIds: number[];
  numberOfReportDataRecords: number;
  locatorMapLayerTemplates: MapLayerTemplate[];
}

export const initialState: State = {
  id: 0,
  name: '',
  createDt: null,
  editedDt: null,
  libraryLocations: [],
  shapeGenerated: false,
  powerBiReports: [],
  selectedShapes: null,
  selectedLocation: null,
  loFiReport: null,
  editingExistingPolygonShape: false,
  referenceLibraries: [],
  batchOperationLocationIds: [],
  numberOfReportDataRecords: 0,
  locatorMapLayerTemplates: []
};

export const locatorReducer = createReducer(
  initialState,
  on(
    getLocatorLibrarySucceeded,
    updateLibrarySucceeded,
    createLibrarySucceeded,
    swapLibrarySucceeded,
    (state, { library }) => {
      return {
        ...state,
        id: library.id,
        name: library.name,
        createDt: library.createDt,
        editedDt: library.editedDt
      };
    }
  ),
  on(
    searchTextCleared,
    mapPinCleared,
    generateTempLocatorLibraryDataPointAttempt,
    swapLibrarySucceeded,
    createLibrarySucceeded,
    (state) => {
      return {
        ...state,
        selectedShapes: null,
        selectedLocation: null,
        shapeGenerated: false,
        loFiReport: null,
        editingExistingPolygonShape: false,
        numberOfReportDataRecords: 0
      };
    }
  ),
  on(generateTempLocatorLibraryDataPointSucceded, (state, { locationData }) => {
    let shape = locationData.shapes[locationData.shapes.length - 1];
    return {
      ...state,
      selectedShapes: [shape],
      selectedLocation: locationData,
      shapeGenerated: true,
      libraryLocations: getUpdatedLocations(state.libraryLocations, [
        locationData
      ])
    };
  }),
  on(updateLocationNameSucceeded, (state, { locationData }) => {
    return {
      ...state,
      selectedLocation: locationData,
      libraryLocations: getUpdatedLocations(state.libraryLocations, [
        locationData
      ])
    };
  }),

  on(generateLocatorLoFiReportAttempt, (state, {}) => {
    return {
      ...state,
      loFiReport: null,
      numberOfReportDataRecords: 0
    };
  }),
  on(
    getLocatorLoFiReportSucceded,
    generateLocatorLoFiReportSucceded,
    (state, { report }) => {
      return {
        ...state,
        loFiReport: report
      };
    }
  ),

  on(clearLocatorLibraryStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  }),

  on(locationFromLocationGridClicked, (state, { locationData }) => {
    return {
      ...state,
      selectedLocation: locationData,
      batchOperationLocationIds: []
    };
  }),

  on(shapeFromLocationShapeListClicked, (state, { id }) => {
    var matchedShape = state.selectedLocation?.shapes.find(
      (shape) => shape.id === id
    );
    var shapes = matchedShape ? [matchedShape] : null;

    return {
      ...state,
      selectedShapes: shapes
    };
  }),
  on(allShapesFromLocationShapeListClicked, (state, { allSelected }) => {
    return {
      ...state,
      selectedShapes: allSelected ? [...state.selectedLocation!.shapes] : null
    };
  }),
  on(getLocatorPowerBiReportingInfoSucceded, (state, { pbiReports }) => {
    return {
      ...state,
      powerBiReports: pbiReports
    };
  }),

  on(getReferenceLibrariesSucceeded, (state, { libraries }) => {
    return {
      ...state,
      referenceLibraries: libraries
    };
  }),

  on(createLocatorShapesAttempt, (state) => {
    return {
      ...state,
      selectedShapes: null,
      shapeGenerated: false,
      loFiReport: null
    };
  }),

  on(updateLocatorShapeAttempt, (state) => {
    return {
      ...state,
      shapeGenerated: false,
      loFiReport: null
    };
  }),

  on(
    createLocatorShapesSucceeded,
    (state, { newShapes, multipleCatchments }) => {
      if (multipleCatchments) {
        return {
          ...state,
          shapeGenerated: true,
          selectedLocation: null,
          selectedShapes: null
        };
      } else {
        let outOfDateLocationData: LocatorLibraryData = state.selectedLocation!;

        const newShapeIds = newShapes.map((s) => s.id);
        const existedShapes = outOfDateLocationData.shapes.filter(
          (shape) => !newShapeIds.includes(shape.id)
        );

        let locationDataWithNewShapes = {
          ...outOfDateLocationData,
          shapes: [...existedShapes, ...newShapes]
        };

        const updatedLocations = getUpdatedLocations(state.libraryLocations, [
          locationDataWithNewShapes
        ]);

        const updatedSelectedLocation = updatedLocations.find(
          (loc) => loc.id === locationDataWithNewShapes.id
        );

        const selectedShapes = updatedSelectedLocation
          ? newShapes.length === 1
            ? [
                updatedSelectedLocation.shapes.find(
                  (s) => s.id === newShapes[0].id
                )!
              ]
            : updatedSelectedLocation.shapes
          : null;

        return {
          ...state,
          selectedShapes: selectedShapes,
          selectedLocation: updatedSelectedLocation || null,
          shapeGenerated: true,
          libraryLocations: updatedLocations
        };
      }
    }
  ),

  on(updateLocatorShapeSucceeded, (state, { updatedShape: updatedShape }) => {
    let outOfDateLocationData: LocatorLibraryData | undefined =
      state.libraryLocations.find(
        (loc) => loc.id === updatedShape.libraryDataId
      );

    if (outOfDateLocationData === undefined) return { ...state };

    const notUpdatedShapes = outOfDateLocationData.shapes.filter(
      (shape) => shape.id !== updatedShape.id
    );
    let locationDataWithUpdatedShape = {
      ...outOfDateLocationData,
      shapes: [...notUpdatedShapes, updatedShape]
    };

    const updatedLocations = getUpdatedLocations(state.libraryLocations, [
      locationDataWithUpdatedShape
    ]);

    const updatedSelectedLocation = updatedLocations.find(
      (loc) => loc.id === updatedShape.libraryDataId
    );

    return {
      ...state,
      selectedShapes: [updatedShape],
      selectedLocation: updatedSelectedLocation
        ? updatedSelectedLocation
        : null,
      shapeGenerated: true,
      libraryLocations: updatedLocations,
      editingExistingPolygonShape: false
    };
  }),

  on(deleteLibraryLocationShapeSucceeded, (state, { shapeId }) => {
    const deletedShape = state.selectedLocation!.shapes.find(
      (shape) => shape.id === shapeId
    );
    const shapeNameToDelete = deletedShape ? deletedShape.name : null;

    var location = { ...state.selectedLocation! };
    location.shapes = location.shapes.filter((s) => s.id !== shapeId);

    const updatedLoFiReport =
      state.loFiReport?.filter(
        (report) => report.shapeName !== shapeNameToDelete
      ) || [];

    return {
      ...state,
      selectedShapes: [],
      selectedLocation: location,
      loFiReport: updatedLoFiReport,
      libraryLocations: getUpdatedLocations(state.libraryLocations, [location!])
    };
  }),

  on(deleteLibraryLocationsSucceeded, (state, { libraryDataIds }) => {
    return {
      ...state,
      libraryLocations: state.libraryLocations?.filter(
        (keyValue) => !libraryDataIds.includes(keyValue.id)
      ),
      selectedShapes: null,
      selectedLocation: null,
      loFiReport: null,
      batchOperationLocationIds: []
    };
  }),

  on(editLocatorPolygonShapeAttempt, (state) => {
    return {
      ...state,
      editingExistingPolygonShape: true
    };
  }),

  on(updateLocatorShapesErrorOccurred, (state) => {
    return {
      ...state,
      editingExistingPolygonShape: false
    };
  }),
  on(getLocatorLibraryDataSucceeded, (state, { locations }) => {
    return {
      ...state,
      libraryLocations: locations
    };
  }),
  on(selectLocationsForBatchOperation, (state, { locationIds }) => {
    return {
      ...state,
      batchOperationLocationIds: locationIds
    };
  }),
  on(deselectAllLocations, (state) => {
    return {
      ...state,
      selectedLocation: null,
      batchOperationLocationIds: []
    };
  }),
  on(generatingCatchmentsComplete, (state) => {
    return {
      ...state,
      selectedLocation: null,
      selectedShapes: null
    };
  }),
  on(
    getPowerBiReportDataCountSucceded,
    (state, { numberOfReportDataRecords }) => {
      return {
        ...state,
        numberOfReportDataRecords: numberOfReportDataRecords
      };
    }
  ),
  on(
    locatorLayersSucceeded,
    (state, { locatorShapeType, locatorMapLayerTemplates }) => {
      return {
        ...state,
        // Filter included to only store templates with dynamic layer, therefore
        // only using memory where required, but can be removed if necessary.
        locatorMapLayerTemplates: locatorMapLayerTemplates.filter(
          (template) => template.featureAction
        )
      };
    }
  )
);

function getUpdatedLocations(
  currentLocations: LocatorLibraryData[],
  updatedLocations: LocatorLibraryData[]
) {
  const updatedLocationIds = updatedLocations.map((location) => location.id);
  const placesUpdated = filterById(currentLocations, updatedLocationIds);
  const locationsExcludingUpdated = _without(
    currentLocations,
    ...placesUpdated
  );
  return [...locationsExcludingUpdated, ...updatedLocations];
}

function filterById(
  entities: LocatorLibraryData[],
  ids: number[]
): LocatorLibraryData[] {
  return entities && ids && entities.filter((e) => ids.includes(e.id));
}

export const getId = (state: State) => state && state.id;
export const getName = (state: State) => state && state.name;
export const getShapeGenerated = (state: State) =>
  state && state.shapeGenerated;

export const getPowerBiReports = (state: State) =>
  state && state.powerBiReports;

export const getSelectedShapes = (state: State) =>
  state && state.selectedShapes;

export const getSelectedLocation = (state: State) =>
  state && state.selectedLocation;

export const getLofiReport = (state: State) => state && state.loFiReport;

export const getEditingExistingPolygonShape = (state: State) =>
  state && state.editingExistingPolygonShape;

export const getReferenceLibraries = (state: State) =>
  state && state.referenceLibraries;

export const getBatchOperationLocationIds = (state: State) =>
  state && state.batchOperationLocationIds;

export const getNumberOfReportDataRecords = (state: State) =>
  state && state.numberOfReportDataRecords;

export const getLocatorMapLayerTemplates = (state: State) =>
  state && state.locatorMapLayerTemplates;
