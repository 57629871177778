import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromUsage from './usage.reducer';

export const UsageFeatureKey = 'usage';

export interface State {
  usage: fromUsage.State;
}

export const reducers: ActionReducerMap<State> = {
  usage: fromUsage.usageReducer
};

export const getUsageModuleState =
  createFeatureSelector<State>(UsageFeatureKey);

export const getUsageState = createSelector(
  getUsageModuleState,
  (state) => state && state.usage
);
