<div>
  <button
    matSuffix
    mat-icon-button
    color="primary"
    aria-label="edit scenario"
    (click)="onOpenEditScenarioDialog()"
    matTooltip="Edit scenario"
    [matTooltipShowDelay]="1000"
    [disabled]="disabled"
  >
    <mat-icon class="edit-icon">edit</mat-icon>
  </button>
</div>
