import {
  colorCategories,
  colorContinuous,
  colorBins
} from '@deck.gl/carto/typed';
import { MapThematicScales } from '../types/map-thematic-scales';
import { Color, Solver } from './color-filter-helper';

export function getThematicColor(
  scaleType: MapThematicScales,
  scaleConfig: any
) {
  const scales = {
    colorBins: (config: any) => colorBins(config),
    colorContinuous: (config: any) => colorContinuous(config),
    colorCategories: (config: any) => colorCategories(config)
  };

  return scales[scaleType](scaleConfig);
}

export function rgbToHex(r: number, g: number, b: number) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function hexToRgb(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

export function getFilterColor(rgb: any) {
  if (rgb != undefined) {
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();

    return result.filterNoPropertyName;
  }

  return '';
}

export function getRadiusBasedOnAttribute(
  radiusAttribute: string,
  feature: any
) {
  if (feature.properties[radiusAttribute]) {
    return feature.properties[radiusAttribute];
  }
  return 6;
}

export function getIconSizeOnAttribute(
  iconSizeAttribute: string,
  feature: any
) {
  if (feature.properties[iconSizeAttribute]) {
    return feature.properties[iconSizeAttribute];
  }

  return 24;
}

export function getIconBasedOnAttribute(
  iconAttribute: string,
  iconDomain: string[],
  icons: string[],
  iconSourceUrl: string,
  iconOther: string,
  iconMask: boolean,
  iconAnchorY: number,
  iconWidth: number,
  iconHeight: number,
  feature: any
) {
  return {
    url: getIconUrl(
      iconAttribute,
      iconDomain,
      icons,
      iconSourceUrl,
      iconOther,
      feature.properties[iconAttribute]
    ),
    x: 0,
    y: 0,
    width: iconWidth,
    height: iconHeight,
    anchorY: iconAnchorY,
    mask: iconMask
  };
}

export function getIconUrl(
  iconAttribute: string,
  iconDomain: string[],
  icons: string[],
  iconSourceUrl: string,
  iconOther: string,
  featureProperty: any
) {
  const position = iconDomain.findIndex(
    (item) => item.toLowerCase() === featureProperty.toLowerCase()
  );
  if (position === -1) {
    return iconOther;
  }
  return iconSourceUrl + icons[position];
}
