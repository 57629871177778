import { Component, Input } from '@angular/core';
import { ProfilerStatusProgressStep } from '../../model/profiler-status-progress-step';

@Component({
  selector: 'atlas-profiler-progress-step',
  templateUrl: './profiler-progress-step.component.html',
  styleUrls: ['./profiler-progress-step.component.less']
})
export class ProfilerProgressStepComponent {
  @Input() progressStep: ProfilerStatusProgressStep;
}
