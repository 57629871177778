import { createSelector } from '@ngrx/store';
import { flattenSupplypointAttributes } from 'src/app/spatial-modeller/helpers/supply-point.helpers';
import { getScenarioState } from '../reducers';
import * as fromScenario from '../reducers/scenario.reducer';

export const getScenarioId = createSelector(
  getScenarioState,
  fromScenario.getId
);

export const getScenarioName = createSelector(
  getScenarioState,
  fromScenario.getName
);

export const getScenarioBase = createSelector(
  getScenarioState,
  fromScenario.getBase
);

export const getScenarioDelta = createSelector(
  getScenarioState,
  fromScenario.getDelta
);

export const getScenarioDeltaSummary = createSelector(
  getScenarioState,
  fromScenario.getDeltaSummary
);

export const getDeltaSupplyPoints = createSelector(
  getScenarioDelta,
  (delta) => delta[0]?.supplyPoints
);

export const getFlattenDeltaSupplyPoints = createSelector(
  getDeltaSupplyPoints,
  (delta) => delta?.map((s) => flattenSupplypointAttributes(s))
);
