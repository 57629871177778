import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUIStore from 'src/app/core/store';
import * as fromProfilerStore from 'src/app/profiler-store';
import { ProfilerStatusTypes } from '../../helpers/profiler-status-helper';
import { Subscription } from 'rxjs';
import { getProfilerLibraryId } from 'src/app/profiler-store';
import { map } from 'rxjs/operators';
@Component({
  selector: 'atlas-profile-status-screen',
  templateUrl: './profile-status-screen.component.html',
  styleUrls: ['./profile-status-screen.component.less']
})
export class ProfileStatusScreenComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  currentLibraryId: number;
  isProfilerFeatureSelected$ = this.UIStore$.select(
    fromUIStore.isProfilerFeatureSelected
  );
  status: ProfilerStatusTypes | undefined;

  profilerStatus$ = this.store$.select(
    fromProfilerStore.getImportStatusMessage
  );

  profilerImportStatus$ = this.store$.select(
    fromProfilerStore.getProfilerImportStatus
  );

  getProgressSteps$ = this.store$.select(fromProfilerStore.getProgressSteps);

  getImportStatusErrors$ = this.store$.select(
    fromProfilerStore.getImportStatusErrorObject
  );
  getProfilerLibraryId$ = this.store$.select(
    fromProfilerStore.getProfilerLibraryId
  );
  private libraryId$ = this.store$.select(getProfilerLibraryId);

  constructor(
    private UIStore$: Store<fromUIStore.State>,
    private store$: Store<fromProfilerStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.profilerStatus$.subscribe((importStatus) => {
        this.status = importStatus;
      })
    );
    this.subscription.add(
      this.libraryId$.subscribe((id) => {
        this.currentLibraryId = id;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isProfileStatusSucceded() {
    return this.status === 'ReportAggregateCompleted';
  }

  onCancelClicked() {
    this.store$.dispatch(
      fromProfilerStore.ProfilerLibraryActions.cancelAndRetrieveLastLibraryAttempt(
        {
          id: this.currentLibraryId
        }
      )
    );
  }

  isErrorOccured() {
    return this.profilerImportStatus$.pipe(
      map(
        (status) => status?.errorMessage !== null && status?.errorMessage !== ''
      )
    );
  }
}
