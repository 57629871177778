import { createAction, props } from '@ngrx/store';

export const fileImportHeaderValidationSuccess = createAction(
  '[Import Service] Header Validation Success',
  props<{
    file: File;
  }>()
);

export const fileImportHeaderValidationFailed = createAction(
  '[Import Service] Header Validation Failed',
  props<{
    errors: string;
  }>()
);

