<form [formGroup]="addEditLibraryForm" autocomplete="off">
  <div class="form-controls-padding">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Library name</mat-label>
      <input
        matInput
        type="text"
        placeholder="What are you working on?"
        formControlName="libraryName"
        cdkFocusInitial
      />
      <mat-error *ngIf="emptyLibraryName()">
        Please enter a library name
      </mat-error>
      <mat-error *ngIf="!uniqueLibraryName()">
        Another library has this name, please choose another name
      </mat-error>
    </mat-form-field>
  </div>
</form>
