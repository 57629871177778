import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atlas-progress-bar',
  templateUrl: './atlas-progress-bar.component.html',
  styleUrls: ['./atlas-progress-bar.component.less']
})
export class AtlasProgressBarComponent implements OnInit {
  @Input() noOfStages: number;
  @Input() currentStage: number = 1;

  stages: number[];

  ngOnInit(): void {
    this.stages = Array(this.noOfStages)
      .fill(1)
      .map((x, index) => index + 1);
  }
}
