import {
  LocatorImportStatusProgressStep,
  LocatorSummaryStatsStatusProgressStep
} from '../models/locator-status-progress-step';

export type LocatorImportStatusTypes =
  | 'LocatorImportDialogOpened'
  | 'FileUploadStarted'
  | 'FileUploadCompleted'
  | 'FileUploadFailed'
  | 'FileValidationStarted'
  | 'FileValidationFailed'
  | 'FileValidationCompleted'
  | 'GeocodingProcessStarted'
  | 'GeocodingProcessFailed'
  | 'GeocodingProcessCompleted'
  | 'ImportLocatorLibraryDataStarted'
  | 'ImportLocatorLibraryDataFailed'
  | 'ImportLocatorLibraryDataCompleted';

export type LocatorSummaryStatsStatusTypes =
  | 'SummaryStatsStarted'
  | 'SummaryStatsCompleted'
  | 'SummaryStatsFailed';

export type LocatorProgressStepsState =
  | 'NotStarted'
  | 'Processing'
  | 'Completed'
  | 'Error';

export type LocatorImportUiDialogSteps = 'select_csv_file' | 'processing_data';
export type LocatorSummaryStatsUiDialogSteps =
  | 'generating_summary_stats'
  | 'show_summary_and_report';

export function buildLocatorImportProgressSteps(
  currentStatus: LocatorImportStatusTypes
): LocatorImportStatusProgressStep[] {
  const progressStates = {
    LocatorImportDialogOpened: () => [
      {
        name: 'select_csv_file',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Processing'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    FileUploadFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Error'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'NotStarted'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    FileValidationCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    GeocodingProcessCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataStarted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Processing'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataFailed: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Error'
      } as LocatorImportStatusProgressStep
    ],
    ImportLocatorLibraryDataCompleted: () => [
      {
        name: 'select_csv_file',
        state: 'Completed'
      } as LocatorImportStatusProgressStep,
      {
        name: 'processing_data',
        state: 'Completed'
      } as LocatorImportStatusProgressStep
    ]
  };

  return progressStates[currentStatus]();
}

export function buildSummaryStatsProgressSteps(
  currentStatus: LocatorSummaryStatsStatusTypes
): LocatorSummaryStatsStatusProgressStep[] {
  const progressStates = {
    SummaryStatsStarted: () => [
      {
        name: 'generating_summary_stats',
        state: 'Processing'
      } as LocatorSummaryStatsStatusProgressStep,
      {
        name: 'show_summary_and_report',
        state: 'NotStarted'
      } as LocatorSummaryStatsStatusProgressStep
    ],
    SummaryStatsCompleted: () => [
      {
        name: 'generating_summary_stats',
        state: 'Completed'
      } as LocatorSummaryStatsStatusProgressStep,
      {
        name: 'show_summary_and_report',
        state: 'Completed'
      } as LocatorSummaryStatsStatusProgressStep
    ],
    SummaryStatsFailed: () => [
      {
        name: 'generating_summary_stats',
        state: 'Error'
      } as LocatorSummaryStatsStatusProgressStep,
      {
        name: 'show_summary_and_report',
        state: 'NotStarted'
      } as LocatorSummaryStatsStatusProgressStep
    ]
  };

  return progressStates[currentStatus]();
}
