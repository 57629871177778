import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MapLayerTemplate } from '../models/map-layer-template';
import { MedianPoint } from 'src/app/shared/atlas-gazetteer/models/median-point.model';

@Injectable({ providedIn: 'root' })
export class MapLayerTemplateService {
  spatialModelerApiUrl = `${environment.baseUrl}api/map-layers/spatial-modeller/`;
  locatorApiUrl = `${environment.baseUrl}api/map-layers/locator/`;
  profilerApiUrl = `${environment.baseUrl}api/map-layers/profiler/`;
  atlasProfilerLibrariesApiUrl = `${environment.baseUrl}api/profiler-libraries/`;

  constructor(private http: HttpClient) {}

  public getAllSpatialModellerLayers(): Observable<MapLayerTemplate[]> {
    return this.http.get<MapLayerTemplate[]>(this.spatialModelerApiUrl);
  }

  public getAllLocatorLayers(): Observable<MapLayerTemplate[]> {
    return this.http.get<MapLayerTemplate[]>(this.locatorApiUrl);
  }

  public getAllProfilerLayers(): Observable<MapLayerTemplate[]> {
    return this.http.get<MapLayerTemplate[]>(this.profilerApiUrl);
  }

  public getProfileMedianPoint(id: number) {
    return this.http.get<MedianPoint>(
      `${this.atlasProfilerLibrariesApiUrl}${id}/median-point`
    );
  }
}
