<div class="signup-body">
  <form
    class="form-container"
    (ngSubmit)="onSignup()"
    [formGroup]="signupForm"
    autocomplete="off"
  >
    <div>
      <h2 class="title">Signup Atlas</h2>
    </div>
    <hr class="line-hr" />
    <div class="signup-div">
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="firstName"
            placeholder="First Name"
          />
          <mat-error *ngIf="emptyFirstName()">
            Please enter your First Name
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label>Surname</mat-label>
          <input
            matInput
            type="text"
            formControlName="surname"
            placeholder="Surname"
          />
          <mat-error *ngIf="emptySurname()">
            Please enter your Surname
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label>Company Id</mat-label>
          <input
            matInput
            type="number"
            formControlName="tenantId"
            placeholder="Company Id"
          />
          <mat-error *ngIf="emptyCompanyId()">
            Please enter your Company Id
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
          />
          <mat-error *ngIf="invalidEmail()">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-controls">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Password"
          />
          <mat-icon matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
          <mat-error *ngIf="emptyPassword()">
            Please enter your password
          </mat-error>
        </mat-form-field>
      </div>
      <div class="signup-btn-div">
        <button mat-raised-button color="primary" type="submit">
          REGISTER
        </button>
      </div>
      <p style="font-size: 12px">
        Already a member?
        <span class="link" href="#" (click)="showLogin()">Login</span>
      </p>
    </div>
  </form>
  <br />
</div>
