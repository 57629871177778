<mat-accordion multi>
  <mat-expansion-panel
    [expanded]="layerExpandedState"
    (opened)="onOpened()"
    (closed)="onClosed()"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="group-panel-header">{{
        group.name
      }}</mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container *ngFor="let layer of group.layers">
      <atlas-layer-legend
        [layer]="layer"
        [layerExpandedState]="layersExpandedDictionary.get(layer.id)"
        (layerExpandedStateChanged)="onLayerToggle(layer.id, $event)"
      >
      </atlas-layer-legend>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
