import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import * as fromServices from './services';
import * as fromComponents from './components';
import { MaterialComponentsModule } from '../shared/material/material-components.module';
import { DevExpressComponentsModule } from '../shared/dev-express/dev-express-components.module';
import { AtlasSpatialModellerRoutingModule } from './spatial-modeller-routing.module';
import { AtlasDialogModule } from '../shared/atlas-dialog/atlas-dialog.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    AtlasSpatialModellerRoutingModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    DevExpressComponentsModule,
    AtlasDialogModule
  ],
  exports: [...fromComponents.components],
  providers: [...fromServices.services]
})
export class AtlasSpatialModellerModule {}
