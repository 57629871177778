<div class="base-map-layer-item-container">
  <mat-button-toggle
    [value]="baseMapLayerType"
    class="atlas-icon-button basemap-background-button"
    [class.basemap-positron-background-button]="baseMapLayerType === 'Positron'"
    [class.basemap-voyager-background-button]="baseMapLayerType === 'Voyager'"
    [class.basemap-satellite-background-button]="
      baseMapLayerType === 'Satellite'
    "
    [class.basemap-darkmatter-background-button]="
      baseMapLayerType === 'Dark_Matter'
    "
  >
  </mat-button-toggle>
  <span class="base-map-layer-name">{{ baseMapLayerName }}</span>
</div>
