import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { LocatorLibrary } from 'src/app/locator/models/locator-library';
import { formValueExistInArrayValidator } from 'src/app/shared/utils/form-validators/form-value-exist-in-array.validator';

@Component({
  selector: 'atlas-add-edit-library-form',
  templateUrl: './add-edit-library-form.component.html',
  styleUrls: ['./add-edit-library-form.component.less']
})
export class AddEditLibraryFormComponent implements OnInit {
  private _library: LocatorLibrary | null = null;
  @Input()
  set library(newValue: LocatorLibrary | null) {
    this._library = newValue;
  }

  get library(): LocatorLibrary | null {
    return this._library;
  }

  addEditLibraryForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder) {}
  private _userLibraries: string[] = [];
  @Input()
  set userLibraries(newValue: string[]) {
    this._userLibraries = [...newValue];
    // Add validator to avoid libraries with the same name
    this.addEditLibraryForm?.controls.libraryName.setValidators([
      Validators.required,
      formValueExistInArrayValidator(
        this.library
          ? this._userLibraries.filter((item) => item !== this.library?.name)
          : this._userLibraries
      )
    ]);
  }
  get userLibraries(): string[] {
    return this._userLibraries;
  }

  ngOnInit() {
    const initialValue: LocatorLibrary = this.library || ({} as LocatorLibrary);
    this.addEditLibraryForm = this.createLibraryForm(initialValue);
  }

  createLibraryForm(initialLibraryValue: LocatorLibrary) {
    var form = this.fb.group({
      id: initialLibraryValue.id,
      libraryName: [initialLibraryValue.name, Validators.required]
    });
    return form;
  }

  emptyLibraryName() {
    if (this.addEditLibraryForm.controls.libraryName.value) {
      return (
        this.addEditLibraryForm.controls.libraryName.value?.trim().length == 0
      );
    }

    return this.addEditLibraryForm.controls.libraryName.errors?.required;
  }

  uniqueLibraryName() {
    return !this.addEditLibraryForm.controls.libraryName.errors
      ?.valueAlreadyExist;
  }

  invalidForm(): boolean {
    return (
      !(this.addEditLibraryForm && this.addEditLibraryForm.controls) ||
      !this.addEditLibraryForm.dirty ||
      !this.addEditLibraryForm.controls?.libraryName.valid ||
      this.emptyLibraryName() ||
      !this.uniqueLibraryName()
    );
  }
}
