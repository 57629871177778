import { createReducer, on } from '@ngrx/store';

import {
  clearLocatorLibraryStateOnLogoff,
  createLibrarySucceeded,
  getLocatorImportLibraryDataSucceded,
  getLocatorLibrarySucceeded,
  swapLibrarySucceeded,
  updateLibrarySucceeded
} from '../actions/locator-library.actions';

import { LocatorImportStatus } from 'src/app/locator/models/locator-import-status';
import {
  hasLocatorLibraryLocationsLimitBeenExceededResult,
  locatorFileImportAttempt,
  locatorFileUploadError
} from '../actions/locator-import.actions';
import {
  pollLocatorImportLibraryDataStatusCompleted,
  pollLocatorImportLibraryDataStatusFailed,
  pollLocatorImportLibraryDataStatusInProgress
} from '../actions/locator-import-status.actions';
import { locatorFileImportHeaderValidationFailed } from '../actions/locator-import-validation.actions';

export interface State {
  id: number;
  importUsingGeocoding: boolean;
  importStatus: LocatorImportStatus | null;
  hasLocatorLibraryLocationsImportLimitBeenExceeded: boolean;
}

export const initialState: State = {
  id: 0,
  importStatus: null,
  importUsingGeocoding: false,
  hasLocatorLibraryLocationsImportLimitBeenExceeded: false
};

export const locatorImportLibraryDataReducer = createReducer(
  initialState,
  on(
    getLocatorLibrarySucceeded,
    updateLibrarySucceeded,
    createLibrarySucceeded,
    swapLibrarySucceeded,
    (state, { library }) => {
      return {
        ...state,
        id: library.id,
        importStatus: null,
        importUsingGeocoding: false,
        hasLocatorLibraryLocationsImportLimitBeenExceeded: false
      };
    }
  ),

  on(clearLocatorLibraryStateOnLogoff, (state) => {
    return {
      ...initialState
    };
  }),

  on(locatorFileImportAttempt, (state, { file, importUsingGeocoding }) => {
    return {
      ...state,
      importStatus: null,
      importUsingGeocoding,
      hasLocatorLibraryLocationsImportLimitBeenExceeded: false
    };
  }),
  on(
    getLocatorImportLibraryDataSucceded,
    pollLocatorImportLibraryDataStatusInProgress,
    pollLocatorImportLibraryDataStatusCompleted,
    pollLocatorImportLibraryDataStatusFailed,
    (state, { locatorImportStatus }) => {
      return {
        ...state,
        importStatus: locatorImportStatus
      };
    }
  ),
  on(
    locatorFileUploadError,
    locatorFileImportHeaderValidationFailed,
    (state, { errors }) => {
      const locatorImportStatus = createLocatorImportStatusFromClientSide(
        state.id,
        errors
      );
      return {
        ...state,
        importStatus: locatorImportStatus
      };
    }
  ),
  on(hasLocatorLibraryLocationsLimitBeenExceededResult, (state, { result }) => {
    return {
      ...state,
      hasLocatorLibraryLocationsImportLimitBeenExceeded: result
    };
  })
);

function createLocatorImportStatusFromClientSide(id: number, errors: string) {
  const locatorImportStatus: LocatorImportStatus = {
    libraryId: id,
    createDt: new Date(Date.now()),
    editedDt: new Date(Date.now()),
    status: 'FileUploadFailed',
    errorMessage: errors,
    importedRecords: 0,
    processedRecords: 0,
    totalRecords: 0,
    fileKey: '',
    exportedFileUrl: ''
  };

  return locatorImportStatus;
}

export const getImportStatus = (state: State) => state && state.importStatus;
export const getImportStatusMessage = (state: State) =>
  state && state.importStatus?.status;
export const getImportUsingGeocoding = (state: State) =>
  state && state.importUsingGeocoding;
export const getJsonImportStatusError = (state: State) =>
  state && state.importStatus?.errorMessage;
export const getHasLocatorLibraryLocationsImportLimitBeenExceeded = (
  state: State
) => state && state.hasLocatorLibraryLocationsImportLimitBeenExceeded;
