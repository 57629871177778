import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DialogMode } from 'src/app/shared/atlas-dialog/enums/dialog-mode.enum';
import * as fromLocatorStore from 'src/app/locator-store';

import { LocatorReferenceLibraryFormComponent } from '../reference-library-form/reference-library-form.component';
import { LocatorReferenceLibrary } from 'src/app/locator/models/locator-reference-library';
import { importReferenceLibraryDataClicked } from 'src/app/locator-store/actions/locator-library.actions';

@Component({
  selector: 'atlas-locator-reference-library-dialog',
  templateUrl: './reference-library-dialog.component.html',
  styleUrls: ['./reference-library-dialog.component.less']
})
export class LocatorReferenceLibraryDialogComponent {
  @ViewChild('referenceLibraryForm')
  referenceLibraryForm: LocatorReferenceLibraryFormComponent;
  @Output() buttonClicked = new EventEmitter();
  dialogMode: DialogMode;

  constructor(
    private dialogRef: MatDialogRef<LocatorReferenceLibraryFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      referenceLibraries: LocatorReferenceLibrary[];
    },
    private locatorStore$: Store<fromLocatorStore.State>
  ) {}

  onCopyReferenceLibrary() {
    this.locatorStore$.dispatch(
      importReferenceLibraryDataClicked({
        referenceLibraryId: this.referenceLibraryForm.selectedLibrary
          ?.id as number
      })
    );

    this.dialogRef.close({});
  }
}
