import { Component } from '@angular/core';

@Component({
  selector: 'atlas-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.less'],
})
export class LegalComponent {
  constructor() {}
}
