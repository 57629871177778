import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import { SupplyPoint, UserAction } from '../models/supply-point';
import { SupplyPointAttribute } from '../models/supply-point-attribute';
import { MapLayerTemplate } from 'src/app/core/models/map-layer-template';
import { MapService } from 'src/app/shared/atlas-mapping/services/map.service';
import { featureActions } from 'src/app/locator/types/feature-action.types';
import {
  getUpdatablesFromRecord,
  showFeatureActionMapLayers
} from 'src/app/shared/atlas-mapping/helpers/dynamic-layers.helper';
import {
  smModelResultsCircleLayerIdentifier,
  smModelResultsDriveTimeLayerIdentifier,
  smModelTestResultsLayerIdentifier
} from 'src/app/shared/atlas-mapping/layers/layer.constants';
import { environment } from 'src/environments/environment';
import { TestStatusResponse } from '../models/test-status-response';

export function showSmLayersEffectedByScenarioSelectionLayers(
  scenarioUpdatables: Record<string, number>,
  smTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  const updatables = getUpdatablesFromRecord(
    scenarioUpdatables,
    featureActions.selectedScenario.updatables
  );
  showFeatureActionMapLayers(
    smTemplates.filter(
      (template) =>
        template.featureAction === featureActions.selectedScenario.actionType
    ),
    updatables,
    mapService,
    false
  );
}

export function showSmLayersEffectedBySupplyPointSelection(
  location: Record<string, number>,
  supplyPoint: SupplyPoint,
  statusResponse: TestStatusResponse,
  smTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  if (!supplyPoint?.isClosed) {
    showModelTestResultsMapLayer(statusResponse.testId, mapService);
  }
  showModelResultsDrivetimeMapLayer(supplyPoint?.location, mapService);
  showModelResultsCircleMapLayer(supplyPoint?.location, mapService);
  showSmSelectedSupplyPointMapLayer(location, smTemplates, mapService);
}

export function showSmSelectedSupplyPointMapLayer(
  locationUpdatables: Record<string, number>,
  smTemplates: MapLayerTemplate[],
  mapService: MapService
) {
  if (location) {
    const updatables = getUpdatablesFromRecord(
      locationUpdatables,
      featureActions.selectedSupplyPoint.updatables
    );
    showFeatureActionMapLayers(
      smTemplates.filter(
        (template) =>
          template.featureAction ===
          featureActions.selectedSupplyPoint.actionType
      ),
      updatables,
      mapService,
      true
    );
  }
}

function showModelTestResultsMapLayer(testId: number, mapService: MapService) {
  mapService.updateLayer(smModelTestResultsLayerIdentifier, {
    visible: true,
    data: `${environment.baseUrl}api/mapping/spatial-modeller/tests/${testId}/boundaries`
  });
}

function showModelResultsDrivetimeMapLayer(
  location: Point | undefined,
  mapService: MapService
) {
  var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
    smModelResultsDriveTimeLayerIdentifier
  );
  let layer = mapService.getLayer(smModelResultsDriveTimeLayerIdentifier);
  let dataUrl = `${environment.baseUrl}api/lds/isolines?lat=${location?.latitude}&lng=${location?.longitude}&mode=${layer.props.lds.mode}&range=${layer.props.lds.range}&range_type=${layer.props.lds.range_type}`;

  if (location) {
    mapService.updateLayer(smModelResultsDriveTimeLayerIdentifier, {
      visible: usersVisiblePreference,
      data: dataUrl
    });
  }
}

function showModelResultsCircleMapLayer(
  location: Point | undefined,
  mapService: MapService
) {
  var usersVisiblePreference = mapService.getLayerVisiblityFromStorage(
    smModelResultsCircleLayerIdentifier
  );
  let layer = mapService.getLayer(smModelResultsCircleLayerIdentifier);
  let dataUrl = `${environment.baseUrl}api/shape/circle?lat=${location?.latitude}&lng=${location?.longitude}&unit=${layer.props.circle.unit}&distance=${layer.props.circle.distance}`;

  if (location) {
    mapService.updateLayer(smModelResultsCircleLayerIdentifier, {
      visible: usersVisiblePreference,
      data: dataUrl
    });
  }
}

export function buildDefaultSupplyPoint(
  supplyPointAttributeList: SupplyPointAttribute[] | null
): SupplyPoint {
  const mappedSupplyPointProperties = mapFixedSupplyPointProperties(
    supplyPointAttributeList
  );

  const supplyPoint = mappedSupplyPointProperties.supplyPoint;

  const additionalProperties =
    getAdditionalSupplyPointPropertiesFromAdditionalAttributes(
      mappedSupplyPointProperties.additionalAttributes,
      supplyPoint['fascia']
    );

  supplyPoint['attributes'] = additionalProperties;
  supplyPoint.userAction = UserAction.Temporary;
  supplyPoint.isClosed = false;
  supplyPoint.supplyId = autoGenerateSupplyId();
  return supplyPoint as SupplyPoint;
}

export function tidyUpSupplyPoinValuesBasedOnFascia(
  supplyPointValues: any,
  supplyPointAttributes: SupplyPointAttribute[]
): any {
  const fasciaValue = supplyPointValues['fascia'];
  if (fasciaValue) {
    // Adding those default supply point values not related to the selected fascia
    // to complete all the supply point table columns in case the user saves it in the DB
    const defaultSupplyPointValuesForNonFasciaAttributes =
      getDefaultSupplyPointValuesForNonFasciaAttributes(
        supplyPointAttributes,
        fasciaValue
      );

    // Adding those default supply point values related to the selected fascia which are missing from the supply point values
    const defaultSupplyPointValuesMissingForFasciaAttributes =
      getDefaultSupplyPointValuesMissingForFasciaAttributes(
        supplyPointAttributes,
        supplyPointValues,
        fasciaValue
      );

    const tidiedUpSupplyPointValuesBasedOnFascia = {
      ...supplyPointValues,
      ...defaultSupplyPointValuesForNonFasciaAttributes,
      ...defaultSupplyPointValuesMissingForFasciaAttributes
    };
    return tidiedUpSupplyPointValuesBasedOnFascia;
  }
  return { ...supplyPointValues };
}

function getDefaultSupplyPointValuesForNonFasciaAttributes(
  supplyPointAttributes: SupplyPointAttribute[],
  fasciaValue: any
) {
  const supplyPointAttributesNotBelongingToFascia =
    supplyPointAttributes?.filter(
      (attribute) =>
        attribute.fasciaDependency !== null &&
        !attribute.fasciaDependency.split(';').includes(fasciaValue)
    );
  const defaultSupplyPointValuesForNonFasciaAttributeList =
    supplyPointAttributesNotBelongingToFascia.map((attribute) => {
      return { [attribute.name]: null };
    });
  const defaultSupplyPointValuesForNonFasciaAttributes = Object.assign(
    {},
    ...defaultSupplyPointValuesForNonFasciaAttributeList
  );
  return defaultSupplyPointValuesForNonFasciaAttributes;
}

function getDefaultSupplyPointValuesMissingForFasciaAttributes(
  supplyPointAttributes: SupplyPointAttribute[],
  supplyPointValues: any,
  fasciaValue: any
) {
  const supplyPointsAttributesForFascia = supplyPointAttributes.filter(
    (attribute) =>
      attribute.fasciaDependency == null ||
      attribute.fasciaDependency.split(';').includes(fasciaValue)
  );
  const supplyPointValuesMissingForFascia = supplyPointsAttributesForFascia
    .filter((attribute) => supplyPointValues[attribute.name] === undefined)
    .map((attribute) => {
      return { [attribute.name]: attribute.defaultValue };
    });

  const defaultSupplyPointValuesMissingForFasciaAttributes = Object.assign(
    {},
    ...supplyPointValuesMissingForFascia
  );
  return defaultSupplyPointValuesMissingForFasciaAttributes;
}

export function buildNewSupplyPoint(
  supplyPointValues: any,
  location?: Point
): SupplyPoint {
  const sampleSupplyPoint: SupplyPoint = new SupplyPoint();
  const supplyPoint: { [key: string]: any } = {};

  supplyPoint.supplyKey = supplyPointValues['supplykey'];
  supplyPoint.supplyId = supplyPointValues['supplyid'];
  supplyPoint.fascia = supplyPointValues['fascia'];
  supplyPoint.name = supplyPointValues['name'];
  supplyPoint.location = location;
  supplyPoint.address1 = supplyPointValues['address1'];
  supplyPoint.address2 = supplyPointValues['address2'];
  supplyPoint.address3 = supplyPointValues['address3'];
  supplyPoint.address4 = supplyPointValues['address4'];

  let supplyPointKeys = Object.keys(sampleSupplyPoint).map((i) =>
    i.toLocaleLowerCase()
  );
  let additionalAttributes = Object.entries(supplyPointValues).filter(
    (element) => {
      return !supplyPointKeys.includes(element[0]);
    }
  );

  const additionalSupplyPointProperties = additionalAttributes?.map(
    (attribute) => ({
      key: attribute[0],
      value: attribute[1] == null ? null : attribute[1] + ''
    })
  );

  supplyPoint['attributes'] = additionalSupplyPointProperties;

  return supplyPoint as SupplyPoint;
}

function autoGenerateSupplyId() {
  return 'Temp_' + Math.round(new Date().getTime() / 1000);
}

function mapFixedSupplyPointProperties(
  supplyPointAttributeList: SupplyPointAttribute[] | null
): {
  supplyPoint: { [key: string]: any };
  additionalAttributes: SupplyPointAttribute[];
} {
  // This is needed to get the properties for a SupplyPoint object dynamically.
  // Notes:
  // 1.- Since this is javascript there is no way to achieve that using reflection.
  // 2.- All properties of that class must be initialised in order to get the expected
  //     values using Object.keys()/Object.getOwnPropertyNames().
  // 3.- SupplyPoint must be a class. It can not be an Interface for the same reason explained in point 1.-
  const sampleSupplyPoint: SupplyPoint = new SupplyPoint();

  // supplyPoint must be declared in this way to allow things like "supplyPoint[x] = value"
  // where x is a string variable;
  const supplyPoint: { [key: string]: any } = {};
  const additionalAttributes: SupplyPointAttribute[] = [];

  supplyPointAttributeList?.forEach((attribute) => {
    const supplyPointProperty =
      getFixedSupplyPointPropertyFromSupplyPointAttribute(attribute);

    if (supplyPointProperty) {
      const key = Object.keys(sampleSupplyPoint).find((property) => {
        return property.toLocaleLowerCase() === supplyPointProperty().key;
      });
      if (key) {
        supplyPoint[key] = supplyPointProperty().value;
      }
    } else {
      additionalAttributes.push(attribute);
    }
  });

  return { supplyPoint, additionalAttributes };
}

function getFixedSupplyPointPropertyFromSupplyPointAttribute(
  attribute: SupplyPointAttribute
) {
  let fixedSupplyPointProperties = (propertyName: string) =>
    ({
      supplykey: () => {
        return { key: 'supplykey', value: attribute.defaultValue };
      },
      supplyid: () => {
        return { key: 'supplyid', value: attribute.defaultValue };
      },
      name: () => {
        return { key: 'name', value: attribute.defaultValue };
      },
      uid: () => {
        return { key: 'uid', value: attribute.defaultValue };
      },
      fascia: () => {
        return { key: 'fascia', value: attribute.defaultValue };
      },
      location: () => {
        return { key: 'location', value: attribute.defaultValue };
      },
      address1: () => {
        return { key: 'address1', value: attribute.defaultValue };
      },
      address2: () => {
        return { key: 'address2', value: attribute.defaultValue };
      },
      address3: () => {
        return { key: 'address3', value: attribute.defaultValue };
      },
      address4: () => {
        return { key: 'address4', value: attribute.defaultValue };
      }
    }[propertyName]);
  return fixedSupplyPointProperties(attribute.name);
}

function getAdditionalSupplyPointPropertiesFromAdditionalAttributes(
  attributes: SupplyPointAttribute[],
  defaultFascia: string
) {
  const additionalSupplyPointProperties = attributes?.map((attribute) => ({
    key: attribute.name,
    value: attribute.fasciaDependency
      ? attribute.fasciaDependency.includes(defaultFascia)
        ? attribute.defaultValue
        : null // this attribute doesn't apply to the default fascia. null value is assigned
      : attribute.defaultValue // if fascia_dependency is null then that attribute is common to all fascias and the default value should be added
  }));
  return additionalSupplyPointProperties;
}

export function flattenSupplypointAttributes(sp: SupplyPoint) {
  // may need to check if the value should be number or boolean
  var arr: any[] = sp.attributes.map((kv: any) => [kv.key, kv.value]);
  // transfor array of property name and value to object
  const obj = Object.fromEntries(arr);
  // map common properties for supplypoints between companies
  obj.fascia = sp.fascia;
  obj.isClosed = sp.isClosed;
  obj.name = sp.name;
  obj.supplyId = sp.supplyId;
  obj.supplyKey = sp.supplyKey;
  obj.status =
    sp.isClosed && sp.userAction != UserAction.Untouched
      ? 'Closed'
      : UserAction[sp.userAction];
  obj.address1 = sp.address1;
  obj.address2 = sp.address2;
  obj.address3 = sp.address3;
  obj.address4 = sp.address4;
  return obj;
}

export function getUpdatedSupplyPointValues(
  supplyPointValues: any,
  supplyPointAttributes: SupplyPointAttribute[],
  currentSupplyPoint: SupplyPoint
) {
  const tidiedUpSupplyPointValuesBasedOnFascia =
    tidyUpSupplyPoinValuesBasedOnFascia(
      supplyPointValues,
      supplyPointAttributes!
    );

  const updatedSupplyPointValues = {
    ...tidiedUpSupplyPointValuesBasedOnFascia,
    supplykey: currentSupplyPoint ? currentSupplyPoint?.supplyKey : 0
  };
  let updatedSupplyPoint = buildNewSupplyPoint(
    updatedSupplyPointValues,
    currentSupplyPoint?.location
  );

  // If there are no supply point attributes configured to display address1/2/3/4 columns,
  // the form doesn't sent values for those columns which will be mapped as null values when we save them in the Db.
  // To avoid this the address columns are restored with their original values
  if (
    !supplyPointAttributes?.some(
      (spa) =>
        spa.name.includes('address1') ||
        spa.name.includes('address2') ||
        spa.name.includes('address3') ||
        spa.name.includes('address4')
    )
  ) {
    updatedSupplyPoint = {
      ...updatedSupplyPoint,
      address1: currentSupplyPoint!.address1,
      address2: currentSupplyPoint!.address2,
      address3: currentSupplyPoint!.address3,
      address4: currentSupplyPoint!.address4
    };
  }
  return updatedSupplyPoint;
}
