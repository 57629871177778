import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromSmStore from 'src/app/spatial-modeller-store';
import { DialogResult } from 'src/app/shared/atlas-dialog/enums/dialog-result.enum';
import { SmTestUserActionSources } from 'src/app/spatial-modeller/models/test-action-sources';

@Component({
  selector: 'retry-supply-point-action-dialog.component',
  templateUrl: './retry-supply-point-action-dialog.component.html',
  styleUrls: ['./retry-supply-point-action-dialog.component.less']
})
export class RetrySupplyPointActionDialogComponent {
  get headerText() {
    return 'Model Failure';
  }

  constructor(
    private store$: Store<fromSmStore.State>,
    private dialogRef: MatDialogRef<RetrySupplyPointActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      source: SmTestUserActionSources;
    }
  ) {}

  onRetry() {
    let action = this.getRetryActionToDispatch(this.data.source);
    this.store$.dispatch(action());
    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  onCancel() {
    this.dialogRef.close({
      action: DialogResult.Affirmative
    });
  }

  private getRetryActionToDispatch(source: SmTestUserActionSources) {
    switch (source) {
      case SmTestUserActionSources.SAVE_SP:
        return fromSmStore.SpatialModellerTestActions
          .spatialModellerModelLocationStartedFromSPSave;
      case SmTestUserActionSources.CLOSE_SP:
        return fromSmStore.SpatialModellerTestActions
          .spatialModellerModelLocationStartedFromSPClose;
      case SmTestUserActionSources.REOPEN_SP:
        return fromSmStore.SpatialModellerTestActions
          .spatialModellerModelLocationStartedFromSPReOpen;
      default:
        return fromSmStore.SpatialModellerTestActions
          .spatialModellerModelLocationStarted;
    }
  }
}
