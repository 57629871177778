import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as fromStore from 'src/app/reporting/store';
import * as fromUIStore from 'src/app/core/store';
import { getModelsAllUsersUsageSummary } from 'src/app/reporting/store';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-user-model-usage-chart',
  templateUrl: './user-model-usage-chart.component.html',
  styleUrls: ['./user-model-usage-chart.component.less']
})
export class UserModelUsageChartComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  allowInternalUsersUsage: boolean;
  data: any[] = [];

  // options

  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Users';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Model runs';
  showGridLines: boolean = false;

  constructor(
    private store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );
    this.subscription.add(
      this.store$.select(getModelsAllUsersUsageSummary).subscribe((usage) => {
        this.data = [];
        const filteredUsers = hideCACIUsers(
          usage,
          this.allowInternalUsersUsage
        );
        filteredUsers?.forEach((user) => {
          this.data.push({
            name: user.fullName,
            value: user.modelRuns
          });
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
