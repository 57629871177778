<atlas-single-button-dialog buttonText="Cancel" [headerText]="data.headerText">
  <form formContent>
    <div style="margin-top: 10px" formContent>
      <div
        *ngIf="data.validationMessage"
        class="list-container"
        [innerText]="data.validationMessage"
      ></div>
    </div>
  </form>
</atlas-single-button-dialog>
