import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  changeLocationCancelled,
  changeLocationCancelledCompleted,
  changeLocationConfirmedAttempt,
  changeLocationConfirmedSucceeded,
  newLocationSelected,
  resetChangeLocationState,
  revertToExistingSelectedLocation
} from '../actions/spatial-modeller-ui.actions';
import { getExistingSelectedLocation } from 'src/app/core/store';
import * as fromUIStore from 'src/app/core/store';
import { getSelectedPoint } from 'src/app/shared/atlas-gazetteer/store';
import { Point } from 'src/app/shared/atlas-gazetteer/models/point.model';
import {
  mapPinCleared,
  selectNewLocationForSelectionPinSucceeded
} from 'src/app/shared/atlas-gazetteer/store/actions/gazetteer.actions';

@Injectable()
export class SpatialModellerUIEffects {
  constructor(
    private actions$: Actions,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  changeLocationCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLocationCancelled),
      withLatestFrom(this.UIStore$.select(getExistingSelectedLocation)),
      switchMap(([_, existingSelectedLocation]) => {
        return of(
          revertToExistingSelectedLocation({
            location: existingSelectedLocation
          })
        );
      })
    )
  );

  changeLocationConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLocationConfirmedAttempt),
      withLatestFrom(this.UIStore$.select(getSelectedPoint)),
      switchMap(([_, selectedLocation]) => {
        return of(
          changeLocationConfirmedSucceeded({
            location: selectedLocation.location as Point
          })
        );
      })
    )
  );

  revertToExistingSelectedLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revertToExistingSelectedLocation),
      withLatestFrom(this.UIStore$.select(getExistingSelectedLocation)),
      switchMap(([_, existingSelectedLocation]) => {
        return of(
          changeLocationCancelledCompleted({
            location: existingSelectedLocation
          })
        );
      })
    )
  );

  resetChangeLocationState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapPinCleared),
      switchMap(() => {
        return of(resetChangeLocationState());
      })
    )
  );

  newLocationSelectedState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectNewLocationForSelectionPinSucceeded),
      switchMap(() => {
        return of(newLocationSelected());
      })
    )
  );
}
