import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from 'src/app/reporting/store';
import { LayoutService } from 'src/app/shared/atlas-layout/services/layout.service';
import * as fromUIStore from 'src/app/core/store';
import { UsersCatchmentUsage } from '../../models/usage-summary';
import { Subscription } from 'rxjs';
import { hideCACIUsers } from 'src/app/shared/utils/usage-utils';

@Component({
  selector: 'atlas-catchment-usage-tab-content',
  templateUrl: './catchment-usage-tab-content.component.html',
  styleUrls: ['./catchment-usage-tab-content.component.less']
})
export class CatchmentUsageTabContentComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  constructor(
    public layoutService: LayoutService,
    private store$: Store<fromStore.State>,
    private UIStore$: Store<fromUIStore.State>
  ) {}

  showGrid = true;

  allUsersUsage: UsersCatchmentUsage[];
  allowInternalUsersUsage: boolean;

  userUsageSummary$ = this.store$.select(
    fromStore.getCatchmentsUserUsageSummary
  );
  companyUsageSummary$ = this.store$.select(
    fromStore.getCatchmentsCompanyUsageSummary
  );

  ngOnInit(): void {
    this.subscription.add(
      this.UIStore$.select(fromUIStore.allowInternalUsersUsage).subscribe(
        (value) => {
          this.allowInternalUsersUsage = value;
        }
      )
    );

    this.subscription.add(
      this.store$
        .select(fromStore.getCatchmentsAllUsersUsageSummary)
        .subscribe((usersUsage) => {
          this.allUsersUsage = hideCACIUsers(
            usersUsage,
            this.allowInternalUsersUsage
          );
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleGridChart() {
    this.showGrid = !this.showGrid;
  }
}
