<div class="main-container">
  <div class="side-column">
    <button
      mat-flat-button
      type="button"
      class="secondary-button back-button"
      (click)="onBack()"
    >
      <mat-icon class="chevron-back">chevron_left</mat-icon>Back
    </button>
  </div>
  <div class="workspace-container">
    <div>
      <div class="title-padding"><h1>Help & Resources</h1></div>
      <div class="sub-title-padding">
        Get in touch and let us know how we can help
      </div>
      <atlas-help-content></atlas-help-content>
    </div>
  </div>
  <div class="side-column"></div>
</div>
