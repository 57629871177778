<atlas-feature-management-buttons
  (featureManagementOptionSelected)="onFeatureManagementOptionSelected($event)"
>
</atlas-feature-management-buttons>
<div class="feature-management-container">
  <atlas-layer-management
    class="layers-container"
    *ngIf="featureManagementOption == 'Layers'"
  ></atlas-layer-management>

  <atlas-library-locations-list
    class="layers-container"
    *ngIf="featureManagementOption == 'LocatorLocations'"
    (locationsClickSelected)="onLocationClickSelected($event)"
    [libraryId]="(libraryId$ | async)"
    [selectedLocationId]="selectedLocationId$ | async"
  ></atlas-library-locations-list>
</div>
