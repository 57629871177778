import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atlas-dialog-affirmative-close-button',
  templateUrl: './dialog-affirmative-button.component.html',
  styleUrls: ['./dialog-affirmative-button.component.less']
})
export class DialogAffirmativeButtonComponent {
  @Input() disabled = false;
  @Input() label = 'Save and close';
  @Input() closeOnSave = true;
  @Output() clicked = new EventEmitter();
}
