@if(!errorsProcessingData){
<div class="title">Importing</div>
<div class="spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="100" class="background-spinner">
  </mat-progress-spinner>
  <mat-progress-spinner
    [mode]="mode"
    [value]="percentage"
    class="percentage-spinner"
  >
  </mat-progress-spinner>
</div>

<div class="percentage-text">
  <span class="percentage-text">{{ percentage }}%</span>
</div>
<div class="message-container">
  <span class="summary-text">
    Importing large files may take a moment. Thanks for your patience
  </span>
</div>
<div class="summary-text-container">
  <div class="processed-records-text-container">
    <span class="summary-text"
      >{{ processedRecords }} of {{ totalRecords }} processed</span
    >
  </div>
</div>
} @else{
<div class="title title-error">Error</div>
<div class="error-spinner-container">
  <mat-progress-spinner [mode]="mode" [value]="100" class="error-spinner">
  </mat-progress-spinner>
  <div class="error-text">
    <span>N/A</span>
  </div>
</div>
}
