import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Scenario } from 'src/app/core/models/scenario';
import * as fromStore from 'src/app/spatial-modeller-store';

import { getScenarioState } from 'src/app/spatial-modeller-store';

import {
  clearDeltaSupplyPointsState,
  getDeltaSupplyPointsForListView,
  moveDeltaSupplyPointsClicked
} from 'src/app/spatial-modeller-store/actions/scenario.actions';
import { supplyPointDeselectAttempt } from 'src/app/spatial-modeller-store/actions/spatial-modeller-test.actions';
import { ScenarioService } from '../../services/scenario.service';

import * as fromScenario from 'src/app/spatial-modeller-store/reducers/scenario.reducer';

@Component({
  selector: 'atlas-delta-supply-points-workspace',
  templateUrl: './delta-supply-points-workspace.component.html',
  styleUrls: ['./delta-supply-points-workspace.component.less']
})
export class DeltaSupplyPointsWorkspaceComponent implements OnInit, OnDestroy {
  constructor(
    private route: Router,
    private store$: Store<fromStore.State>,
    private scenarioService: ScenarioService
  ) {}

  private subscription = new Subscription();
  public selectedSupplyIds: string[] = [];

  userScenarios: Scenario[] = [];

  deltaSupplyPoints$ = this.store$.select(
    fromStore.getFlattenDeltaSupplyPoints
  );

  ngOnInit(): void {
    this.store$.dispatch(supplyPointDeselectAttempt());
    this.store$.dispatch(getDeltaSupplyPointsForListView());
    this.getCurrentScenario();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.store$.dispatch(clearDeltaSupplyPointsState());
  }

  private getCurrentScenario() {
    this.subscription.add(
      this.store$
        .select(getScenarioState)
        .subscribe((scenario: fromScenario.State) =>
          this.getAllScenariosExcludingCurrentScenario({
            name: scenario.name,
            id: scenario.id,
            createDt: scenario.createDt!,
            editedDt: scenario.editedDt!,
            base: scenario.base,
            delta: scenario.delta,
            tests: scenario.tests,
            dependentScenarios: scenario.dependentScenarios
          })
        )
    );
  }
  private getAllScenariosExcludingCurrentScenario(currentScenario: Scenario) {
    this.subscription.add(
      this.scenarioService
        .getAllScenarios()
        .subscribe((scenarios: Scenario[]) => {
          // remove current scenario
          scenarios.forEach((value: Scenario, index: number) => {
            if (value.id == currentScenario.id) scenarios.splice(index, 1);
          });

          this.userScenarios = scenarios;
        })
    );
  }

  onBack(event: Event) {
    this.route.navigate(['/home']);
  }

  onMove(event: Scenario) {
    this.store$.dispatch(
      moveDeltaSupplyPointsClicked({
        toScenarioId: event.id,
        supplyIds: this.selectedSupplyIds
      })
    );
  }

  onDeltaLocationClickSelected(supplyIds: string[]) {
    this.selectedSupplyIds = supplyIds;
  }
}
